import React, { useState, useEffect, useRef, Fragment } from "react";
// import ContentLoader from "react-content-loader";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Chart } from "react-google-charts";
import FilterView from "./FilterView";
import RadioButton from "./RadioButton";
import PropagateLoader from "react-spinners/PropagateLoader";
import { Popover, Checkbox } from "antd";
import {
  actionGetOptions,
  actionSetIndicatorData,
  actionGetDataByFilter,
} from "../../redux/actions/indicatorAction";
import moment from "moment";
import CustomSelect from "./CustomSelect";
const _ = require("lodash");
const numeral = require("numeral");
const MONTHS = [
  "Janv.",
  "Févr.",
  "Mars",
  "Avr.",
  "Mai",
  "Juin",
  "Juill.",
  "Août",
  "Sept.",
  "Oct.",
  "Nov.",
  "Déc.",
];

let theme2 = "0";
const data2 = localStorage.getItem("theme");

if (data2) {
  theme2 = JSON.parse(data2).theme;
}

/**
 * Cette fonction permet de regrouper des données en jour, mois ou année selon l'option
 * @param {*} rows est un tableau contenant les données deja en format jour
 * @param {*} option définit sous quelle forme doit être regrouper les données  1 => jour, 2 => mois, 3 => année
 */
export const regroupe = (rows, option) => {
  if (option == "1") {
    return rows;
  }
  if (rows.length < 1) {
    return [];
  }
  let debut = rows[0][0];
  let fin = rows[rows.length - 1][0];

  switch (option) {
    case "2":
      if (
        debut.getFullYear() === fin.getFullYear() &&
        debut.getMonth() === fin.getMonth()
      ) {
        let total = 0;
        for (let i = 0; i < rows.length; i++) {
          const element = rows[i];
          total += element[1];
        }
        return [[MONTHS[debut.getMonth()], total]];
      } else {
        let result = [];
        let is = debut.getFullYear() === fin.getFullYear();
        const years = _.groupBy(rows, (e) => e[0].getFullYear());
        for (const key in years) {
          if (Object.hasOwnProperty.call(years, key)) {
            const year = years[key];
            const month = _.groupBy(year, (e) => e[0].getMonth());
            for (const tag in month) {
              let total = 0;
              if (Object.hasOwnProperty.call(month, tag)) {
                const days = month[tag];
                for (let i = 0; i < days.length; i++) {
                  const item = days[i];
                  total += item[1];
                }
              }
              result.push([
                is ? `${MONTHS[tag]}` : `${MONTHS[tag]} ${key}`,
                total,
              ]);
            }
          }
        }
        return result;
      }
      break;
    case "3":
      if (debut.getFullYear() === fin.getFullYear()) {
        let total = 0;
        for (let i = 0; i < rows.length; i++) {
          const element = rows[i];
          total += element[1];
        }
        return [[debut.getFullYear(), total]];
      } else {
        let result = [];
        let years = _.groupBy(rows, (e) => e[0].getFullYear());
        for (const key in years) {
          let total = 0;
          if (Object.hasOwnProperty.call(years, key)) {
            const days = years[key];
            for (let i = 0; i < days.length; i++) {
              const item = days[i];
              total += item[1];
            }
            result.push([key, total]);
          }
        }
        return result;
      }
      break;
    case "4":
      let data = _.groupBy(rows, (item) => {
        let current = moment(item[0]);
        current = current.startOf("isoWeek");
        return current.year() + "-" + current.isoWeek();
      });
      let response = [];
      for (const key in data) {
        if (Object.hasOwnProperty.call(data, key)) {
          const element = data[key];
          let total = 0;
          for (let index = 0; index < element.length; index++) {
            const item = element[index];
            total += item[1];
          }
          let first = moment(element[0][0]);
          first = first.startOf("isoWeek");

          let last = moment(element[0][0]);
          last = last.endOf("isoWeek");

          if (debut.getFullYear() === fin.getFullYear()) {
            response.push([
              `${first.date()} ${MONTHS[first.month()]} - ${last.date()} ${
                MONTHS[last.month()]
              }`,
              total,
            ]);
          } else {
            response.push([
              `${first.date()} ${
                MONTHS[first.month()]
              } ${first.year()} - ${last.date()} ${
                MONTHS[last.month()]
              } ${last.year()}`,
              total,
            ]);
          }
        }
      }
      return response;
      break;
  }
  return [];
};

export const regroupeByInterval = (rows, option) => {
  if (rows.length < 1) {
    return [];
  }
  let debut = rows[0][0];
  let fin = rows[rows.length - 1][0];

  switch (option) {
    case "2":
      if (
        debut.getFullYear() === fin.getFullYear() &&
        debut.getMonth() === fin.getMonth()
      ) {
        let total = 0;
        for (let i = 0; i < rows.length; i++) {
          const element = rows[i];
          total += element[1];
        }
        return [
          {
            month: debut.getMonth(),
            year: debut.getFullYear(),
            debut: null,
            fin: null,
          },
        ];
      } else {
        let result = [];
        let is = debut.getFullYear() === fin.getFullYear();
        const years = _.groupBy(rows, (e) => e[0].getFullYear());
        for (const key in years) {
          if (Object.hasOwnProperty.call(years, key)) {
            const year = years[key];
            const month = _.groupBy(year, (e) => e[0].getMonth());
            for (const tag in month) {
              let total = 0;
              if (Object.hasOwnProperty.call(month, tag)) {
                const days = month[tag];
                for (let i = 0; i < days.length; i++) {
                  const item = days[i];
                  total += item[1];
                }
              }
              // result.push([
              //   is ? `${MONTHS[tag]}` : `${MONTHS[tag]} ${key}`,
              //   total,
              // ]);
              result.push({ month: tag, year: key, debut: null, fin: null });
            }
          }
        }
        return result;
      }
      break;
    case "3":
      if (debut.getFullYear() === fin.getFullYear()) {
        let total = 0;
        for (let i = 0; i < rows.length; i++) {
          const element = rows[i];
          total += element[1];
        }
        return [
          { month: null, year: debut.getFullYear(), debut: null, fin: null },
        ];
      } else {
        let result = [];
        let years = _.groupBy(rows, (e) => e[0].getFullYear());
        for (const key in years) {
          let total = 0;
          if (Object.hasOwnProperty.call(years, key)) {
            const days = years[key];
            for (let i = 0; i < days.length; i++) {
              const item = days[i];
              total += item[1];
            }
            // result.push([key, total]);
            result.push({ month: null, year: key, debut: null, fin: null });
          }
        }
        return result;
      }
      break;
    case "4":
      let data = _.groupBy(rows, (item) => {
        let current = moment(item[0]);
        current = current.startOf("isoWeek");
        return current.year() + "-" + current.isoWeek();
      });
      let response = [];
      for (const key in data) {
        if (Object.hasOwnProperty.call(data, key)) {
          const element = data[key];
          let total = 0;
          for (let index = 0; index < element.length; index++) {
            const item = element[index];
            total += item[1];
          }
          let first = moment(element[0][0]);
          first = first.startOf("isoWeek");

          let last = moment(element[0][0]);
          last = last.endOf("isoWeek");

          if (debut.getFullYear() === fin.getFullYear()) {
            // response.push([
            //   `${first.date()} ${MONTHS[first.month()]} - ${last.date()} ${
            //     MONTHS[last.month()]
            //   }`,
            //   total,
            // ]);
            response.push({
              month: null,
              year: null,
              debut: new Date(first.format("YYYY-MM-DD")).getTime(),
              fin: new Date(last.format("YYYY-MM-DD")).getTime(),
            });
          } else {
            // response.push([
            //   `${first.date()} ${
            //     MONTHS[first.month()]
            //   } ${first.year()} - ${last.date()} ${
            //     MONTHS[last.month()]
            //   } ${last.year()}`,
            //   total,
            // ]);
            response.push({
              month: null,
              year: null,
              debut: new Date(first.format("YYYY-MM-DD")).getTime(),
              fin: new Date(last.format("YYYY-MM-DD")).getTime(),
            });
          }
        }
      }
      return response;
      break;
  }
  return [];
};

// const colors = [
//   // "#d02173",
//   theme2 === "2" ? "#007FC1" : "#d02173",
//   "#e22749",
//   "#29B6F6",
//   "#2979FF",
//   "#C62828",
//   "#F50057",
//   "#AB47BC",
//   "#4527A0",
//   "#FFA726",
//   "#EF5350",
//   "#FF3D00",
//   "#0277BD",
//   "#C6FF00",
//   "#EF6C00",
//   "#AD1457",
//   "#00B0FF",
//   "#D4E157",
//   "#283593",
//   "#3D5AFE",
//   "#6A1B9A",
//   "#42A5F5",
//   "#5C6BC0",
//   "#9E9D24",
//   "#FF7043",
//   "#651FFF",
//   "#7E57C2",
//   "#FF9100",
//   "#FF1744",
//   "#1565C0",
//   "#D500F9",
//   "#FF3D00",
//   "#EC407A",
// ];
const options = [
  { label: "Jour", value: "1" },
  { label: "Semaine", value: "4" },
  { label: "Mois", value: "2" },
  { label: "Année", value: "3" },
];

let theme = "1";
const data = localStorage.getItem("theme");

if (data) {
  theme = JSON.parse(data).theme;
}
export const numberFormat = (nbre) => {
  let response = numeral(nbre)
    .format("0,0.0")
    .split(",")
    .join(" ")
    .split(".")
    .join(",");

  if (response.split(",0").length > 1) {
    return response.split(",0")[0];
  }
  return response;
};

export default function IndicatorView({ large, viewRef }) {
  const userEmail = useSelector((state) => state.user.email);
  const interval = useSelector((state) => state.indicator.interval);
  const intervalRef = useRef();
  const printRef = useRef();
  intervalRef.current = interval;
  const indicator = useSelector((state) => state.indicator.indicator);
  const indicatorRef = useRef();
  indicatorRef.current = indicator;
  const isGet = useSelector((state) => state.indicator.isGet);
  const filters = useSelector((state) => state.indicator.filters);
  const filtersRef = useRef();
  filtersRef.current = filters;
  const axes = useSelector((state) => state.indicator.axes);
  const axesOptions = useRef();
  axesOptions.current = axes;
  const rows = useSelector((state) => state.indicator.rows);
  const data = useSelector((state) => state.indicator.data);
  const dataRef = useRef();
  dataRef.current = data;
  const columns = useSelector((state) => state.indicator.columns);
  const colsRef = useRef();
  colsRef.current = columns;

  const timeInterval = useSelector((state) => state.time.interval);
  const timeValue = useSelector((state) => state.time.value);

  const [axe, setAxe] = useState(undefined);
  const [value, setValue] = useState("1");
  const [isDate, setIsDate] = useState(true);
  const [total, setTotal] = useState(0);
  const [avg, setAvg] = useState(0);
  const [counter, setCounter] = useState(0); //ajout du compteur

  const [lastValue, setLastValue] = useState(0);
  const [mode, setMode] = useState(1);
  const modeRef = useRef();
  modeRef.current = mode;

  const [modeValue, setModeValue] = useState(undefined);
  const axeRef = useRef();
  axeRef.current = axe;
  // const [filterOptions, setFilterOptions] = useState(undefined);

  const dispatch = useDispatch();

  useEffect(() => {
    if (data) {
      let total = 0;
      let avg = 0;
      let counter = 0;

      for (let c = 0; c < data.length; c++) {
        const row = data[c];
        total += parseInt(row.value);
        avg += parseInt(row.avg);
        counter += parseInt(row.count);
      }

      if (
        indicatorRef.current &&
        parseInt(indicatorRef.current.operation) === 3
      ) {
        if (data.length > 0) {
          setTotal(parseInt(data[data.length - 1].value));
        } else {
          setTotal(0);
        }
      } else {
        setTotal(total);
      }

      setAvg(avg / data.length);
      setCounter(counter);
    }
  }, [data]);

  useEffect(() => {
    if (indicator) {
      const columnsLanda = indicator.cols;
      let rowsLanda = indicator.rows;
      const dataLanda = indicator.data;
      let filtersLanda = [];

      if (parseInt(indicator.operation) === 2) {
        setMode(2);
        rowsLanda = [];
        let axe = null;
        for (
          let index = 0;
          index < indicatorRef.current.columns.length;
          index++
        ) {
          const element = indicatorRef.current.columns[index];
          if (parseInt(element.idcolumn) === parseInt(indicator.iddefault)) {
            axe = element;
            break;
          }
        }
        if (!axe) {
          for (
            let index = 0;
            index < indicatorRef.current.columns.length;
            index++
          ) {
            const element = indicatorRef.current.columns[index];
            if (parseInt(element.idcolumn) === parseInt(indicator.idfilter)) {
              axe = element;
              break;
            }
          }
        }
        if (indicator.data) {
          for (let c = 0; c < indicator.data.length; c++) {
            const row = indicator.data[c];
            if (parseInt(axe.dataType) === 3) {
              rowsLanda.push([new Date(row._id), row.avg]);
            } else {
              rowsLanda.push([row._id, row.avg]);
            }
          }
          setModeValue({
            label: `${_.round(indicator.avg)} ~ Moyenne`,
            value: 2,
          });
        }
      } else {
        setMode(1);
        setModeValue({ label: `${indicator.total} ~ Cumul`, value: 1 });
      }

      const axesLanda = _.filter(indicator.columns, (o) => {
        return o.columnType !== "indicator";
      });

      for (let i = 0; i < indicator.columns.length; i++) {
        const element = indicator.columns[i];
        if (
          !(
            +indicator.idEmailColumn === +element.idcolumn &&
            indicator.showByEmail
          )
        ) {
          filtersLanda.push({
            id: element.idcolumn,
            name: element.name,
            type: parseInt(element.dataType),
            checked: element.idcolumn == indicator.idfilter ? true : false,
            indicator: Boolean(element.columnType === "indicator"),
            min: null,
            max: null,
            loading: parseInt(element.dataType) === 2 ? true : false,
            value: null,
            values: [],
            options: [],
            interval:
              element.idcolumn == indicator.idfilter
                ? [intervalRef.current[0], intervalRef.current[1]]
                : [null, null],
          });
        }

        if (parseInt(element.dataType) === 2) {
          dispatch(
            actionGetOptions(
              element.iddatasource,
              element.name,
              (status, result) => {
                if (status === 200) {
                  let alpha = [...filtersRef.current];
                  for (let j = 0; j < alpha.length; j++) {
                    const item = alpha[j];
                    if (parseInt(item.id) === parseInt(element.idcolumn)) {
                      alpha[j]["options"] = result;
                      alpha[j]["loading"] = false;
                      dispatch(
                        actionSetIndicatorData({
                          filters: [...alpha],
                        })
                      );
                    }
                  }
                }
              }
            )
          );
        }
      }

      let betaAxes = [];
      for (let j = 0; j < axesLanda.length; j++) {
        const element = axesLanda[j];
        betaAxes.push({ value: element.idcolumn, label: element.name });
      }

      dispatch(
        actionSetIndicatorData({
          data: dataLanda,
          rows: rowsLanda,
          columns: columnsLanda,
          axes: [...betaAxes],
          filters: filtersLanda,
        })
      );
      setTimeout(() => {
        if (indicator.showperiode) {
          onChange(indicator.periode);
          setValue(indicator.periode);
        }
      }, 50);

      for (let index = 0; index < betaAxes.length; index++) {
        const element = betaAxes[index];
        if (parseInt(element.value) === parseInt(indicator.iddefault)) {
          setAxe(element);
          break;
        }
      }
      setLastValue(indicator.lastValue ? indicator.lastValue : 0);
    }
  }, [indicator]);

  useEffect(() => {
    setModeValue(
      mode === 1
        ? { label: `${total} ~ Cumul`, value: 1 }
        : { label: `${avg ? _.round(avg) : 0} ~ Moyenne`, value: 2 }
    );
  }, [total, avg]);

  useEffect(() => {
    if (axe) {
      for (
        let index = 0;
        index < indicatorRef.current.columns.length;
        index++
      ) {
        const element = indicatorRef.current.columns[index];
        if (parseInt(element.idcolumn) === parseInt(axeRef.current.value)) {
          setIsDate(Boolean(parseInt(element.dataType) === 3));
          break;
        }
      }
    }
  }, [axe]);

  const renderLastValue = () => {
    const pourcentage = ((total - lastValue) * 100) / lastValue;

    const periode = [
      "Hier",
      "La semaine dernière",
      "Le mois dernier",
      "Le trimestre dernier",
      "Le semestre dernier",
      "L'année dernière",
    ];

    let text = "";

    const nbre = parseInt(timeValue) - 1;

    if (nbre >= 0) {
      text = periode[nbre];
    } else {
      text = `du ${new Date(timeInterval[0]).toISOString().split("T")[0]} au ${
        new Date(timeInterval[1]).toISOString().split("T")[0]
      }`;
    }

    if (total > lastValue) {
      return (
        <span
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img alt="haut" src="/haut.png" height={"20px"} />
          <i
            style={{
              color: "#2ECC71",
              fontStyle: "normal",
              fontWeight: "bold",
              fontSize: "15px",
              display: "inline-block",
              marginLeft: "10px",
            }}
          >
            {numberFormat(pourcentage)}%{" "}
            <i
              style={{
                color: parseInt(theme) === 0 ? "#FFF" : "#000",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "12px",
                display: "inline-block",
                marginLeft: "10px",
              }}
            >
              {text}
            </i>
          </i>
        </span>
      );
    } else {
      return (
        <span
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img alt="bas" src="/bas.png" height={"20px"} />
          <i
            style={{
              color: "#E74C3C",
              fontStyle: "normal",
              fontWeight: "bold",
              fontSize: "15px",
              display: "inline-block",
              marginLeft: "10px",
            }}
          >
            {numberFormat(pourcentage)}%{" "}
            <i
              style={{
                color: parseInt(theme) === 0 ? "#FFF" : "#000",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "12px",
                display: "inline-block",
                marginLeft: "10px",
              }}
            >
              {text}
            </i>
          </i>
        </span>
      );
    }
  };

  const handlerChangeAxe = (e) => {
    setValue("1");
    setAxe(e);
    setTimeout(() => {
      let axe = null;
      for (
        let index = 0;
        index < indicatorRef.current.columns.length;
        index++
      ) {
        const element = indicatorRef.current.columns[index];
        if (parseInt(element.idcolumn) === parseInt(axeRef.current.value)) {
          axe = element;
          break;
        }
      }

      let metric = null;
      for (
        let index = 0;
        index < indicatorRef.current.columns.length;
        index++
      ) {
        const element = indicatorRef.current.columns[index];
        if (
          parseInt(element.idcolumn) === parseInt(indicatorRef.current.idaxe)
        ) {
          metric = element;
          break;
        }
      }
      let columns = [];
      columns.push({
        type:
          axe.dataType == 1 || axe.dataType == 4
            ? "number"
            : axe.dataType == 3
            ? "datetime"
            : "string",
        label: axe.name,
      });

      columns.push({
        type:
          metric.dataType == 1 || metric.dataType == 4
            ? "number"
            : metric.dataType == 3
            ? "datetime"
            : "number",
        label: metric.name,
      });

      dispatch(
        actionSetIndicatorData({
          columns: [...columns],
        })
      );
      synchronazeData();
    }, 100);
  };

  const generateInterval = () => {
    if (dataRef.current && dataRef.current.length > 0) {
      let rows = [];
      for (let c = 0; c < dataRef.current.length; c++) {
        const row = dataRef.current[c];
        rows.push([new Date(row._id), row.value]);
      }
      return regroupeByInterval([...rows], value);
    }
  };

  const synchronazeData = () => {
    setValue("1");
    let alphas = [];
    for (let index = 0; index < filtersRef.current.length; index++) {
      const element = filtersRef.current[index];
      if (element.checked) {
        if (element.type == "1" && (element.min || element.max)) {
          alphas.push({
            name: element.name,
            type: 1,
            class: element.indicator ? "metrics" : "dimensions",
            min: element.min,
            max: element.max,
          });
        } else if (element.type == "2" && element.values.length) {
          alphas.push({
            name: element.name,
            type: 2,
            class: "dimensions",
            values: element.values,
          });
        } else if (
          element.type == "3" &&
          (element.interval[0] || element.interval[1])
        ) {
          alphas.push({
            name: element.name,
            type: 3,
            class: "time",
            interval: [
              element.interval[0] ? new Date(element.interval[0]) : null,
              element.interval[1] ? new Date(element.interval[1]) : null,
            ],
          });
        }
      }
    }

    let axe = null;
    for (let index = 0; index < indicatorRef.current.columns.length; index++) {
      const element = indicatorRef.current.columns[index];
      if (parseInt(element.idcolumn) === parseInt(axeRef.current.value)) {
        axe = element;
        break;
      }
    }

    let emailAxe = null;
    if (
      indicatorRef.current.idEmailColumn &&
      indicatorRef.current.showByEmail
    ) {
      for (
        let index = 0;
        index < indicatorRef.current.columns.length;
        index++
      ) {
        const element = indicatorRef.current.columns[index];
        if (
          parseInt(element.idcolumn) ===
          parseInt(indicatorRef.current.idEmailColumn)
        ) {
          emailAxe = element;
          break;
        }
      }
    }

    if (emailAxe) {
      alphas.push({
        name: emailAxe.name,
        type: 2,
        class: "dimensions",
        values: [userEmail],
      });
    }

    let metric = null;
    for (let index = 0; index < indicatorRef.current.columns.length; index++) {
      const element = indicatorRef.current.columns[index];
      if (parseInt(element.idcolumn) === parseInt(indicatorRef.current.idaxe)) {
        metric = element;
        break;
      }
    }

    if (parseInt(axe.dataType) === 3) {
      let col = [...colsRef.current];
      col[0].type = "datetime";
      dispatch(
        actionSetIndicatorData({
          columns: [...col],
        })
      );
    }

    dispatch(
      actionSetIndicatorData({
        isGet: true,
      })
    );
    dispatch(
      actionGetDataByFilter(
        metric.iddatasource,
        metric.name,
        {
          name: axe.name,
          class: parseInt(axe.dataType) === 3 ? "time" : "dimensions",
        },
        alphas,
        (status, result) => {
          if (status === 200) {
            let rows = [];
            switch (parseInt(indicatorRef.current.operation)) {
              case 1 || 3:
                for (let c = 0; c < result.length; c++) {
                  const row = result[c];
                  if (parseInt(axe.dataType) === 3) {
                    rows.push([new Date(row._id), row.value]);
                  } else {
                    rows.push([row._id, row.value]);
                  }
                }
                break;

              case 2:
                for (let c = 0; c < result.length; c++) {
                  const row = result[c];
                  if (parseInt(axe.dataType) === 3) {
                    rows.push([new Date(row._id), row.avg]);
                  } else {
                    rows.push([row._id, row.avg]);
                  }
                }
                break;

              case 4:
                for (let c = 0; c < result.length; c++) {
                  const row = result[c];
                  if (parseInt(axe.dataType) === 3) {
                    rows.push([new Date(row._id), row.count]);
                  } else {
                    rows.push([row._id, row.count]);
                  }
                }
                break;

              default:
                for (let c = 0; c < result.length; c++) {
                  const row = result[c];
                  if (parseInt(axe.dataType) === 3) {
                    rows.push([new Date(row._id), row.value]);
                  } else {
                    rows.push([row._id, row.value]);
                  }
                }
            }

            dispatch(
              actionSetIndicatorData({
                data: result,
                rows: rows,
                isGet: false,
              })
            );
          }
        }
      )
    );
  };

  const onChange = (value) => {
    if (dataRef.current && dataRef.current.length > 0) {
      let col = [...colsRef.current];
      if (parseInt(value) === 1) {
        col[0].type = "datetime";
      } else {
        col[0].type = "string";
      }
      let rows = [];
      for (let c = 0; c < dataRef.current.length; c++) {
        const row = dataRef.current[c];
        rows.push([new Date(row._id), row.value]);
      }
      rows = regroupe([...rows], value);
      dispatch(
        actionSetIndicatorData({
          columns: [...col],
          rows,
        })
      );
    }
  };

  return (
    <div className="" style={{ cursor: "default", paddingBottom: "30px" }}>
      <div className="wrap-flex filtres">
        <div className="row wrap-inputs">
          <div
            className="col-md-6"
            style={{ display: "flex", flexDirection: "row", minWidth: "350px" }}
          >
            <label
              htmlFor=""
              className="label-default"
              style={{ marginTop: "13px" }}
            >
              Filtres
            </label>
            <FilterView filtersOptions={filters} />
          </div>
        </div>
      </div>
      <div className="filtres-actions">
        <ul>
          <li>
            <Popover
              placement={"bottom"}
              content={
                <div>
                  {!filters
                    ? null
                    : filters.map((e, index) => (
                        <p>
                          <Checkbox
                            checked={filters[index].checked}
                            onClick={() => {
                              let landa = [...filters];
                              landa[index].checked = !filters[index].checked;
                              dispatch(
                                actionSetIndicatorData({
                                  filters: [...landa],
                                })
                              );
                            }}
                          >
                            <span
                              style={{
                                color: "#1f2128",
                              }}
                              className="color-black"
                            >
                              {e.name}
                            </span>
                          </Checkbox>
                        </p>
                      ))}
                </div>
              }
              title="Ajouter un filtre"
              trigger="click"
            >
              <Link to="">
                <i className="fa-light fa-plus"></i>
              </Link>
            </Popover>
          </li>
          <li>
            <Link to="" onClick={synchronazeData}>
              <i className="fa-light fa-arrow-rotate-right"></i>
            </Link>
          </li>
        </ul>
      </div>
      <div className="wrap-flex">
        <div className="row wrap-select axes">
          <div
            className="col-md-4"
            style={{
              display: "flex",
              flexDirection: "row",
              paddingLeft: "13px",
            }}
          >
            <label htmlFor="axes-card" className="label-default">
              Axes
            </label>
            <div
              style={{
                display: "inline-block",
                minWidth: "197px",
                paddingLeft: "13px",
              }}
            >
              <CustomSelect
                id="axes-card"
                value={axe}
                options={axes}
                onChange={handlerChangeAxe}
                noSearchable
              />
            </div>
          </div>
        </div>
        <div className="options">
          {indicator &&
          parseInt(indicator.operation) !== 3 &&
          isDate &&
          modeRef.current === 1 ? (
            <RadioButton
              onChange={onChange}
              defaultValue={"1"}
              value={value}
              setValue={setValue}
              options={options}
            />
          ) : null}
        </div>
      </div>
      <div
        ref={viewRef}
        style={{
          background: !viewRef
            ? "transparent"
            : theme === "0"
            ? "#232731"
            : "#f5f5f5",
        }}
      >
        <div style={{ width: "100%", textAlign: "center", marginTop: 10 }}>
          <div style={{ width: "200px", margin: "auto" }}>
            <p
              className="chiffre"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                fontSize: "28px",
              }}
            >
              {indicator && parseInt(indicator.operation) === 1
                ? numberFormat(total)
                : indicator && parseInt(indicator.operation) === 2
                ? numberFormat(avg)
                : indicator && parseInt(indicator.operation) === 4
                ? numberFormat(counter)
                : numberFormat(total)}
              {indicator &&
              indicator.symbole !== null &&
              indicator.symbole !== ""
                ? ` ${indicator.symbole} `
                : " "}
            </p>
          </div>
        </div>
        <div className="gchart">
          {isGet || !indicator || !columns ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "100px",
              }}
            >
              <PropagateLoader
                color={
                  theme === "0" ? "#fff" : theme === "1" ? "#1f2128" : "#1f2128"
                }
                loading={true}
                size={5}
                speedMultiplier={0.75}
              />
            </div>
          ) : (
            <Chart
              width={"100%"}
              chartLanguage="fr"
              height={"100%"}
              chartType={
                indicator && indicator.type === "0"
                  ? "ComboChart"
                  : indicator && indicator.type === "1"
                  ? "LineChart"
                  : "PieChart"
              }
              loader={
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: "100px",
                  }}
                >
                  <PropagateLoader
                    // color={"#fff"}
                    color={
                      theme === "0"
                        ? "#fff"
                        : theme === "1"
                        ? "#1f2128"
                        : "#1f2128"
                    }
                    loading={true}
                    size={5}
                    speedMultiplier={0.75}
                  />
                </div>
              }
              columns={(() => {
                if (columns.length > 0 && indicator && indicator.type === "2") {
                  return [
                    { label: columns[0].label, type: "string" },
                    columns[1],
                  ];
                }
                if (
                  indicator &&
                  indicator.goals.length > 0 &&
                  parseInt(indicator.operation) === 1
                ) {
                  return [
                    ...columns,
                    { label: "cumul", type: "number" },
                    { label: "Objectif", type: "number" },
                  ];
                } else if (
                  indicator &&
                  indicator.goals.length > 0 &&
                  parseInt(indicator.operation) === 2
                ) {
                  return [...columns, { label: "Objectif", type: "number" }];
                } else if (
                  indicator.showcumul &&
                  parseInt(indicator.operation) !== 2
                ) {
                  return [...columns, { label: "cumul", type: "number" }];
                }
                return columns;
              })()}
              // columns={[...columns, {role: 'style', label: 'Style'}, {role: 'annotation', label: null}]}
              rows={(() => {
                if (indicator && indicator.type === "2") {
                  return rows;
                }
                if (indicator.goals.length > 0) {
                  let finish = [];
                  let total = 0;
                  let noPeriodique = _.sortBy(indicator.goals, [
                    (o) => o.startdate,
                  ]);
                  // let item = noPeriodique[0];
                  // const intervals = generateInterval();
                  for (let i = 0; i < rows.length; i++) {
                    const row = rows[i];

                    // const interval = intervals ? intervals[i] : null;
                    let item = null;
                    for (let i = 0; i < noPeriodique.length; i++) {
                      const goal = noPeriodique[i];
                      let start = new Date(goal.startdate).getTime();
                      let end = new Date(goal.enddate).getTime();
                      let current = new Date(row[0]).getTime();
                      if (current >= start && current <= end) {
                        item = goal;
                      }
                      // if (value === "1") {
                      //   let start = new Date(goal.startdate).getTime();
                      //   let end = new Date(goal.enddate).getTime();
                      //   let current = new Date(row[0]).getTime();
                      //   if (current >= start && current <= end) {
                      //     item = goal;
                      //   }
                      // } else if (value === "2") {
                      //   // for month
                      //   let start = new Date(goal.startdate);
                      //   let end = new Date(goal.enddate);
                      //   if (
                      //     (start.getMonth() == interval.month &&
                      //       start.getFullYear() == interval.year) ||
                      //     (end.getMonth() == interval.month &&
                      //       end.getFullYear() == interval.year)
                      //   ) {
                      //     item = goal;
                      //   }
                      // } else if (value === "3") {
                      //   // for year
                      //   let start = new Date(goal.startdate);
                      //   let end = new Date(goal.enddate);
                      //   if (
                      //     start.getFullYear() === interval.year ||
                      //     end.getFullYear() === interval.year
                      //   ) {
                      //     item = goal;
                      //   }
                      // } else if (value === "4") {
                      //   // for week
                      //   let start = new Date(goal.startdate).getTime();
                      //   let end = new Date(goal.enddate).getTime();
                      //   let current = interval.fin;
                      //   if (current >= start && current <= end) {
                      //     item = goal;
                      //   }
                      // }
                    }
                    total += row[1];
                    if (parseInt(indicator.operation) === 1) {
                      finish.push([
                        ...row,
                        total,
                        item ? item.targetvalue : null,
                      ]);
                    } else {
                      finish.push([...row, item ? item.targetvalue : null]);
                    }
                  }
                  return finish;
                } else if (
                  indicator.showcumul &&
                  parseInt(indicator.operation) !== 2
                ) {
                  let finish = [];
                  let total = 0;
                  for (let i = 0; i < rows.length; i++) {
                    const row = rows[i];
                    total += row[1];
                    finish.push([...row, total]);
                  }
                  return finish;
                }
                return rows;
              })()}
              options={{
                chartArea: {
                  height:
                    indicator && parseInt(indicator.type) === 2
                      ? "100%"
                      : "60%",
                  width:
                    indicator && parseInt(indicator.type) === 2
                      ? "100%"
                      : "80%",
                },
                backgroundColor: "transparent",
                colors: [
                  "#05BAF3",
                  // theme2 === "2" ? "#007FC1" : "#d02173",
                  ...(indicator && parseInt(indicator.operation) === 2
                    ? ["#25F700", "blue"]
                    : ["blue", "#25F700"]),
                  "#2979FF",
                  "#C62828",
                  "#F50057",
                  "#AB47BC",
                  "#4527A0",
                  "#FFA726",
                  "#EF5350",
                  "#FF3D00",
                  "#0277BD",
                  "#C6FF00",
                  "#EF6C00",
                  "#AD1457",
                  "#00B0FF",
                  "#D4E157",
                  "#283593",
                  "#3D5AFE",
                  "#6A1B9A",
                  "#42A5F5",
                  "#5C6BC0",
                  "#9E9D24",
                  "#FF7043",
                  "#651FFF",
                  "#7E57C2",
                  "#FF9100",
                  "#FF1744",
                  "#1565C0",
                  "#D500F9",
                  "#FF3D00",
                  "#EC407A",
                ],
                annotations: {
                  textStyle: {
                    // fontName: 'Times-Roman',
                    fontSize: 18,
                    // bold: true,
                    // italic: true,
                    // The color of the text.
                    color:
                      theme === "0"
                        ? "#FFF"
                        : theme === "1"
                        ? "#1f2128"
                        : "#1f2128",
                    // "white",
                    // The color of the text outline.
                    auraColor:
                      theme === "0"
                        ? "#ffffff"
                        : theme === "1"
                        ? "#1f2128"
                        : "#1f2128",
                    // "#ffffff",
                    // The transparency of the text.
                    // opacity: 1
                  },
                },
                pieSliceText: "label",
                legend:
                  indicator && parseInt(indicator.type) === 2
                    ? {
                        position: "right",
                        textStyle: {
                          color: theme === "0" ? "#FFF" : "#000",
                          fontSize: 12,
                        },
                      }
                    : {
                        position: "none",
                      },
                ...(indicator && indicator.type === "0"
                  ? {
                      seriesType: "bars",
                      series: {
                        1: { type: "line" },
                        2: { type: "line" },
                      },
                    }
                  : {}),
                // trendlines: {
                //   0: {
                //     type: "linear",
                //     // type: "exponential",
                //     // type: "polynomial",
                //     // degree: 2,
                //     color: "green",
                //     // color: "#e22749",
                //     // opacity: 1,
                //     // pointsVisible: true
                //   },
                //   // n: {
                //   //   pointsVisible: false,
                //   // },
                // },

                hAxis: {
                  gridlines: {
                    // count: 0,
                    color: "transparent",
                  },
                  minorGridlines: {
                    count: 0,
                  },
                  textStyle: {
                    // color: theme === 0 ? "#FFF" : "",
                    color:
                      theme === "0"
                        ? "#FFF"
                        : theme === "1"
                        ? "black"
                        : "black",
                  },
                },
                vAxis: {
                  baselineColor: theme === "0" ? "#FFF" : "#000",
                  minorGridlines: {
                    count: 0,
                  },
                  // textStyle: {
                  //   color: "#FFF",
                  // },
                  textStyle: {
                    // color: theme === 0 ? "#FFF" : "",
                    color:
                      theme === "0"
                        ? "#FFF"
                        : theme === "1"
                        ? "#1f2128"
                        : "#1f2128",
                  },
                },
              }}
            />
          )}
        </div>
        {indicator &&
        indicator.goals &&
        indicator.type !== "2" &&
        indicator.goals.length > 0 ? (
          <div
            style={{
              height: "25px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p>
              <span
                style={{
                  display: "inline-block",
                  width: "10px",
                  height: "10px",
                  backgroundColor: "#25F700",
                }}
              ></span>{" "}
              <i style={{ fontSize: "15px" }}>Objectif</i>
            </p>

            {parseInt(indicator.operation) === 1 ? (
              <p>
                <span
                  style={{
                    display: "inline-block",
                    width: "10px",
                    height: "10px",
                    marginLeft: "10px",
                    backgroundColor: "blue",
                  }}
                ></span>{" "}
                <i style={{ fontSize: "15px" }}>Cumul</i>
              </p>
            ) : null}

            <p>
              <span
                style={{
                  display: "inline-block",
                  width: "10px",
                  height: "10px",
                  marginLeft: "10px",
                  backgroundColor: "#05BAF3",
                  // backgroundColor:
                  //   theme === "0" || theme === "1" ? "#d02173" : "#007FC1",
                }}
              ></span>{" "}
              <i style={{ fontSize: "15px" }}>Valeur</i>
            </p>
          </div>
        ) : null}

        {indicator &&
        indicator.showcumul &&
        parseInt(indicator.operation) !== 2 &&
        indicator.type !== "2" &&
        !(indicator.goals.length > 0) ? (
          <div
            style={{
              height: "25px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p>
              <span
                style={{
                  display: "inline-block",
                  width: "10px",
                  height: "10px",
                  marginLeft: "10px",
                  backgroundColor: "blue",
                }}
              ></span>{" "}
              <i style={{ fontSize: "15px" }}>Cumul</i>
            </p>

            <p>
              <span
                style={{
                  display: "inline-block",
                  width: "10px",
                  height: "10px",
                  marginLeft: "10px",
                  backgroundColor: "#05BAF3",
                }}
              ></span>{" "}
              <i style={{ fontSize: "15px" }}>Valeur</i>
            </p>
          </div>
        ) : null}

        {lastValue ? renderLastValue() : null}
      </div>

      {/* <div className="resume">
        <div className="goal">
          <p className="target">
            <i className="fa-light fa-bullseye-pointer"></i> 26
          </p>
          <p className="final positive">
            <i className="fa-solid fa-circle-arrow-up"></i> <span>22%</span>{" "}
            Above goal
          </p>
        </div>
        <div className="compare">
          <p className="last-month">
            <i className="fa-light fa-clock-rotate-left"></i> Last Month : 36
          </p>
          <p className="percent-compare negative">
            <i className="fa-solid fa-circle-arrow-down"></i> <span>-39%</span>{" "}
            Vs Last Month
          </p>
        </div>
      </div> */}
    </div>
  );
}
