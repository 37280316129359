import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { actionForgetPassword } from "../../redux/actions/UserAction";

export default function ForgetPassword() {
  const { t } = useTranslation();
  const loading = useSelector((state) => state.user.loading);
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const onSubmit = (e) => {
    e.preventDefault();
    if (email) {
      dispatch(
        actionForgetPassword(email, (status) => {
          if (status === 200) {
            alert("email was send");
          } else {
            alert("email should not send");
          }
        })
      );
    } else {
      alert("email is require");
    }
  };
  return (
    <main className="intro-pages">
      <section id="login">
        <div className="container">
          <div className="logo-container"></div>

          <div className="text-center subtitles">
            <span>Envoyer email de recupération de compte</span>
          </div>

          <form action="" className="login-form bg-default" onSubmit={onSubmit}>
            <div className="row">
              <div className="col-md-12">
                <div className="input-container">
                  <label for="email" className="label-default">
                    Votre email
                  </label>
                  <span className="span-block">
                    <input
                      className="input-default inputCustom"
                      name="email"
                      id="email"
                      type="email"
                      placeholder="Obligatoire"
                      required
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </span>
                  <Link className="link-small" to="/">
                    Connexion ?
                  </Link>
                </div>
              </div>
              <div className="col-md-12">
                <button className="btn-full" type="submit" disabled={loading}>
                  {loading ? "loading..." : "Envoyer email"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
    </main>
  );
}
