import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import host from "../../redux/actions/host";
import Modal from "./Modal";
import { useSelector, useDispatch } from "react-redux";
import { LinkOutlined } from "@ant-design/icons/lib/icons";
import { useSnackbar } from "notistack";
import CustomSelect from "./CustomSelect";
import styled from "styled-components";
import { ReactMultiEmail } from "react-multi-email";
import "react-multi-email/dist/style.css";
import { actionGetTeamsByUser } from "../../redux/actions/teamAction";
import { Checkbox } from "antd";

const _ = require("lodash");

export default function ModalShared({
  visibility,
  onCancel,
  dashboard,
  isViewOnly,
  token,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [link, setLink] = useState("");
  const [role, setRole] = useState({
    value: "VIEW",
    label: "Lecture seulement",
  });
  const [emails, setEmails] = useState([]);
  const [teamSelected, setTeamSelected] = useState([]);
  const [listGraphe, setListGraphe] = useState([]);

  const [isInvited, setInvited] = useState(false);
  const [isGenerated, setGenerated] = useState(false);
  const [fullAccess, setFullAccess] = useState(false);

  const idUser = useSelector((state) => state.user.id);
  const teams = useSelector((state) => state.team.teams);
  const lists = useSelector((state) => state.list.lists);

  useEffect(() => {
    dispatch(actionGetTeamsByUser(idUser, token));
  }, []);
  useEffect(() => {
    if (fullAccess) {
      setListGraphe([
        ...lists.map((item) => {
          return { value: +item.idlist, label: item.name };
        }),
      ]);
    } else {
      setListGraphe([]);
    }
  }, [fullAccess]);

  const onInvite = (e) => {
    e.preventDefault();
    if (
      (emails.length >= 1 || teamSelected.length >= 1) &&
      listGraphe.length >= 1
    ) {
      let myHeaders = new Headers();
      myHeaders.append("Authorization", token);
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        iddashboard: dashboard.iddashboard,
        role: role.value,
        emails: emails,
        teams: teamSelected.map((t) => t.value),
        lists: listGraphe.map((l) => l.value),
        fullAccess: fullAccess,
      });
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      fetch(`${host}/dashboard/invited`, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          cancel();
          enqueueSnackbar("Opération réalisée avec succès", {
            variant: "success",
            duration: 1000,
          });
        })
        .catch((error) =>
          enqueueSnackbar("Une erreur s'est produite", {
            variant: "error",
            duration: 1000,
          })
        );
    } else {
      enqueueSnackbar(
        listGraphe.length >= 1
          ? "Veillez soit sélectionner une équipe ou entrez un email"
          : "veillez sélectionner minimum une vue",
        {
          variant: "error",
          duration: 1000,
        }
      );
    }
  };

  const cancel = () => {
    setLink("");
    setRole({
      value: "VIEW",
      label: "Lecture seulement",
    });
    setEmails([]);
    setTeamSelected([]);
    setFullAccess(false);
    onCancel();
  };

  const onGenerate = () => {
    if (fullAccess ? true : listGraphe.length > 1) {
      setGenerated(true);
      let myHeaders = new Headers();
      myHeaders.append("Authorization", token);
      myHeaders.append("Content-Type", "application/json");
      let raw = JSON.stringify({
        iddashboard: dashboard.iddashboard,
        role: role.value,
        lists: JSON.stringify(listGraphe),
        fullAccess: fullAccess,
      });
      let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      fetch(`${host}/dashboard/invited-link`, requestOptions)
        .then((response) => {
          if (response.status != 200 && response.status != 201) {
            return null;
          }
          return response.json();
        })
        .then((result) => {
          if (result) {
            setLink(result.url);
            // message.color('re').success()
            // message.success("generated")
            enqueueSnackbar("generated", {
              variant: "success",
              autoHideDuration: 2000,
              anchorOrigin: { horizontal: "center", vertical: "top" },
            });
          } else {
            enqueueSnackbar("erreur", {
              variant: "erreur",
              autoHideDuration: 2000,
              anchorOrigin: { horizontal: "center", vertical: "top" },
            });
            // message.error("erreure");
          }
        })
        .catch(() => {})
        .then(() => {
          setGenerated(false);
        });
    } else {
      enqueueSnackbar("Veillez sélectionner au  minimum une vue", {
        variant: "error",
      });
    }
  };

  return (
    <Modal
      visible={visibility}
      title={"Partager"}
      onCancel={isInvited || isGenerated ? null : cancel}
    >
      <form onSubmit={onInvite}>
        <div className="row">
          <div className="col-md-12">
            <ColumnBox>
              <SmallBox>
                <Text className="label-default">Sélectionnez équipe(s)</Text>
                <CustomSelect
                  classStyle={"input-default"}
                  id="refresh"
                  style={{ with: "100%" }}
                  value={teamSelected}
                  options={teams.map((item) => {
                    return { value: item.idteam, label: item.name };
                  })}
                  onChange={(e) => setTeamSelected([...e])}
                  isMulti={true}
                />
              </SmallBox>
            </ColumnBox>
            <ColumnBox>
              <SmallBox>
                <Text className="label-default">
                  Entrez les adresses mails (Appuyer Entrer pour ajouter)
                </Text>
                <ReactMultiEmail
                  placeholder="Entrez les addresse mails"
                  emails={emails}
                  onChange={(email) => {
                    setEmails([...email]);
                  }}
                  getLabel={(email, index, removeEmail) => {
                    return (
                      <div data-tag key={index}>
                        {email}
                        <span
                          data-tag-handle
                          onClick={() => removeEmail(index)}
                          style={{
                            color: "#444",
                            fontWeight: "bold",
                            fontSize: 14,
                          }}
                        >
                          x
                        </span>
                      </div>
                    );
                  }}
                />
              </SmallBox>
            </ColumnBox>
          </div>
          <div
            className="col-md--12"
            style={{
              marginTop: 10,
              marginLeft: 14,
            }}
          >
            <ColumnBox style={{ marginBottom: 0 }}>
              <SmallBox>
                <Checkbox
                  checked={fullAccess}
                  onChange={(e) => {
                    setFullAccess(e.target.checked);
                  }}
                >
                  <Text className="label-default">Toutes les vues</Text>
                </Checkbox>
              </SmallBox>
            </ColumnBox>
          </div>
          <div className="col-md-12">
            <ColumnBox>
              <SmallBox>
                <Text className="label-default">Sélectionnez vue(s)</Text>
                <CustomSelect
                  status={fullAccess}
                  classStyle={"input-default"}
                  value={listGraphe}
                  onChange={(e) => {
                    if (Array.isArray(e) && e.length !== 0) {
                      if (e.find((a) => a.value === "all")) {
                        setListGraphe([]);
                        setListGraphe([
                          ...lists.map((item) => {
                            return { value: +item.idlist, label: item.name };
                          }),
                        ]);
                      } else {
                        setListGraphe([...e]);
                      }
                    } else {
                      setListGraphe([]);
                    }
                  }}
                  className="color-black"
                  options={[
                    { value: "all", label: "Toutes les vues" },
                    ...lists.map((item) => {
                      return { value: +item.idlist, label: item.name };
                    }),
                  ]}
                  isMulti={true}
                />
              </SmallBox>
            </ColumnBox>
          </div>
          <div className="col-md-12">
            <ColumnBox>
              <SmallBox>
                <Text className="label-default">Privilège</Text>
                <CustomSelect
                  id="refresh"
                  style={{ fontSize: "5px" }}
                  options={[
                    { value: "VIEW", label: "Lecture seulement" },
                    idUser && !isViewOnly
                      ? {
                          value: "ADMIN",
                          label: "Administrateur",
                        }
                      : null,
                  ]}
                  value={role}
                  onChange={(e) => {
                    setRole(e);
                  }}
                />
              </SmallBox>
            </ColumnBox>
          </div>
          <div className="col-md-12">
            <ColumnBox>
              <Text className="label-default" style={{ marginBottom: 0 }}>
                Lien
              </Text>
              <SmallBox
                style={{
                  // marginTop: 0,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignContent: "center",
                    alignItems: "center",
                    width: "70%",
                  }}
                >
                  <input
                    id="link-to-copy"
                    style={{
                      flexGrow: 1,
                      height: "40px",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                      marginBottom: "7px",
                      maxWidth: "90%",
                      marginRight: "5px",
                    }}
                    className="input-default inputCustom"
                    type="text"
                    value={link}
                    disabled
                    placeholder="Aucun lien généré"
                  />
                  <i style={{ marginBottom: 15 }}>
                    <LinkOutlined
                      style={{ color: "#05BAF3", textAlign: "center" }}
                      onClick={() => {
                        let input = document.body.appendChild(
                          document.createElement("input")
                        );
                        input.value = link;
                        input.focus();
                        input.select();
                        document.execCommand("copy");
                        input.parentNode.removeChild(input);
                        if (link) {
                          enqueueSnackbar("Copied!", {
                            variant: "success",
                            autoHideDuration: 2000,
                            anchorOrigin: {
                              horizontal: "center",
                              vertical: "top",
                            },
                          });
                        } else {
                          enqueueSnackbar("Veuillez générer le lien!", {
                            variant: "error",
                            autoHideDuration: 2000,
                            anchorOrigin: {
                              horizontal: "center",
                              vertical: "top",
                            },
                          });
                        }
                      }}
                    />
                  </i>
                </div>
                <button
                  style={{
                    width: "27%",
                    textAlign: "center",
                    fontSize: "14px",
                    paddingLeft: "15px",
                    paddingRight: "15px",
                  }}
                  className="btn-container btn-border"
                  type="reset"
                  onClick={onGenerate}
                  disabled={isGenerated}
                >
                  {isGenerated ? "Loading..." : "Générer lien"}
                </button>
              </SmallBox>
            </ColumnBox>
          </div>
          <div className="col-md-12 wrap-btn-modal mt-3">
            <div className="btn-container">
              <button className="btn-full" type="submit" disabled={isInvited}>
                {isInvited ? "Loading..." : "Inviter"}
              </button>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
}
const Text = styled.h2`
  // font-weight: bold;
`;
const SmallBox = styled.div`
  // min-width: 200px;
  // max-width: 420px;
`;
const ColumnBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 13px;
`;
const RowBox = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 10px;
`;
