import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { actionLogin } from "../../redux/actions/UserAction";
import { useSnackbar } from "notistack";
const _ = require("lodash");
let theme = "1";
const data = localStorage.getItem("theme");

if (data) {
  theme = JSON.parse(data).theme;
}

export default function Login() {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const loading = useSelector((state) => state.user.loading);
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  if (localStorage.getItem("data")) {
    window.location.replace("/home");
  }

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(
      actionLogin(email, password, (status, link) => {
        if (status === 200) {
          navigate(link ? `/${link}` : "/home");
        } else {
          enqueueSnackbar("Mot de passe ou email invalide !", {
            variant: "warning",
          });
        }
      })
    );
  };
  return (
    <main className="intro-pages">
      <section id="login">
        <div className="container">
          <div className="logo-container"></div>
          <div className="text-center subtitles">
            <span className="myspan" style={{ fontSize: "18px" }}>
              Bienvenue sur votre espace, veuillez vous connecter
            </span>
          </div>

          <form action="" className="login-form bg-default" onSubmit={onSubmit}>
            <div className="input-container">
              <label className="label-default " htmlFor="identifiant">
                Votre email ou identifiant
              </label>
              <span className="span-block">
                <input
                  className="inputCustom input-default "
                  type="email"
                  name="identifiant"
                  id="identifiant"
                  placeholder="Obligatoire"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </span>
            </div>

            <div className="input-container">
              <label className="label-default" htmlFor="password">
                Mot de passe
              </label>
              <span className="span-block picto-password">
                <input
                  className="inputCustom input-default"
                  type="password"
                  name="password"
                  id="password"
                  placeholder="Obligatoire"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                {/* <i className="fa-regular fa-eye"></i> */}
                <i
                  class="fa-regular fa-eye"
                  onClick={(e) => {
                    if (
                      document.getElementById("password").type === "password"
                    ) {
                      e.target.className = "fa fa-eye-slash";
                      document.getElementById("password").type = "text";
                    } else {
                      e.target.className = "fa-regular fa-eye";
                      document.getElementById("password").type = "password";
                    }
                  }}
                ></i>
              </span>
              <Link className="link-small" to="/forget-password">
                Mot de passe oublié ?
              </Link>
            </div>

            <div className="btn-container btnContainer">
              <button
                className="btn-full2 btnCustom"
                type="submit"
                disabled={loading}
              >
                {loading ? "loading..." : "Se connecter"}
              </button>
              <Link
                className="btnCustom2"
                style={{ color: theme == 1 ? "#444" : "white" }}
                to="/register"
              >
                Inscription
              </Link>
            </div>
          </form>
        </div>
      </section>
    </main>
  );
}
