import { CloseOutlined } from "@ant-design/icons";
import { AccessTimeOutlined } from "@material-ui/icons";
import Planche from "../../img/planche-1.jpg";
import React from "react";
import styled from "styled-components";
import Popover from "@mui/material/Popover";
import { useState } from "react";

let theme = "1";
const data = localStorage.getItem("theme");

if (data) {
  theme = JSON.parse(data).theme;
}

export default function Card({
  name,
  onDelete,
  onUpdate,
  onShowDetail,
  updateAt,
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const tag = open ? `simple-popover${name}` : undefined;

  return (
    <Container>
      <DeleteButton onClick={onDelete}>
        <CloseOutlined color="white" />
      </DeleteButton>
      <CardTop>
        <Image src={Planche} />
      </CardTop>
      <CardBottom>
        <CardHeader>
          <Title className="title-4">{name}</Title>
          <Small>
            <div>
              <Span className="title-4" style={{ textAlign: "left" }}>
                <AccessTimeOutlined style={{ fontSize: 12 }} />
                &nbsp; Modifié le {updateAt}
              </Span>
              {/* <Span style={{ textAlign: "left" }}>
                <ShareRounded style={{ fontSize: 12 }} />
                &nbsp; Partagé avec équipes, 1 personne
              </Span> */}
            </div>
          </Small>
        </CardHeader>
        <CardFooter>
          <More
            aria-describedby={tag}
            className="fa-light fa-ellipsis more"
            style={{
              color:
                theme === "0" ? "#fff" : theme === "1" ? "#1f2128" : "#1f2128",
            }}
            onClick={handleClick}
          />
          <Popover
            id={tag}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "center",
              horizontal: "left",
            }}
          >
            <div className="mui-popover-custom">
              <ul>
                <>
                  <li>
                    <a
                      href="#null"
                      className="mui-popover-item"
                      onClick={(e) => {
                        e.preventDefault();
                        handleClose();
                        if (onShowDetail) onShowDetail();
                      }}
                    >
                      Détail
                    </a>
                  </li>
                  <li>
                    <a
                      href="#null"
                      className="mui-popover-item"
                      onClick={(e) => {
                        e.preventDefault();
                        handleClose();
                        if (onUpdate) onUpdate();
                      }}
                    >
                      Modifier
                    </a>
                  </li>
                </>
              </ul>
            </div>
          </Popover>
        </CardFooter>
      </CardBottom>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 220px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
`;
const More = styled.i`
  cursor: pointer;
  margin-right: 2px;
  font-size: 20px;
  width: 32px;
  height: 32px;
  text-align: center;
  line-height: 32px;
  transition: all 0.8s;
  :hover {
    background: ${theme === "0" ? "#242731" : "#f5f5f5"};
    border-radius: 30px;
  }
`;
const CardTop = styled.div`
  width: 100%;
  height: calc(100% - 70px);
`;

const CardBottom = styled.div`
  height: 70px;
  display: flex;
  justify-content: space-between;
  background: #fff;
  padding: 2px 8px;
  background: ${theme === "0" ? "#323541" : "#e0e0e0"};
`;

const Image = styled.img`
  background-position-y: center;
  background-size: cover;
  background-position-x: center;
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-grow: 3;
  height: 100%;
`;

const CardFooter = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  margin-right: 3;
  align-items: center;
`;

const Title = styled.h2`
  font-size: 0.9rem;
  margin-bottom: 5px;
  padding: 0;
  font-weight: 600;
`;

const Span = styled.span`
  font-size: 0.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Small = styled.small`
  font-size: 0.7rem;
  margin: 0;
  display: flex;
  align-items: center;
  padding-top: 4;
`;

const DeleteButton = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  width: 30px;
  height: 25px;
  border-radius: 5px;
  background-color: #ff725e;
  color: #6d1f1f;
  cursor: pointer;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
