import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Switch } from "antd";
import Modal from "./Modal";
import {
  removeUserDashboardList,
  removeTeamDashboardList,
} from "../../redux/actions/dashboardAction";

export default function Member({
  id,
  name,
  isViewOnly,
  role,
  update,
  remove,
  lists,
  idMember,
  isTeam,
  token,
}) {
  const [showlist, SetShowList] = useState(false);
  const dispatch = useDispatch();
  return (
    <>
      <Modal
        visible={showlist}
        title={name}
        onCancel={() => SetShowList(false)}
      >
        {lists && lists.length ? (
          <div className="wrap-table" style={{ marginTop: 40 }}>
            <table className="display responsive">
              <thead>
                <tr>
                  <td className="table-style">Vues</td>
                  <td className="table-style"></td>
                  <td className="table-style"></td>
                </tr>
              </thead>
              <tbody
                axis="y"
                lockAxis="y"
                useDragHandle
                disableAutoscroll
                helperClass="row-dragging"
              >
                {lists && lists?.length
                  ? lists.map(({ idlist, name }) => {
                      return (
                        <tr id={idlist}>
                          <td className="table-style">{name}</td>
                          <td className="table-style"> </td>
                          <td className="table-style">
                            <a
                              href="#"
                              className="btn-simple-round supprimer"
                              onClick={() => {
                                if (isTeam) {
                                  dispatch(
                                    removeTeamDashboardList(
                                      idMember,
                                      idlist,
                                      token,
                                      () => {}
                                    )
                                  );
                                } else {
                                  dispatch(
                                    removeUserDashboardList(
                                      idMember,
                                      idlist,
                                      token,
                                      () => {}
                                    )
                                  );
                                }
                              }}
                            >
                              <i className="fa-light fa-trash-can"></i>
                            </a>
                          </td>
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </table>
          </div>
        ) : (
          <p style={{ padding: 40, textAlign: "center" }}>
            Aucune vue n'est présente
          </p>
        )}
      </Modal>
      <tr>
        <td className="table-style">{name}</td>

        <td className="table-style">
          <ul className="actions">
            <li>
              {!isViewOnly ? (
                <Switch
                  id={`${id}`}
                  defaultChecked={role === "ADMIN" ? true : false}
                  onChange={(value) => {
                    update(value);
                  }}
                />
              ) : null}
            </li>
            <li>
              <a
                href="#"
                className="btn-simple-round modifier"
                onClick={() => SetShowList(true)}
              >
                <i className="fa-light fa-pen-to-square"></i>
              </a>
            </li>
            <li>
              <a
                href="#"
                className="btn-simple-round supprimer"
                onClick={() => remove()}
              >
                <i className="fa-light fa-trash-can"></i>
              </a>
            </li>
          </ul>
        </td>
      </tr>
    </>
  );
}
