import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { actionRegister } from "../../redux/actions/UserAction";
import validator from "validator";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useSnackbar } from "notistack";
const _ = require("lodash");

let theme = "1";
const data = localStorage.getItem("theme");

if (data) {
  theme = JSON.parse(data).theme;
}

export default function Register() {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const loading = useSelector((state) => state.user.loading);
  const dispatch = useDispatch();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirm, setConfirm] = useState("");
  const [validmail, setValidMail] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    if (firstName && lastName && email && password && confirm) {
      if (password.length >= 8) {
        if (password === confirm) {
          if (validator.isEmail(email)) {
            dispatch(
              actionRegister(
                firstName,
                lastName,
                email,
                phone,
                password,
                (status) => {
                  if (status === 200) {
                    navigate("/home");
                  }
                }
              )
            );
          } else {
            enqueueSnackbar("L'adresse email n'est pas valide !", {
              variant: "warning",
            });
            // alert("Invalid mail");
          }
        } else {
          // alert("password et password confirm ne sont pas egaux");
          enqueueSnackbar("Les mots de passe doivent être identiques!", {
            variant: "warning",
          });
          setConfirm("");
        }
      } else {
        // alert("mot de passe min 8 caracteres");
        enqueueSnackbar(
          "Le mot de passe doit comporter au minimum 8 caractères!",
          {
            variant: "warning",
          }
        );
      }
    } else {
      // alert("all require");
      enqueueSnackbar("Tous les champs sont obligatoires!", {
        variant: "warning",
      });
    }
  };

  return (
    <main className="intro-pages">
      <section id="login">
        <div className="container">
          <div className="logo-container">
            {/* <img 
                //  src="./logo-viabber.svg"
                 src={theme === "0" ? 
                          "./logo-viabber.svg" : 
                          theme === "1" ? 
                          "./logo-viabber2.svg" : 
                          "./logo-blue.png"}
                 alt="" /> */}
          </div>

          <div className="text-center subtitles">
            <span>Bienvenue sur viabber</span>
          </div>

          <form action="" className="login-form bg-default" onSubmit={onSubmit}>
            <div className="row">
              <div className="col-md-6">
                <div className="input-container">
                  <label for="nom" className="label-default">
                    Votre nom*
                  </label>
                  <span className="span-block">
                    <input
                      className="input-default inputCustom"
                      name="nom"
                      id="nom"
                      type="text"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      required
                      placeholder="Obligatoire"
                    />
                  </span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="input-container">
                  <label for="prenom" className="label-default">
                    Votre prénom*
                  </label>
                  <span className="span-block">
                    <input
                      className="input-default inputCustom"
                      name="prenom"
                      id="prenom"
                      type="text"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      required
                      placeholder="Obligatoire"
                    />
                  </span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="input-container">
                  <label for="prenom" className="label-default">
                    Votre email*{" "}
                    {validmail ? (
                      <p style={{ color: "red" }}>Entrez un Email valid !</p>
                    ) : (
                      ""
                    )}
                  </label>
                  <span className="span-block">
                    <input
                      className="input-default inputCustom"
                      name="prenom"
                      id="prenom"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                      placeholder="Obligatoire"
                    />
                  </span>
                </div>
              </div>
              {/* <div className="col-md-6">
                <div className="input-container no-margin-md">
                  <label for="phone" className="label-default">
                    Votre téléphone (optionnel)
                  </label>
                  <PhoneInput
                    country={'fr'}
                    value={phone}
                    onChange={setPhone}
                  />
                </div>
              </div> */}
              <div className="col-md-6">
                <div className="input-container">
                  <label for="" className="label-default">
                    Mot de passe*
                  </label>
                  <span className="span-block picto-password">
                    <input
                      id="password"
                      className="input-default inputCustom"
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                      placeholder="Obligatoire"
                      minLength={8}
                    />
                    <i
                      className="fa-regular fa-eye"
                      onClick={(e) => {
                        if (
                          document.getElementById("password").type ===
                          "password"
                        ) {
                          e.target.className = "fa fa-eye-slash";
                          document.getElementById("password").type = "text";
                        } else {
                          e.target.className = "fa-regular fa-eye";
                          document.getElementById("password").type = "password";
                        }
                      }}
                    ></i>
                  </span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="input-container">
                  <label for="" className="label-default">
                    Confirmer le mot de passe*
                  </label>
                  <span className="span-block picto-password">
                    <input
                      id="password1"
                      className="input-default inputCustom"
                      type="password"
                      value={confirm}
                      onChange={(e) => setConfirm(e.target.value)}
                      required
                      placeholder="Obligatoire"
                      minLength={8}
                    />
                    <i
                      className="fa-regular fa-eye"
                      onClick={(e) => {
                        if (
                          document.getElementById("password1").type ===
                          "password"
                        ) {
                          e.target.className = "fa fa-eye-slash";
                          document.getElementById("password1").type = "text";
                        } else {
                          e.target.className = "fa-regular fa-eye";
                          document.getElementById("password1").type =
                            "password";
                        }
                      }}
                    ></i>
                  </span>
                </div>
              </div>
            </div>
            <div className="btn-container btnContainer">
              <button
                className="btn-full2 btnCustom"
                type="submit"
                disabled={loading}
              >
                {loading ? "loading..." : "Inscription"}
              </button>
              <Link
                className="btnCustom2"
                style={{ color: theme == 1 ? "#444" : "white" }}
                to="/"
              >
                Se connecter
              </Link>
            </div>
          </form>
        </div>
      </section>
    </main>
  );
}
