import host from "./host";
export const ACTION_SET_DATASOURCES = "ACTION_SET_DATASOURCES";
export const ACTION_REMOVE_DATASOURCE = "ACTION_REMOVE_DATASOURCE";
export const ACTION_IS_GET_DATASOURCES = "ACTION_IS_GET_DATASOURCES";
export const ACTION_IS_UPDATE_DATASOURCE = "ACTION_IS_UPDATE_DATASOURCE";
export const ACTION_IS_DELETE_DATASOURCE = "ACTION_IS_DELETE_DATASOURCE";
export const ACTION_IS_ADD_DATASOURCE = "ACTION_IS_ADD_DATASOURCE";
export const ACTION_SET_DATA_DATASOURCE = "ACTION_SET_DATA_DATASOURCE";
export const ACTION_SET_LAST_DATE_DATASOURCE =
  "ACTION_SET_LAST_DATE_DATASOURCE";
export const ACTION_SET_ONE_DATASOURCE = "ACTION_SET_ONE_DATASOURCE";

// * --------- Begin actions --------

export const actionSetOneDatasource = (payload) => {
  return { type: ACTION_SET_ONE_DATASOURCE, payload };
};

export const actionSetLastDateDatasource = (payload) => {
  return { type: ACTION_SET_LAST_DATE_DATASOURCE, payload };
};

export const actionSetDataDatasources = (payload) => {
  return { type: ACTION_SET_DATA_DATASOURCE, payload };
};

export const actionIsGetDatasources = (payload) => {
  return { type: ACTION_IS_GET_DATASOURCES, payload };
};

export const actionIsUpdateDatasource = (payload) => {
  return { type: ACTION_IS_UPDATE_DATASOURCE, payload };
};

export const actionIsDeleteDatasource = (payload) => {
  return { type: ACTION_IS_DELETE_DATASOURCE, payload };
};

export const actionIsAddDatasource = (payload) => {
  return { type: ACTION_IS_ADD_DATASOURCE, payload };
};

export const actionSetDatasources = (payload) => {
  return { type: ACTION_SET_DATASOURCES, payload };
};

export const actionRemoveDatasource = (payload) => {
  return { type: ACTION_REMOVE_DATASOURCE, payload };
};

// * --------- End actions --------

// * --------- Begin --------

export const actionGetDatasources = (id, token, callBack) => {
  return (dispatch) => {
    dispatch(actionIsGetDatasources(true));

    let myHeaders = new Headers();
    myHeaders.append("Authorization", token);

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${host}/users/getDatasourcesByUser/${id}`, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) {
          return null;
        }
        return response.json();
      })
      .then((result) => {
        if (result) {
          if (callBack) {
            callBack(result);
          }
          dispatch(
            actionSetDatasources({ data: result.datasources, type: "ALL" })
          );
        }
      })
      .catch(() => {})
      .then(() => dispatch(actionIsGetDatasources(false)));
  };
};

export const actionUpdateDatasource = (
  name,
  startDate,
  isAsync,
  operation,
  periode,
  refreshed,
  nbre,
  id,
  token,
  callback
) => {
  return (dispatch) => {
    dispatch(actionIsUpdateDatasource(true));

    let myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    let raw = JSON.stringify({
      name,
      startdate: startDate,
      periode,
      refreshed,
      nbre,
      operation,
      isAsync,
    });

    let requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${host}/datasource/update/${id}`, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) {
          callback(false);
          return null;
        }
        callback(true);
        return response.json();
      })
      .then((result) => {
        if (result) {
          dispatch(
            actionSetDatasources({ data: result.update, type: "UPDATE" })
          );
        }
      })
      .catch((e) => {
        callback(false);
      })
      .then(() => {
        dispatch(actionIsUpdateDatasource(false));
      });
  };
};

export const actionDeleteDatasource = (id, token, callback) => {
  return (dispatch) => {
    dispatch(actionIsDeleteDatasource(true));
    let myHeaders = new Headers();
    myHeaders.append("Authorization", token);

    let requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${host}/datasource/delete/${id}`, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) {
          callback(400);
          return null;
        } else {
          callback(200);
          dispatch(actionRemoveDatasource(id));
        }
      })
      .catch(() => {
        callback(400);
      })
      .then(() => dispatch(actionIsDeleteDatasource(false)));
  };
};

export const actionAddDatasource = (
  iduser,
  idconnector,
  json,
  token,
  callBack
) => {
  return (dispatch) => {
    dispatch(actionIsAddDatasource(true));
    let myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    let raw = JSON.stringify({
      iduser,
      idconnector,
      json,
    });

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${host}/insertion/insert-datasource`, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) {
          callBack(400);
          return null;
        }
        return response.json();
      })
      .then((result) => {
        if (result) {
          dispatch(
            actionSetDatasources({ data: result.datasource, type: "ADD" })
          );
          callBack(200, result.datasource);
        }
      })
      .catch(() => {
        callBack(400);
      })
      .then(() => dispatch(actionIsAddDatasource(false)));
  };
};

export const actionRefreshAuto = (iddatasource, token, callBack) => {
  return (dispatch) => {
    let myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    let raw = JSON.stringify({});

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${host}/insertion/refresh-auto/${iddatasource}`, requestOptions)
      .then((response) => {
        if (response.status === 202) {
          return callBack(202);
        }
        if (response.status !== 200 && response.status !== 201) {
          callBack(400);
          return null;
        }
        return response.json();
      })
      .then((result) => {
        if (result) {
          dispatch(actionSetLastDateDatasource(iddatasource));
          callBack(200, result);
        }
      })
      .catch(() => {
        callBack(400);
      });
  };
};

export const actionRefreshManual = (iddatasource, json, token, callBack) => {
  return (dispatch) => {
    let myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    let raw = JSON.stringify({ json });

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${host}/insertion/refresh-manual/${iddatasource}`, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) {
          callBack(400);
          return null;
        }
        return response.json();
      })
      .then((result) => {
        if (result) {
          dispatch(actionSetLastDateDatasource(iddatasource));
          callBack(200, result);
        }
      })
      .catch(() => {
        callBack(400);
      });
  };
};

// * --------- End --------
