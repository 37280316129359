import { ArrowDownward, ArrowUpward } from "@material-ui/icons";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { actionGetTotalByFilter } from "../../redux/actions/indicatorAction";

let theme = "1";
const data = localStorage.getItem("theme");

if (data) {
  theme = JSON.parse(data).theme;
}

const SelectedIndicator = ({ indicators: data, beginDate }) => {
  const [indicators, setIndicator] = useState(data);
  const indicatorsRef = useRef();
  indicatorsRef.current = indicators;
  const dispatch = useDispatch();

  const getIndicatorCurrentValue = () => {
    if (beginDate) {
      for (let counter = 0; counter < indicatorsRef.current.length; counter++) {
        const indicator = indicatorsRef.current[counter];
        const id = parseInt(indicator.idindicator);
        let axe = null;
        let filter = null;
        let defaultAxe = null;

        for (let index = 0; index < indicator.columns.length; index++) {
          const column = indicator.columns[index];
          if (column.idcolumn == indicator.idaxe) {
            axe = column;
            break;
          }
        }

        for (let index = 0; index < indicator.columns.length; index++) {
          const column = indicator.columns[index];
          if (column.idcolumn == indicator.idfilter) {
            filter = column;
            break;
          }
        }

        if (indicator.iddefault) {
          for (let index = 0; index < indicator.columns.length; index++) {
            const column = indicator.columns[index];
            if (column.idcolumn == indicator.iddefault) {
              defaultAxe = column;
              break;
            }
          }
        } else {
          defaultAxe = { ...filter };
        }
        /************************ */
        const now = new Date();
        const fin = new Date(now.setHours(23, 59, 59)).getTime();
        dispatch(
          actionGetTotalByFilter(
            parseInt(axe.iddatasource),
            axe.name,
            {
              name: defaultAxe.name,
              class:
                parseInt(defaultAxe.dataType) === 3 ? "time" : "dimensions",
            },
            [
              {
                name: filter.name,
                type: 3,
                class: "time",
                interval: [
                  new Date(beginDate).toISOString().split(".")[0],
                  new Date(fin).toISOString().split(".")[0],
                ],
              },
            ],
            (status, result) => {
              if (status === 200) {
                if (result) {
                  const newIndicator = [...indicatorsRef.current];
                  for (let b = 0; b < indicatorsRef.current.length; b++) {
                    const indicator = indicatorsRef.current[b];
                    if (parseInt(indicator.idindicator) === parseInt(id)) {
                      let total = 0;
                      if (result.length > 0) {
                        if (parseInt(indicator.operation) !== 2) {
                          total = result[0].value;
                        } else {
                          total = result[0].avg;
                        }
                      }
                      newIndicator[b]["currentValue"] = total;
                      break;
                    }
                  }
                  setIndicator([...newIndicator]);
                }
              }
            }
          )
        );
      }
    }
  };

  useEffect(() => {
    // getIndicatorValue();
    getIndicatorCurrentValue();
  }, [beginDate]);

  useEffect(() => {}, [indicators]);

  const convert = (number) => {
    const v = number?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return v;
  };

  return (
    <div>
      <h2 className="label-default">
        Indicateur{indicators.length > 1 ? "s" : ""} (valeur récupérée du{" "}
        {beginDate &&
          new Date(beginDate)
            .toISOString()
            .split("T")[0]
            .split("-")
            .reverse()
            .join("/")}{" "}
        jusqu'à ce jour)
      </h2>
      {indicators.length === 0 ? (
        <EmptyIndicator>
          <h1
            className="label-default"
            style={{
              textAlign: "center",
              margin: 0,
            }}
          >
            Aucun indicateur n'a été sélectionné
          </h1>
        </EmptyIndicator>
      ) : (
        <IndicatorContainer>
          {indicators.map((indic, index) => (
            <Card key={index}>
              <CardHeader>
                <Text>{`${convert(indic.currentValue)} ${indic.symbole}`}</Text>
              </CardHeader>
              <CardFooter>
                <SubTitle className="title-4">{indic.name}</SubTitle>
              </CardFooter>
            </Card>
          ))}
        </IndicatorContainer>
      )}
    </div>
  );
};

const IndicatorContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(200px, 400px));
  grid-template-rows: 180px;
  gap: 10px;
  margin: 20px 0 40px;

  @media (max-width: 1100px) {
    grid-template-columns: repeat(3, minmax(200px, 400px));
  }
  @media (max-width: 800px) {
    grid-template-columns: repeat(2, minmax(200px, 400px));
  }
  @media (max-width: 550px) {
    grid-template-columns: repeat(1, minmax(200px, 550px));
  }
`;

const Card = styled.div`
  border-radius: 15px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
  overflow: hidden;
`;

const CardHeader = styled.div`
  background-color: #54b7ed;
  height: 65%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Text = styled.h2`
  color: #fff;
  font-size: 24px;
  font-weight: bold;
  margin: 0;
`;

const CardFooter = styled.div`
  height: 35%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 10px;
  background: ${theme === "0" ? "#323541" : "#ffffff"};
`;

const SubTitle = styled.h3`
  font-weight: bold;
  font-size: 14px;
`;

const EmptyIndicator = styled.h1`
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: ${theme === "0" ? "#323541" : "#E0E0E0"};
`;
export default SelectedIndicator;
