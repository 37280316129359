import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  actionAcceptInvitationLogin,
  actionAcceptInvitationRegister,
  actionAcceptInvitationViewOnly,
  actionLogin,
} from "../../redux/actions/UserAction";
import PhoneInput from "react-phone-input-2";
import Modal from "../Views/Modal";
import Popover from "@mui/material/Popover";
import { useSnackbar } from "notistack";
const _ = require("lodash");

export default function InvitedLink() {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const loading = useSelector((state) => state.user.loading);
  const dispatch = useDispatch();
  const [loginVisibility, setLoginVisibility] = useState(false);
  const [registerVisibility, setRegisterVisibility] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirm, setConfirm] = useState("");

  const open = Boolean(anchorEl);
  const tag = open ? `simple-popover` : undefined;

  const [token, setToken] = useState(null);

  let theme = "1";
  const data = localStorage.getItem("theme");

  if (data) {
    theme = JSON.parse(data).theme;
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    setToken(searchParams.get("noreply"));
    if (!searchParams.get("noreply")) {
      navigate("/");
    }
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSubmitRegister = (e) => {
    e.preventDefault();
    if (firstName && lastName && email && password && confirm) {
      if (password.length >= 8) {
        if (password === confirm) {
          dispatch(
            actionAcceptInvitationRegister(
              email,
              firstName,
              lastName,
              password,
              phone,
              token,
              (status) => {
                if (status === 200) {
                  navigate("/board", {
                    replace: true,
                  });
                }
              }
            )
          );
        } else {
          enqueueSnackbar("Les mots de passe ne sont pas égaux!", {
            variant: "warning",
          });
          setConfirm("");
        }
      } else {
        enqueueSnackbar(
          "Le mot de passe doit comporter au minimum 8 caractères!",
          {
            variant: "warning",
          }
        );
      }
    } else {
      enqueueSnackbar("Tous les champs sont obligatoires!", {
        variant: "warning",
      });
    }
  };

  const onSubmitLogin = (e) => {
    e.preventDefault();
    if (email && password) {
      dispatch(
        actionAcceptInvitationLogin(email, password, token, (status) => {
          if (status === 200) {
            navigate("/board", {
              replace: true,
            });
          }
        })
      );
    } else {
      enqueueSnackbar("Tous les champs sont obligatoires!", {
        variant: "warning",
      });
    }
  };

  const onSubmitViewOnly = () => {
    dispatch(
      actionAcceptInvitationViewOnly(token, (status) => {
        if (status === 200) {
          navigate("/board", {
            replace: true,
          });
        } else {
        }
      })
    );
  };

  const onCancelLogin = () => {
    setLoginVisibility(false);
    setPassword("");
    setEmail("");
  };

  const onCancelRegister = () => {
    setRegisterVisibility(false);
    setPassword("");
    setConfirm("");
    setFirstName("");
    setLastName("");
    setPhone("");
  };

  return (
    <>
      <Modal
        title={"Connexion"}
        visible={loginVisibility}
        onCancel={onCancelLogin}
      >
        <form action="" className="login-form" onSubmit={onSubmitLogin}>
          <div className="input-container">
            <label className="label-default" htmlFor="identifiant">
              Votre email ou identifiant
            </label>
            <span className="span-block">
              <input
                className="input-default inputCustom"
                type="email"
                name="identifiant"
                id="identifiant"
                placeholder="Obligatoire"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </span>
          </div>

          <div className="input-container">
            <label className="label-default" htmlFor="password">
              Mot de passe
            </label>
            <span className="span-block picto-password">
              <input
                className="input-default inputCustom"
                type="password"
                name="password"
                id="password"
                placeholder="Obligatoire"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <i
                className="fa-regular fa-eye"
                onClick={(e) => {
                  if (document.getElementById("password").type === "password") {
                    e.target.className = "fa fa-eye-slash";
                    document.getElementById("password").type = "text";
                  } else {
                    e.target.className = "fa-regular fa-eye";
                    document.getElementById("password").type = "password";
                  }
                }}
              ></i>
            </span>
            <Link className="link-small" to="/forget-password">
              Mot de passe oublié ?
            </Link>
          </div>

          <div className="btn-container">
            <button className="btn-full" type="submit" disabled={loading}>
              {loading ? "loading..." : "Se connecter"}
            </button>
          </div>
        </form>
      </Modal>

      <Modal
        title={"Inscription"}
        visible={registerVisibility}
        large={true}
        onCancel={onCancelRegister}
      >
        <form action="" onSubmit={onSubmitRegister}>
          <div className="row">
            <div className="col-md-6">
              <div className="input-container">
                <label for="nom" className="label-default">
                  Votre nom
                </label>
                <span className="span-block">
                  <input
                    className="input-default inputCustom"
                    name="nom"
                    id="nom"
                    type="text"
                    placeholder="Obligatoire"
                    required
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="input-container">
                <label for="prenom" className="label-default">
                  Votre prénom
                </label>
                <span className="span-block">
                  <input
                    className="input-default inputCustom"
                    name="prenom"
                    id="prenom"
                    type="text"
                    placeholder="Obligatoire"
                    required
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="input-container">
                <label className="label-default" htmlFor="identifiant">
                  Votre email ou identifiant
                </label>
                <span className="span-block">
                  <input
                    className="input-default inputCustom"
                    type="email"
                    name="identifiant"
                    id="identifiant"
                    placeholder="Obligatoire"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </span>
              </div>
            </div>
            {/* <div className="col-md-6">
              <div className="input-container no-margin-md">
                <label for="phone" className="label-default">
                  Votre téléphone (optionnel)
                </label>
                <PhoneInput country={"fr"} value={phone} onChange={setPhone} />
              </div>
            </div> */}
            <div className="col-md-6">
              <div className="input-container">
                <label for="" className="label-default">
                  Mot de passe
                </label>
                <span className="span-block picto-password">
                  <input
                    id="password"
                    className="input-default inputCustom"
                    type="password"
                    placeholder="Obligatoire"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    minLength={8}
                  />
                  <i
                    className="fa-regular fa-eye"
                    onClick={(e) => {
                      if (
                        document.getElementById("password").type === "password"
                      ) {
                        e.target.className = "fa fa-eye-slash";
                        document.getElementById("password").type = "text";
                      } else {
                        e.target.className = "fa-regular fa-eye";
                        document.getElementById("password").type = "password";
                      }
                    }}
                  ></i>
                </span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="input-container">
                <label for="" className="label-default">
                  Confirmer le mot de passe
                </label>
                <span className="span-block picto-password">
                  <input
                    id="password1"
                    className="input-default inputCustom"
                    type="password"
                    placeholder="Obligatoire"
                    required
                    value={confirm}
                    onChange={(e) => setConfirm(e.target.value)}
                    minLength={8}
                  />
                  <i
                    className="fa-regular fa-eye"
                    onClick={(e) => {
                      if (
                        document.getElementById("password1").type === "password"
                      ) {
                        e.target.className = "fa fa-eye-slash";
                        document.getElementById("password1").type = "text";
                      } else {
                        e.target.className = "fa-regular fa-eye";
                        document.getElementById("password1").type = "password";
                      }
                    }}
                  ></i>
                </span>
              </div>
            </div>
          </div>
          <div className="btn-container">
            <button className="btn-full" type="submit" disabled={loading}>
              {loading ? "loading..." : "Inscription"}
            </button>
          </div>
        </form>
      </Modal>

      <main className="intro-pages">
        <div className="filtre"></div>
        <section id="register">
          <div className="container">
            <span className="welcome">Bienvenue sur</span>
            <div className="logo-container"></div>

            <h2 className="title-2">
              Vous avez été invité à rejoindre un Tableau
            </h2>
            <p className="content">
              Bienvenue sur Viabber, un outil d'analyse de données simple,
              rapide, fiable et collaboratif.
            </p>

            <div className="form-langage">
              <div className="input-container">
                <label className="label-default" for="langue">
                  Langue
                </label>
                <select
                  // className="select-border"
                  className="custom-select"
                  style={{
                    backgroundColor: theme === "0" ? "" : "white",
                    width: "250px",
                  }}
                  name="langue"
                  id="langue"
                >
                  <option value="fr">Français</option>
                  <option value="en">Anglais</option>
                  {/* <option value="it">Italien</option>
                <option value="es">Espagnol</option> */}
                </select>
              </div>
              <button
                className="btn-full"
                style={{
                  backgroundColor: theme === "0" ? "" : "white",
                  marginTop: "-17px",
                }}
                type="submit"
                aria-describedby={tag}
                onClick={handleClick}
                disabled={loading}
              >
                {loading ? "Loading..." : "Accepter l'invitation"}
              </button>
              <Popover
                id={tag}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
              >
                <div className="mui-popover-custom">
                  <button
                    className="btn-border-link"
                    type="submit"
                    onClick={() => {
                      handleClose();
                      onSubmitViewOnly();
                    }}
                    aria-describedby={tag}
                    style={{
                      paddingLeft: "2px",
                      paddingRight: "2px",
                      width: "135px",
                      marginTop: "10px",
                    }}
                  >
                    Lecture seul
                  </button>
                  <br />
                  <br />
                  <button
                    className="btn-border-link"
                    type="submit"
                    onClick={() => {
                      handleClose();
                      setLoginVisibility(true);
                    }}
                    aria-describedby={tag}
                    style={{ width: "135px" }}
                  >
                    Connexion
                  </button>
                  <br />
                  <br />
                  <button
                    className="btn-border-link"
                    type="submit"
                    onClick={() => {
                      handleClose();
                      setRegisterVisibility(true);
                    }}
                    aria-describedby={tag}
                    style={{
                      paddingLeft: "2px",
                      paddingRight: "2px",
                      width: "135px",
                      marginBottom: "10px",
                    }}
                  >
                    Inscription
                  </button>
                </div>
              </Popover>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
