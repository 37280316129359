
import { ACTION_GET_OPERATORS, ACTION_SET_OPERATOR } from "../actions/operatorAction";

const initialStateoperator = {
    operators: [],
    selectedoperator: null,
};

const operatorReducer = (state = initialStateoperator, action) => {
    switch (action.type) {
        case ACTION_GET_OPERATORS:
            return {
                ...state,
                operators: action.payload,
            };
        case ACTION_SET_OPERATOR:
            return {
                ...state,
                selectedOperator: action.payload,
            };
        default:
            return state;
    }
};


export default operatorReducer;
