import { useDispatch, useSelector } from "react-redux";
import { Select, Pagination, Tooltip } from "antd";
import { Button, Dropdown } from "antd";
import Modal from "../../Views/Modal";
import {
  actionChangeBonusStatus,
  actionDeleteBonus,
  actionGetBonusesByUser,
  actionUpdateBonus,
} from "../../../redux/actions/bonusAction";
import { enqueueSnackbar } from "notistack";
import styled from "styled-components";
import { useEffect, useState } from "react";
import ModalUpdateBonus from "../ModalUpdateBonus";
import { numberFormat } from "../IndicatorView";

export default function PrimeList() {
  const [bonus, setBonus] = useState(null);
  const isBonusActive = useSelector((state) => state.bonus.isBonusActive);
  const isChangeStatus = useSelector((state) => state.bonus.isChangeStatus);
  const rules = useSelector((state) => state.rule.rules);
  const bonuses = useSelector((state) => state.bonus.bonuses);
  const goals = useSelector((state) => state.goal.goals);
  const isDeleteBonus = useSelector((state) => state.bonus.isDeleteBonus);
  const isUpdateBonus = useSelector((state) => state.bonus.isUpdateBonus);
  const idUser = useSelector((state) => state.user.id);
  const [isBonusActiveState, setisBonusActiveState] = useState(true);
  const [deleteVisibility, setDeleteVisibility] = useState(false);
  const [updateVisibility, setUpdateVisibility] = useState(false);
  const token = useSelector((state) => state.user.token);
  const [show, setShow] = useState(false);
  const teams = useSelector((state) => state.team.teams);
  const [users, setUsers] = useState([]);
  const dispatch = useDispatch();
  const onDelete = (e) => {
    e.preventDefault();
    dispatch(
      actionDeleteBonus(bonus.idbonus, token, (success) => {
        if (success) {
          setDeleteVisibility(false);
          enqueueSnackbar("La prime a bien été supprimée.", {
            variant: "success",
          });
        } else {
          enqueueSnackbar("Une erreur est survenue lors de la suppression.", {
            variant: "error",
          });
        }
      })
    );
  };
  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 4;

  // Calculate pagination range
  const indexOfLastItem = currentPage * pageSize;
  const indexOfFirstItem = indexOfLastItem - pageSize;
  const currentItems = bonuses.slice(indexOfFirstItem, indexOfLastItem);
  console.log("currentItems", currentItems);
  useEffect(() => {
    setUsers(
      Array.from(
        new Set(
          teams
            .flatMap((team) => team.userTeams.map((userTeam) => userTeam.user))
            .map((user) => user.id)
        ),
        (id) =>
          teams
            .flatMap((team) =>
              team.userTeams
                .filter((userTeam) => userTeam.user.id === id)
                .map((userTeam) => userTeam.user)
            )
            .pop()
      )
    );
  }, [teams]);
  const handleChangeStatus = (bonus) => {
    setBonus(bonus);
    // Vérifier si l'utilisateur actuel est propriétaire du bonus
    if (idUser === bonus?.idowner) {
      const isBonusActive = bonuses.find((bonus) => bonus.idbonus)?.status;

      // Mettre à jour le statut du bonus avec l'ID spécifique
      dispatch(
        actionChangeBonusStatus(
          bonus?.idowner,
          bonus?.idbonus,
          token,
          (bonusStatusChanged) => {
            // dispatch(actionGetBonusesByUser(bonus?.idowner, token, () => {}));
            window.location.reload();
          }
        )
      );
    } else {
      // Si l'utilisateur n'est pas propriétaire, afficher un message d'erreur ou prendre une autre action
      enqueueSnackbar(
        "Vous n'êtes pas autorisé à modifier le statut de ce bonus.",
        {
          variant: "warning",
        }
      );
    }
  };
  const showUpdate = (bonus) => {
    setBonus(bonus);
    setShow(true);
  };

  useEffect(() => {
    if (!deleteVisibility && !updateVisibility) {
      setBonus(null);
    }
  }, [deleteVisibility, updateVisibility]);
  useEffect(() => {
    dispatch(
      actionGetBonusesByUser(idUser, token, (bonuses) => {
        if (bonuses) {
          // console.log("bonuses received", bonuses);
        }
      })
    );
  }, [dispatch, idUser, token]);

  return (
    <>
      <Modal
        visible={deleteVisibility}
        title={`Confirmer la suppression de la prime "${bonus?.bonusName}"`}
        onCancel={isDeleteBonus ? null : () => setDeleteVisibility(false)}
      >
        <div className="row">
          <div className="col-md-12 wrap-btn-modal">
            <div className="btn-container">
              <button
                className="btn-border close-btn-submit"
                type="reset"
                onClick={
                  isDeleteBonus ? null : () => setDeleteVisibility(false)
                }
              >
                Annuler
              </button>
              <button
                className="btn-full"
                disabled={isDeleteBonus}
                onClick={isDeleteBonus ? null : onDelete}
              >
                {isDeleteBonus ? "Loading" : "Supprimer"}
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <ModalUpdateBonus
        visibility={show}
        onCancel={() => setShow(!show)}
        large={true}
        bonus={bonus}
        rules={bonus && bonus.rules}
      />
      <div className="flex items-center justify-start gap-4 w-full">
        <div className="flex gap-4 relative ">
          {currentItems?.length > 0 ? (
            currentItems.map((bonus) => {
              //equip items:
              const items = [
                {
                  key: "1",
                  label: (
                    <button
                      onClick={() => showUpdate(bonus)}
                      className="text-right text-smi text-foundation-blue-normal-active focus:outline-none"
                    >
                      Modifier la prime
                    </button>
                  ),
                },
                {
                  key: "2",
                  label: (
                    <button
                      onClick={() => {
                        setBonus(bonus);
                        setDeleteVisibility(true);
                      }}
                      className="text-right text-smi text-foundation-blue-normal-active focus:outline-none"
                    >
                      Supprimer la prime
                    </button>
                  ),
                },
              ];

              return (
                <div
                  key={bonus?.idbonus}
                  className="transition-all duration-200 w-[300px] h-[320px] rounded-lg py-4 px-6 relative gap-[20px] border-[1px] border-solid border-blue-400 "
                >
                  <div className="w-full flex flex-row items-center justify-between text-xs ">
                    <div className="w-[173px] flex flex-col items-start justify-center gap-[5px]">
                      <div className="flex flex-row items-center justify-start gap-[8px]">
                        <img
                          className="relative w-3.5 h-3.5"
                          alt=""
                          src="imgPrimes/profile.png"
                        />
                        {bonus?.idteam == null ? (
                          <div className="relative">bonus individuelle</div>
                        ) : (
                          <div className="relative">bonus collective</div>
                        )}
                      </div>
                      <Tooltip title={bonus?.bonusName}>
                        <div className="relative w-32">
                          <p className="truncate ... text-xl font-semibold text-black">
                            {bonus?.bonusName}
                          </p>
                        </div>
                      </Tooltip>
                    </div>
                    {idUser === bonus?.idowner ? (
                      <Dropdown
                        className="shadow-[0px_4px_24px_rgba(0,_0,_0,_0.15)] flex flex-col items-start justify-start text-right text-smi text-foundation-blue-normal-active"
                        menu={{
                          items,
                        }}
                        placement="bottomRight"
                        arrow={{
                          pointAtCenter: true,
                        }}
                      >
                        <Button className="flex">
                          <b className="size-4 opacity-40">...</b>
                        </Button>
                      </Dropdown>
                    ) : null}
                  </div>
                  <img
                    className="self-stretch relative max-w-full overflow-hidden h-2px shrink-0 z-[1]"
                    alt=""
                    src="imgPrimes/Vector 380.png"
                  />
                  <div className="truncate ... ">
                    <em>Nombre de règles créées : {bonus?.rules?.length}</em>
                    {bonus?.rules?.slice(0, 1).map((rule) => (
                      <div
                        key={rule.idrule}
                        className=" truncate ... mb-2 w-full h-28 border-[1px] rounded-lg px-2 py-2 border-[#32b4ff] flex-1 flex flex-col items-start justify-center gap-[5px]"
                      >
                        <div className="truncate ... flex flex-row items-center justify-start text-left text-sm text-gray-500">
                          <div className="relative truncate ...">
                            Règle appliquée : {rule.ruleName}
                          </div>
                        </div>
                        <div className="flex flex-row items-center justify-start gap-[5px] truncate ...">
                          <div className="truncate ... rounded bg-[#CCF3FF] flex flex-row items-center justify-start py-0 px-[5px] text-xs text-[#05BAF3]">
                            <div className="relative font-medium opacity-[0.8] truncate ...">
                              SI
                            </div>
                          </div>
                          <div className="rounded bg-[#A28FFC] opacity-40 flex flex-row items-center justify-center py-px px-[3px] text-mediumpurple-300 truncate ...">
                            <div className="relative truncate ...">
                              {rule.goal.name}
                            </div>
                          </div>
                          <div className="relative opacity-[0.5] truncate ...">
                            {rule.goal.operator}
                          </div>
                          <div className="relative opacity-[0.5] truncate ...">
                            {numberFormat(rule.goal.targetvalue)} €
                          </div>
                        </div>
                        <div className="w-full flex flex-row flex-wrap items-center justify-start gap-[5px] truncate ... ">
                          <div className="rounded bg-[#CCF3FF] flex flex-row items-center justify-start py-0 px-[5px] text-xs text-[#05BAF3] truncate ...">
                            <div className="relative font-medium opacity-[0.8] truncate ...">
                              ALORS
                            </div>
                          </div>
                          <div className="rounded bg-[#A28FFC] opacity-40 flex flex-row items-center justify-center py-px px-[3px] text-mediumpurple-300 truncate ...">
                            <div className="relative truncate ...">
                              Commission
                            </div>
                          </div>
                          <div className="relative opacity-[0.5] truncate ...">
                            =
                          </div>
                          <div className="relative opacity-[0.5] truncate ... ">
                            {rule?.formula}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <img
                    className="self-stretch relative max-w-full overflow-hidden h-2px shrink-0 z-[1]"
                    alt=""
                    src="imgPrimes/Vector 380.png"
                  />
                  <div className="self-stretch flex flex-row items-start justify-between text-center text-3xs text-black">
                    <div className="flex flex-col items-center justify-start gap-[5px]">
                      <div className="relative opacity-[0.5] flex flex-col items-start">
                        <b>Attribué à </b>
                        {bonus?.idteam === null ? (
                          <div className="relative">
                            <p>
                              {bonus.BonusUsers.map((user) => (
                                <div className="flex gap-2">
                                  <h3>{user?.user?.firstName}</h3>
                                  <h3>{user?.user?.lastName}</h3>
                                </div>
                              ))}
                            </p>
                          </div>
                        ) : (
                          <div className="relative">
                            <p>{bonus?.team?.name}</p>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="self-stretch flex flex-col items-center justify-start gap-[10px]">
                      <div className="self-stretch flex-1 rounded-md bg-mintcream flex flex-row items-center justify-start py-0 px-2.5 text-left text-xs text-limegreen">
                        <div className="self-stretch flex flex-col items-center justify-start gap-[10px]">
                          <div className="relative opacity-[0.5]">Statut</div>
                          <button
                            className={`w-full relative focus:outline-none ${
                              bonus?.status === true
                                ? "bg-[#F5FFF7] text-[#00B327] hover:bg-[#00b34b]"
                                : "bg-[#FBEFED] text-[#CD4C2F] hover:bg-[#b30000]"
                            } transition-all delay-75 ease-in hover:cursor-pointer hover:text-white px-4 py-2 rounded-lg font-medium flex justify-center items-center `}
                            onClick={() => handleChangeStatus(bonus)}
                          >
                            {bonus?.status === true ? "Active" : "Inactive"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="  no-bonus-message flex flex-col justify-center items-center">
              <img
                className="no-bonus-gif w-48 animate-bounce"
                alt="No bonus gif"
                src="/no-prime.jpg"
              />

              <p className="mt-2 text-lg text-gray-600 font-semibold">
                Aucune prime n'a encore été créée.
              </p>
            </div>
          )}
        </div>
      </div>
      {bonuses?.length > 0 && (
        <div className="flex justify-end w-full mt-4">
          <Pagination
            current={currentPage}
            total={bonuses.length}
            pageSize={pageSize}
            onChange={(page) => setCurrentPage(page)}
          />
        </div>
      )}
    </>
  );
}
