import React from "react";
import { Button, Input, Popover } from "antd";
import { CloseOutlined, FilterOutlined, MinusOutlined } from "@ant-design/icons";

let theme = "1";
const data = localStorage.getItem("theme");
if (data) {
  theme = JSON.parse(data).theme;
}

export default function FilterItem({ onDelete, showIcon, content, name }) {
  return (
    <div style={{ width: "max-content", display:"flex", alignItems: "center", justifyContent: "flex-end"}}>
      <Popover placement={"bottomLeft"} trigger="click" content={content}>
        <Input
          className="site-input-right h-antd-custom-input"
          type="button"
          readOnly
          value={name}
          //suffix={}
          style={{
            width: 100,
            textAlign: "center",
            height: 40,
            border: "1px solid rgba(111, 108, 129, 0.3)",
            background: "#fff",

            color: " #000",    
            // color: theme === "0" ? "#ffffff" : theme === "1" ? "#1f2128" : "#1f2128",
            fontSize: "14px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: 'center'
          }}
        />
      </Popover>
      {/* Bouton contenant le x */}
      <Button
      className="site-input-left "
      type="default" 
      icon={showIcon ? <FilterOutlined size={1} style={{color: "#000"}} /> : <MinusOutlined />}
      style={{
          textAlign: "center",
          width: 30,
          height: 40,
          margin: 0,
          border: "1px solid rgba(111, 108, 129, 0.3)",
          borderLeft: "none",
          backgroundColor: "#fff",
          position: "relative",
          left: "-2px",
          color: "#fff"
        }}/>
      <Button
        className="site-input-left"
        onClick={onDelete}
        icon={<CloseOutlined /> }
        style={{
          textAlign: "center",
          width: 50,
          height: 40,
          margin: 0,
          border: "1px solid rgba(111, 108, 129, 0.3)",
          background: "#fff",
          borderLeft: "none",
          color: " #000",
          position: "relative",
          left: "-4px",
          // color: theme === "0" ? "#ffffff" : theme === "1" ? "#1f2128" : "#1f2128",
          fontSize: "14px",
        }}
      />
    </div>
  );
}
