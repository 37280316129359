import React from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import useState from "react-usestateref";
import {
  actionSetDataDatasources,
  actionSetDatasources,
} from "../../redux/actions/datasourceAction";
import host from "../../redux/actions/host";

export default function useCheck() {
  const dispatch = useDispatch();
  const [ref, setRef, reference] = useState(null);
  const token = useSelector((state) => state.user.token);
  const id = useSelector((state) => state.datasource.id);
  const canCheck = useSelector((state) => state.datasource.visibilityProgress);

  React.useEffect(() => {
    if (id && canCheck) {
      setRef(
        window.setInterval(() => {
          const myHeaders = new Headers();
          myHeaders.append("Authorization", token);
          const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
          };
          fetch(`${host}/insertion/check/${id}`, requestOptions)
            .then((response) => {
              if (response.status !== 200 && response.status !== 201) {
                return null;
              }
              return response.json();
            })
            .then((result) => {
              if (result) {
                if (+result.datasource.status === 102) {
                  dispatch(
                    actionSetDataDatasources({
                      pourcent: +result.pourcent,
                    })
                  );
                } else {
                  clearInterval(reference.current);
                  dispatch(
                    actionSetDataDatasources({
                      pourcent: 0,
                      visibilityProgress: false,
                      id: null,
                    })
                  );
                  dispatch(
                    actionSetDatasources({
                      data: result.datasource,
                      type: "UPDATE",
                    })
                  );
                }
              }
            })
            .catch((e) => {});
        }, 1000)
      );
    }
  }, [id, canCheck]);

  return null;
}
