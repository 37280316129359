// import React, { useEffect, useState } from "react";
// import { BarChart, Bar, XAxis, Tooltip, ResponsiveContainer } from "recharts";

// const EvolutionPrimesUserMiniature = ({ filteredTransactions, interval }) => {
//   const [data, setData] = useState([]);

//   useEffect(() => {
//     if (filteredTransactions && filteredTransactions.length > 0) {
//       const [startDate, endDate] = interval;

//       // Initialiser les données avec des valeurs nulles pour chaque mois de l'année
//       const currentDate = new Date();
//       const initialData = [];
//       for (let i = 0; i < 12; i++) {
//         const month = new Date(currentDate.getFullYear(), i);
//         initialData.push({
//           month: abbreviateMonth(
//             month.toLocaleString("default", { month: "long" })
//           ),
//           fullMonth: month.toLocaleString("default", { month: "long" }),
//           commissions: 0,
//         });
//       }

//       // Mettre à jour les données uniquement pour les mois où il y a des transactions individuelles
//       const updatedData = [...initialData];
//       filteredTransactions.forEach((transaction) => {
//         const transactionDate = new Date(transaction.dateBonusTransaction);
//         const monthIndex = transactionDate.getMonth();

//         if (transaction.iduser) {
//           updatedData[monthIndex].commissions += parseFloat(
//             transaction.commission
//           );
//         }
//       });

//       setData(updatedData);
//     }
//   }, [filteredTransactions, interval]);

//   const abbreviateMonth = (month) => {
//     const options = { month: "long", locale: "fr" };
//     switch (month.toLocaleString("default", options)) {
//       case "janvier":
//         return "jan";
//       case "février":
//         return "fév";
//       case "mars":
//         return "mar";
//       case "avril":
//         return "avr";
//       case "mai":
//         return "mai";
//       case "juin":
//         return "jui";
//       case "juillet":
//         return "jui";
//       case "août":
//         return "aou";
//       case "septembre":
//         return "sep";
//       case "octobre":
//         return "oct";
//       case "novembre":
//         return "nov";
//       case "décembre":
//         return "dec";
//       default:
//         return month;
//     }
//   };

//   return (
//     <div className="w-full">
//       <ResponsiveContainer width={"100%"} height={140}>
//         <BarChart data={data}>
//           <XAxis dataKey="month" tick={{ fontSize: 10 }} />

//           <Tooltip
//             labelFormatter={(value) =>
//               data.find((entry) => entry.month === value)?.fullMonth || value
//             }
//           />
//           <Bar
//             dataKey="commissions"
//             fill="#b19fffa2"
//             barSize={6}
//             radius={[5, 5, 5, 5]}
//           />
//         </BarChart>
//       </ResponsiveContainer>
//     </div>
//   );
// };

// export default EvolutionPrimesUserMiniature;
import React, { useEffect, useState } from "react";
import { BarChart, Bar, XAxis, Tooltip, ResponsiveContainer } from "recharts";

const EvolutionPrimesUserMiniature = ({
  filteredTransactions,
  interval,
  userId,
}) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (filteredTransactions && filteredTransactions.length > 0) {
      const [startDate, endDate] = interval;

      // Filter transactions for the specified user ID
      const userTransactions = filteredTransactions.filter(
        (transaction) => transaction.user.id === userId
      );

      // Initialiser les données avec des valeurs nulles pour chaque mois de l'année
      const currentDate = new Date();
      const initialData = [];
      for (let i = 0; i < 12; i++) {
        const month = new Date(currentDate.getFullYear(), i);
        initialData.push({
          month: abbreviateMonth(
            month.toLocaleString("default", { month: "long" })
          ),
          fullMonth: month.toLocaleString("default", { month: "long" }),
          commissions: 0,
        });
      }

      // Mettre à jour les données uniquement pour les mois où il y a des transactions individuelles
      const updatedData = [...initialData];
      userTransactions.forEach((transaction) => {
        const transactionDate = new Date(transaction.dateBonusTransaction);
        const monthIndex = transactionDate.getMonth();

        updatedData[monthIndex].commissions += parseFloat(
          transaction.commission
        );
      });

      setData(updatedData);
    }
  }, [filteredTransactions, interval, userId]);

  const abbreviateMonth = (month) => {
    const options = { month: "long", locale: "fr" };
    switch (month.toLocaleString("default", options)) {
      case "janvier":
        return "jan";
      case "février":
        return "fév";
      case "mars":
        return "mar";
      case "avril":
        return "avr";
      case "mai":
        return "mai";
      case "juin":
        return "jui";
      case "juillet":
        return "jui";
      case "août":
        return "aou";
      case "septembre":
        return "sep";
      case "octobre":
        return "oct";
      case "novembre":
        return "nov";
      case "décembre":
        return "dec";
      default:
        return month;
    }
  };

  return (
    <div className="w-full">
      <ResponsiveContainer width={"100%"} height={140}>
        <BarChart data={data}>
          <XAxis dataKey="month" tick={{ fontSize: 10 }} />

          <Tooltip
            labelFormatter={(value) =>
              data.find((entry) => entry.month === value)?.fullMonth || value
            }
          />
          <Bar
            dataKey="commissions"
            fill="#b19fffa2"
            barSize={6}
            radius={[5, 5, 5, 5]}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default EvolutionPrimesUserMiniature;
