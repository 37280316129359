import { TOGGLE_BONUS_STATUS, ACTION_ADD_BONUS, ACTION_IS_ADD_BONUS, ACTION_IS_DELETE_BONUS, ACTION_IS_GET_BONUSES, ACTION_IS_UPDATE_BONUS, ACTION_REMOVE_BONUS, ACTION_SET_BONUS, ACTION_SET_BONUSES, ACTION_IS_CHANGE_STATUS_BONUS } from "../actions/bonusAction";


const initialState = {
    bonuses: [],
    isDeleteBonus: false,
    isUpdateBonus: false,
    isGetBonus: false,
    isAddBonus: false,
    isChangeStatus: false,
    isBonusActive: true
};

const bonusReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_SET_BONUSES: {
            if (action.payload.type === "ADD") {
                const bonuses = [...state.bonuses];
                bonuses.unshift(action.payload.data);
                return {
                    ...state,
                    bonuses: [...bonuses],
                };
            } else if (action.payload.type === "UPDATE") {
                const bonuses = [...state.bonuses];
                for (let i = 0; i < bonuses.length; i++) {
                    const element = bonuses[i];
                    if (parseInt(element.idbonus) === parseInt(action.payload.id)) {
                        bonuses[i] = action.payload.data;
                        break;
                    }
                }
                return {
                    ...state,
                    bonuses: [...bonuses],
                };
            } else if (action.payload.type === "DELETE") {
                const bonuses = [...state.bonuses];
                for (let i = 0; i < bonuses.length; i++) {
                    const element = bonuses[i];
                    if (parseInt(element.idbonus) === parseInt(action.payload.data)) {
                        bonuses.splice(i, 1);
                        break;
                    }
                }
                return {
                    ...state,
                    bonuses: [...bonuses],
                };
            }
            else if (action.payload.type === "ALL") {
                return {
                    ...state,
                    bonuses: [...action.payload.data],
                };

            }
            break;
        }
        case ACTION_IS_ADD_BONUS: {
            return {
                ...state,
                isAddBonus: action.payload,
            };
        }
        case ACTION_IS_UPDATE_BONUS: {
            return {
                ...state,
                isUpdateBonus: action.payload,
            };
        }
        case ACTION_IS_DELETE_BONUS: {
            return {
                ...state,
                isDeleteBonus: action.payload,
            };
        }
        case ACTION_IS_CHANGE_STATUS_BONUS: {
            return {
                ...state,
                isChangeStatus: action.payload,
            };
        }
        case TOGGLE_BONUS_STATUS:
            return {
                ...state,
                bonuses: state.bonuses.map((bonus) =>
                    bonus.idbonus === action.payload.bonusId
                        ? { ...bonus, isBonusActive: !bonus.status }
                        : bonus
                ),
            };
        default:
            return state;
    }
};

export default bonusReducer;
