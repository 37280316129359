import host from "./host";
export const ACTION_GET_ALL_USER = "ACTION_GET_ALL_USER";
export const ACTION_IS_GET_USER = "ACTION_IS_GET_USER";
export const ACTION_GET_USER_BY_EMAIL = "ACTION_GET_USER_BY_EMAIL";
export const ACTION_GET_ERROR = "ACTION_GET_ERROR";
export const ACTION_GET_INDICATOR_GOAL = "ACTION_GET_INDICATOR_GOAL";
export const ACTION_GET_DASHBORAD_BY_USER_ID =
  "ACTION_GET_DASHBORAD_BY_USER_ID";
export const ACTION_POST_USER_TEAM = "ACTION_POST_USER_TEAM";
export const ACTION_GET_TEAMS = "ACTION_GET_TEAMS";
export const ACTION_GET_TEAMS_BY_USER = "ACTION_GET_TEAMS_BY_USER";
export const ACTION_UPDATE_TEAMS = "ACTION_UPDATE_TEAMS";
export const ACTION_GET_USER_TEAM = "ACTION_GET_USER_TEAM";
export const ACTION_GET_USER_TEAMS_BY_USER_ID =
  "ACTION_GET_USER_TEAMS_BY_USER_ID";
export const ACTION_DELETE_USERS_TEAMS = "ACTION_DELETE_USERS_TEAMS";

// * --------- Begin actions --------

export const actionGetAllUsers = (payload) => {
  return { type: ACTION_GET_ALL_USER, payload };
};

export const actionUpdateAllUsers = (payload) => {
  return { type: ACTION_UPDATE_TEAMS, payload };
};

export const actionGetTeams = (payload) => {
  return { type: ACTION_GET_TEAMS, payload };
};

export const actionPostUserTeam = (payload) => {
  return { type: ACTION_POST_USER_TEAM, payload };
};

export const actionGetTeamByUser = (payload) => {
  return { type: ACTION_GET_TEAMS_BY_USER, payload };
};

export const actionGetDashBoardByUser = (payload) => {
  return { type: ACTION_GET_DASHBORAD_BY_USER_ID, payload };
};

export const actionIsGetUsers = (payload) => {
  return { type: ACTION_IS_GET_USER, payload };
};

export const actionGetError = (payload) => {
  return { type: ACTION_GET_ERROR, payload };
};

export const actionIndicatorGoal = (payload) => {
  return { type: ACTION_GET_INDICATOR_GOAL, payload };
};

export const actionDeleteAll = (payload) => {
  return { type: ACTION_GET_INDICATOR_GOAL, payload };
};

export const actionGetUserByEmail = (payload) => {
  return { type: ACTION_GET_USER_BY_EMAIL, payload };
};

export const actionGetUserTeam = (payload) => {
  return { type: ACTION_GET_USER_TEAM, payload };
};
export const actionGetUserTeamsByUserId = (payload) => {
  return { type: ACTION_GET_USER_TEAMS_BY_USER_ID, payload };
};

// * --------- End actions --------

// * --------- Begin --------

export const actionUserByEmail = (token, email) => {
  return (dispatch) => {
    dispatch(actionIsGetUsers(true));

    let myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    let raw = JSON.stringify({
      email: email,
    });

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${host}/users/email`, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) {
          actionGetError(true);
          return null;
        }
        return response.json();
      })
      .then((result) => {
        if (result) {
          dispatch(actionGetUserByEmail(result));
        }
      })
      .catch((err) => {
        actionGetError(true);
      })
      .then(() => dispatch(actionIsGetUsers(false)));
  };
};

export const actionGetAllUserTeamById = (token, id) => {
  return (dispatch) => {
    dispatch(actionIsGetUsers(true));

    let myHeaders = new Headers();
    myHeaders.append("Authorization", token);

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${host}/team/user/${id}`, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) {
          return null;
        }
        return response.json();
      })
      .then((result) => {
        if (result) {
          dispatch(actionGetUserTeamsByUserId(result));
          actionIsGetUsers(false);
        }
      })
      .catch(() => {})
      .then(() => dispatch(actionIsGetUsers(false)));
  };
};

export const actionGetAllUserTeam = (token) => {
  return (dispatch) => {
    dispatch(actionIsGetUsers(true));

    let myHeaders = new Headers();
    myHeaders.append("Authorization", token);

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${host}/userTeam/`, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) {
          return null;
        }
        return response.json();
      })
      .then((result) => {
        if (result) {
          dispatch(actionGetUserTeam(result));
          actionIsGetUsers(false);
        }
      })
      .catch(() => {})
      .then(() => dispatch(actionIsGetUsers(false)));
  };
};

export const actionGetUsers = (token) => {
  return (dispatch) => {
    dispatch(actionIsGetUsers(true));

    let myHeaders = new Headers();
    myHeaders.append("Authorization", token);

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${host}/users/all`, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) {
          return null;
        }
        return response.json();
      })
      .then((result) => {
        if (result) {
          dispatch(actionGetAllUsers(result));
          actionIsGetUsers(false);
        }
      })
      .catch(() => {})
      .then(() => dispatch(actionIsGetUsers(false)));
  };
};

export const actionDeleteAllTeamUser = (token, id, callback) => {
  return (dispatch) => {
    dispatch(actionIsGetUsers(true));

    let myHeaders = new Headers();
    myHeaders.append("Authorization", token);

    let requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${host}/userTeam/delete/${id}`, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) {
          if (callback) {
            callback(400);
          }
          return null;
        }
        return response.json();
      })
      .then((result) => {
        if (result) {
          dispatch(actionGetAllUsers(result));
          actionIsGetUsers(false);
          if (callback) {
            callback(200);
          }
        }
      })
      .catch(() => {})
      .then(() => {
        dispatch(actionIsGetUsers(false));
        if (callback) {
          callback(true);
        }
      });
  };
};

export const actionPostTeam = (token, data, callback) => {
  return (dispatch) => {
    dispatch(actionIsGetUsers(true));

    let myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: data,
      redirect: "follow",
    };

    fetch(`${host}/userTeam/createTeam`, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) {
          if (callback) {
            callback(400);
          }
          return null;
        }
        return response.json();
      })
      .then((result) => {
        if (result) {
          dispatch(actionPostUserTeam(result));
          if (callback) {
            callback(200);
          }
        }
      })
      .catch((err) => {})
      .then(() => {
        dispatch(actionIsGetUsers(false));
      });
  };
};

export const actionUpdateTeam = (token, id, data, callback) => {
  return (dispatch) => {
    dispatch(actionIsGetUsers(true));

    let myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: data,
      redirect: "follow",
    };

    fetch(`${host}/userTeam/updateTeam/${id}`, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) {
          if (callback) {
            callback(400);
          }
          return null;
        }
        return response.json();
      })
      .then((result) => {
        if (result) {
          dispatch(actionPostUserTeam(result));
          if (callback) {
            callback(200);
          }
        }
      })
      .catch((err) => {})
      .then(() => {
        dispatch(actionIsGetUsers(false));
      });
  };
};

export const actionGetAllTeam = (token) => {
  return (dispatch) => {
    dispatch(actionIsGetUsers(true));

    let myHeaders = new Headers();
    myHeaders.append("Authorization", token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${host}/team/`, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) {
          return null;
        }

        return response.json();
      })
      .then((result) => {
        if (result) {
          dispatch(actionGetTeams(result));
        }
      })
      .catch((err) => {})
      .then(() => dispatch(actionIsGetUsers(false)));
  };
};
export const actionGetTeamsByUserId = (token, id) => {
  return (dispatch) => {
    dispatch(actionIsGetUsers(true));

    let myHeaders = new Headers();
    myHeaders.append("Authorization", token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${host}/team/user/${id}`, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) {
          return null;
        }

        return response.json();
      })
      .then((result) => {
        if (result) {
          dispatch(actionGetTeamByUser(result));
        }
      })
      .catch((err) => {})
      .then(() => dispatch(actionIsGetUsers(false)));
  };
};

export const actionGetIndicatorGoal = (token, id) => {
  return (dispatch) => {
    dispatch(actionIsGetUsers(true));

    let myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${host}/users/getIndicatorsAndGoalsByUser/${id}`, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) {
          return null;
        }
        return response.json();
      })
      .then((result) => {
        if (result) {
          dispatch(actionIndicatorGoal(result));
        }
      })
      .catch(() => {})
      .then(() => dispatch(actionIsGetUsers(false)));
  };
};

export const actionGetDashboardByUserId = (token, id) => {
  return (dispatch) => {
    dispatch(actionIsGetUsers(true));

    let myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${host}/users/getDashboardsByUser/${id}`, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) {
          return null;
        }
        return response.json();
      })
      .then((result) => {
        if (result) {
          dispatch(actionGetDashBoardByUser(result));
        }
      })
      .catch(() => {})
      .then(() => dispatch(actionIsGetUsers(false)));
  };
};

// * --------- End --------
