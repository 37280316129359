import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function RadioButton({ options, onChange, defaultValue, value, setValue, ...props }) {
  const { t } = useTranslation();
  // const [value, setValue] = useState(defaultValue);

  return (
    <>
      <div className="options" {...props} >
        <ul>
          {options.map((option, index) => {
            return (
              <li key={index}>
                <Link
                  className={option.value === value ? "h-actived " : ""}
                  to=""
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if(option.value !== value){
                      setValue(option.value);
                      if (onChange) {
                        onChange(option.value);
                      }
                    }
                  }}
                >
                  {option.label}
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
}
