import host from "./host";
import { ACTION_REMOVE_RULE_FROM_BONUS } from "./ruleAction";

// Action types
export const ACTION_SET_BONUSES = 'ACTION_SET_BONUSES';
export const ACTION_IS_GET_BONUSES = "ACTION_IS_GET_BONUSES";
export const ACTION_IS_ADD_BONUS = 'ACTION_IS_ADD_BONUS';
export const ACTION_IS_CHANGE_STATUS_BONUS = 'ACTION_IS_CHANGE_STATUS_BONUS';
export const TOGGLE_BONUS_STATUS = 'TOGGLE_BONUS_STATUS';
export const ACTION_IS_UPDATE_BONUS = "ACTION_IS_UPDATE_BONUS";
export const ACTION_IS_DELETE_BONUS = "ACTION_IS_DELETE_BONUS";
export const ACTION_ADD_BONUS = 'ACTION_ADD_BONUS';
export const ACTION_SET_BONUS = 'ACTION_SET_BONUS';
export const ACTION_REMOVE_BONUS = 'ACTION_REMOVE_BONUS'


// * --------- Begin actions --------
export const actionRemoveRuleFromBonus = (payload) => {
    return { type: ACTION_REMOVE_RULE_FROM_BONUS, payload };
};
export const actionSetBonuses = (payload) => {
    return { type: ACTION_SET_BONUSES, payload };
};
export const actionIsGetBonuses = (payload) => {
    return { type: ACTION_IS_GET_BONUSES, payload };
};
export const actionIsChangeStatusBonus = (payload) => {
    return { type: ACTION_IS_CHANGE_STATUS_BONUS, payload };
};
export const actionAddBonus = (payload) => {
    return { type: ACTION_ADD_BONUS, payload };
};
export const actionSetBonus = (payload) => {
    return { type: ACTION_SET_BONUS, payload };
};
export const actionIsAddBonus = (payload) => ({
    type: ACTION_IS_ADD_BONUS,
    payload
})
export const actionIsDeleteBonus = (payload) => {
    return { type: ACTION_IS_DELETE_BONUS, payload };
};

export const actionIsUpdateBonus = (payload) => {
    return { type: ACTION_IS_UPDATE_BONUS, payload };
};
export const actionRemoveBonus = (payload) => {
    return { type: ACTION_REMOVE_BONUS, payload };
};


export const actionCreateBonus = (
    name,
    idTeam,
    idUsers,
    idOwner,
    idRules,
    token,
    callback) => {
    return (dispatch) => {
        dispatch(actionIsAddBonus(true));

        let myHeaders = new Headers();
        myHeaders.append("Authorization", token);
        myHeaders.append("Content-Type", "application/json");

        let requestBody = {
            name,
            idTeam,
            idUsers,
            idOwner,
            idRules,
        }

        let requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify(requestBody),
            redirect: "follow",
        };

        fetch(`${host}/bonus/create`, requestOptions)
            .then((response) => {
                if (response.status !== 200 && response.status !== 201) {
                    return null;
                }
                return response.json();
            })
            .then((result) => {
                if (result) {
                    dispatch(
                        actionGetBonusesByUser(idOwner, token, () =>
                            console.log("success")
                        )
                    );
                    callback(200);
                }
            })
            .catch(() => {
            })
            .then(() => dispatch(actionIsAddBonus(false)));

    };
};
export const actionUpdateBonus = (idbonus, name, idTeam, idUsers, idOwner, idRules, token, callback) => {
    return (dispatch) => {
        dispatch(actionIsUpdateBonus(true));

        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", token);

        let requestBody = {
            name,
            idTeam,
            idUsers,
            idOwner,
            idRules,
        }

        let requestOptions = {
            method: "PUT",
            headers: myHeaders,
            body: JSON.stringify(requestBody),
            redirect: "follow",
        };

        fetch(`${host}/bonus/update/${idbonus}`, requestOptions)
            .then((response) => {
                if (response.status !== 200 && response.status !== 201) {
                    callback(false);
                    return null;
                }
                callback(true);

                return response.json();
            })
            .then((result) => {
                if (result) {
                    dispatch(actionSetBonuses({ type: "UPDATE", data: result.new, id: idbonus }
                    ));
                } else {
                }
            })
            .catch((e) => {
                console.log('e', e);
            }).then(() => {
                dispatch(actionIsUpdateBonus(false));
            });
    };
};

export const actionDeleteBonus = (id, token, callback) => {
    return (dispatch) => {
        dispatch(actionIsDeleteBonus(true));
        let myHeaders = new Headers();
        myHeaders.append("Authorization", token);

        let requestOptions = {
            method: "DELETE",
            headers: myHeaders,
            redirect: "follow",
        };

        fetch(`${host}/bonus/delete/${id}`, requestOptions)
            .then((response) => {
                console.log('response', response)
                if (response.status !== 200 && response.status !== 201) {
                    callback(400);
                    // return null;
                }
                else {
                    dispatch(actionSetBonuses({ type: "DELETE", data: id }));
                    callback(200);
                }

            })
            .catch((error) => {
                console.error('Error deleting bonus:', error);
                callback(400);
            })

            .then(() => dispatch(actionIsDeleteBonus(false)));
    };
};
export const actionGetBonusesByUser = (id, token, callback) => {
    return (dispatch) => {
        dispatch(actionIsGetBonuses(true));

        let myHeaders = new Headers();
        myHeaders.append("Authorization", token);

        let requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };

        fetch(`${host}/bonus/get-by-user/${id}`, requestOptions)
            .then((response) => {
                if (response.status !== 200 && response.status !== 201) {
                    return null;
                }
                return response.json();
            })
            .then((result) => {
                if (result) {
                    dispatch(actionSetBonuses({ type: "ALL", data: result.bonus }));
                    callback(200);
                } else {
                    callback(400);
                }
            })
            .catch(() => {
                // callback(400);
            })
            .then(() => dispatch(actionIsGetBonuses(false)));
    };
};
export const toggleBonusStatus = (bonusId) => ({
    type: "TOGGLE_BONUS_STATUS",
    payload: {
        bonusId,
    }
})
export const actionChangeBonusStatus = (idOwner, idbonus, token, callback) => {
    return (dispatch) => {
        dispatch(actionIsChangeStatusBonus(true));

        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", token);

        let requestBody = {
            idOwner,
            token,
            callback
        }

        let requestOptions = {
            method: "PUT",
            headers: myHeaders,
            body: JSON.stringify(requestBody),
            redirect: "follow",
        };

        fetch(`${host}/bonus/change-status/${idbonus}`, requestOptions)
            .then((response) => {
                if (response.status !== 200 && response.status !== 201) {
                    callback(false);
                    return null;
                }
                callback(true);
                return response.json();
            })
            .then((result) => {
                if (result) {
                    console.log('result from update status bonus', result);

                    dispatch(toggleBonusStatus(idbonus));

                } else {
                    console.log("error update bonus")
                }
            })
            .catch((e) => {
                console.log('e', e);
            }).then(() => {
                dispatch(actionIsUpdateBonus(false));
            });
    };
};

