import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip, Pagination } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { actionGetBonusesByUser } from "../../../redux/actions/bonusAction";
import Progress from "../Progress";
import { numberFormat } from "../IndicatorView";

const GoalsQuantities = ({ goalsQuantities }) => {
  const idUser = useSelector((state) => state.user.id);
  const token = useSelector((state) => state.user.token);
  const bonuses = useSelector((state) => state.bonus.bonuses);
  const isGetBonus = useSelector((state) => state.bonus.isGetBonus);
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 4;

  useEffect(() => {
    dispatch(actionGetBonusesByUser(idUser, token));
  }, [dispatch, idUser, token]);

  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  // if (isGetBonus) {
  //   return (
  //     <div className="flex justify-center items-center h-screen">
  //       <LoadingOutlined
  //         className="flex justify-center items-center content-center font-semibold text-lg"
  //         spin
  //       />
  //     </div>
  //   );
  // }

  // Maintain a set of unique goal IDs
  const uniqueGoalIds = new Set();

  // Filter and map bonuses
  const paginatedData = goalsQuantities.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  return (
    <>
      {paginatedData.length === 0 ? (
        <div className=" w-full overflow-hidden p-4 relative flex flex-col justify-center items-center content-center">
          <img
            className="no-bonus-gif w-48 animate-pulse"
            src="/no-goal.svg"
            alt="no-goal"
          />
          <em>Aucun objectif quantitatif trouvé</em>
        </div>
      ) : (
        <div>
          <div className="text-[#05BAF3] text-lg font-bold font-['Poppins'] mb-4">
            Objectifs quantitatifs
          </div>
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="text-left text-xs font-medium text-gray-500 uppercase"
                >
                  Indicateur
                </th>
                <th
                  scope="col"
                  className="text-left text-xs font-medium text-gray-500 uppercase "
                >
                  Valeur actuelle
                </th>
                <th
                  scope="col"
                  className="text-left text-xs font-medium text-gray-500 uppercase"
                >
                  Avancement de l’objectif
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {paginatedData.map(({ bonus, goal }) => {
                const a = parseInt(
                  (goal.currentvalue * 100) / goal.targetvalue
                );
                let percentage = a > 100 ? 100 : a;

                return (
                  <tr key={goal.idgoal} className="bg-white">
                    <td className="pl-0 whitespace-nowrap">
                      <Tooltip title={goal.name} placement="topLeft">
                        <div className="text-sm font-medium text-gray-900 truncate ... w-24">
                          {goal.name}
                        </div>
                      </Tooltip>
                    </td>
                    <td className="whitespace-nowrap pl-0">
                      <Tooltip
                        title={numberFormat(goal.currentvalue) + " € "}
                        placement="topLeft"
                      >
                        <div className="text-sm text-gray-900">
                          {numberFormat(goal.currentvalue)} €
                        </div>
                      </Tooltip>
                    </td>
                    <td className="whitespace-nowrap pl-0">
                      {percentage < 100 ? (
                        <Progress
                          percent={percentage}
                          color={
                            percentage < 10
                              ? "#FE6C6C"
                              : percentage < 50
                              ? "#FF9364"
                              : percentage < 80
                              ? "#FFD572"
                              : "#00B327"
                          }
                          text={numberFormat(goal.targetvalue) + " € "}
                        />
                      ) : (
                        <Progress
                          percent={percentage}
                          color="#00B327"
                          text=""
                        />
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="absolute bottom-2 right-4 flex justify-end">
            <Pagination
              current={currentPage}
              total={paginatedData.length}
              pageSize={pageSize}
              onChange={handleChangePage}
              showSizeChanger={false}
              size="small"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default GoalsQuantities;
