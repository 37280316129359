import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { actionAcceptInvitationViewOnly } from "../../redux/actions/UserAction";

export const InvitedOnlyView = () => {
  const [token, setToken] = useState(null);
  const [error, setError] = useState(false);
  const [today, setToday] = useState("");
  const [lastWeek, setLastWeek] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    setToken(searchParams.get("noreply"));
    setToday(searchParams.get("end"));
    setLastWeek(searchParams.get("start"));
    if (!searchParams.get("noreply")) {
      navigate("/");
    }
if(today && lastWeek) {
    localStorage.setItem('today', today);
    localStorage.setItem('lastWeek', lastWeek);
}
  }, []);
  useEffect(() => {
    dispatch(
      actionAcceptInvitationViewOnly(token, (status) => {
        if (status === 200) {
          navigate(`/board?today=${today}&lastweek=${lastWeek}`, {
            replace: true,
          });
        } else {
          setError(true);
        }
      })
    );
  }, [token, today, lastWeek]);
  return <></>;
};
