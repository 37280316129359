import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";
import Header from "../Header";
import Modal from "../Views/Modal";
// import useState from "react-usestateref";
import { useSelector, useDispatch } from "react-redux";
import {
  actionGetUser,
  actionUpdateUser,
  actionChangePassword2,
} from "../../redux/actions/UserAction";

import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import Visibility from "@material-ui/icons/Visibility";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Input from "@material-ui/core/Input";

import { useSnackbar } from "notistack";
const _ = require("lodash");

let theme = "1";
const data = localStorage.getItem("theme");

if (data) {
  theme = JSON.parse(data).theme;
}

export default function Profile() {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const userid = useSelector((data) => data.user.id);
  const token = useSelector((state) => state.user.token);
  const useremail = useSelector((data) => data.user.email);
  const userfname = useSelector((data) => data.user.firstName);
  const userlname = useSelector((data) => data.user.lastName);
  const userphone = useSelector((data) => data.user.telephone);
  const usercompany = useSelector((data) => data.user.company);
  const isUpdate = useSelector((state) => state.user.isUpdate);
  // const userpassword = useSelector(data => data.user.password);

  const [new_password, setNewPassword] = useState("");
  const [old_password, setOldPassword] = useState("");

  const userdata = useRef({});
  const dispatch = useDispatch();

  const [fname, setFName] = useState("");
  // const [fname, setFName] = useState(userfname);

  const [lname, setLName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [updater, setUpdater] = useState(false);
  const [updater2, setUpdater2] = useState(false);
  const [userfile, setUserfile] = useState(
    "https://cdn4.iconfinder.com/data/icons/famous-character-vol-2-flat/48/Avatar_Famous_Characters-07-256.png"
  );

  const update_Cancel = () => {
    setUpdater(false);
  };
  const update_Cancel2 = () => {
    setUpdater2(false);
  };

  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });
  const [values2, setValues2] = React.useState({
    password2: "",
    showPassword2: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handlePasswordChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword2 = () => {
    setValues2({ ...values2, showPassword2: !values2.showPassword2 });
  };

  const handleMouseDownPassword2 = (event) => {
    event.preventDefault();
  };

  const handlePasswordChange2 = (prop) => (event) => {
    setValues2({ ...values2, [prop]: event.target.value });
  };

  const updateUser = (e) => {
    e.preventDefault();

    dispatch(
      actionUpdateUser(
        {
          id: userid,
          firstName: fname,
          lastName: lname,
          email: email,
          telephone: phone,
        },
        token,
        (status) => {
          if (status === 200) {
            //   alert("data updated");
            enqueueSnackbar("Les modifications demandées ont été effectuées", {
              variant: "success",
            });
          } else {
            // alert("data not updated");
            enqueueSnackbar("Les modifications  n'ont pas été effectuées !", {
              variant: "warning",
            });
          }
        }
      )
    );

    // console.log("Inside update user");
  };

  const updatePassword = (e) => {
    e.preventDefault();
    // console.log(values2.password2.length, "--result--",values2.password2);
    // if(new_password.length >= 8){
    dispatch(
      actionChangePassword2(
        old_password,
        new_password,
        userid,
        token,
        (status) => {
          if (status === 200) {
            enqueueSnackbar("Le mot de passe a été modifé", {
              variant: "success",
            });
          } else {
            enqueueSnackbar("Le mot de passe n'a pas été modifié ", {
              variant: "warning",
            });
          }
          cancelPassword();
        }
      )
    );
    /*  }
         else{
            enqueueSnackbar("Le mot de passe doit contenir au minimum 8 caractères", {
                variant: "warning",
              });
        } */
  };

  const cancelPassword = () => {
    setNewPassword("");
    setOldPassword("");
    // document.getElementById("new_password").value = "";
    // document.getElementById("old_password").value = "";
  };

  useEffect(() => {
    dispatch(actionGetUser(userid, token));
  }, []);

  useEffect(() => {
    setFName(userfname);
    setLName(userlname);
    setPhone(userphone);
    setEmail(useremail);
  }, [useremail, userfname, userlname, userphone, usercompany]);

  return (
    <main>
      <Header />
      <div class="filtre"></div>
      <div class="container">
        <div class="head">
          <div class="titre">
            <h1 class="title-1">Profil</h1>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-4">
            <div class="wgwhite profilecard text-center">
              <div class="user_profile">
                <img src={userfile} type="file" alt="" />
              </div>
              {/* <a className='change-picture' onClick={() => document.getElementById('getFile').click()}>Changer La Photo</a> */}
              {/* <input type='file' onChange={(e) => setUserfile(e.target.files[0])} id="getFile" style={{ display: "none" }} /> */}
              {/* <input
                                
                                type='file'
                                name='file'
                                onChange={(e) => alert(e.target.value)}
                               
                                className='file btn'
                           
                            /> */}
            </div>
          </div>
          <div class="col-xl-8 wgwhite p15 cardsblock">
            <div
              class="bloc-border-blanc bloc-large"
              // style={{border: "none", backgroundColor: "none", borderRadius: "none"
              // }}
              style={{
                border: "none",
                backgroundColor: theme === "0" ? "#242731" : "none",
                borderRadius: "none",
              }}
            >
              <div class="bloc-head">
                <div class="col-xs-12">
                  <h3 style={{ color: theme === "0" ? "#fff" : "none" }}>
                    {fname} {userlname}
                  </h3>
                  {/* <p>Company Name</p> */}
                </div>
                <div class="add_list">
                  <button
                    class="btn-full"
                    type="submit"
                    onClick={() => setUpdater(true)}
                  >
                    Modifier Le Profile
                  </button>
                </div>
              </div>
              <div class="col-xs-12">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                  <li class="nav-item" role="presentation">
                    <a
                      className="my-navigator nav-link active"
                      id="home-tab"
                      data-toggle="tab"
                      data-target="#home"
                      type="button"
                      role="tab"
                      aria-controls="home"
                      aria-selected="true"
                    >
                      A Propos
                    </a>
                  </li>
                  <li class="nav-item" role="presentation">
                    <a
                      className="my-navigator nav-link"
                      id="profile-tab"
                      data-toggle="tab"
                      data-target="#profile"
                      type="button"
                      role="tab"
                      aria-controls="profile"
                      aria-selected="false"
                    >
                      Modifier le mot de passe
                    </a>
                  </li>
                </ul>
                <div class="tab-content user_profile_data" id="myTabContent">
                  <div
                    class="tab-pane fade show active"
                    id="home"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                  >
                    <ul class="user_info">
                      <li>
                        <div>Nom et prenom</div>
                        <div>
                          {fname} {userlname}
                        </div>
                      </li>
                      <li>
                        <div>Email</div>
                        <div>{useremail}</div>
                      </li>
                      <li>
                        <div>Phone</div>
                        <div>{userphone}</div>
                      </li>
                      <li>
                        <div>Status</div>
                        <div>Actif</div>
                      </li>
                    </ul>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="profile"
                    role="tabpanel"
                    aria-labelledby="profile-tab"
                  >
                    <div className="centering">
                      <form onSubmit={updatePassword}>
                        <br />
                        <p style={{ marginLeft: "30px", fontSize: "16px" }}>
                          Modifier le mot de passe
                        </p>
                        <br />
                        <span
                          style={{ width: "250px", height: "25px" }}
                          className="span-block picto-password"
                        >
                          <input
                            className="input-default inputCustom"
                            id="password"
                            type="password"
                            style={{
                              backgroundColor: theme === "0" ? "#fff" : "none",
                            }}
                            value={old_password}
                            onChange={(e) => setOldPassword(e.target.value)}
                            required
                            placeholder="Ancien mot de passe"
                            minLength={8}
                          />
                          <i
                            style={{ paddingTop: "30px" }}
                            className="fa-regular fa-eye"
                            onClick={(e) => {
                              if (
                                document.getElementById("password").type ===
                                "password"
                              ) {
                                e.target.className = "fa fa-eye-slash";
                                document.getElementById("password").type =
                                  "text";
                              } else {
                                e.target.className = "fa-regular fa-eye";
                                document.getElementById("password").type =
                                  "password";
                              }
                            }}
                          ></i>
                        </span>
                        <br />
                        <br />
                        <span
                          style={{ width: "250px", height: "25px" }}
                          className="span-block picto-password"
                        >
                          <input
                            id="password1"
                            className="input-default inputCustom"
                            type="password"
                            style={{
                              backgroundColor: theme === "0" ? "#fff" : "none",
                            }}
                            value={new_password}
                            onChange={(e) =>
                              setNewPassword(e.target.vpasswordalue)
                            }
                            required
                            placeholder="Nouveau mot de passe"
                            minLength={8}
                          />
                          <i
                            style={{ paddingTop: "30px" }}
                            className="fa-regular fa-eye"
                            onClick={(e) => {
                              if (
                                document.getElementById("password1").type ===
                                "password"
                              ) {
                                e.target.className = "fa fa-eye-slash";
                                document.getElementById("password1").type =
                                  "text";
                              } else {
                                e.target.className = "fa-regular fa-eye";
                                document.getElementById("password1").type =
                                  "password";
                              }
                            }}
                          ></i>
                        </span>

                        <br />
                        <br />
                        <br />

                        <button
                          type="button"
                          style={{
                            marginLeft: "8px",
                            marginRight: "13px",
                            paddingLeft: "27px",
                            paddingRight: "27px",
                          }}
                          class="btn-full"
                          onClick={() => cancelPassword()}
                        >
                          Annuler
                        </button>
                        <button
                          style={{
                            paddingLeft: "27px",
                            paddingRight: "27px",
                          }}
                          class="btn-full"
                          type="submit"
                        >
                          Modifier
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        visible={updater}
        title={"Modifier la source de donnée"}
        onCancel={isUpdate ? null : update_Cancel}
      >
        <form onSubmit={updateUser}>
          <div className="row">
            <div className="col-md-12">
              <div className="input-container">
                <label for="nom" className="label-default">
                  Entrer le nouveau nom
                </label>
                <span className="span-block">
                  <input
                    className="input-default inputCustom"
                    name="nom"
                    id="nom"
                    type="text"
                    value={fname}
                    onChange={(e) => setFName(e.target.value)}
                    placeholder="Obligatoire"
                    required
                  />
                </span>
                <br />
                <label for="nom" className="label-default">
                  Entrer le nouveau prenom
                </label>
                <span className="span-block">
                  <input
                    className="input-default inputCustom"
                    name="name"
                    id="lname"
                    type="text"
                    value={lname}
                    onChange={(e) => setLName(e.target.value)}
                    placeholder="Obligatoire"
                    required
                  />
                </span>
                <br />
                <label for="nom" className="label-default">
                  Entrer la nouveau Email
                </label>{" "}
                <span className="span-block">
                  <input
                    className="input-default inputCustom"
                    name="email"
                    id="email"
                    type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Obligatoire"
                    required
                  />
                </span>
                <br />
                <label for="nom" className="label-default">
                  Entrer le nouveau numero de telephone
                </label>
                <span className="span-block">
                  <input
                    className="input-default inputCustom"
                    name="phone"
                    id="phone"
                    type="text"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    placeholder="Obligatoire"
                    required
                  />
                </span>
              </div>
            </div>
            <div className="col-md-12 wrap-btn-modal">
              <div className="btn-container">
                <button
                  className="btn-border close-btn-submit"
                  type="reset"
                  disabled={isUpdate}
                  onClick={isUpdate ? null : update_Cancel}
                >
                  Retour{" "}
                </button>

                <button className="btn-full" type="submit" disabled={isUpdate}>
                  {" "}
                  {isUpdate ? "Loading..." : "Modifier"}
                </button>
              </div>
            </div>
          </div>
        </form>
      </Modal>
    </main>
  );
}
