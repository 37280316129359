import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function Footer() {
  const { t } = useTranslation();
  return (
    <footer>
      <div className="container">
        <div className="content">
          <Link title="CGU" target="_self" to="">
            Les conditions générales d’utilisation
          </Link>{" "}
          <p>• Viabber {new Date().getFullYear()}</p>
        </div>
        {/* <!-- /.content --> */}
      </div>
      {/* <!-- /.container --> */}
    </footer>
  );
}
