import React, { useState, useEffect } from "react";
// import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import ModalIndicator from "./ModalIndicator";
import { Chart } from "react-google-charts";
import { Draggable } from "react-dnd-beautiful";
import PropagateLoader from "react-spinners/PropagateLoader";
import { useNavigate } from "react-router-dom";
import {
  actionSetIndicator,
  actionSetComments,
} from "../../redux/actions/indicatorAction";

import {
  FullscreenOutlined,
  // DeleteOutlined,
  // ShareAltOutlined,
} from "@ant-design/icons";
import { actionSetGoals } from "../../redux/actions/GoalAction";
import { Progress } from "antd";
import { numberFormat, regroupe, regroupeByInterval } from "./IndicatorView";
import RadioButton from "./RadioButton";
const numeral = require("numeral");
const _ = require("lodash");

let theme = "1";
const data = localStorage.getItem("theme");
if (data) {
  theme = JSON.parse(data).theme;
}

export default function CardLarge({
  data,
  key,
  index,
  setIsDraggable,
  isDraggable,
}) {
  // const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [visibility, setVisibility] = useState(false);
  const [value, setValue] = useState("1");
  const isViewOnly = useSelector((state) => state.board.isViewOnly);
  const timeInterval = useSelector((state) => state.time.interval);
  const timeValue = useSelector((state) => state.time.value);
  const idUser = useSelector((state) => state.user.id);
  const options = [
    { label: "Jour", value: "1" },
    { label: "Semaine", value: "4" },
    { label: "Mois", value: "2" },
    { label: "Année", value: "3" },
  ];

  const onCancel = () => {
    setIsDraggable(true);
    setVisibility(false);
  };

  const renderLastValue = () => {
    const { total, lastValue } = data;
    const pourcentage = ((total - lastValue) * 100) / lastValue;

    const periode = [
      "Hier",
      "La semaine dernière",
      "Le mois dernier",
      "Le trimestre dernier",
      "Le semestre dernier",
      "L'année dernière",
    ];

    let text = "";

    const nbre = parseInt(timeValue) - 1;

    if (nbre >= 0) {
      text = periode[nbre];
    } else {
      text = `du ${new Date(timeInterval[0]).toISOString().split("T")[0]} au ${
        new Date(timeInterval[1]).toISOString().split("T")[0]
      }`;
    }

    if (total > lastValue) {
      return (
        <span
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img alt="haut" src="/haut.png" height={"20px"} />
          <i
            style={{
              color: "#2ECC71",
              fontStyle: "normal",
              fontWeight: "bold",
              fontSize: "15px",
              display: "inline-block",
              marginLeft: "10px",
            }}
          >
            {numberFormat(pourcentage)}%{" "}
            <i
              style={{
                color: parseInt(theme) === 0 ? "#FFF" : "#000",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "12px",
                display: "inline-block",
                marginLeft: "10px",
              }}
            >
              {text}
            </i>
          </i>
        </span>
      );
    } else {
      return (
        <span
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img alt="bas" src="/bas.png" height={"20px"} />
          <i
            style={{
              color: "#E74C3C",
              fontStyle: "normal",
              fontWeight: "bold",
              fontSize: "15px",
              display: "inline-block",
              marginLeft: "10px",
            }}
          >
            {numberFormat(pourcentage)}%{" "}
            <i
              style={{
                color: parseInt(theme) === 0 ? "#FFF" : "#000",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "12px",
                display: "inline-block",
                marginLeft: "10px",
              }}
            >
              {text}
            </i>
          </i>
        </span>
      );
    }
  };

  const getAxe = () => {
    let axe = null;
    for (let index = 0; index < data.columns.length; index++) {
      const element = data.columns[index];
      if (parseInt(element.idcolumn) === parseInt(data.idaxe)) {
        axe = element;
        break;
      }
    }
    return axe;
  };

  useEffect(() => {
    if (data) {
      if (data.showperiode) {
        setValue(data.periode);
      } else {
        setValue("1");
      }
    }
  }, [data]);

  return (
    <>
      <ModalIndicator
        visible={visibility}
        onCancel={onCancel}
        data={data}
        setVisible={setVisibility}
      />
      <Draggable
        draggableId={data.idindicator + ""}
        index={index}
        isDragDisabled={isViewOnly || !idUser || visibility}
      >
        {(provided) => (
          <div
            className={"col-12"}
            key={key}
            id={`indicator-${data.idindicator}`}
            onClick={() => {
              setIsDraggable(false);
              setVisibility(true);
            }}
            ref={provided.innerRef}
            {...provided.dragHandleProps}
            {...provided.draggableProps}
          >
            <div className="bloc-fond-fonce rose">
              {/* Header Begin */}
              <div
                className="indicator_header"
                /* style={{
                  position: "relative",
                  top: "-10px",
                  display: "flex",
                  justifyContent: "space-between",
                }} */
              >
                <div
                  className="indicator_icon"
                  /* style={{
                   position: "relative",
                    display: "flex",
                    justifyContent: "space-around", 
                    textAlign: "right",
                    width: "75px",
                  }} */
                >
                  {/* <DeleteOutlined
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    style={{
                      paddingRight: "10px",
                      fontSize: "17px",
                      color:
                        theme === "0"
                          ? "#fff"
                          : theme === "1"
                          ? "#1f2128"
                          : "#1f2128",
                    }}
                  />
                  <ShareAltOutlined
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    style={{
                      fontSize: "17px",
                      paddingRight: "10px",
                      color:
                        theme === "0"
                          ? "#fff"
                          : theme === "1"
                          ? "#1f2128"
                          : "#1f2128",
                    }}
                  /> */}
                  <FullscreenOutlined
                    onClick={(e) => {
                      e.stopPropagation();
                      sessionStorage.setItem("indicator", JSON.stringify(data));
                      dispatch(actionSetIndicator(data));
                      dispatch(actionSetComments([...data.commentaires]));
                      dispatch(
                        actionSetGoals({ type: "ALL", data: data.goals })
                      );
                      navigate("/indicator");
                    }}
                    style={{
                      fontSize: "17px",
                      paddingRight: "10px",
                      color:
                        theme === "0"
                          ? "#fff"
                          : theme === "1"
                          ? "#1f2128"
                          : "#1f2128",
                    }}
                  />
                  {/* <i
                  className="fa-light fa-ellipsis more"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  style={{
                    paddingRight: "10px",
                    color:
                      theme === "0"
                        ? "#fff"
                        : theme === "1"
                        ? "#1f2128"
                        : "#1f2128",
                    cursor: "pointer",
                  }}
                ></i> */}
                </div>
                {/* <i
                  className="fa-light fa-ellipsis more"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  style={{
                    color:
                      theme === "0"
                        ? "#fff"
                        : theme === "1"
                        ? "#1f2128"
                        : "#1f2128",
                    cursor: "pointer",
                  }}
                ></i> */}
              </div>
              {/* Header End */}

              {/* Title Begin */}
              <div className="row">
                <div
                  className="col-md stats no-padding-right-md"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <p className="title">{data.name}</p>
                  <p
                    className="chiffre"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      fontSize: "27px",
                      margin: "auto",
                    }}
                  >
                    {data && parseInt(data.operation) === 1
                      ? numberFormat(data.total)
                      : data && parseInt(data.operation) === 2
                      ? numberFormat(data.avg)
                      //ajout du counter:
                      : data && parseInt(data.operation) === 4
                      ? numberFormat(data?.data?.length)
                      ////////////////////////////////////////
                      : numberFormat(data.total)}
                      
                    {data && data.symbole !== null && data.symbole !== ""
                      ? ` ${data.symbole} `
                      : " "}
                  </p>
                </div>
              </div>
              {/* Title End */}
              {data.lastValue ? renderLastValue() : null}
              <br />
              <div
                className="h-custom-options"
                hidden={!(data && data.showperiode)}
              >
                {(() => {
                  let axe = null;
                  for (let index = 0; index < data.columns.length; index++) {
                    const element = data.columns[index];
                    if (
                      parseInt(element.idcolumn) === parseInt(data.iddefault)
                    ) {
                      axe = element;
                      break;
                    }
                  }
                  return parseInt(axe.dataType) === 3 ? (
                    <RadioButton
                      onChange={(value) => {}}
                      defaultValue={"1"}
                      value={value}
                      setValue={setValue}
                      options={options}
                    />
                  ) : null;
                })()}
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                  alignItems: "center",
                  width: "100%",
                  marginLeft: "3%",
                  marginRight: "3%",
                  padding: 0,
                  left: 0,
                  right: 0,
                }}
              >
                {/* Chart Begin */}
                <div
                  id="vente_5"
                  className="gchart"
                  style={{ width: "100%", height: "100%", minHeight: "120px" }}
                >
                  {!data.is || data.isLoading ? (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        minHeight: "50px",
                      }}
                    >
                      <PropagateLoader
                        color={
                          theme === "0"
                            ? "#fff"
                            : theme === "1"
                            ? "#1f2128"
                            : "#1f2128"
                        }
                        loading={true}
                        size={5}
                        speedMultiplier={0.75}
                      />
                    </div>
                  ) : (
                    <Chart
                      width={"100%"}
                      chartLanguage="fr"
                      // height={"150px"}
                      chartType={
                        data && data.type === "2"
                          ? "PieChart"
                          : data && data.type === "1"
                          ? "LineChart"
                          : "ComboChart"
                      }
                      loading={true}
                      loader={
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                            minHeight: "50px",
                          }}
                        >
                          <PropagateLoader
                            color={
                              theme === "0"
                                ? "#fff"
                                : theme === "1"
                                ? "#1f2128"
                                : "#1f2128"
                            }
                            loading={true}
                            size={5}
                            speedMultiplier={0.75}
                          />
                        </div>
                      }
                      columns={(() => {
                        if (data && data.type === "2") {
                          return [
                            { label: data.cols[0].label, type: "string" },
                            data.cols[1],
                          ];
                        }
                        if (
                          data.goals.length > 0 &&
                          parseInt(data.operation) === 1 &&
                          !data.notshowcumulwithgoal
                        ) {
                          return [
                            {
                              label: data.cols[0].label,
                              type:
                                value === "1" || !value
                                  ? data.cols[0].type
                                  : "string",
                            },
                            data.cols[1],
                            { label: "cumul", type: "number" },
                            { label: "Objectif", type: "number" },
                          ];
                        } else if (
                          data.goals.length > 0 &&
                          (parseInt(data.operation) === 2 ||
                            data.notshowcumulwithgoal)
                        ) {
                          return [
                            {
                              label: data.cols[0].label,
                              type:
                                value === "1" || !value
                                  ? data.cols[0].type
                                  : "string",
                            },
                            data.cols[1],
                            { label: "Objectif", type: "number" },
                          ];
                        } else if (
                          data.showcumul &&
                          parseInt(data.operation) !== 2
                        ) {
                          return [
                            {
                              label: data.cols[0].label,
                              type:
                                value === "1" || !value
                                  ? data.cols[0].type
                                  : "string",
                            },
                            data.cols[1],
                            { label: "cumul", type: "number" },
                          ];
                        }
                        return [
                          {
                            label: data.cols[0].label,
                            type:
                              value === "1" || !value
                                ? data.cols[0].type
                                : "string",
                          },
                          data.cols[1],
                        ];
                      })()}
                      rows={(() => {
                        if (data && data.type === "2") {
                          // si le graph est de type pie
                          return data.rows;
                        }

                        if (data.goals.length > 0) {
                          // si l'indicateur à au moins un(1) objectif
                          let finish = [];
                          let total = 0;
                          let noPeriodique = _.sortBy(data.goals, [
                            (o) => o.startdate,
                          ]);
                          // let item = noPeriodique[0];
                          let last = null;
                          if (value === "1" || !value) {
                            for (let i = 0; i < data.rows.length; i++) {
                              const row = data.rows[i];
                              let item = null;
                              for (let i = 0; i < noPeriodique.length; i++) {
                                const goal = noPeriodique[i];
                                let start = new Date(goal.startdate).getTime();
                                let end = new Date(goal.enddate).getTime();
                                let current = new Date(row[0]).getTime();
                                if (current >= start && current <= end) {
                                  // if (!last || goal.idgoal != last.idgoal) {
                                  //   total = 0;
                                  // }
                                  item = goal;
                                  last = goal;
                                  break;
                                } else if (i == noPeriodique.length - 1) {
                                  // if (last) {
                                  //   total = 0;
                                  // }
                                  last = null;
                                }
                              }
                              total += row[1];
                              // if (!item) {
                              //   total = null;
                              // }
                              if (
                                parseInt(data.operation) === 1 &&
                                !data.notshowcumulwithgoal
                              ) {
                                finish.push([
                                  ...row,
                                  total,
                                  item ? item.targetvalue : null,
                                ]);
                              } else {
                                finish.push([
                                  ...row,
                                  item ? item.targetvalue : null,
                                ]);
                              }
                            }
                          } else {
                            const rows = regroupe(data.rows, value);
                            const intervals = regroupeByInterval(
                              data.rows,
                              value
                            );
                            for (let i = 0; i < rows.length; i++) {
                              const row = rows[i];
                              const interval = intervals[i];
                              let item = null;
                              for (let i = 0; i < noPeriodique.length; i++) {
                                const goal = noPeriodique[i];
                                if (value === "2") {
                                  // for month
                                  let start = new Date(goal.startdate);
                                  let end = new Date(goal.enddate);
                                  if (
                                    (start.getMonth() == interval.month &&
                                      start.getFullYear() == interval.year) ||
                                    (end.getMonth() == interval.month &&
                                      end.getFullYear() == interval.year)
                                  ) {
                                    // if (!last || goal.idgoal != last.idgoal) {
                                    //   total = 0;
                                    // }
                                    item = goal;
                                    last = goal;
                                    break;
                                  }
                                } else if (value === "3") {
                                  // for year
                                  let start = new Date(goal.startdate);
                                  let end = new Date(goal.enddate);
                                  if (
                                    start.getFullYear() === interval.year ||
                                    end.getFullYear() === interval.year
                                  ) {
                                    // if (!last || goal.idgoal != last.idgoal) {
                                    //   total = 0;
                                    // }
                                    item = goal;
                                    last = goal;
                                    break;
                                  }
                                } else if (value === "4") {
                                  // for week
                                  let start = new Date(
                                    goal.startdate
                                  ).getTime();
                                  let end = new Date(goal.enddate).getTime();
                                  let current = interval.fin;
                                  if (current >= start && current <= end) {
                                    // if(!last || (goal.idgoal != last.idgoal)){
                                    //   total=0;
                                    // }
                                    item = goal;
                                    last = goal;
                                    break;
                                  }
                                } else {
                                  let start = new Date(
                                    goal.startdate
                                  ).getTime();
                                  let end = new Date(goal.enddate).getTime();
                                  let current = new Date(row[0]).getTime();
                                  if (current >= start && current <= end) {
                                    if (!last || goal.idgoal != last.idgoal) {
                                      total = 0;
                                    }
                                    item = goal;
                                    last = goal;
                                    break;
                                  }
                                }
                                if (i === noPeriodique.length - 1) {
                                  // if(last){
                                  //   total = 0;
                                  // }
                                  last = null;
                                }
                              }
                              total += row[1];
                              if (!item) {
                                total = null;
                              }
                              if (
                                parseInt(data.operation) === 1 &&
                                !data.notshowcumulwithgoal
                              ) {
                                finish.push([
                                  ...row,
                                  total,
                                  item ? item.targetvalue : null,
                                ]);
                              } else {
                                finish.push([
                                  ...row,
                                  item ? item.targetvalue : null,
                                ]);
                              }
                            }
                          }
                          return finish;
                        } else if (
                          data.showcumul &&
                          parseInt(data.operation) !== 2
                        ) {
                          // si l'obtient voir cumul est active
                          let finish = [];
                          let total = 0;
                          if (value === "1" || !value) {
                            for (let i = 0; i < data.rows.length; i++) {
                              const row = data.rows[i];
                              total += row[1];
                              finish.push([...row, total]);
                            }
                          } else {
                            const rows = regroupe(data.rows, value);
                            for (let i = 0; i < rows.length; i++) {
                              const row = rows[i];
                              total += row[1];
                              finish.push([...row, total]);
                            }
                          }
                          return finish;
                        }

                        if (value === "1" || !value) {
                          return data.rows;
                        } else {
                          return regroupe(data.rows, value);
                        }
                      })()}
                      options={{
                        chartArea: {
                          height:
                            data && parseInt(data.type) === 2 ? "95%" : "70%",
                          width:
                            data && parseInt(data.type) === 2 ? "95%" : "80%",
                        },
                        crosshair: { trigger: "both", orientation: "both" },
                        // trendlines: {
                        //   0: {
                        //     // type: "linear",
                        //     // type: "exponential",
                        //     type: "polynomial",
                        //     degree: 3,
                        //     color: "green",
                        //     // color: "#e22749",
                        //     // opacity: 1,
                        //     // pointsVisible: true
                        //   },
                        //   // n: {
                        //   //   pointsVisible: false
                        //   // }
                        // },
                        hAxis: {
                          gridlines: {
                            // count: 0,
                            color: "transparent",
                          },
                          minorGridlines: {
                            count: 0,
                          },
                          textStyle: {
                            color:
                              theme === "0"
                                ? "#FFF"
                                : theme === "1"
                                ? "black"
                                : "black",
                          },
                        },
                        ...(data && data.type === "0"
                          ? {
                              seriesType: "bars",
                              series: {
                                1: { type: "line" },
                                2: { type: "line" },
                              },
                            }
                          : {}),
                        vAxis: {
                          baselineColor: theme === "0" ? "#FFF" : "#000",
                          minorGridlines: {
                            count: 0,
                          },
                          textStyle: {
                            color:
                              theme === "0"
                                ? "#FFF"
                                : theme === "1"
                                ? "#1f2128"
                                : "#1f2128",
                          },
                        },
                        backgroundColor: "transparent",
                        colors: [
                          "#05BAF3",
                          // theme === "0" || theme === "1"
                          //   ? "#d02173"
                          //   : "#007FC1",
                          ...(data &&
                          (parseInt(data.operation) === 2 ||
                            data.notshowcumulwithgoal)
                            ? ["#25F700", "blue"]
                            : ["blue", "#25F700"]),
                          "#2979FF",
                          "#C62828",
                          "#F50057",
                          "#AB47BC",
                          "#4527A0",
                          "#FFA726",
                          "#EF5350",
                          "#FF3D00",
                          "#0277BD",
                          "#C6FF00",
                          "#EF6C00",
                          "#AD1457",
                          "#00B0FF",
                          "#D4E157",
                          "#283593",
                          "#3D5AFE",
                          "#6A1B9A",
                          "#42A5F5",
                          "#5C6BC0",
                          "#9E9D24",
                          "#FF7043",
                          "#651FFF",
                          "#7E57C2",
                          "#FF9100",
                          "#FF1744",
                          "#1565C0",
                          "#D500F9",
                          "#FF3D00",
                          "#EC407A",
                        ],
                        pieSliceText: "label",
                        legend:
                          data && parseInt(data.type) === 2
                            ? {
                                position: "left",
                                textStyle: {
                                  color: theme === "0" ? "#FFF" : "#000",
                                  fontSize: 12,
                                },
                              }
                            : {
                                position: "none",
                              },
                      }}
                    />
                  )}
                </div>
                {/* Chart End */}

                {/* Objectif Begin */}
                <div className="col-md-auto">
                  {data && data.goals && data.goals.length > 0
                    ? (() => {
                        let noPeriodique = _.sortBy(data.goals, [
                          (o) => o.startdate,
                        ]);
                        let item = null;
                        let current = new Date().getTime();
                        for (let i = 0; i < noPeriodique.length; i++) {
                          const goal = noPeriodique[i];
                          let start = new Date(goal.startdate).getTime();
                          let end = new Date(goal.enddate).getTime();
                          if (current >= start && current <= end) {
                            item = goal;
                          }
                        }
                        if (!item) {
                          let end = new Date(noPeriodique[0].enddate).getTime();
                          if (current > end) {
                            item = noPeriodique[noPeriodique.length - 1];
                          } else {
                            item = noPeriodique[0];
                          }
                        }
                        return (
                          <div
                            className="bloc-border-blanc-inner"
                            title={`Objectif du ${item.startdate} au ${item.enddate}`}
                          >
                            <p className="objectif2">Objectif</p>
                            <p className="objectif">{`${item.enddate}`}</p>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <Progress
                                type="circle"
                                width="50px"
                                percent={
                                  item.currentvalue
                                    ? _.round(
                                        (item.currentvalue / item.targetvalue) *
                                          100,
                                        1
                                      )
                                    : 0
                                }
                                strokeColor={"#05BAF3"}
                                trailColor={"#CCD4E2"}
                                format={(percent) => {
                                  return (
                                    <i
                                      style={{
                                        color:
                                          theme === "0"
                                            ? "#fff"
                                            : theme === "1"
                                            ? "#1f2128"
                                            : "#1f2128",
                                        fontSize: "12px",
                                      }}
                                    >
                                      {_.round(
                                        (item.currentvalue / item.targetvalue) *
                                          100,
                                        1
                                      ) + "%"}
                                    </i>
                                  );
                                }}
                              />
                            </div>
                            <p
                              className="t-center"
                              style={{ color: "#25F700" }}
                            >
                              <br />
                              {item.targetvalue
                                ? numeral(item.targetvalue)
                                    .format("0,0")
                                    .split(",")
                                    .join(" ")
                                : 0}
                            </p>
                          </div>
                        );
                      })()
                    : null}
                </div>
                {/* Objectif End */}
              </div>

              {data &&
              data.goals &&
              data.type !== "2" &&
              data.goals.length > 0 ? (
                <div
                  style={{
                    height: "25px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <p>
                    <span
                      style={{
                        display: "inline-block",
                        width: "10px",
                        height: "10px",
                        backgroundColor: "#25F700",
                      }}
                    ></span>{" "}
                    <i style={{ fontSize: "15px" }}>Objectif</i>
                  </p>

                  {parseInt(data.operation) === 1 &&
                  !data.notshowcumulwithgoal ? (
                    <p>
                      <span
                        style={{
                          display: "inline-block",
                          width: "10px",
                          height: "10px",
                          marginLeft: "10px",
                          backgroundColor: "blue",
                        }}
                      ></span>{" "}
                      <i style={{ fontSize: "15px" }}>Cumul</i>
                    </p>
                  ) : null}

                  <p>
                    <span
                      style={{
                        display: "inline-block",
                        width: "10px",
                        height: "10px",
                        marginLeft: "10px",
                        backgroundColor: "#05BAF3",
                        // backgroundColor:
                        //   theme === "0" || theme === "1"
                        //     ? "#d02173"
                        //     : "#007FC1",
                      }}
                    ></span>{" "}
                    <i style={{ fontSize: "15px" }}>Réalisé</i>
                  </p>
                </div>
              ) : null}

              {data &&
              data.showcumul &&
              parseInt(data.operation) !== 2 &&
              data.type !== "2" &&
              !(data.goals.length > 0) ? (
                <div
                  style={{
                    height: "25px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <p>
                    <span
                      style={{
                        display: "inline-block",
                        width: "10px",
                        height: "10px",
                        marginLeft: "10px",
                        backgroundColor: "blue",
                      }}
                    ></span>{" "}
                    <i style={{ fontSize: "15px" }}>Cumul</i>
                  </p>

                  <p>
                    <span
                      style={{
                        display: "inline-block",
                        width: "10px",
                        height: "10px",
                        marginLeft: "10px",
                        backgroundColor: "#05BAF3",
                      }}
                    ></span>{" "}
                    <i style={{ fontSize: "15px" }}>
                      {data && getAxe() ? getAxe().name : null}
                    </i>
                  </p>
                </div>
              ) : null}

              {/* {data.lastValue ? renderLastValue() : null} */}
            </div>
          </div>
        )}
      </Draggable>
    </>
  );
}
