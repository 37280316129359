const fr = {
    translation: {
        "404": "La page n'existe pas",
        "add": "Ajouter",
        "add_board": "Ajouter un tableau",
        "add_indicator": "Ajouter un nouvel indicateur",
        "add_list": "Ajouter une liste",
        "all_required": "Tous les champs sont obligatoires",
        "bad_insert": "Une erreur est survenue lors de l'insertion",
        "bad_login": "Une erreur est survenue lors de la connexion",
        "bad_register": "Une erreur est survenue lors de l'inscription",
        "bar_graph": "graph en barre",
        "cancel": "Annuler",
        "conflit_register": "L'email est déja utilisé",
        "connectors": "Connecteurs",
        "data_src": "Datasets",
        "delete": "Supprimer",
        "description": "Description",
        "empty": "Aucun tableau",
        "good_insert": "Insertion réalisée avec succès",
        "good_login": "Connexion réalisée avec succes",
        "good_register": "Inscription réalisée avec succès",
        "home": "Accueil",
        "incorrect_identifiers": "Indiantifiants incorrectes",
        "insert_name": "Entrer un nom",
        "invite": "Inviter",
        "invited_title": "Inviter sur le tableau",
        "language": "Langue",
        "line_graph": "graph linéaire",
        "mail_title": "Adresse email",
        "manager": "Responsable",
        "members": "Membres",
        "min_password": "Le mot de passe doit avoir au minimum 8 caractéres",
        "name_new": "Nom du nouvel element",
        "new_product": "Nouveau produit",
        "objectifs": "Objectifs",
        "password_not_confirm": "Le mot de passe et le mot de passe de confirmation de sont pas égale",
        "planche": "Planches",
        "required": "Veuillez remplir le champ",
        "select_graph": "Selectionner un graph",
        "test": "Bonjour le monde",
        "update": "Modifier",
        "update_indicator": "Modifier l'indicateur"
    }
}
export default fr;