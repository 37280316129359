import { useState } from "react";
import Header from "../Header";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { EditText } from "react-edit-text";
import Popover from "@mui/material/Popover";
import { Tooltip, Switch, Checkbox } from "antd";
import Avatar from "antd/lib/avatar/avatar";
import stc from "string-to-color";
import ModalPrint from "../Views/ModalPrint";
import moment from "moment";
import CalendarView from "../Views/CalendarView";
import ManagerPrime from "./ManagerPrime";
import PrimesSetings from "./ParametragePrimes/";
import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import {
  actionSetTime,
  actionSetTimeValue,
} from "../../redux/actions/timeAction";
import Modal from "../Views/Modal";
import CustomSelect from "../Views/CustomSelect";
import ModalAddSource from "../Views/ModalAddSource";
import { isInteger, parseInt } from "lodash";
import {
  actionAddDatasource,
  actionSetDataDatasources,
} from "../../redux/actions/datasourceAction";
import { actionSetLists } from "../../redux/actions/listAction";
import {
  actionAddIndicator,
  actionSetIndicatorData,
  actionGetDataByFilter,
  actionGetTotalByFilter,
} from "../../redux/actions/indicatorAction";
import { actionGetConnectors } from "../../redux/actions/connectorAction";
import { actionGetDatasources } from "../../redux/actions/datasourceAction";
import ModalShared from "../Views/ModalShared";
import Snackbar from "@mui/material/Snackbar";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Alert from "@mui/material/Alert";
import SwitchMUI from "@mui/material/Switch";
import { useSnackbar } from "notistack";
import RadioButton from "../Views/RadioButton";
import useReceive from "../DataSource/useReceive";
import useCheck from "../DataSource/useCheck";
import Bar from "../../Bar_graph.svg";
import Linear from "../../Linear_graph.svg";
import Pie from "../../circle.png";
import Member from "../Views/Member";
import BonusWrapper from "./BonusWrapper";
import { actionGetBonusTransactionsByUser } from "../../redux/actions/bonusTransactionAction";

let theme = "1";
const data = localStorage.getItem("theme");

if (data) {
  theme = JSON.parse(data).theme;
}

export const getIntervalByValue = (value) => {
  if (value !== "0") {
    let a = moment();
    let b = moment();
    switch (value) {
      case "1":
        a.hours(0);
        b.hours(23);

        a.minutes(0);
        b.minutes(59);

        a.seconds(0);
        b.seconds(0);

        a.milliseconds(0);
        b.milliseconds(0);

        break;

      case "2":
        a.hours(0);
        b.hours(23);

        a.minutes(0);
        b.minutes(59);

        a.seconds(0);
        b.seconds(59);

        a.milliseconds(0);
        b.milliseconds(0);

        a.weekday(0);
        b.weekday(7);

        break;

      case "3":
        a.hours(0);
        b.hours(23);

        a.minutes(0);
        b.minutes(59);

        a.seconds(0);
        b.seconds(59);

        a.milliseconds(0);
        b.milliseconds(0);

        a.date(1);
        b.date(1);

        b.month(b.month() + 1);

        break;

      case "4":
        a.hours(0);
        b.hours(23);

        a.minutes(0);
        b.minutes(59);

        a.seconds(0);
        b.seconds(59);

        a.milliseconds(0);
        b.milliseconds(0);

        a.date(1);
        b.date(1);
        a.month(a.month() - 2);
        b.month(b.month() + 1);

        break;

      case "5":
        a.hours(0);
        b.hours(23);

        a.minutes(0);
        b.minutes(59);

        a.seconds(0);
        b.seconds(59);

        a.milliseconds(0);
        b.milliseconds(0);

        a.date(1);
        b.date(1);
        a.month(a.month() - 5);
        b.month(b.month() + 1);

        break;

      case "6":
        a.hours(0);
        b.hours(23);

        a.minutes(0);
        b.minutes(59);

        a.seconds(0);
        b.seconds(59);

        a.milliseconds(0);
        b.milliseconds(0);

        a.date(1);
        b.date(1);

        a.month(0);
        b.month(0);

        a.month(0);
        b.year(b.year() + 1);

        break;
      default:
        return [null, null];
    }
    return [a, b];
  } else {
    return [null, null];
  }
};

export default function Prime() {
  const [idperiod, setIdPeriod] = useState(null);
  const teams = useSelector((state) => state.team.teams);
  const [userTeams, setUserTeams] = useState([]);
  const [enabled, setEnabled] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [size, setSize, sizeRef] = useState("1");
  const [symbole, setSymbole, symboleRef] = useState("");
  const [sourceName, setSourceName, sourceNameRef] = useState("");
  const [indicatorName, setIndicatorName, indicatorNameRef] = useState("");
  const [showRadioRegroupe, setShowRadioRegroupe, showRadioRegroupeRef] =
    useState(false);
  const [
    radioRegroupeDefault,
    setRadioRegroupeDefault,
    radioRegroupeDefaultRef,
  ] = useState("1");

  const animated = () => {
    let duration = 2000;
    for (let i = 0; i < lists.length; i++) {
      const list = lists[i];
      for (let j = 0; j < list.indicators.length; j++) {
        const indicator = list.indicators[j];
        setTimeout(() => {
          const element = document.getElementById(
            `indicator-${indicator.idindicator}`
          );
          if (element) {
            element.scrollIntoView({
              behavior: "smooth",
              block: "start",
              inline: "center",
            });
          }
        }, duration);
        duration += 2000;
      }
    }
  };

  let navigate = useNavigate();
  const dispatch = useDispatch();

  const pourcent = useSelector((state) => state.datasource.pourcent);
  const visibilityProgress = useSelector(
    (state) => state.datasource.visibilityProgress
  );
  // board state ---->
  const board = useSelector((state) => state.board.dashboard);

  const manager = useSelector((state) => state.board.manager);
  const managerRef = useRef();
  managerRef.current = manager;
  const isDeleteBoard = useSelector((state) => state.board.isDelete);
  const isViewOnly = useSelector((state) => state.board.isViewOnly);
  const isOwner = useSelector((state) => state.board.isOwner);
  // <-----------

  // list state ---->
  const lists = useSelector((state) => state.list.lists);
  const listsRef = useRef();
  listsRef.current = lists;
  // <-----------

  // user state ---->
  const token = useSelector((state) => state.user.token);
  const idUser = useSelector((state) => state.user.id);
  // console.log(teams);
  // <-----------

  // datasource state ---->
  const [idconnector, setIdConnector, idConnectorRef] = useState(null);
  const [url, setUrl, urlRef] = useState(null);
  const datasources = useSelector((state) => state.datasource.datasources);
  const datasourcesRef = useRef();
  datasourcesRef.current = datasources;
  const [refresh, setRefresh, refreshRef] = useState({
    value: false,
    label: "Manuel",
  });
  const [period, setPeriod, periodRef] = useState({
    value: 0,
    label: "Heure",
  });
  const [externalIds, setExternalIds] = useState(null);
  const operationOptions = [
    { value: 1, label: "Remplacer les données." },
    { value: 2, label: "Ajouter les données." },
    { value: 3, label: "Ajout intelligent des données." },
  ];
  const [data_operation, setData_operation, data_operationRef] = useState(
    operationOptions[0]
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const [landa, setLanda] = useState("title-1");
  const [name, setName] = useState("");
  const [value, setValue, periodeRef] = useState("0");
  const [interval, setInterval, intervalRef] = useState([null, null]);
  const [modalSharedVisibility, setModalSharedVisibility] = useState(false);
  const [idlist, setIdlist, idListRef] = useState(null);
  const [deleteVisibility, setDeleteVisibility] = useState(false);
  const [sourceVisibility, setSourceVisibility] = useState(false);
  const [iframeVisibility, setIframeVisibility] = useState(false);
  const [visibility, setVisibility] = useState(false);
  const [isAsync, setIsAsync] = useState(false);
  const [source, setSource, sourceRef] = useState(null);
  const [metric, setMetric, metricRef] = useState(null);
  const [showLastValue, setShowLastValue] = useState(false);
  const [notShowCumulWithGoal, setNotShowCumulWithGoal] = useState(false);
  const [showCumul, setShowCumul] = useState(false);
  const [axeDate, setAxeDate, axeDateRef] = useState(null);
  const [axes, setAxes, axesRef] = useState(null);
  const [type, setType, typeRef] = useState(null);
  const [defaultAxe, setDefaultAxe, defaultAxeRef] = useState(null);
  const [selectedLists, setSelectedLists] = useState([]);
  const [optionsMetric, setOptionsMetric] = useState([]);
  const [optionsAxe, setOptionsAxe] = useState([]);
  const [optionsAxeDate, setOptionsAxeDate] = useState([]);
  const [optionsDatasources, setOptionsDatasources] = useState([]);
  const [color, setColor] = useState(theme === "0" ? "white" : "black");
  const [modifParam, setModifParam] = useState(false);

  const onChangeDate = (interval, value, period = null) => {
    setValue(value);
    setInterval(interval);
    dispatch(actionSetTime(interval));
    dispatch(actionSetTimeValue(value));
    if (interval[0] && interval[1]) {
    }
  };
  const update = () => {
    setAnchorEl(null);
    let element = document.getElementById(
      `input-default-text-editable-selected`
    );
    let evObj = document.createEvent("Events");
    evObj.initEvent("click", true, false);
    element.dispatchEvent(evObj);
    setTimeout(() => {
      document.getElementById(`input-default-text-editable-selected`).focus();
    }, 250);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const cancelSource = () => {
    setVisibility(true);
    setSourceVisibility(false);
    setSourceName("");
    setRefresh({ value: false, label: "Manuel" });
    setPeriod({ value: 0, label: "Heure" });
    setFrequence(1);
  };

  const showIframe = (e) => {
    e.preventDefault();
    setIframeVisibility(true);
    setVisibility(false);
  };

  const cancelIframe = () => {
    setIframeVisibility(false);
    setVisibility(true);
  };

  const handlerChangeDatasource = (data) => {
    setSource(data);
    const { value } = data;
    if (isInteger(parseInt(value))) {
      const index = parseInt(value);
      const src = datasources[index];
      let axesLanda = [];
      let metricsLanda = [];
      let timesLanda = [];
      for (let i = 0; i < src.columns.length; i++) {
        const column = src.columns[i];
        if (column.columnType === "indicator") {
          metricsLanda.push({ value: i, label: column.name });
        } else {
          axesLanda.push({ value: i, label: column.name });
        }
        if (parseInt(column.dataType) === 3) {
          timesLanda.push({ value: i, label: column.name });
        }
      }

      setOptionsAxe(axesLanda);
      setOptionsMetric(metricsLanda);
      setOptionsAxeDate(timesLanda);

      setAxes(null);
      setMetric(null);
      setAxeDate(null);
      setDefaultAxe(null);

      if (timesLanda.length === 1) {
        setAxeDate(timesLanda[0]);
      }

      if (metricsLanda.length === 1) {
        setMetric(metricsLanda[0]);
      }
    }
  };

  const cancelShared = () => {
    setModalSharedVisibility(false);
  };

  // The date
  const defaultData = () => {
    for (let i = 0; i < listsRef.current.length; i++) {
      const list = listsRef.current[i];
      for (let j = 0; j < list.indicators.length; j++) {
        const indicator = list.indicators[j];
        const id = parseInt(indicator.idindicator);
        let axe = null;
        let filter = null;
        let defaultAxe = null;

        for (let index = 0; index < indicator.columns.length; index++) {
          const column = indicator.columns[index];
          if (column.idcolumn == indicator.idaxe) {
            axe = column;
            break;
          }
        }

        for (let index = 0; index < indicator.columns.length; index++) {
          const column = indicator.columns[index];
          if (column.idcolumn == indicator.idfilter) {
            filter = column;
            break;
          }
        }

        if (indicator.iddefault) {
          for (let index = 0; index < indicator.columns.length; index++) {
            const column = indicator.columns[index];
            if (column.idcolumn == indicator.iddefault) {
              defaultAxe = column;
              break;
            }
          }
        } else {
          defaultAxe = { ...filter };
        }
        dispatch(
          actionGetDataByFilter(
            parseInt(axe.iddatasource),
            axe.name,
            {
              name: defaultAxe.name,
              class:
                parseInt(defaultAxe.dataType) === 3 ? "time" : "dimensions",
            },
            [
              {
                name: filter.name,
                type: 3,
                class: "time",
                interval: [
                  new Date(
                    new Date(intervalRef.current[0]).setHours(21, 0, 0, 0)
                  )
                    .toISOString()
                    .split(".")[0],
                  new Date(
                    new Date(intervalRef.current[1]).setHours(21, 0, 0, 0)
                  )
                    .toISOString()
                    .split(".")[0],
                ],
              },
            ],
            (status, result) => {
              if (status === 200) {
                if (result) {
                  const listsNew = [...listsRef.current];
                  for (let a = 0; a < listsNew.length; a++) {
                    const list = listsNew[a];
                    for (let b = 0; b < list.indicators.length; b++) {
                      const indicator = list.indicators[b];
                      if (parseInt(indicator.idindicator) === id) {
                        let columns = [];
                        let rows = [];

                        if (defaultAxe)
                          columns.push({
                            type:
                              defaultAxe.dataType == 1 ||
                              defaultAxe.dataType == 4
                                ? "number"
                                : defaultAxe.dataType == 3
                                ? "datetime"
                                : "string",
                            label: defaultAxe.name,
                          });

                        if (axe)
                          columns.push({
                            type:
                              axe.dataType == 1 || axe.dataType == 4
                                ? "number"
                                : axe.dataType == 3
                                ? "datetime"
                                : "number",
                            label: axe.name,
                          });
                        let total = 0;
                        let avg = 0;
                        let counter = 0;

                        switch (listsNew[a].indicators[b].operation) {
                          case 1 || 3:
                            for (let c = 0; c < result.length; c++) {
                              const row = result[c];
                              avg += parseInt(row.avg);
                              total += parseInt(row.value);
                              counter += parseInt(row.count);
                              if (defaultAxe.dataType == 3) {
                                rows.push([new Date(row._id), row.value]);
                              } else {
                                rows.push([row._id, row.value]);
                              }
                            }
                            break;
                          case 2:
                            for (let c = 0; c < result.length; c++) {
                              const row = result[c];
                              avg += parseInt(row.avg);
                              total += parseInt(row.value);
                              counter += parseInt(row.count);
                              if (defaultAxe.dataType == 3) {
                                rows.push([new Date(row._id), row.avg]);
                              } else {
                                rows.push([row._id, row.avg]);
                              }
                            }
                            break;

                          case 4:
                            for (let c = 0; c < result.length; c++) {
                              const row = result[c];
                              avg += parseInt(row.avg);
                              total += parseInt(row.value);
                              counter += parseInt(row.count);
                              if (defaultAxe.dataType == 3) {
                                rows.push([new Date(row._id), row.count]);
                              } else {
                                rows.push([row._id, row.count]);
                              }
                            }
                            break;

                          default:
                            for (let c = 0; c < result.length; c++) {
                              const row = result[c];
                              avg += parseInt(row.avg);
                              total += parseInt(row.value);
                              counter += parseInt(row.count);
                              if (defaultAxe.dataType == 3) {
                                rows.push([new Date(row._id), row.value]);
                              } else {
                                rows.push([row._id, row.value]);
                              }
                            }
                            break;
                        }

                        listsNew[a].indicators[b]["isLoading"] = false;
                        listsNew[a].indicators[b]["is"] = true;
                        listsNew[a].indicators[b]["data"] = result;
                        listsNew[a].indicators[b]["rows"] = rows;
                        listsNew[a].indicators[b]["total"] = total;
                        listsNew[a].indicators[b]["count"] = counter;

                        try {
                          if (
                            parseInt(listsNew[a].indicators[b].operation) === 3
                          ) {
                            listsNew[a].indicators[b]["total"] =
                              rows[rows.length - 1][1];
                          }
                        } catch (error) {
                          console.log("error", error);
                        }
                        listsNew[a].indicators[b]["avg"] = avg / result.length;
                        listsNew[a].indicators[b]["cols"] = columns;
                        break;
                      }
                    }
                  }
                  dispatch(
                    actionSetLists({ type: "ALL", data: [...listsNew] })
                  );
                }
              }
            }
          )
        );
        if (indicator.showlastvalue) {
          const debut = new Date(
            new Date(intervalRef.current[0]).setHours(21, 0, 0, 0)
          ).getTime();
          const fin = new Date(
            new Date(intervalRef.current[1]).setHours(21, 0, 0, 0)
          ).getTime();
          const ecart = fin - debut;

          dispatch(
            actionGetTotalByFilter(
              parseInt(axe.iddatasource),
              axe.name,
              {
                name: defaultAxe.name,
                class:
                  parseInt(defaultAxe.dataType) === 3 ? "time" : "dimensions",
              },
              [
                {
                  name: filter.name,
                  type: 3,
                  class: "time",
                  interval: [
                    new Date(debut - ecart).toISOString().split(".")[0],
                    new Date(fin - ecart).toISOString().split(".")[0],
                  ],
                },
              ],
              (status, result) => {
                if (status === 200) {
                  if (result) {
                    const listsNew = [...listsRef.current];
                    for (let a = 0; a < listsNew.length; a++) {
                      const list = listsNew[a];
                      for (let b = 0; b < list.indicators.length; b++) {
                        const indicator = list.indicators[b];
                        if (parseInt(indicator.idindicator) === id) {
                          let total = 0;

                          if (result.length > 0) {
                            switch (
                              parseInt(listsNew[a].indicators[b].operation)
                            ) {
                              case 1 || 3:
                                total = result[0].value;
                                break;
                              case 2:
                                total = result[0].avg;
                                break;
                              case 4:
                                total = result[0].count;
                                break;

                              default:
                                total = result[0].value;
                                break;
                            }
                          }

                          listsNew[a].indicators[b]["lastValue"] = total;
                          break;
                        }
                      }
                    }
                    dispatch(
                      actionSetLists({ type: "ALL", data: [...listsNew] })
                    );
                  }
                }
              }
            )
          );
        }
      }
    }
  };

  const open = Boolean(anchorEl);
  const tag = open ? `simple-popover-selected` : undefined;
  const { enqueueSnackbar } = useSnackbar();
  const [frequence, setFrequence, frequenceRef] = useState(1);
  const [screenX, setScreenX] = useState(window.innerWidth);
  const [trelloY, setTrelloY] = useState(window.screen.height - 270);
  const [simpleY, setSimpleY] = useState(window.screen.height - 270);

  const bonusTransactions = useSelector(
    (state) => state.bonusTransaction.bonusTransactions
  );
  const [filteredTransactions, setFilteredTransactions] = useState([]);

  useEffect(() => {
    dispatch(
      actionGetBonusTransactionsByUser(idUser, token, (status, data) => {})
    );
  }, [dispatch, idUser, token]);
  useEffect(() => {
    const [startOfMonth, endOfMonth] = getIntervalByValue("6");
    setInterval([startOfMonth, endOfMonth]);
  }, []);
  useEffect(() => {
    if (bonusTransactions && bonusTransactions.length > 0) {
      // Filtrer les transactions en fonction de l'intervalle spécifié
      const filtered = bonusTransactions.filter((transaction) => {
        const transactionDate = new Date(transaction.dateBonusTransaction);
        return (
          transactionDate >= interval[0] && // Date de début de l'intervalle
          transactionDate <= interval[1] // Date de fin de l'intervalle
        );
      });
      setFilteredTransactions(filtered);
    }
  }, [bonusTransactions, interval]);

  return (
    <>
      <style>
        {`
          /* Barre de défilement verticale */
          ::-webkit-scrollbar {
            width: 5px;
            background-color: transparent;
          }

          ::-webkit-scrollbar-thumb {
            background-color: #05BAF3;
            border-radius: 4px;
          }

          /* Barre de défilement horizontale */
          ::-webkit-scrollbar-track {
            background-color: transparent;
          }

          /* Barre de défilement verticale pour les autres navigateurs */
          .custom-scrollbar {
            scrollbar-width: thin;
            scrollbar-color: #05BAF3 transparent;
          }

          /* Barre de défilement horizontale pour les autres navigateurs */
          .custom-scrollbar::-webkit-scrollbar {
            width: 8px;
            background-color: transparent;
          }

          .custom-scrollbar::-webkit-scrollbar-thumb {
            background-color: #05BAF3;
            border-radius: 4px;
          }

          .custom-scrollbar::-webkit-scrollbar-track {
            background-color: transparent;
          }
        `}
      </style>
      <div className="fixed left-0 top-0 right-0 z-40">
        <Header />
      </div>

      <div
        className={
          "bg-gray-400/15 backdrop-blur-md flex items-end justify-end  mt-[13px] shadow-md w-full fixed top-8 left-0 z-40"
        }
        id="bonus-container"
      >
        <div
          className="flex justify-center items-center rounded-lg px-3 py-2
        "
        >
          {screenX > 700 ? (
            <label
              htmlFor="periode"
              className="flex items-center justify-center pr-2"
            >
              Période spécifique
            </label>
          ) : null}

          <CalendarView
            value={value}
            interval={interval}
            period={idperiod}
            onPeriodChange={(value) => {
              setIdPeriod(value);
            }}
            onChange={(interval, value, period) => {
              onChangeDate(interval, value, period);
            }}
            class="myColor2"
            selectStyle={{
              width: 150,
            }}
            calendarStyle={{
              width: 250,
            }}
          />
        </div>
      </div>
      <BonusWrapper
        isViewOnly={isViewOnly || !idUser}
        height={simpleY}
        screenX={screenX}
        interval={interval}
      />
    </>
  );
}
