import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Popover from "@mui/material/Popover";
import { useSelector, useDispatch } from "react-redux";
import Select from "../Views/Select";
import { NavDropdown, Nav, Item } from "react-bootstrap";
import CustomDrawer from "../Views/CustomDrawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { TeamOutlined, AreaChartOutlined } from "@ant-design/icons";
import styled from "styled-components";

let theme = "1";
const data = localStorage.getItem("theme");

if (data) {
  theme = JSON.parse(data).theme;
}

export default function Header() {
  const { t } = useTranslation();
  const [openDrawer, setOpen] = useState(false);
  const [largeur, setLargeur] = useState(window.innerWidth);
  const [anchorEl, setAnchorEl] = useState(null);
  const idUser = useSelector((state) => state.user.id);
  const email = useSelector((state) => state.user.email);
  const defaultLink = useSelector((state) => state.user.defaultLink);
  const location = useLocation();
  // const firstname = useSelector((state) => data.user.firstName);
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  window.onresize = () => {
    // console.log("window.innerHeight", window.innerHeight);
    // console.log("window.innerWidth", window.innerWidth);
    setLargeur(window.innerWidth);
  };

  const open = Boolean(anchorEl);
  const id = open ? `simple-popover-header` : undefined;
  return (
    <>
      {largeur < 900 ? (
        <CustomDrawer open={openDrawer} onClose={() => setOpen(false)}>
          <List>
            <ListItem
              button
              key={"dashboard"}
              onClick={() => {
                setOpen(false);
                navigate("/home");
              }}
            >
              <ListItemText primary={"Dashboard"} />
            </ListItem>
            <ListItem
              button
              key={"datasources"}
              onClick={() => {
                setOpen(false);
                navigate("/datasources");
              }}
            >
              <ListItemText primary={"Datasets"} />
            </ListItem>

            <ListItem
              button
              key={"connectors"}
              onClick={() => {
                setOpen(false);
                navigate("/connectors");
              }}
            >
              <ListItemText primary={"Connecteurs"} />
            </ListItem>

            <ListItem
              button
              key={"goals"}
              onClick={() => {
                setOpen(false);
                navigate("/goals");
              }}
            >
              <ListItemText primary={"Objectifs"} />
            </ListItem>

            <ListItem
              button
              key={"rapport"}
              onClick={() => {
                setOpen(false);
                navigate("/rapport");
              }}
            >
              <ListItemText primary={"Rapports"} />
            </ListItem>
            <ListItem
              button
              key={"equipes"}
              onClick={() => {
                setOpen(false);
                navigate("/equipes");
              }}
            >
              <ListItemText primary={"Equipes"} />
            </ListItem>
            <ListItem
              button
              key={"primes"}
              onClick={() => {
                setOpen(false);
                navigate("/primes");
              }}
            >
              <ListItemText primary={"Primes"} />
            </ListItem>

            {/* <ListItem button key={"theme"}>
          <ListItemText primary={"Theme"} />
        </ListItem> */}
          </List>
          <Nav>
            <NavDropdown title="Thème" id="basic-nav-dropdown">
              <NavDropdown.Item
                href="#action/3.1"
                onClick={() => {
                  localStorage.setItem("theme", JSON.stringify({ theme: "0" }));
                  window.location.reload();
                }}
              >
                Dark
              </NavDropdown.Item>
              <NavDropdown.Item
                href="#action/3.2"
                onClick={() => {
                  localStorage.setItem("theme", JSON.stringify({ theme: "1" }));
                  window.location.reload();
                }}
              >
                Light
              </NavDropdown.Item>
              <NavDropdown.Item
                href="#action/3.2"
                onClick={() => {
                  localStorage.setItem("theme", JSON.stringify({ theme: "2" }));
                  window.location.reload();
                }}
              >
                {/* Blue */}
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </CustomDrawer>
      ) : null}

      <header id="viabber-header">
        <div className="container-fluid">
          <div className="row">
            <div
              className="col-12 header-block"
              style={{
                ...(largeur > 900 ? {} : { justifyContent: "space-between" }),
              }}
            >
              {largeur < 900 ? (
                <i
                  className="fa-solid fa-bars"
                  style={{
                    marginRight: "10px",
                    fontSize: "17px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setOpen(true);
                  }}
                ></i>
              ) : null}

              <a className="logo">
                <img
                  src={
                    theme === "0"
                      ? "./viabber-logo.svg"
                      : theme === "1"
                      ? "./logo-viabber3.svg"
                      : "./logo-blue.png"
                  }
                  alt=""
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    if (idUser) {
                      navigate(defaultLink ? `/${defaultLink}` : "/home");
                    } else {
                      navigate("/");
                    }
                  }}
                />
              </a>
              {largeur > 900 ? (
                <nav
                  className="header-block-nav"
                  style={{ flexDirection: idUser ? "" : "row-reverse" }}
                >
                  <ul>
                    {idUser ? (
                      <>
                        <li
                          className={
                            location.pathname === "/home"
                              ? "header-block-nav-li active"
                              : "header-block-nav-li"
                          }
                        >
                          <Link to="/home" title="Dashboard" target="_self">
                            <a href="#" title="Datasets" target="_self">
                              <i class="fa fa-dashboard"></i>
                              Dashboard
                            </a>
                          </Link>
                        </li>
                        <li
                          className={
                            location.pathname === "/datasources"
                              ? "header-block-nav-li active"
                              : "header-block-nav-li"
                          }
                        >
                          <Link
                            to="/datasources"
                            title="Datasets"
                            target="_self"
                          >
                            <a href="#" title="Datasets" target="_self">
                              <i className="icon-Icon_Source"></i>Datasets
                            </a>
                          </Link>
                        </li>
                        <li
                          className={
                            location.pathname === "/connectors"
                              ? "header-block-nav-li active"
                              : "header-block-nav-li"
                          }
                        >
                          <Link
                            to="/connectors"
                            title="Connecteurs"
                            target="_self"
                          >
                            <a href="#" title="Connecteurs" target="_self">
                              <i className="icon-Icone_Connecteurs"></i>
                              Connecteurs
                            </a>
                          </Link>
                        </li>
                        <li
                          className={
                            location.pathname === "/periode"
                              ? "header-block-nav-li active"
                              : "header-block-nav-li"
                          }
                        >
                          <Link to="/periode" title="Periode" target="_self">
                            <a href="#" title="Periode" target="_self">
                              <i class="fa-solid fa-calendar-days"></i>
                              Periode
                            </a>
                          </Link>
                        </li>
                        <li
                          className={
                            location.pathname === "/goals"
                              ? "header-block-nav-li active"
                              : "header-block-nav-li"
                          }
                        >
                          <Link to="/goals" title="Objectifs" target="_self">
                            <a href="#" title="Objectifs" target="_self">
                              <i className="icon-Icone_Objectif"></i>Objectifs
                            </a>
                          </Link>
                        </li>
                        <li
                          className={
                            location.pathname === "/rapport"
                              ? "color_link_active header-block-nav-li active"
                              : "header-block-nav-li color_link"
                          }
                        >
                          <Link to="/rapport" title="Rapport" target="_self">
                            <a href="#" title="Rapport" target="_self">
                              {/* <i className="icon-Icone_Objectif"></i> */}
                              <span>
                                <AreaChartOutlined />
                              </span>
                              Rapports
                            </a>
                          </Link>
                        </li>
                        <li
                          className={
                            location.pathname === "/equipes"
                              ? "color_link_active header-block-nav-li active"
                              : "header-block-nav-li color_link"
                          }
                        >
                          <Link to="/equipes" title="Equipes" target="_self">
                            <a href="#" title="Equipes" target="_self">
                              {/* <i className="icon-Icone_Objectif"></i> */}
                              <span>
                                <TeamOutlined />
                              </span>
                              Equipes
                            </a>
                          </Link>
                        </li>
                        <li
                          className={
                            location.pathname === "/primes"
                              ? "header-block-nav-li active"
                              : "header-block-nav-li"
                          }
                        >
                          <Link to="/primes" title="Primes" target="_self">
                            <a href="#" title="Primes" target="_self">
                              <i className="fa fa-gift"></i> Primes
                            </a>
                          </Link>
                        </li>
                        {/* <li
                      className={
                        location.pathname === "/team"
                          ? "header-block-nav-li active"
                          : "header-block-nav-li"
                      }
                    >
                      <Link to="/team" title="Membres" target="_self">
                        <a href="#" title="Membres" target="_self">
                          <i className="icon-Ico_Users"></i>Membres
                        </a>
                      </Link>
                    </li> */}
                      </>
                    ) : null}

                    <li>
                      <Nav>
                        <NavDropdown title="Thème" id="basic-nav-dropdown">
                          <NavDropdown.Item
                            href="#action/3.1"
                            onClick={() => {
                              localStorage.setItem(
                                "theme",
                                JSON.stringify({ theme: "0" })
                              );
                              window.location.reload();
                            }}
                          >
                            Dark
                          </NavDropdown.Item>
                          <NavDropdown.Item
                            href="#action/3.2"
                            onClick={() => {
                              localStorage.setItem(
                                "theme",
                                JSON.stringify({ theme: "1" })
                              );
                              window.location.reload();
                            }}
                          >
                            Light
                          </NavDropdown.Item>
                          <NavDropdown.Item
                            href="#action/3.2"
                            onClick={() => {
                              localStorage.setItem(
                                "theme",
                                JSON.stringify({ theme: "2" })
                              );
                              window.location.reload();
                            }}
                          >
                            {/* Blue */}
                          </NavDropdown.Item>
                        </NavDropdown>
                      </Nav>
                    </li>
                  </ul>
                </nav>
              ) : null}

              <div className="header-block-wrap">
                {/* {largeur > 600 ? (
                  <Select
                    style={{ height: 40, marginRight: 30 }}
                    className="select-border"
                    defaultValue="fr"
                    options={[
                      { value: "fr", label: "Français" },
                      // { value: "en", label: "English" },
                    ]}
                    render={(item) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-around",
                            width: "100px",
                          }}
                        >
                          <img src={`./styles/img/flags/${item.value}.png`} />{" "}
                          <span style={{ fontSize: 15 }}>{item.label}</span>
                        </div>
                      );
                    }}
                  />
                ) : null} */}

                {idUser ? (
                  <>
                    <div
                      className="profil blackprofile lightprofile"
                      onClick={handleClick}
                      aria-describedby={id}
                      title={email}
                    >
                      <div className="profil-picture">
                        <img
                          src="./styles/img/user-1.jpg"
                          alt="Charles Simon"
                          style={{
                            width: "35px",
                            height: "35px",
                            borderRadius: "50%",
                            cursor: "pointer",
                          }}
                        />
                      </div>
                      {/* <div className="identity">
                      <i className="fa-solid fa-angle-down"></i>
                    </div> */}
                    </div>
                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      <div className="mui-popover-custom">
                        <ul>
                          <li>
                            <Link
                              to="/profile"
                              href="#"
                              className="mui-popover-item"
                              onClick={() => {
                                sessionStorage.removeItem("data");
                              }}
                            >
                              Mon compte
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/"
                              href="#"
                              className="mui-popover-item"
                              onClick={() => {
                                localStorage.removeItem("data");
                              }}
                            >
                              Me déconnecter
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </Popover>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
