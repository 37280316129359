import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actionGetBonusTransactionsByUser } from "../../../redux/actions/bonusTransactionAction";
import { Pagination, Modal, Tooltip } from "antd";
import { numberFormat } from "../IndicatorView";

const BonusDetails = ({ interval }) => {
  const dispatch = useDispatch();
  const idUser = useSelector((state) => state.user.id);
  const token = useSelector((state) => state.user.token);

  const bonusTransactions = useSelector(
    (state) => state.bonusTransaction.bonusTransactions
  );
  console.log("bonusTransactions", bonusTransactions);
  useEffect(() => {
    dispatch(
      actionGetBonusTransactionsByUser(idUser, token, (status) => {
        if (status === 200) {
          console.log("Bonus transactions received");
        }
      })
    );
  }, [dispatch, idUser, token]);

  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 4;
  const [selectedTransactionId, setSelectedTransactionId] = useState(null);

  const indexOfLastItem = currentPage * pageSize;
  const indexOfFirstItem = indexOfLastItem - pageSize;
  const currentItems = bonusTransactions
    // Tri des transactions selon la dateBonusTransaction
    .sort(
      (a, b) =>
        new Date(b.dateBonusTransaction) - new Date(a.dateBonusTransaction)
    )
    .filter((transaction) => {
      const transactionDate = new Date(transaction.dateBonusTransaction);
      if (interval)
        return (
          transactionDate >= new Date(interval[0]) &&
          transactionDate <= new Date(interval[1])
        );
    })
    .slice(indexOfFirstItem, indexOfLastItem);

  const handleTransactionDoubleClick = (transactionId) => {
    setSelectedTransactionId(transactionId);
  };

  const handleCloseModal = () => {
    setSelectedTransactionId(null);
  };

  const startDate = new Date(interval?.[0]);
  const endDate = new Date(interval?.[1]);

  return (
    <div className="mt-7 inset-0 rounded-lg bg-white h-[99.57%] w-full shadow-md mb-4">
      <div className="rounded-lg bg-white w-full overflow-hidden flex flex-col items-start justify-start py-4 px-4 box-border relative gap-4">
        <div className="flex flex-row items-center justify-center">
          <div className="text-[#05BAF3] text-xl font-bold font-['Poppins']">
            Transactions générées
            {interval?.[0] && interval?.[1] && (
              <span className="ml-1 text-gray-600 text-sm">
                ({startDate.toLocaleDateString()} -{" "}
                {endDate.toLocaleDateString()})
              </span>
            )}
          </div>
        </div>
        <div className="flex items-center justify-start gap-4 w-full h-full overflow-hidden">
          {currentItems.length > 0
            ? currentItems.map((transaction) => (
                <div
                  className="w-[300px] mt-3 hover:cursor-pointer"
                  key={transaction.idbonustransaction}
                  onDoubleClick={() =>
                    handleTransactionDoubleClick(transaction.idbonustransaction)
                  }
                >
                  <div className="rounded-lg flex flex-col items-center  py-4 px-6 relative  border-[1px] border-solid border-[#B2EAFB] w-[300px] h-[300px] ">
                    <div className="w-full flex flex-row items-center justify-between text-xs">
                      <div className="w-[173px] flex flex-col items-start justify-center gap-[5px]">
                        <div className="flex flex-row items-center justify-start gap-[8px]">
                          <img
                            className="relative w-3.5 h-3.5"
                            alt=""
                            src="imgPrimes/profile.png"
                          />
                          <div className="relative">
                            {transaction.iduser != null
                              ? "Prime individuelle"
                              : "Prime collective"}
                          </div>
                        </div>
                        <Tooltip title={transaction.bonus.bonusName}>
                          <div className="relative w-32">
                            <p className="truncate ... text-xl font-semibold text-black">
                              {transaction.bonus.bonusName}
                            </p>
                          </div>
                        </Tooltip>
                      </div>
                    </div>
                    <img
                      className="self-stretch relative max-w-full overflow-hidden h-px shrink-0 z-[1]"
                      alt=""
                      src="imgPrimes/Vector 380.png"
                    />
                    <div className="self-stretch flex flex-row items-center justify-between truncate ...">
                      <div className="flex-1 flex flex-col items-start justify-center gap-[5px] truncate ...">
                        <div className="flex flex-row items-center justify-start truncate ...">
                          <div className="relative text-gray-400 truncate ...">
                            Montant de la commission
                          </div>
                        </div>
                        <div className="relative text-xl font-medium text-foundation-blue-darker truncate ...">
                          {numberFormat(transaction.commission)} €
                        </div>
                      </div>
                    </div>
                    <img
                      className="self-stretch relative max-w-full overflow-hidden h-px shrink-0  truncate ..."
                      alt=""
                      src="imgPrimes/Vector 379.png"
                    />
                    <div className="self-stretch flex flex-col items-start justify-start gap-[5px] truncate ...">
                      <div className="relative text-gray-400 truncate ...">
                        Conditions d’obtention
                      </div>
                      <div
                        key={transaction.rule.idrule}
                        className=" truncate ... mb-2 w-full h-28 border-[1px] rounded-lg px-2 py-2 border-[#32b4ff] flex-1 flex flex-col items-start justify-center gap-[5px]"
                      >
                        <div className=" flex flex-row items-center justify-start text-left text-sm text-gray-500 truncate ... ">
                          <div className="relative truncate ...">
                            Règle appliquée : {transaction.rule.ruleName}
                          </div>
                        </div>
                        <div className="flex flex-row items-center justify-start gap-[5px] truncate ...">
                          <div className="rounded bg-[#CCF3FF] flex flex-row items-center justify-start py-0 px-[5px] text-xs text-[#05BAF3] truncate ...">
                            <div className="relative font-medium opacity-[0.8] truncate ...">
                              SI
                            </div>
                          </div>
                          <div className="rounded bg-[#A28FFC] opacity-40 flex flex-row items-center justify-center py-px px-[3px] text-mediumpurple-300 truncate ...">
                            <div className="relative truncate ...">
                              {transaction.rule.goal.name}
                            </div>
                          </div>
                          <div className="relative opacity-[0.5] truncate ...">
                            {transaction.rule.goal.operator}
                          </div>
                          <div className="relative opacity-[0.5] truncate ...">
                            {numberFormat(transaction.rule.goal.targetvalue)} €
                          </div>
                        </div>
                        <div className="w-full flex flex-row flex-wrap items-center justify-start gap-[5px] truncate ...">
                          <div className="rounded bg-[#CCF3FF] flex flex-row items-center justify-start py-0 px-[5px] text-xs text-[#05BAF3] truncate ...">
                            <div className="relative font-medium opacity-[0.8] truncate ...">
                              ALORS
                            </div>
                          </div>
                          <div className="rounded bg-[#A28FFC] opacity-40 flex flex-row items-center justify-center py-px px-[3px] text-mediumpurple-300 truncate ...">
                            <div className="relative truncate ...">
                              Commission
                            </div>
                          </div>
                          <div className="relative opacity-[0.5] truncate ...">
                            =
                          </div>
                          <div className="relative opacity-[0.5] truncate ...">
                            {transaction.rule?.formula}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-full flex flex-row items-center justify-between text-xs text-gray-500">
                      Date de la transaction :{" "}
                      {new Date(
                        transaction.dateBonusTransaction
                      ).toLocaleDateString("fr-FR")}
                    </div>
                    <img
                      className="self-stretch relative max-w-full overflow-hidden h-px shrink-0 z-[5]"
                      alt=""
                      src="imgPrimes/Vector 379.png"
                    />
                  </div>
                </div>
              ))
            : interval?.[0] &&
              interval?.[1] && (
                <div className="text-center text-gray-500">
                  Aucune transaction pour la période (
                  {startDate.toLocaleDateString()} -{" "}
                  {endDate.toLocaleDateString()})
                </div>
              )}
        </div>
        {currentItems.length > 0 && (
          <div className="flex justify-end w-full">
            <Pagination
              current={currentPage}
              total={bonusTransactions.length}
              pageSize={pageSize}
              onChange={(page) => setCurrentPage(page)}
            />
          </div>
        )}
      </div>

      <Modal
        title="Détails de la transaction"
        visible={selectedTransactionId !== null}
        onCancel={handleCloseModal}
        footer={null}
        centered
        width={"auto"}
      >
        {selectedTransactionId !== null && (
          <div className="p-6 rounded-lg ">
            {currentItems
              .filter(
                (transaction) =>
                  transaction.idbonustransaction === selectedTransactionId
              )
              .map((transaction) => (
                <div key={transaction.idbonustransaction} className="mb-4">
                  <div className="flex items-center mb-2">
                    <span className="font-bold text-blue-500 mr-2">
                      Type de prime :
                    </span>
                    <span>
                      {transaction.bonus.iduser != null
                        ? "Prime individuelle"
                        : "Prime collective"}
                    </span>
                  </div>
                  <div className="flex items-center mb-2">
                    <span className="font-bold text-blue-500 mr-2">
                      Nom de la prime :
                    </span>
                    <span>{transaction.bonus.bonusName}</span>
                  </div>
                  <div className="flex items-center mb-2">
                    <span className="font-bold text-blue-500 mr-2">
                      Montant de la commission :
                    </span>
                    <span>{transaction.commission} €</span>
                  </div>
                  <div className="flex items-center mb-2">
                    <span className="font-bold text-blue-500 mr-2">
                      Règle appliquée :
                    </span>
                    <span>{transaction.rule.ruleName}</span>
                  </div>
                  <div className="flex items-center mb-2">
                    <span className="font-bold text-blue-500 mr-2">
                      Condition d'obtention :
                    </span>
                    <span>
                      {transaction.rule.goal.name}{" "}
                      {transaction.rule.goal.operator}{" "}
                      {numberFormat(transaction.rule.goal.targetvalue)} €
                    </span>
                  </div>
                  <div className="flex items-center mb-2">
                    <span className="font-bold text-blue-500 mr-2">
                      Formule :
                    </span>
                    <span>{transaction.rule?.formula}</span>
                  </div>
                  <div className="flex items-center mb-2">
                    <span className="font-bold text-blue-500 mr-2">
                      Date de la transaction :
                    </span>
                    <span>
                      {new Date(
                        transaction.dateBonusTransaction
                      ).toLocaleDateString("fr-FR")}
                    </span>
                  </div>
                </div>
              ))}
          </div>
        )}
      </Modal>
    </div>
  );
};

export default BonusDetails;
