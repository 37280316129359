import React, { useEffect, useRef } from "react";
import useState from "react-usestateref";
import Header from "../Header";
import { Link, useNavigate } from "react-router-dom";
import CalendarView from "../Views/CalendarView";
import { useSelector, useDispatch } from "react-redux";
import {
  actionGetDashboard,
  actionSetDashboard,
  actionSetRolesDashboard,
  actionUpdateDashboard,
  actionDeleteDashboard,
  actionChangeTime,
  removeUserDashboard,
  actionSetUserRoleDashboard,
  actionSetTeamRoleDashboard,
  removeTeamDashboard,
} from "../../redux/actions/dashboardAction";
import {
  actionSetTime,
  actionSetTimeValue,
} from "../../redux/actions/timeAction";
import Popover from "@mui/material/Popover";
import { EditText } from "react-edit-text";
import Modal from "../Views/Modal";
import Avatar from "antd/lib/avatar/avatar";
import stc from "string-to-color";
import CustomSelect from "../Views/CustomSelect";
import ModalAddSource from "../Views/ModalAddSource";
import { isInteger, parseInt, unionBy } from "lodash";
import {
  actionAddDatasource,
  actionSetDataDatasources,
} from "../../redux/actions/datasourceAction";
import { actionSetLists } from "../../redux/actions/listAction";
import {
  actionAddIndicator,
  actionSetIndicatorData,
  actionGetDataByFilter,
  actionGetTotalByFilter,
  actionGetOptions,
} from "../../redux/actions/indicatorAction";
import { actionGetConnectors } from "../../redux/actions/connectorAction";
import { actionGetDatasources } from "../../redux/actions/datasourceAction";
import ModalShared from "../Views/ModalShared";
import Snackbar from "@mui/material/Snackbar";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Alert from "@mui/material/Alert";
import moment from "moment";
import { Tooltip, Checkbox, Popover as PopoverAntd } from "antd";
import { useSnackbar } from "notistack";
import RadioButton from "../Views/RadioButton";
import useReceive from "../DataSource/useReceive";
import useCheck from "../DataSource/useCheck";
import Bar from "../../Bar_graph.svg";
import Linear from "../../Linear_graph.svg";
import Pie from "../../circle.png";
import ModalPrint from "../Views/ModalPrint";
import TrelloDashboard from "./TrelloDashboard";
import SimpleDashboard from "./SimpleDashboard";
import Member from "../Views/Member";
import FilterView from "../Views/FilterView";

let theme = "1";
const data = localStorage.getItem("theme");

if (data) {
  theme = JSON.parse(data).theme;
}

export const getIntervalByValue = (value) => {
  if (value !== "0") {
    let a = moment();
    let b = moment();
    switch (value) {
      case "1":
        a.hours(0);
        b.hours(23);

        a.minutes(0);
        b.minutes(59);

        a.seconds(0);
        b.seconds(0);

        a.milliseconds(0);
        b.milliseconds(0);

        break;

      case "2":
        a.hours(0);
        b.hours(23);

        a.minutes(0);
        b.minutes(59);

        a.seconds(0);
        b.seconds(59);

        a.milliseconds(0);
        b.milliseconds(0);

        a.weekday(0);
        b.weekday(7);

        break;

      case "3":
        a.hours(0);
        b.hours(23);

        a.minutes(0);
        b.minutes(59);

        a.seconds(0);
        b.seconds(59);

        a.milliseconds(0);
        b.milliseconds(0);

        a.date(1);
        b.date(1);

        b.month(b.month() + 1);

        break;

      case "4":
        a.hours(0);
        b.hours(23);

        a.minutes(0);
        b.minutes(59);

        a.seconds(0);
        b.seconds(59);

        a.milliseconds(0);
        b.milliseconds(0);

        a.date(1);
        b.date(1);
        a.month(a.month() - 2);
        b.month(b.month() + 1);

        break;

      case "5":
        a.hours(0);
        b.hours(23);

        a.minutes(0);
        b.minutes(59);

        a.seconds(0);
        b.seconds(59);

        a.milliseconds(0);
        b.milliseconds(0);

        a.date(1);
        b.date(1);
        a.month(a.month() - 5);
        b.month(b.month() + 1);

        break;

      case "6":
        a.hours(0);
        b.hours(23);

        a.minutes(0);
        b.minutes(59);

        a.seconds(0);
        b.seconds(59);

        a.milliseconds(0);
        b.milliseconds(0);

        a.date(1);
        b.date(1);

        a.month(0);
        b.month(0);

        a.month(0);
        b.year(b.year() + 1);

        break;
      default:
        return [null, null];
    }
    return [a, b];
  } else {
    return [null, null];
  }
};

export default function Board() {
  const { enqueueSnackbar } = useSnackbar();
  const [frequence, setFrequence, frequenceRef] = useState(1);
  const [filters, setFilters, filtersRef] = useState([]);
  const [screenX, setScreenX] = useState(window.innerWidth);
  const [trelloY, setTrelloY] = useState(window.screen.height - 270);
  const [simpleY, setSimpleY] = useState(window.screen.height - 270);

  let navigate = useNavigate();
  const dispatch = useDispatch();

  const pourcent = useSelector((state) => state.datasource.pourcent);
  const visibilityProgress = useSelector(
    (state) => state.datasource.visibilityProgress
  );

  // board state ---->
  const board = useSelector((state) => state.board.dashboard);

  const manager = useSelector((state) => state.board.manager);
  const managerRef = useRef();
  managerRef.current = manager;
  const isDeleteBoard = useSelector((state) => state.board.isDelete);
  const isViewOnly = useSelector((state) => state.board.isViewOnly);
  const isOwner = useSelector((state) => state.board.isOwner);
  // <-----------

  // list state ---->
  const lists = useSelector((state) => state.list.lists);
  const listsRef = useRef();
  listsRef.current = lists;
  // <-----------

  // user state ---->
  const token = useSelector((state) => state.user.token);
  const idUser = useSelector((state) => state.user.id);
  const userEmail = useSelector((state) => state.user.email);
  // console.log(teams);
  // <-----------

  // datasource state ---->
  const [idconnector, setIdConnector, idConnectorRef] = useState(null);
  const [url, setUrl, urlRef] = useState(null);
  const datasources = useSelector((state) => state.datasource.datasources);
  const datasourcesRef = useRef();
  datasourcesRef.current = datasources;
  const [refresh, setRefresh, refreshRef] = useState({
    value: false,
    label: "Manuel",
  });
  const [period, setPeriod, periodRef] = useState({
    value: 0,
    label: "Heure",
  });
  const [idperiod, setIdPeriod] = useState(null);
  const [externalIds, setExternalIds] = useState(null);
  const operationOptions = [
    { value: 1, label: "Remplacer les données." },
    { value: 2, label: "Ajouter les données." },
    { value: 3, label: "Ajout intelligent des données." },
  ];
  const [data_operation, setData_operation, data_operationRef] = useState(
    operationOptions[0]
  );

  const [data, setData] = useReceive(url);
  useCheck();
  // <-----------

  // indicator state ---->
  const isAddIndicator = useSelector((state) => state.indicator.isAdd);
  const [description, setDescription, descriptionRef] = useState("");

  const formuleOptions = [
    { value: 1, label: "Somme" },
    { value: 2, label: "Moyenne" },
    { value: 3, label: "Aucun calcul" },
    { value: 4, label: "Compteur" },
  ];

  const [operation, setOperation, operationRef] = useState(formuleOptions[0]);
  const [size, setSize, sizeRef] = useState("1");
  const [symbole, setSymbole, symboleRef] = useState("");
  const [sourceName, setSourceName, sourceNameRef] = useState("");
  const [indicatorName, setIndicatorName, indicatorNameRef] = useState("");
  const [showRadioRegroupe, setShowRadioRegroupe, showRadioRegroupeRef] =
    useState(false);
  const [
    radioRegroupeDefault,
    setRadioRegroupeDefault,
    radioRegroupeDefaultRef,
  ] = useState("1");
  const [anchorEl, setAnchorEl] = useState(null);
  const [landa, setLanda] = useState("title-1");
  const [name, setName] = useState("");
  const [value, setValue, periodeRef] = useState("0");
  const [interval, setInterval, intervalRef] = useState([null, null]);
  const [modalSharedVisibility, setModalSharedVisibility] = useState(false);
  const [idlist, setIdlist, idListRef] = useState(null);
  const [deleteVisibility, setDeleteVisibility] = useState(false);
  const [sourceVisibility, setSourceVisibility] = useState(false);
  const [iframeVisibility, setIframeVisibility] = useState(false);
  const [visibility, setVisibility] = useState(false);
  const [isAsync, setIsAsync] = useState(false);
  const [source, setSource, sourceRef] = useState(null);
  const [metric, setMetric, metricRef] = useState(null);
  const [showLastValue, setShowLastValue] = useState(false);
  const [notShowCumulWithGoal, setNotShowCumulWithGoal] = useState(false);
  const [showCumul, setShowCumul] = useState(false);
  const [axeEmail, setAxeEmail, axeEmailRef] = useState(null);
  const [showByEmail, setShowByEmail, showByEmailRef] = useState(false);
  const [optionsAxeEmail, setOptionsAxeEmail] = useState([]);
  const [axeDate, setAxeDate, axeDateRef] = useState(null);
  const [axes, setAxes, axesRef] = useState(null);
  const [type, setType, typeRef] = useState(null);
  const [defaultAxe, setDefaultAxe, defaultAxeRef] = useState(null);
  const [selectedLists, setSelectedLists] = useState([]);
  const [optionsMetric, setOptionsMetric] = useState([]);
  const [optionsAxe, setOptionsAxe] = useState([]);
  const [optionsAxeDate, setOptionsAxeDate] = useState([]);
  const [optionsDatasources, setOptionsDatasources] = useState([]);
  const [color, setColor] = useState(theme === "0" ? "white" : "black");
  const [modifParam, setModifParam] = useState(false);
  const [loading, setLoading] = useState(false);

  // <-----------

  const update = () => {
    setAnchorEl(null);
    let element = document.getElementById(
      `input-default-text-editable-selected`
    );
    let evObj = document.createEvent("Events");
    evObj.initEvent("click", true, false);
    element.dispatchEvent(evObj);
    setTimeout(() => {
      document.getElementById(`input-default-text-editable-selected`).focus();
    }, 250);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const tag = open ? `simple-popover-selected` : undefined;

  const resize = () => {
    setScreenX(window.innerWidth);
    const x = document.getElementById("viabber-header").clientHeight;
    const x2 = document.getElementById("viabber-board-container").clientHeight;
    setTrelloY(document.documentElement.clientHeight - (x + x2 + 25));
    setSimpleY(document.documentElement.clientHeight - (x + x2 + 25));
  };

  useEffect(() => {
    if (data) {
      setIframeVisibility(false);
      setSourceVisibility(true);
    }
  }, [data]);

  useEffect(() => {
    window.addEventListener("resize", resize);
    resize();

    const today = new URL(window.location.href).searchParams.get("today");
    const lastWeek = new URL(window.location.href).searchParams.get("lastweek");
    const now = new Date();
    const last = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 6);

    if (today && lastWeek) {
      setInterval([
        moment(new Date(new Date(parseInt(lastWeek)).toISOString())),
        moment(new Date(new Date(parseInt(today)).toISOString())),
      ]);
      dispatch(
        actionSetTime([
          moment(new Date(new Date(parseInt(lastWeek)).toISOString())),
          moment(new Date(new Date(parseInt(today)).toISOString())),
        ])
      );
      dispatch(actionSetTimeValue("2"));
      setValue("2");
      dispatch(
        actionSetIndicatorData({
          interval: [
            moment(new Date(new Date(parseInt(lastWeek)).toISOString())),
            moment(new Date(new Date(parseInt(today)).toISOString())),
          ],
        })
      );
      const data = localStorage.getItem("data");
      const dashboard = sessionStorage.getItem("dashboard");
      const token = sessionStorage.getItem("token");

      if (!data && !dashboard && !token) {
        navigate("/");
      } else if (!dashboard) {
        navigate("/home");
      } else if (data) {
        setName(JSON.parse(dashboard).name);
        dispatch(actionSetDashboard(JSON.parse(dashboard)));
        dispatch(
          actionGetDashboard(
            JSON.parse(dashboard).iddashboard,
            idUser,
            "Bearer " + JSON.parse(data).token,
            (status, response, userDashboards) => {
              if (status === 200) {
                let manager = null;
                for (let index = 0; index < userDashboards.length; index++) {
                  const element = userDashboards[index];
                  if (element.role === "OWNER") {
                    manager = element;
                    break;
                  }
                }
                for (let index = 0; index < userDashboards.length; index++) {
                  const element = userDashboards[index];
                  if (
                    parseInt(element.iduser) ===
                    parseInt(JSON.parse(data).userId)
                  ) {
                    if (element.interval) {
                    } else if (manager && manager.interval) {
                    }
                    break;
                  }
                }
                defaultData();
                setName(response.name);
                if (
                  parseInt(managerRef.current.id) ===
                  parseInt(JSON.parse(data).userId)
                ) {
                  dispatch(
                    actionSetRolesDashboard({
                      isViewOnly: false,
                      isOwner: true,
                    })
                  );
                } else {
                  for (
                    let index = 0;
                    index < response.userDashboards.length;
                    index++
                  ) {
                    const element = response.userDashboards[index];
                    if (
                      parseInt(element.iduser) ===
                      parseInt(JSON.parse(data).userId)
                    ) {
                      if (element.role === "OWNER") {
                        dispatch(
                          actionSetRolesDashboard({
                            isViewOnly: false,
                            isOwner: true,
                          })
                        );
                      } else if (element.role === "VIEW") {
                        dispatch(
                          actionSetRolesDashboard({
                            isViewOnly: true,
                            isOwner: false,
                          })
                        );
                      } else {
                        dispatch(
                          actionSetRolesDashboard({
                            isViewOnly: false,
                            isOwner: false,
                          })
                        );
                      }
                      break;
                    }
                  }
                }
              } else {
                alert("error");
              }
            }
          )
        );
        dispatch(actionGetConnectors("Bearer " + JSON.parse(data).token));
        dispatch(
          actionGetDatasources(
            JSON.parse(data).userId,
            "Bearer " + JSON.parse(data).token
          )
        );
      } else {
        setName(JSON.parse(dashboard).name);
        dispatch(actionSetDashboard(JSON.parse(dashboard)));
        dispatch(
          actionGetDashboard(
            JSON.parse(dashboard).iddashboard,
            idUser,
            JSON.parse(token).token,
            (status, response, userDashboards) => {
              if (status === 200) {
                for (let index = 0; index < userDashboards.length; index++) {
                  const element = userDashboards[index];
                  if (element.role === "OWNER") {
                    if (element.interval) {
                    }
                    break;
                  }
                }
                defaultData();
              } else {
                alert("error");
              }
            }
          )
        );
      }
      return;
      //////////////////////////////////////////////////////////////////////////
    } else {
      setInterval(getIntervalByValue("3"));
      dispatch(actionSetTime(getIntervalByValue("3")));
      dispatch(actionSetTimeValue("3"));
      setValue("3");
      dispatch(
        actionSetIndicatorData({
          interval: getIntervalByValue("3"),
        })
      );

      const data = localStorage.getItem("data");
      const dashboard = sessionStorage.getItem("dashboard");
      const token = sessionStorage.getItem("token");
      if (!data && !dashboard && !token) {
        navigate("/");
      } else if (!dashboard) {
        navigate("/home");
      } else if (data) {
        setName(JSON.parse(dashboard).name);
        dispatch(actionSetDashboard(JSON.parse(dashboard)));
        dispatch(
          actionGetDashboard(
            JSON.parse(dashboard).iddashboard,
            idUser,

            "Bearer " + JSON.parse(data).token,
            (status, response, userDashboards) => {
              if (status === 200) {
                let manager = null;
                for (let index = 0; index < userDashboards.length; index++) {
                  const element = userDashboards[index];
                  if (element.role === "OWNER") {
                    manager = element;
                    break;
                  }
                }
                for (let index = 0; index < userDashboards.length; index++) {
                  const element = userDashboards[index];
                  if (
                    parseInt(element.iduser) ===
                    parseInt(JSON.parse(data).userId)
                  ) {
                    if (element.interval) {
                      setInterval([
                        moment(new Date(element.datedebut)),
                        moment(new Date(element.datefin)),
                      ]);
                      setValue(element.interval);
                      dispatch(
                        actionSetTime([
                          moment(new Date(element.datedebut)),
                          moment(new Date(element.datefin)),
                        ])
                      );
                      dispatch(actionSetTimeValue(element.interval));
                      dispatch(
                        actionSetIndicatorData({
                          interval: [
                            moment(new Date(element.datedebut)),
                            moment(new Date(element.datefin)),
                          ],
                        })
                      );
                    } else if (manager && manager.interval) {
                      setInterval([
                        moment(new Date(manager.datedebut)),
                        moment(new Date(manager.datefin)),
                      ]);
                      setValue(manager.interval);
                      dispatch(
                        actionSetTime([
                          moment(new Date(manager.datedebut)),
                          moment(new Date(manager.datefin)),
                        ])
                      );
                      dispatch(actionSetTimeValue(manager.interval));
                      dispatch(
                        actionSetIndicatorData({
                          interval: [
                            moment(new Date(manager.datedebut)),
                            moment(new Date(manager.datefin)),
                          ],
                        })
                      );
                    }
                    break;
                  }
                }
                defaultData();
                setName(response.name);
                if (
                  parseInt(managerRef.current.id) ===
                  parseInt(JSON.parse(data).userId)
                ) {
                  dispatch(
                    actionSetRolesDashboard({
                      isViewOnly: false,
                      isOwner: true,
                    })
                  );
                } else {
                  for (
                    let index = 0;
                    index < response.userDashboards.length;
                    index++
                  ) {
                    const element = response.userDashboards[index];
                    if (
                      parseInt(element.iduser) ===
                      parseInt(JSON.parse(data).userId)
                    ) {
                      if (element.role === "OWNER") {
                        dispatch(
                          actionSetRolesDashboard({
                            isViewOnly: false,
                            isOwner: true,
                          })
                        );
                      } else if (element.role === "VIEW") {
                        dispatch(
                          actionSetRolesDashboard({
                            isViewOnly: true,
                            isOwner: false,
                          })
                        );
                      } else {
                        dispatch(
                          actionSetRolesDashboard({
                            isViewOnly: false,
                            isOwner: false,
                          })
                        );
                      }
                      break;
                    }
                  }
                }
              } else {
                alert("error");
              }
            }
          )
        );
        dispatch(actionGetConnectors("Bearer " + JSON.parse(data).token));
        dispatch(
          actionGetDatasources(
            JSON.parse(data).userId,
            "Bearer " + JSON.parse(data).token
          )
        );
      } else {
        setName(JSON.parse(dashboard).name);
        dispatch(actionSetDashboard(JSON.parse(dashboard)));
        dispatch(
          actionGetDashboard(
            JSON.parse(dashboard).iddashboard,
            idUser,
            JSON.parse(token).token,
            (status, response, userDashboards) => {
              if (status === 200) {
                for (let index = 0; index < userDashboards.length; index++) {
                  const element = userDashboards[index];
                  if (element.role === "OWNER") {
                    if (element.interval) {
                      setInterval([
                        moment(new Date(element.datedebut)),
                        moment(new Date(element.datefin)),
                      ]);
                      setValue(element.interval);
                      dispatch(
                        actionSetTime([
                          moment(new Date(element.datedebut)),
                          moment(new Date(element.datefin)),
                        ])
                      );
                      dispatch(actionSetTimeValue(element.interval));
                      dispatch(
                        actionSetIndicatorData({
                          interval: [
                            moment(new Date(element.datedebut)),
                            moment(new Date(element.datefin)),
                          ],
                        })
                      );
                    }
                    break;
                  }
                }
                defaultData();
              } else {
                alert("error");
              }
            }
          )
        );
      }
    }
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);

  const animated = () => {
    let duration = 2000;
    for (let i = 0; i < lists.length; i++) {
      const list = lists[i];
      for (let j = 0; j < list.indicators.length; j++) {
        const indicator = list.indicators[j];
        setTimeout(() => {
          const element = document.getElementById(
            `indicator-${indicator.idindicator}`
          );
          if (element) {
            element.scrollIntoView({
              behavior: "smooth",
              block: "start",
              inline: "center",
            });
          }
        }, duration);
        duration += 2000;
      }
    }
  };

  useEffect(() => {
    let datasourcesArray = [];
    for (let i = 0; i < datasources.length; i++) {
      const element = datasources[i];
      datasourcesArray.push({ value: i, label: element.name });
    }

    setAxes(null);
    setSource(null);
    setMetric(null);
    setAxeDate(null);
    setDefaultAxe(null);

    setOptionsAxeEmail([]);
    setOptionsAxe([]);
    setOptionsMetric([]);
    setOptionsAxeDate([]);
    setOptionsDatasources(datasourcesArray);
  }, [datasources]);

  const onAdd = (e) => {
    if (data_operation.value === 3) {
      if (Array.isArray(externalIds)) {
        for (let i = 0; i < externalIds.length; i++) {
          const column = externalIds[i];
          for (let j = 0; j < data.columns.length; j++) {
            if (data.columns[j].index === column.value) {
              data.columns[j].unique = true;
            }
          }
        }
      } else {
        enqueueSnackbar(
          "Veuillez sélectionner au moins un identifiant unique.",
          {
            variant: "warning",
          }
        );
      }
    }
    e.preventDefault();
    if (sourceNameRef.current) {
      dispatch(
        actionAddDatasource(
          idUser,
          idConnectorRef.current,
          {
            name: sourceNameRef.current,
            operation: data_operationRef.current.value,
            periode: refreshRef.current.value ? periodRef.current.value : 0,
            refreshed: Boolean(data.url) ? refreshRef.current.value : false,
            nbre: refreshRef.current.value ? frequenceRef.current : 1,
            url: data.url,
            rows: data.rows,
            columns: data.columns,
            callback: data.callback,
            isAsync: isAsync,
          },
          token,
          (status, datasource) => {
            if (status === 200) {
              setSourceVisibility(false);
              setVisibility(true);
              setData(null);
              dispatch(
                actionSetDataDatasources({
                  id: datasource.iddatasource,
                  visibilityProgress: true,
                })
              );
            } else {
              enqueueSnackbar(
                "Une erreur c'est produite lors de l'insertion.",
                { variant: "error" }
              );
            }
          }
        )
      );
    }
  };

  const cancelShared = () => {
    setModalSharedVisibility(false);
  };

  const defaultData = () => {
    for (let i = 0; i < listsRef.current.length; i++) {
      const list = listsRef.current[i];
      for (let j = 0; j < list.indicators.length; j++) {
        const indicator = list.indicators[j];
        const id = parseInt(indicator.idindicator);
        let axe = null;
        let filter = null;
        let defaultAxe = null;
        let emailAxe = null;

        for (let index = 0; index < indicator.columns.length; index++) {
          const column = indicator.columns[index];
          if (column.idcolumn == indicator.idaxe) {
            axe = column;
            break;
          }
        }

        if (indicator.idEmailColumn && indicator.showByEmail) {
          for (let index = 0; index < indicator.columns.length; index++) {
            const column = indicator.columns[index];
            if (+column.idcolumn == +indicator.idEmailColumn) {
              emailAxe = column;
              break;
            }
          }
        }

        for (let index = 0; index < indicator.columns.length; index++) {
          const column = indicator.columns[index];
          if (column.idcolumn == indicator.idfilter) {
            filter = column;
            break;
          }
        }

        if (indicator.iddefault) {
          for (let index = 0; index < indicator.columns.length; index++) {
            const column = indicator.columns[index];
            if (column.idcolumn == indicator.iddefault) {
              defaultAxe = column;
              break;
            }
          }
        } else {
          defaultAxe = { ...filter };
        }

        const filters = [
          {
            name: filter.name,
            type: 3,
            class: "time",
            interval: [
              new Date(new Date(intervalRef.current[0]).setHours(21, 0, 0, 0))
                .toISOString()
                .split(".")[0],
              new Date(new Date(intervalRef.current[1]).setHours(21, 0, 0, 0))
                .toISOString()
                .split(".")[0],
            ],
          },
        ];

        if (emailAxe) {
          filters.push({
            name: emailAxe.name,
            type: 2,
            class: "dimensions",
            values: [userEmail],
          });
        }
        // console.log("filters", filters);
        dispatch(
          actionGetDataByFilter(
            parseInt(axe.iddatasource),
            axe.name,
            {
              name: defaultAxe.name,
              class:
                parseInt(defaultAxe.dataType) === 3 ? "time" : "dimensions",
            },
            filters,
            (status, result) => {
              if (status === 200) {
                if (result) {
                  const listsNew = [...listsRef.current];
                  for (let a = 0; a < listsNew.length; a++) {
                    const list = listsNew[a];
                    for (let b = 0; b < list.indicators.length; b++) {
                      const indicator = list.indicators[b];
                      if (parseInt(indicator.idindicator) === id) {
                        let columns = [];
                        let rows = [];

                        if (defaultAxe)
                          columns.push({
                            type:
                              defaultAxe.dataType == 1 ||
                              defaultAxe.dataType == 4
                                ? "number"
                                : defaultAxe.dataType == 3
                                ? "datetime"
                                : "string",
                            label: defaultAxe.name,
                          });

                        if (axe)
                          columns.push({
                            type:
                              axe.dataType == 1 || axe.dataType == 4
                                ? "number"
                                : axe.dataType == 3
                                ? "datetime"
                                : "number",
                            label: axe.name,
                          });
                        let total = 0;
                        let avg = 0;
                        let counter = 0;

                        switch (listsNew[a].indicators[b].operation) {
                          case 1 || 3:
                            for (let c = 0; c < result.length; c++) {
                              const row = result[c];
                              avg += parseInt(row.avg);
                              total += parseInt(row.value);
                              counter += parseInt(row.count);
                              if (defaultAxe.dataType == 3) {
                                rows.push([new Date(row._id), row.value]);
                              } else {
                                rows.push([row._id, row.value]);
                              }
                            }
                            break;
                          case 2:
                            for (let c = 0; c < result.length; c++) {
                              const row = result[c];
                              avg += parseInt(row.avg);
                              total += parseInt(row.value);
                              counter += parseInt(row.count);
                              if (defaultAxe.dataType == 3) {
                                rows.push([new Date(row._id), row.avg]);
                              } else {
                                rows.push([row._id, row.avg]);
                              }
                            }
                            break;

                          case 4:
                            for (let c = 0; c < result.length; c++) {
                              const row = result[c];
                              avg += parseInt(row.avg);
                              total += parseInt(row.value);
                              counter += parseInt(row.count);
                              if (defaultAxe.dataType == 3) {
                                rows.push([new Date(row._id), row.count]);
                              } else {
                                rows.push([row._id, row.count]);
                              }
                            }
                            break;

                          default:
                            for (let c = 0; c < result.length; c++) {
                              const row = result[c];
                              avg += parseInt(row.avg);
                              total += parseInt(row.value);
                              counter += parseInt(row.count);
                              if (defaultAxe.dataType == 3) {
                                rows.push([new Date(row._id), row.value]);
                              } else {
                                rows.push([row._id, row.value]);
                              }
                            }
                            break;
                        }

                        listsNew[a].indicators[b]["isLoading"] = false;
                        listsNew[a].indicators[b]["is"] = true;
                        listsNew[a].indicators[b]["data"] = result;
                        listsNew[a].indicators[b]["rows"] = rows;
                        listsNew[a].indicators[b]["total"] = total;
                        listsNew[a].indicators[b]["count"] = counter;

                        try {
                          if (
                            parseInt(listsNew[a].indicators[b].operation) === 3
                          ) {
                            listsNew[a].indicators[b]["total"] =
                              rows[rows.length - 1][1];
                          }
                        } catch (error) {
                          console.log("error", error);
                        }
                        listsNew[a].indicators[b]["avg"] = avg / result.length;
                        listsNew[a].indicators[b]["cols"] = columns;
                        break;
                      }
                    }
                  }
                  dispatch(
                    actionSetLists({ type: "ALL", data: [...listsNew] })
                  );
                }
              }
            }
          )
        );
        if (indicator.showlastvalue) {
          const debut = new Date(
            new Date(intervalRef.current[0]).setHours(21, 0, 0, 0)
          ).getTime();
          const fin = new Date(
            new Date(intervalRef.current[1]).setHours(21, 0, 0, 0)
          ).getTime();
          const ecart = fin - debut;

          dispatch(
            actionGetTotalByFilter(
              parseInt(axe.iddatasource),
              axe.name,
              {
                name: defaultAxe.name,
                class:
                  parseInt(defaultAxe.dataType) === 3 ? "time" : "dimensions",
              },
              [
                {
                  name: filter.name,
                  type: 3,
                  class: "time",
                  interval: [
                    new Date(debut - ecart).toISOString().split(".")[0],
                    new Date(fin - ecart).toISOString().split(".")[0],
                  ],
                },
              ],
              (status, result) => {
                if (status === 200) {
                  if (result) {
                    const listsNew = [...listsRef.current];
                    for (let a = 0; a < listsNew.length; a++) {
                      const list = listsNew[a];
                      for (let b = 0; b < list.indicators.length; b++) {
                        const indicator = list.indicators[b];
                        if (parseInt(indicator.idindicator) === id) {
                          let total = 0;

                          if (result.length > 0) {
                            switch (
                              parseInt(listsNew[a].indicators[b].operation)
                            ) {
                              case 1 || 3:
                                total = result[0].value;
                                break;
                              case 2:
                                total = result[0].avg;
                                break;
                              case 4:
                                total = result[0].count;
                                break;

                              default:
                                total = result[0].value;
                                break;
                            }
                          }

                          listsNew[a].indicators[b]["lastValue"] = total;
                          break;
                        }
                      }
                    }
                    dispatch(
                      actionSetLists({ type: "ALL", data: [...listsNew] })
                    );
                  }
                }
              }
            )
          );
        }
      }
    }
  };

  const onChangeDate = (interval, value, period = null) => {
    // console.log("idperiod", period);
    setValue(value);
    setInterval(interval);
    dispatch(actionSetTime(interval));
    dispatch(actionSetTimeValue(value));
    if (interval[0] && interval[1]) {
      dispatch(
        actionSetIndicatorData({
          interval: interval,
        })
      );
      dispatch(
        actionChangeTime(
          board.iddashboard,
          idUser,
          value,
          new Date(interval[0]).toISOString().split(".")[0],
          new Date(interval[1]).toISOString().split(".")[0],
          period,
          token,
          () => {}
        )
      );
      let newLists = [...listsRef.current];
      for (let i = 0; i < newLists.length; i++) {
        const list = newLists[i];
        for (let j = 0; j < list.indicators.length; j++) {
          newLists[i].indicators[j].isLoading = true;
        }
      }
      dispatch(actionSetLists({ type: "ALL", data: [...newLists] }));
      setTimeout(() => {
        defaultData();
      }, 100);
    }
  };

  const cancel = () => {
    setSelectedLists([]);
    setVisibility(false);
    setIndicatorName("");
    setShowCumul(false);
    setSource(null);
    setMetric(null);
    setAxeDate(null);
    setAxes(null);
    setType(null);
    setAxeEmail(null);
    setShowByEmail(false);
    setOptionsAxeEmail([]);
    setDefaultAxe(null);
    setDescription("");
    setSourceName("");
    setData_operation({
      value: 1,
      label: "Remplacer les données.",
    });
    setRefresh({ value: false, label: "Manuel" });
    setPeriod({ value: 0, label: "Heure" });
    setFrequence(1);
    setSymbole("");
    setSize("1");
    setRadioRegroupeDefault("1");
    setShowRadioRegroupe(false);
    setShowLastValue(false);
  };

  useEffect(() => {
    setFrequence(1);
    setSourceName("");
    setData_operation({
      value: 1,
      label: "Remplacer les données.",
    });
    setPeriod({ value: 0, label: "Heure" });
    if (data?.callback) {
      setIsAsync(true);
    } else {
      setIsAsync(false);
    }
    if (data?.url || data?.callback) {
      setRefresh({ value: true, label: "Automatique" });
    } else {
      setRefresh({ value: false, label: "Manuel" });
    }
  }, [sourceVisibility, data]);

  useEffect(() => {
    if (!sourceVisibility) {
      setData();
    }
  }, [sourceVisibility]);

  useEffect(() => {
    if (sourceRef.current) {
      const filters = [];
      const datasource = datasourcesRef.current[sourceRef.current.value];
      const columns = datasource.columns.filter((item) => +item.dataType !== 3);

      for (let i = 0; i < columns.length; i++) {
        const column = columns[i];

        filters.push({
          id: column.idcolumn,
          name: column.name,
          type: parseInt(column.dataType),
          checked: false,
          indicator: Boolean(column.columnType === "indicator"),
          min: null,
          max: null,
          loading: parseInt(column.dataType) === 2 ? true : false,
          value: null,
          values: [],
          options: [],
          interval: [null, null],
        });

        setFilters([...filters]);

        if (parseInt(column.dataType) === 2) {
          dispatch(
            actionGetOptions(
              column.iddatasource,
              column.name,
              (status, result) => {
                if (status === 200) {
                  let alpha = [...filtersRef.current];
                  for (let j = 0; j < alpha.length; j++) {
                    const item = alpha[j];
                    if (parseInt(item.id) === parseInt(column.idcolumn)) {
                      alpha[j]["options"] = result;
                      alpha[j]["loading"] = false;
                      setFilters([...alpha]);
                    }
                  }
                }
              }
            )
          );
        }
      }
    } else {
      setFilters([]);
    }
  }, [metric, axeDate, axeEmail, axes, source]);

  const cancelSource = () => {
    setVisibility(true);
    setSourceVisibility(false);
    setSourceName("");
    setRefresh({ value: false, label: "Manuel" });
    setPeriod({ value: 0, label: "Heure" });
    setFrequence(1);
  };

  const showIframe = (e) => {
    e.preventDefault();
    setIframeVisibility(true);
    setVisibility(false);
  };

  const cancelIframe = () => {
    setIframeVisibility(false);
    setVisibility(true);
  };

  const submit = (e) => {
    e.preventDefault();

    if (
      indicatorNameRef.current &&
      selectedLists.length > 0 &&
      sourceRef.current &&
      metricRef.current &&
      axeDateRef.current &&
      typeRef.current &&
      axesRef.current &&
      defaultAxeRef.current
    ) {
      const description = descriptionRef.current;
      const name = indicatorNameRef.current;
      const type = typeRef.current.value;
      const datasource = datasourcesRef.current[sourceRef.current.value];
      const metric = datasource.columns[metricRef.current.value];
      const axeDefault = datasource.columns[defaultAxeRef.current.value];

      const axeEmail = axeEmailRef.current
        ? datasource.columns[axeEmailRef.current.value]
        : null;
      const axeDate = datasource.columns[axeDateRef.current.value];
      const axes = [];

      const filters = filtersRef.current.filter((item) => item.checked);

      for (let i = 0; i < filters.length; i++) {
        const filter = filters[i];
        if (
          (filter.type === 1 || filter.type === 4) &&
          !filter.max &&
          !filter.min
        ) {
          enqueueSnackbar(`Le filtre ${filter.name} n'est pas renseigné`, {
            variant: "warning",
          });
          return;
        } else if (filter.type === 2 && !filter.values.length > 0) {
          enqueueSnackbar(`Le filtre ${filter.name} n'est pas renseigné`, {
            variant: "warning",
          });
          return;
        }
      }

      if (!(parseInt(axeDefault.dataType) === 3 && parseInt(type) === 2)) {
        for (let i = 0; i < axesRef.current.length; i++) {
          const element = axesRef.current[i];
          if (parseInt(element.value) !== parseInt(axeDateRef.current.value)) {
            axes.push(parseInt(datasource.columns[element.value].idcolumn));
          }
        }
        dispatch(
          actionAddIndicator(
            idUser,
            [...selectedLists.map((item) => item.value)],
            parseInt(metric.idcolumn),
            parseInt(axeDate.idcolumn),
            parseInt(axeDefault.idcolumn),
            axeEmail ? parseInt(axeEmail.idcolumn) : null,
            [
              ...axes,
              parseInt(metric.idcolumn),
              parseInt(axeDate.idcolumn),
              ...(axeEmail ? [parseInt(axeEmail.idcolumn)] : []),
            ],
            filters,
            name,
            description,
            type,
            token,
            operationRef.current.value,
            symboleRef.current,
            sizeRef.current,
            showCumul,
            notShowCumulWithGoal,
            showRadioRegroupeRef.current,
            showLastValue,
            showByEmail,
            radioRegroupeDefaultRef.current,
            (status, indicator) => {
              if (status === 200) {
                const filters = [
                  {
                    name: axeDate.name,
                    type: 3,
                    class: "time",
                    interval: [
                      new Date(intervalRef.current[0])
                        .toISOString()
                        .split(".")[0],
                      new Date(intervalRef.current[1])
                        .toISOString()
                        .split(".")[0],
                    ],
                  },
                ];
                if (axeEmail) {
                  filters.push({
                    name: axeEmail.name,
                    type: 2,
                    class: "dimensions",
                    values: [userEmail],
                  });
                }
                dispatch(
                  actionGetDataByFilter(
                    parseInt(metric.iddatasource),
                    metric.name,
                    {
                      name: axeDefault.name,
                      class:
                        parseInt(axeDefault.dataType) === 3
                          ? "time"
                          : "dimensions",
                    },
                    filters,
                    (status, result) => {
                      if (status === 200) {
                        if (result) {
                          let columns = [];
                          let rows = [];

                          columns.push({
                            type:
                              axeDefault.dataType == 1 ||
                              axeDefault.dataType == 4
                                ? "number"
                                : axeDefault.dataType == 3
                                ? "datetime"
                                : "string",
                            label: axeDefault.name,
                          });

                          columns.push({
                            type:
                              metric.dataType == 1 || metric.dataType == 4
                                ? "number"
                                : metric.dataType == 3
                                ? "datetime"
                                : "number",
                            label: metric.name,
                          });
                          let total = 0;
                          let avg = 0;
                          let counter = 0;

                          switch (parseInt(indicator.operation)) {
                            case 1 || 3:
                              for (let c = 0; c < result.length; c++) {
                                const row = result[c];
                                avg += parseInt(row.avg);
                                total += parseInt(row.value);
                                counter += parseInt(row.count);
                                if (axeDefault.dataType == 3) {
                                  rows.push([new Date(row._id), row.value]);
                                } else {
                                  rows.push([row._id, row.value]);
                                }
                              }
                              break;
                            case 2:
                              for (let c = 0; c < result.length; c++) {
                                const row = result[c];
                                avg += parseInt(row.avg);
                                total += parseInt(row.value);
                                counter += parseInt(row.count);
                                if (axeDefault.dataType == 3) {
                                  rows.push([new Date(row._id), row.avg]);
                                } else {
                                  rows.push([row._id, row.avg]);
                                }
                              }
                              break;
                            case 4:
                              for (let c = 0; c < result.length; c++) {
                                const row = result[c];
                                console.log("row here : ", row);
                                avg += parseInt(row.avg);
                                total += parseInt(row.value);
                                counter += parseInt(row.count);
                                if (axeDefault.dataType == 3) {
                                  rows.push([new Date(row._id), row.count]);
                                } else {
                                  rows.push([row._id, row.count]);
                                }
                              }
                              break;

                            default:
                              break;
                          }

                          indicator["isLoading"] = false;
                          indicator["is"] = true;
                          indicator["data"] = result;
                          indicator["rows"] = rows;
                          indicator["cols"] = columns;
                          indicator["total"] = total;
                          indicator["count"] = counter;

                          try {
                            if (parseInt(indicator.operation) === 3) {
                              indicator["total"] = rows[rows.length - 1][1];
                            }
                          } catch (error) {
                            console.log("error", error);
                          }

                          indicator["avg"] = avg / result.length;

                          const newLists = [...listsRef.current];

                          for (let i = 0; i < selectedLists.length; i++) {
                            const id = selectedLists[i].value;
                            for (let k = 0; k < newLists.length; k++) {
                              const list = newLists[k];
                              if (+list.idlist === +id) {
                                newLists[k].indicators.push(indicator);
                                break;
                              }
                            }
                          }

                          dispatch(
                            actionSetLists({
                              type: "ALL",
                              data: [...newLists],
                            })
                          );

                          setVisibility(false);
                          cancel();
                          enqueueSnackbar("L'indicateur a été ajouté", {
                            variant: "success",
                          });
                        }
                      }
                    }
                  )
                );
              } else {
                enqueueSnackbar("L'indicateur n'a pas été ajouté !", {
                  variant: "warning",
                });
              }
            }
          )
        );
      } else {
        enqueueSnackbar(
          "Un graph de type Pie ne peut avoir pour axe par défaut une date.",
          {
            variant: "warning",
          }
        );
      }
    } else {
      enqueueSnackbar("Veuillez entrer tous les champs obligatoires", {
        variant: "warning",
      });
    }
  };

  const handlerChangeDatasource = (data) => {
    setSource(data);
    const { value } = data;
    if (isInteger(parseInt(value))) {
      const index = parseInt(value);
      const src = datasources[index];
      let axesLanda = [];
      let axesEmail = [];
      let metricsLanda = [];
      let timesLanda = [];

      for (let i = 0; i < src.columns.length; i++) {
        const column = src.columns[i];
        if (column.columnType === "indicator") {
          metricsLanda.push({ value: i, label: column.name });
        } else {
          axesLanda.push({ value: i, label: column.name });
        }
        if (parseInt(column.dataType) === 2) {
          axesEmail.push({ value: i, label: column.name });
        }
        if (parseInt(column.dataType) === 3) {
          timesLanda.push({ value: i, label: column.name });
        }
      }

      setOptionsAxe(axesLanda);
      setOptionsAxeEmail(axesEmail);
      setOptionsMetric(metricsLanda);
      setOptionsAxeDate(timesLanda);

      setAxes(null);
      setMetric(null);
      setAxeDate(null);
      setDefaultAxe(null);
      setAxeEmail(null);

      if (timesLanda.length === 1) {
        setAxeDate(timesLanda[0]);
      }

      if (metricsLanda.length === 1) {
        setMetric(metricsLanda[0]);
      }
    }
  };

  const onWheel = (event) => {
    const parents = document.getElementsByClassName("custom-scroll-bar-list");
    const modals = document.getElementsByClassName("parent-modal-closable");
    let test = true;
    for (let i = 0; i < modals.length; i++) {
      const parent = modals[i];
      if (parent.contains(event.target) || parent === event.target) {
        return;
      }
    }
    for (let i = 0; i < parents.length; i++) {
      const parent = parents[i];
      if (parent.contains(event.target) || parent === event.target) {
        if (parent.scrollHeight > parent.clientHeight) {
          test = false;
        }
        break;
      }
    }
    if (event.deltaY !== -0 && test) {
      if (document.getElementById("trello")) {
        document.getElementById("trello").scrollLeft += event.deltaY;
      }
    }
  };

  return (
    <>
      <Modal
        visible={modifParam}
        title={"Gestion des membres"}
        onCancel={() => setModifParam(false)}
      >
        {/* {" "}{console.log("Well see  rows", rows) }S */}
        {board && board.teamDashboards?.length > 0 ? (
          <div className="wrap-table">
            <table className="display responsive">
              <tbody
                axis="y"
                lockAxis="y"
                useDragHandle
                disableAutoscroll
                helperClass="row-dragging"
              >
                <tr>
                  <td className="table-style">Equipe(s)</td>
                  <td className="table-style">
                    <ul className="actions">
                      <li>Admin</li>
                      <li> {""}</li>
                    </ul>
                  </td>
                </tr>
                {board && board.teamDashboards
                  ? board.teamDashboards.map(
                      ({
                        team,
                        idteam,
                        role,
                        iddashboard,
                        teamDashboardLists,
                        idteamdashboard,
                      }) => {
                        return (
                          <Member
                            name={team.name}
                            token={token}
                            isTeam={true}
                            isViewOnly={isViewOnly}
                            lists={teamDashboardLists?.map(({ list }) => list)}
                            idMember={idteamdashboard}
                            id={`${idteam}`}
                            role={role}
                            update={(value) => {
                              dispatch(
                                actionSetTeamRoleDashboard(
                                  iddashboard,
                                  idteam,
                                  value === true ? "ADMIN" : "VIEW",
                                  token,
                                  () => {}
                                )
                              );
                            }}
                            remove={() => {
                              dispatch(
                                removeTeamDashboard(
                                  iddashboard,
                                  idteam,
                                  token,

                                  () => {
                                    enqueueSnackbar(
                                      `${team.name} a été retirer de la dashboard`,
                                      {
                                        variant: "success",
                                      }
                                    );
                                  }
                                )
                              );
                            }}
                          />
                        );
                      }
                    )
                  : null}
              </tbody>
            </table>
          </div>
        ) : (
          <p style={{ padding: 40, textAlign: "center" }}>
            Aucune Equipe n'est présente
          </p>
        )}
        {board && board?.userDashboards?.length > 0 ? (
          <div className="wrap-table" style={{ marginTop: 40 }}>
            <table className="display responsive">
              <tbody
                axis="y"
                lockAxis="y"
                useDragHandle
                disableAutoscroll
                helperClass="row-dragging"
              >
                <tr>
                  <td className="table-style">Membre(s)</td>
                  <td className="table-style">
                    <ul className="actions">
                      <li>Admin</li>
                      <li> {""}</li>
                    </ul>
                  </td>
                </tr>

                {board && board.userDashboards
                  ? board.userDashboards.map(
                      ({
                        user,
                        role,
                        iduser,
                        iddashboard,
                        userDashboardLists,
                        iduserdashboard,
                      }) => {
                        return (
                          <Member
                            name={user.email}
                            token={token}
                            isTeam={false}
                            isViewOnly={isViewOnly}
                            id={`${iduser}`}
                            lists={userDashboardLists?.map(({ list }) => list)}
                            idMember={iduserdashboard}
                            role={role}
                            update={(value) => {
                              dispatch(
                                actionSetUserRoleDashboard(
                                  iddashboard,
                                  iduser,
                                  value === true ? "ADMIN" : "VIEW",
                                  token,
                                  () => {}
                                )
                              );
                            }}
                            remove={() => {
                              dispatch(
                                removeUserDashboard(
                                  iddashboard,
                                  iduser,
                                  token,
                                  () => {
                                    enqueueSnackbar(
                                      `${user.email} a été retirer de la dashboard`,
                                      {
                                        variant: "success",
                                      }
                                    );
                                  }
                                )
                              );
                            }}
                          />
                        );
                      }
                    )
                  : null}
              </tbody>
            </table>
          </div>
        ) : (
          <p style={{ padding: 40, textAlign: "center" }}>
            Aucun membre n'est présent
          </p>
        )}
        <button
          onClick={() => {
            setModifParam(false);
            setModalSharedVisibility(true);
          }}
          title="Partager"
          className="mt-3 btn-border"
        >
          <i className="fa-solid fa-users"></i> Partager
        </button>
      </Modal>
      <Modal
        visible={deleteVisibility}
        title={"Confirmer la suppression"}
        onCancel={isDeleteBoard ? null : () => setDeleteVisibility(false)}
      >
        <div className="row">
          <div className="col-md-12 wrap-btn-modal">
            <div className="btn-container">
              <button
                className="btn-border close-btn-submit"
                type="reset"
                onClick={
                  isDeleteBoard ? null : () => setDeleteVisibility(false)
                }
              >
                Annuler
              </button>
              <button
                className="btn-full"
                type="submit"
                disabled={isDeleteBoard}
                onClick={() => {
                  dispatch(
                    actionDeleteDashboard(board.iddashboard, token, (is) => {
                      if (is) {
                        sessionStorage.removeItem("dashboard");
                        navigate("/home");
                        setDeleteVisibility(false);
                      }
                    })
                  );
                }}
              >
                {isDeleteBoard ? "Loading..." : "Supprimer"}
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <ModalAddSource
        visibility={iframeVisibility}
        onCancel={cancelIframe}
        url={url}
        id={idconnector}
        setId={setIdConnector}
        setUrl={setUrl}
        onReceive={(json, total, id) => {
          if (sourceName) {
            dispatch(
              actionAddDatasource(
                idUser,
                id,
                {
                  name: sourceName,
                  url: json.url,
                  rows: json.rows,
                  columns: json.columns,
                },
                token,
                (status, datasource) => {
                  if (status === 200) {
                    dispatch(
                      actionSetDataDatasources({
                        id: datasource.iddatasource,
                        total: total,
                        visibilityProgress: true,
                        count: 0,
                      })
                    );
                    setSourceVisibility(false);
                    setIframeVisibility(false);
                    setVisibility(true);
                    setSourceName("");
                  }
                }
              )
            );
          }
        }}
      />
      <Modal
        visible={sourceVisibility}
        title={"Ajouter une source de donnée"}
        onCancel={cancelSource}
      >
        <form onSubmit={onAdd}>
          <div className="row">
            <div className="col-md-12">
              <div className="input-container">
                <label for="nom" className="label-default">
                  Entrer le nom de la source de donnée
                </label>
                <span className="span-block">
                  <input
                    className="input-default inputCustom"
                    name="nom"
                    id="nom"
                    type="text"
                    value={sourceName}
                    onChange={(e) => setSourceName(e.target.value)}
                    placeholder="Obligatoire"
                    required
                  />
                </span>
                {(data?.url || data?.callback) && (
                  <>
                    <br />
                    <label className="label-default">
                      Type de rafraichissement
                    </label>
                    <CustomSelect
                      id="refresh"
                      value={refresh}
                      style={{ textSize: "5px" }}
                      options={[
                        { value: false, label: "Manuel" },
                        { value: true, label: "Automatique" },
                      ]}
                      onChange={setRefresh}
                    />
                    <br />
                    <label className="label-default">
                      Frequence et Periode de rafraichissement
                    </label>
                    <div className="row">
                      <div className="col-3 col-md-3">
                        <input
                          className="input-default inputCustom"
                          disabled={!refresh.value}
                          type="number"
                          value={frequence}
                          min={1}
                          style={{ textAlign: "center" }}
                          onChange={(e) => {
                            if (
                              parseInt(e.target.value) > 0 ||
                              e.target.value === ""
                            ) {
                              setFrequence(e.target.value);
                            }
                          }}
                        />
                      </div>
                      <div className="col-9 col-md-9">
                        <CustomSelect
                          status={!refresh.value}
                          id="period"
                          value={period}
                          style={{ textSize: "5px", height: "20px" }}
                          options={[
                            { value: 0, label: "Heure" },
                            { value: 1, label: "Jour" },
                            { value: 2, label: "Semaine" },
                            { value: 3, label: "Mois" },
                            { value: 4, label: "Année" },
                          ]}
                          onChange={setPeriod}
                        />
                      </div>
                    </div>
                  </>
                )}
                <br />
                <label className="label-default">
                  Opération à réalisé lors du rafraichissement
                </label>
                <Alert severity="info">
                  {data_operation.value === 1 ? (
                    <>
                      Lors du <strong>rafraichissement</strong> toutes les
                      données seront remplacées par les nouvelles données.
                    </>
                  ) : data_operation.value === 2 ? (
                    <>
                      Lors du <strong>rafraichissement</strong> les nouvelles
                      données seront ajoutées aux anciennes données.
                    </>
                  ) : (
                    <>
                      Lors du <strong>rafraichissement</strong> les nouvelles
                      données seront ajoutées aux anciennes données avec{" "}
                      <strong>possibilité de modifier un élement</strong>{" "}
                      nécessite <strong>un identifiant unique</strong>.
                    </>
                  )}
                </Alert>
                <br />
                <CustomSelect
                  value={data_operation}
                  style={{ textSize: "5px" }}
                  options={operationOptions}
                  onChange={setData_operation}
                />
                {data_operation.value === 3 && (
                  <>
                    <br />
                    <label className="label-default">Indentifiant unique</label>
                    <Alert severity="warning">
                      Après insertion{" "}
                      <strong>cette valeur ne poura plus être modifiée</strong>.
                    </Alert>
                    <br />
                    <CustomSelect
                      isMulti
                      value={externalIds}
                      style={{ textSize: "5px" }}
                      options={
                        data?.columns
                          ? data.columns.map((item) => {
                              return { value: item.index, label: item.name };
                            })
                          : []
                      }
                      onChange={setExternalIds}
                    />
                  </>
                )}
                {data?.callback && (
                  <>
                    <br />
                    <span
                      className="span-block h-custom-options"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "left",
                      }}
                    >
                      <Checkbox
                        checked={isAsync}
                        onClick={() => {
                          setIsAsync(!isAsync);
                        }}
                      >
                        <span>
                          Insertion asynchrone (Pour des données volumineuses)
                        </span>
                      </Checkbox>
                    </span>
                  </>
                )}

                <br />
              </div>
            </div>
            <div className="col-md-12 wrap-btn-modal">
              <div className="btn-container">
                <button
                  className="btn-border close-btn-submit"
                  type="reset"
                  onClick={cancelSource}
                >
                  Retour
                </button>
                <button className="btn-full" type="submit">
                  Continuer
                </button>
              </div>
            </div>
          </div>
        </form>
      </Modal>
      <Modal
        visible={visibility}
        title={"Ajouter un indicateur"}
        onCancel={isAddIndicator ? null : cancel}
        large={true}
      >
        <form onSubmit={submit}>
          <div className="row">
            <div className="col-md-6">
              <div className="input-container">
                <label for="nom" className="label-default">
                  Entrer le nom de l'indicateur *
                </label>
                <span className="span-block">
                  <input
                    className="input-default inputCustom"
                    id="nom"
                    type="text"
                    value={indicatorName}
                    onChange={(e) => setIndicatorName(e.target.value)}
                    placeholder="Obligatoire"
                    required
                  />
                </span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="input-container">
                <label for="datasource" className="label-default">
                  Sélectionner la source de donnée *
                </label>
                <div className="row">
                  <span className="col-9 span-block">
                    <CustomSelect
                      id="datasource"
                      value={source}
                      options={optionsDatasources}
                      onChange={handlerChangeDatasource}
                    />
                  </span>
                  <span
                    className="col-2 btn-full"
                    onClick={showIframe}
                    style={{
                      cursor: "pointer",
                      alignItems: "center",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      fontSize: 20,
                    }}
                  >
                    +
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="input-container">
                <label for="metric" className="label-default">
                  Sélectionner la métric *
                </label>
                <span className="span-block">
                  <CustomSelect
                    id="metric"
                    value={metric}
                    options={optionsMetric}
                    onChange={setMetric}
                  />
                </span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="input-container">
                <label for="default-axe-date" className="label-default">
                  Sélectionner l'axe date par défaut *
                </label>
                <span className="span-block">
                  <CustomSelect
                    id="default-axe-date"
                    value={axeDate}
                    options={optionsAxeDate}
                    onChange={setAxeDate}
                  />
                </span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="input-container">
                <label for="axes" className="label-default">
                  Sélectionner axe d'analyse *
                </label>
                <span className="span-block">
                  <CustomSelect
                    id="axes"
                    isMulti
                    value={axes}
                    options={optionsAxe}
                    onChange={(data) => {
                      setAxes(data);
                      setDefaultAxe(null);
                    }}
                  />
                </span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="input-container">
                <label for="default-axe" className="label-default">
                  Sélectionner l'axe d'analyse par défault *
                </label>
                <span className="span-block">
                  <CustomSelect
                    id="default-axe"
                    value={defaultAxe}
                    options={axes}
                    onChange={setDefaultAxe}
                  />
                </span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="input-container">
                <label for="axe-email" className="label-default">
                  Sélectionner l'axe email
                </label>
                <span className="span-block">
                  <CustomSelect
                    id="axe-email"
                    isClearable
                    value={axeEmail}
                    options={optionsAxeEmail}
                    onChange={setAxeEmail}
                  />
                </span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="input-container">
                <label for="operation" className="label-default">
                  Formule
                </label>
                <span className="span-block">
                  <CustomSelect
                    id="operation"
                    value={operation}
                    options={formuleOptions} //here we get the options Somme, Moyenne, Aucun Calcul, Compteur
                    onChange={setOperation}
                  />
                </span>
              </div>
            </div>

            {board?.type === 2 && (
              <div className="col-md-6">
                <div className="input-container">
                  <label className="label-default">Sélectionner vues *</label>
                  <span className="span-block">
                    <CustomSelect
                      isMulti
                      value={selectedLists}
                      options={lists.map((item) => {
                        return { value: item.idlist, label: item.name };
                      })}
                      onChange={setSelectedLists}
                    />
                  </span>
                </div>
              </div>
            )}

            <div className="col-md-6">
              <div className="input-container">
                <label for="type" className="label-default">
                  Sélectionner type graph *
                </label>
                <span className="span-block">
                  <CustomSelect
                    id="type"
                    value={type}
                    options={[
                      { value: "0", label: "Bar Graph" },
                      { value: "1", label: "Linear Graph" },
                      { value: "2", label: "Pie Graph" },
                    ]}
                    onChange={setType}
                  />
                </span>
              </div>
            </div>

            <div className="col-md-6">
              <div className="input-container">
                <label for="description" className="label-default">
                  Description
                </label>
                <span className="span-block">
                  <textarea
                    id="description"
                    className="input-default inputCustom"
                    name="descritpion"
                    type="text"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    placeholder="Description (optionnel)"
                    style={{ height: "100px" }}
                  />
                </span>
              </div>
            </div>

            {type && (
              <div className="col-md-6">
                <div className="input-container">
                  <label className="label-default">
                    Visualisation du type de graph
                  </label>

                  <span className="span-block" style={{ textAlign: "center" }}>
                    {type && (
                      <img
                        src={
                          type.label.split(" ")[0] === "Linear"
                            ? Linear
                            : type.label.split(" ")[0] === "Pie"
                            ? Pie
                            : Bar
                        }
                        width={100}
                      />
                    )}
                  </span>
                </div>
              </div>
            )}

            <div className="col-12">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "left",
                  marginBottom: "15px",
                }}
              >
                <h4 className="title-4">Filtres</h4>
                <div
                  style={{
                    height: "1px",
                    flex: "2",
                    margin: "0px 10px",
                    border: `1px dashed ${
                      theme === "0" ? "#FFFFFF50" : "#1f212850"
                    }`,
                  }}
                ></div>
              </div>
            </div>

            <div
              className="col-md-6"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginBottom: "30px",
              }}
            >
              <FilterView
                filtersOptions={filters}
                setFilterOptions={setFilters}
              />
              <PopoverAntd
                placement={"right"}
                content={
                  <div>
                    {filters.map((e, index) => (
                      <p>
                        <Checkbox
                          checked={filters[index].checked}
                          onClick={() => {
                            let landa = [...filters];
                            landa[index].checked = !filters[index].checked;
                            setFilters([...landa]);
                          }}
                        >
                          <span
                            style={{
                              color: "#1f2128",
                            }}
                            className="color-black"
                          >
                            {e.name}
                          </span>
                        </Checkbox>
                      </p>
                    ))}
                  </div>
                }
                title="Ajouter un filtre"
                trigger="click"
              >
                <Link to="">
                  <i className="fa-light fa-plus add-filter"></i>
                </Link>
              </PopoverAntd>
            </div>

            <div className="col-12">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "left",
                  marginBottom: "30px",
                }}
              >
                <h4 className="title-4">Options d'affichage</h4>
                <div
                  style={{
                    height: "1px",
                    flex: "2",
                    margin: "0px 10px",
                    border: `1px dashed ${
                      theme === "0" ? "#FFFFFF50" : "#1f212850"
                    }`,
                  }}
                ></div>
              </div>
            </div>

            <div className="input-container col-md-6">
              <label for="symbole" className="label-default">
                Entrer le symbole (optionnel)
              </label>
              <span className="span-block">
                <input
                  className="input-default inputCustom"
                  id="symbole"
                  type="text"
                  value={symbole}
                  onChange={(e) => setSymbole(e.target.value)}
                  placeholder="Ex. %"
                />
              </span>
            </div>

            <div className="input-container col-md-6">
              <label for="large" className="label-default">
                Affichage
              </label>
              <span className="span-block h-custom-options">
                <RadioButton
                  onChange={(value) => {}}
                  defaultValue={"1"}
                  value={size}
                  setValue={setSize}
                  options={[
                    { label: "Miniature", value: "0" },
                    { label: "Normal", value: "1" },
                    { label: "En évidence", value: "2" },
                  ]}
                />
              </span>
            </div>
            <div className="col-md-12">
              <div className="row">
                {axeEmail && (
                  <div className="input-container col-md-4">
                    <span className="span-block">
                      <Checkbox
                        id="show-by-email"
                        checked={showByEmail}
                        onClick={() => {
                          setShowByEmail(!showByEmail);
                        }}
                      >
                        <span>Afficher donnée par email</span>
                      </Checkbox>
                    </span>
                  </div>
                )}
                <div className="input-container col-md-4">
                  <span className="span-block">
                    <Checkbox
                      id="show-cumul"
                      checked={showCumul}
                      onClick={() => {
                        setShowCumul(!showCumul);
                      }}
                    >
                      <span>Afficher courbe de cumul</span>
                    </Checkbox>
                  </span>
                </div>
                <div className="input-container col-md-4">
                  <span className="span-block">
                    <Checkbox
                      id="not-show-cumul-with-goal"
                      checked={notShowCumulWithGoal}
                      onClick={() => {
                        setNotShowCumulWithGoal(!notShowCumulWithGoal);
                      }}
                    >
                      <span>Ne pas afficher cumul avec l'objectif</span>
                    </Checkbox>
                  </span>
                </div>
                <div
                  className="input-container col-md-4"
                  hidden={(() => {
                    if (sourceRef.current && defaultAxeRef.current) {
                      const datasource =
                        datasourcesRef.current[sourceRef.current.value];
                      const axeDefault =
                        datasource.columns[defaultAxeRef.current.value];
                      if (axeDefault.dataType === "3") {
                        return false;
                      }
                      return true;
                    }
                  })()}
                >
                  <span
                    className="span-block h-custom-options"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      margin: 0,
                      alignItems: "start",
                      justifyContent: "center",
                    }}
                  >
                    <Checkbox
                      checked={showRadioRegroupe}
                      onClick={() => {
                        setShowRadioRegroupe(!showRadioRegroupe);
                      }}
                      style={{ marginBottom: "10px" }}
                    >
                      <span>Afficher periode</span>
                    </Checkbox>
                    <RadioButton
                      defaultValue={"1"}
                      value={radioRegroupeDefault}
                      hidden={!showRadioRegroupe}
                      setValue={setRadioRegroupeDefault}
                      options={[
                        { label: "Jour", value: "1" },
                        { label: "Semaine", value: "4" },
                        { label: "Mois", value: "2" },
                        { label: "Année", value: "3" },
                      ]}
                    />
                  </span>
                </div>
                <div className="input-container col-md-4">
                  <span className="span-block">
                    <Checkbox
                      id="idShowLastValue"
                      checked={showLastValue}
                      onClick={() => {
                        setShowLastValue(!showLastValue);
                      }}
                    >
                      <span>Afficher valeur précédente</span>
                    </Checkbox>
                  </span>
                </div>
              </div>
            </div>

            <div className="col-md-12 wrap-btn-modal">
              <div className="btn-container">
                <button
                  className="btn-border close-btn-submit"
                  type="reset"
                  onClick={isAddIndicator ? null : cancel}
                >
                  Annuler
                </button>
                <button
                  className="btn-full"
                  type="submit"
                  disabled={isAddIndicator}
                >
                  {isAddIndicator ? "Loading..." : "Ajouter"}
                </button>
              </div>
            </div>
          </div>
        </form>
      </Modal>
      <ModalShared
        visibility={modalSharedVisibility}
        dashboard={board}
        onCancel={cancelShared}
        token={token}
        isViewOnly={isViewOnly}
      />
      <Snackbar
        open={visibilityProgress}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Paper
          elevation={3}
          style={{
            width: "200px",
            height: "75px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-evenly",
            backgroundColor: "#323541",
            color: "#fff",
          }}
        >
          <CircularProgress
            variant={pourcent === 0 ? "indeterminate" : "determinate"}
            value={pourcent}
            size={25}
          />
          Extration...
        </Paper>
      </Snackbar>
      <Header />
      <main onWheel={onWheel}>
        <div className="container-fluid" id="viabber-board-container">
          <div className="row actions-nav">
            <div
              className={(() => {
                if (screenX > 950) {
                  return "titre col-3";
                } else if (screenX > 500) {
                  return "titre col-6";
                } else {
                  return "titre col-3";
                }
              })()}
            >
              {idUser ? (
                <div
                  style={{
                    width: "25px",
                    height: "25px",
                    borderRadius: "25px",
                    backgroundColor: "#05baf375",
                    marginRight: "10px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Link
                    to="/home"
                    title="Voir Mes Tableaux de bords"
                    target="_self"
                  >
                    <i
                      className="fa-solid fa-angle-left"
                      style={{ color: "#FFF" }}
                    ></i>
                  </Link>
                </div>
              ) : null}
              {screenX > 950 ? (
                <div
                  className="image-tableau"
                  style={{ backgroundImage: `url('./planche1.jpg')` }}
                ></div>
              ) : null}
              {screenX > 500
                ? (() => {
                    return idUser && isOwner ? (
                      <>
                        <EditText
                          id={`input-default-text-editable-selected`}
                          role="heading"
                          aria-level="1"
                          defaultValue={board ? board.name : ""}
                          value={name}
                          onChange={setName}
                          className={landa}
                          type="text"
                          onBlur={() => {
                            setColor(theme === "0" ? "white" : "black");
                            setLanda("title-1");
                          }}
                          onEditMode={() => {
                            setColor("black");
                            setLanda("input-default-text-editable");
                          }}
                          style={{ color }}
                          onSave={() => {
                            if (!name) {
                              setName(board.name);
                            } else {
                              dispatch(
                                actionUpdateDashboard(
                                  name,
                                  board.iddashboard,
                                  token,
                                  () => {}
                                )
                              );
                            }
                          }}
                        />
                      </>
                    ) : (
                      <h1 className="title-1">{board ? board.name : ""}</h1>
                    );
                  })()
                : null}

              <i
                aria-describedby={tag}
                className="fa-light fa-ellipsis more"
                style={{
                  color:
                    theme === "0"
                      ? "#fff"
                      : theme === "1"
                      ? "#1f2128"
                      : "#1f2128",
                }}
                onClick={handleClick}
              ></i>
              <Popover
                id={tag}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "center",
                  horizontal: "left",
                }}
              >
                <div className="mui-popover-custom">
                  <ul>
                    {idUser && isOwner ? (
                      <>
                        <li>
                          <a
                            href="#"
                            title="Modifier"
                            className="mui-popover-item"
                            onClick={update}
                          >
                            Modifier
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            onClick={() => {
                              setAnchorEl(null);
                              setModalSharedVisibility(true);
                            }}
                            title="Inviter à rejoindre"
                            className="mui-popover-item"
                          >
                            Inviter à rejoindre
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            title="Supprimer"
                            className="mui-popover-item"
                            onClick={() => {
                              setAnchorEl(null);
                              setDeleteVisibility(true);
                            }}
                          >
                            Supprimer
                          </a>
                        </li>
                      </>
                    ) : (
                      <li>
                        <a
                          href="#"
                          onClick={() => {
                            setAnchorEl(null);
                            setModalSharedVisibility(true);
                          }}
                          title="Inviter à rejoindre"
                          className="mui-popover-item"
                        >
                          Inviter à rejoindre
                        </a>
                      </li>
                    )}
                  </ul>
                </div>
              </Popover>
            </div>
            {screenX > 950
              ? (() => {
                  return idUser ? (
                    <div
                      className="col-md-3"
                      style={{
                        color:
                          theme === "0"
                            ? "#ffffff"
                            : theme === "1"
                            ? "#ffffff"
                            : "#99c5f7a2",
                      }}
                    >
                      <div className="partage">
                        <ul
                          style={{
                            marginTop: 11,
                            marginBottom: 13,
                          }}
                        >
                          <li className="top-user">
                            <Tooltip
                              title={manager ? manager.email : ""}
                              placement="bottomLeft"
                            >
                              <Avatar
                                size={27}
                                style={{
                                  backgroundColor: stc(
                                    manager
                                      ? manager.firstName +
                                          manager.lastName +
                                          manager.email
                                      : null
                                  ),
                                }}
                              >
                                {manager
                                  ? manager.firstName.substr(0, 1) +
                                    " " +
                                    manager.lastName.substr(0, 1)
                                  : null}
                              </Avatar>
                            </Tooltip>
                          </li>
                          {board && board.userDashboards
                            ? board.userDashboards.map(({ user }) => {
                                return (
                                  <li>
                                    <Tooltip
                                      title={() => {
                                        return (
                                          <div>
                                            <p
                                              style={{
                                                fontStyle: "normal",
                                                fontWeight: "normal",
                                                fontSize: "unset",
                                                color: "#fff",
                                              }}
                                            >
                                              {user.email}
                                            </p>
                                          </div>
                                        );
                                      }}
                                      placement="bottomLeft"
                                    >
                                      <Avatar
                                        size={27}
                                        style={{
                                          backgroundColor: stc(
                                            user.firstName +
                                              user.lastName +
                                              user.email
                                          ),
                                        }}
                                      >
                                        {user.firstName.substr(0, 1) +
                                          " " +
                                          user.lastName.substr(0, 1)}
                                      </Avatar>
                                    </Tooltip>
                                  </li>
                                );
                              })
                            : null}
                        </ul>

                        {idUser && isOwner ? (
                          <button
                            onClick={() => setModifParam(true)}
                            className="btn-border"
                            style={{
                              width: 100,
                              padding: 10,
                              textAlign: "center",
                            }}
                          >
                            Gérer
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="col-3"></div>
                  );
                })()
              : null}
            <div className={screenX > 500 ? "col-6" : "col-9"}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-around",
                  position: "relative",
                  top: "25%",
                  color: "#000",
                }}
              >
                <ModalPrint interval={interval} />
                {screenX > 700 ? (
                  <label for="periode" className="label-default">
                    Période spécifique
                  </label>
                ) : null}

                <CalendarView
                  value={value}
                  interval={interval}
                  period={idperiod}
                  onPeriodChange={(value) => {
                    setIdPeriod(value);
                  }}
                  onChange={(interval, value, period) => {
                    onChangeDate(interval, value, period);
                  }}
                  class="myColor2"
                  selectStyle={{
                    width: 150,
                  }}
                  calendarStyle={{
                    width: 250,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        {board?.type === 1 || !Boolean(board?.type) ? (
          <TrelloDashboard
            isViewOnly={isViewOnly || !idUser}
            lists={lists}
            onAddIndicator={setVisibility}
            setIdList={setIdlist}
            trelloY={trelloY}
          />
        ) : (
          <SimpleDashboard
            isViewOnly={isViewOnly || !idUser}
            height={simpleY}
            screenX={screenX}
            idlist={idlist}
            setIdList={setIdlist}
            onAddIndicator={setVisibility}
            lists={lists}
          />
        )}
      </main>
    </>
  );
}
