import React, { useState, useEffect } from "react";
import { Button, Drawer, Space, Progress, Select, Tooltip } from "antd";
import EvolutionPrimesUser from "./EvolutionPrimesUser";
import GoalsQuantities from "./GoalsQuantities";
import { useDispatch, useSelector } from "react-redux";
import { actionGetBonusTransactionsByUser } from "../../../redux/actions/bonusTransactionAction";
import GoalsQualities from "./GoalsQualities";
import { numberFormat } from "../IndicatorView";

const CommissionsDetails = ({ interval, filteredTransactions }) => {
  const { Option } = Select;

  const dispatch = useDispatch();
  const idUser = useSelector((state) => state.user.id);
  const token = useSelector((state) => state.user.token);
  const bonuses = useSelector((state) => state.bonus.bonuses);
  const uniqueGoalIds = new Set();

  const goalsQuantities = bonuses
    .filter((bonus) => bonus.rules.some((rule) => rule.goal.operator === "="))
    .flatMap((bonus) =>
      bonus.rules.map((rule) => {
        const goal = rule.goal;
        if (!uniqueGoalIds.has(goal.idgoal)) {
          uniqueGoalIds.add(goal.idgoal);
          return { bonus, goal };
        }
        return null;
      })
    )
    .filter((item) => item !== null);
  const goalsQualities = bonuses
    .filter((bonus) => bonus.rules.some((rule) => rule.goal.operator !== "="))
    .flatMap((bonus) =>
      bonus.rules.map((rule) => {
        const goal = rule.goal;
        if (!uniqueGoalIds.has(goal.idgoal)) {
          uniqueGoalIds.add(goal.idgoal);
          return { bonus, goal };
        }
        return null;
      })
    )
    .filter((item) => item !== null);
  useEffect(() => {
    dispatch(
      actionGetBonusTransactionsByUser(idUser, token, (status) => {
        if (status === 200) {
        }
      })
    );
  }, [dispatch, idUser, token]);

  // Calculer la somme des commissions individuelles
  const sumIndividualCommissions = filteredTransactions
    .filter((transaction) => transaction.iduser)
    .reduce((acc, curr) => acc + parseFloat(curr.commission), 0)
    .toFixed(2);

  // Calculer la somme des commissions par l'équipe
  const sumTeamCommissions = filteredTransactions
    .filter((transaction) => transaction.idteam)
    .reduce((acc, curr) => acc + parseFloat(curr.commission), 0)
    .toFixed(2);

  return (
    <>
      <div className="flex items-center content-center gap-4">
        <div className="mt-4 inset-0 rounded-lg bg-white h-[616px] w-[60%] shadow-md ">
          <div className="py-4 px-4 flex flex-row items-center justify-between">
            <div className="text-[#05BAF3] text-xl font-bold font-['Poppins']">
              Commissions et primes
            </div>
          </div>
          <div className="w-full px-4 self-stretch h-[526px] flex flex-row items-center justify-start gap-2 text-sm text-black">
            <div className="self-stretch flex flex-col items-start gap-4 justify-center w-full">
              <div className="flex flex-row items-center justify-center w-full gap-4">
                <div className="rounded-lg box-border w-[50%] h-[210px] flex flex-col items-center justify-between px-6 border-[1px] border-solid border-[#B2EAFB]">
                  <div className="flex-1 flex flex-col justify-center items-center gap-[5px]">
                    <div className=" truncate ... bottom-12 self-stretch relative opacity-50 text-sm font-['Poppins']">
                      Primes individuelles
                    </div>
                    <div className=" relative text-[40px] opacity-65 font-bold text-[#024155]">
                      {`${numberFormat(sumIndividualCommissions)} €`}
                    </div>
                  </div>
                </div>
                <div className="rounded-lg box-border w-[50%] h-[210px] flex flex-col items-center justify-between px-6 border-[1px] border-solid border-[#B2EAFB]">
                  <div className="flex-1 flex flex-col justify-center items-center gap-[5px]">
                    <div className=" truncate ... bottom-12 self-stretch relative opacity-50 text-sm font-['Poppins']">
                      Primes d'équipe
                    </div>
                    <div className="relative text-[40px] opacity-65 font-bold text-[#024155]">
                      {`${numberFormat(sumTeamCommissions)} €`}
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full">
                <EvolutionPrimesUser
                  bonusTransactions={filteredTransactions}
                  interval={interval}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-center gap-4 w-[40%] mt-4 h-full">
          <div
            className={`rounded-lg bg-white w-full shadow-md overflow-hidden p-4 relative ${"h-[300px]"}`}
          >
            <GoalsQuantities goalsQuantities={goalsQuantities} />
          </div>
          <div
            className={`rounded-lg bg-white w-full shadow-md overflow-hidden p-4 relative ${"h-[300px]"}`}
          >
            <GoalsQualities goalsQualities={goalsQualities} />
          </div>
        </div>
      </div>
    </>
  );
};

export default CommissionsDetails;
