import { useEffect, useRef, useState } from "react";
import { Switch } from "@headlessui/react";
import { Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Popover from "@mui/material/Popover";
import { actionGetUser } from "../../../redux/actions/UserAction";
import { actionGetTeamsByUser } from "../../../redux/actions/teamAction";
import { actionGetBonusTransactionsByUser } from "../../../redux/actions/bonusTransactionAction";
import { numberFormat } from "../IndicatorView";
const WelcomeMessage = ({
  isManager,
  enabled,
  change,
  filteredTransactions,
  teamsOwnedByUser,
  teams,
}) => {
  const [userTeams, setUserTeams] = useState([]);
  const idUser = useSelector((state) => state.user.id);
  const token = useSelector((state) => state.user.token);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const firstname = useSelector((state) => state.user.firstName);
  const lastname = useSelector((state) => state.user.lastName);
  const [defaultSelectVal, setdefaultSelectVal] = useState(null);

  // Calculer la somme des commissions individuelles
  const sumIndividualCommissions = filteredTransactions
    .filter((transaction) => transaction.iduser)
    .reduce((acc, curr) => acc + parseFloat(curr.commission), 0);

  // Calculer la somme des commissions par l'équipe
  const sumTeamCommissions = filteredTransactions
    .filter((transaction) => transaction.idteam)
    .reduce((acc, curr) => acc + parseFloat(curr.commission), 0);

  // Calculer le total des commissions
  const total = sumIndividualCommissions + sumTeamCommissions;

  // Mettre à jour l'état local avec le total des commissions
  // settotalCommissions(total.toFixed(2));
  const { Option } = Select;
  const dispatch = useDispatch();
  const handleSwitch = () => {
    change(!enabled);
  };

  const selectedTeamOwnedByUser = teamsOwnedByUser?.filter(
    (team) => selectedTeam === team.idteam
  );

  const handleTeamChange = (value) => {
    setSelectedTeam(value);
  };
  useEffect(() => {
    Promise.all([
      dispatch(actionGetBonusTransactionsByUser(idUser, token)),
      dispatch(actionGetUser(idUser, token)),
      dispatch(actionGetTeamsByUser(idUser, token)),
    ]);
  }, [dispatch, idUser, token]);

  useEffect(() => {
    if (teamsOwnedByUser?.length > 0) {
      setdefaultSelectVal(teamsOwnedByUser[0].idteam);
      setSelectedTeam(defaultSelectVal);
    }
    setUserTeams(
      Array.from(
        new Set(
          teams.flatMap((team) => team.userTeams.map((userTeam) => userTeam))
        )
      )
    );
  }, [teams]);
  return (
    <div className="w-full shadow-md  inset-0 rounded-[12px] bg-white  flex flex-row content-center items-center justify-between px-4 backdrop-blur-md backdrop-brightness-150  mt-4 ">
      {!enabled && isManager ? (
        <div className="h-[200px] flex flex-row items-center justify-center gap-[30px]">
          <div className="relative w-[100px] h-[100px]">
            <div className="flex justify-center items-center content-center">
              <img
                className="absolute top-[0px] left-[0px] w-full h-auto rounded-full"
                alt=""
                src="/imgPrimes/Progress Bar.png"
              />

              {idUser ? (
                <div className="profil-picture">
                  <img
                    src="./styles/img/user-1.jpg"
                    alt="Charles Simon"
                    className="absolute top-[8%] left-[8%] transform translate(-50%, -50%) w-[84px] h-[84px] rounded-full"
                  />
                </div>
              ) : null}
            </div>
          </div>
          <div className="relative flex flex-col items-start justify-start gap-[8px] text-xl ">
            <div className="flex flex-col items-start justify-start gap-[6px]">
              <b
                className="relative"
                style={{ color: "#05BAF3", letterSpacing: "-0.02em" }}
              >
                {firstname} {lastname}
              </b>
            </div>
            {isManager ? (
              <div className="flex flex-row items-center content-center justify-start gap-[14px] text-base text-gray-600">
                <div
                  className={`relative font-['Poppins'] ${
                    !enabled ? "font-bold" : "leading-[25px] text-black/60 "
                  }  `}
                >
                  Vue équipe
                </div>
                <div className="relative rounded-[9px] w-[70px] h-[35px]">
                  <Switch
                    checked={enabled}
                    onChange={handleSwitch}
                    className={`${enabled ? "bg-[#05BAF3]" : "bg-[#F4A746]"}
     relative inline-flex h-[33px] w-[70px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75`}
                  >
                    <span
                      aria-hidden="true"
                      className={`${
                        enabled ? "translate-x-9" : "translate-x-[2px]"
                      }
             pointer-events-none inline-block h-[25px] w-[25px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out absolute top-[2px]`}
                    />
                  </Switch>
                </div>
                <div
                  className={`relative font-['Poppins'] ${
                    enabled ? "font-bold" : "leading-[25px] text-black/60 "
                  }  `}
                >
                  Vue personnelle
                </div>
              </div>
            ) : null}

            <div class="relative w-full flex flex-col items-start justify-start gap-[6px]">
              <label
                for="equipe-select"
                class="relative text-[14px] leading-[25px] text-black inline-block w-[210px] opacity-[0.5]"
              >
                Filtrer par équipe :
              </label>
              {teamsOwnedByUser &&
                teamsOwnedByUser.length > 0 &&
                defaultSelectVal !== null && (
                  <Select
                    onChange={handleTeamChange}
                    className="w-52 shadow-md bg-white"
                    defaultValue={defaultSelectVal}
                  >
                    {teamsOwnedByUser.map((team) => (
                      <Select.Option key={team.idteam} value={team.idteam}>
                        {team.name}
                      </Select.Option>
                    ))}
                  </Select>
                )}
            </div>
          </div>
        </div>
      ) : (
        <div className="h-[200px] flex flex-row items-center justify-center gap-[30px]">
          <div className="relative w-[100px] h-[100px]">
            <div className="flex justify-center items-center content-center">
              {/* Progress Bar Image */}
              <img
                className="absolute top-[0px] left-[0px] w-[200px] h-auto rounded-full"
                alt=""
                src="/imgPrimes/Progress Bar.png"
              />

              {/* User Image */}
              {idUser ? (
                <div className="profil-picture">
                  <img
                    src="./styles/img/user-1.jpg"
                    alt="Charles Simon"
                    className="absolute top-[8%] left-[8%] transform translate(-50%, -50%) w-[84px] h-[84px] rounded-full"
                  />
                </div>
              ) : null}
            </div>
          </div>
          <div className="relative flex flex-col items-start justify-start gap-[8px] text-xl ">
            <div className="flex flex-col items-start justify-start gap-[6px]">
              <b
                className="relative"
                style={{ color: "#05BAF3", letterSpacing: "-0.02em" }}
              >
                {firstname} {lastname}
              </b>
            </div>

            {isManager ? (
              <div className="flex flex-row items-center content-center justify-start gap-[14px] text-base text-gray-600">
                <div
                  className={`relative font-['Poppins'] ${
                    !enabled ? "font-bold" : "leading-[25px] text-black/60 "
                  }  `}
                >
                  Vue équipe
                </div>
                <div className="relative rounded-[9px] w-[70px] h-[35px]">
                  <Switch
                    checked={enabled}
                    onChange={handleSwitch}
                    className={`${enabled ? "bg-[#05BAF3]" : "bg-[#F4A746]"}
     relative inline-flex h-[33px] w-[70px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75`}
                  >
                    <span
                      aria-hidden="true"
                      className={`${
                        enabled ? "translate-x-9" : "translate-x-[2px]"
                      }
             pointer-events-none inline-block h-[25px] w-[25px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out absolute top-[2px]`}
                    />
                  </Switch>
                </div>
                <div
                  className={`relative font-['Poppins'] ${
                    enabled ? "font-bold" : "leading-[25px] text-black/60 "
                  }  `}
                >
                  Vue personnelle
                </div>
              </div>
            ) : null}

            {/* <div className="flex flex-col items-start justify-start gap-[6px]">
              <div class="relative w-full font-['Poppins'] font-medium leading-[25px] text-[#8e8585]">
                <p
                  for="equipe-select"
                  class="relative text-[14px] leading-[25px] text-gray-500 inline-block w-full"
                >
                  La vue personelle ne dispose pas
                  <br />
                  <br />
                  de filtre par équipe
                </p>
              </div>
            </div> */}
          </div>
        </div>
      )}
      {!enabled && isManager ? (
        <div class="flex flex-col items-center justify-start py-0 px-3 text-xs text-dimgray">
          <div class="flex flex-row items-center justify-center">
            <div class="flex flex-col items-center justify-center relative">
              <img
                class="absolute my-0 mx-0 top-4 left-0 w-full h-12 object-cover z-0"
                alt=""
                src="imgPrimes/19-converti-1@2x.png"
              />

              <div className="opacity-70 text-sm font-['Poppins'] text-[#b36767]">
                Vos commissions :
              </div>
              <div class="relative text-[#05BAF3] text-[33px] leading-6 font-bold z-2 mt-6">
                {`${numberFormat(total)} €`}
              </div>
            </div>
          </div>
        </div>
      ) : (
        // Afficher la commission totale du user connecté
        <div class="relative flex flex-col items-center justify-center py-0 px-3 text-xs text-dimgray">
          <div class="flex flex-row items-center justify-center">
            <div class="flex flex-col items-center justify-center relative">
              <img
                class="absolute my-0 mx-0 top-4 left-0 w-full h-12 object-cover z-0"
                alt=""
                src="imgPrimes/19-converti-1@2x.png"
              />
              <div className="opacity-70 text-sm font-['Poppins'] text-[#4f4f4f]">
                Vos commissions :
              </div>
              <div class="relative text-[#05BAF3] text-[33px] leading-6 font-bold z-2 mt-6">
                {`${numberFormat(total)} €`}
              </div>
            </div>
          </div>
        </div>
      )}
      {!enabled && isManager && (
        <div className="flex justify-between gap-10">
          <div className="relative">
            <div className="relative w-full">
              <div className="w-full flex flex-col items-center justify-between box-border gap-[5px]">
                <div className="flex justify-between gap-6">
                  {selectedTeamOwnedByUser?.map((team) =>
                    team?.userTeams?.slice(0, 3).map((user) => {
                      return (
                        <div key={user.user.id}>
                          <div className="flex flex-col items-center justify-center gap-[2px] text-base">
                            <img
                              className="relative w-[66px] h-[52px] overflow-hidden shrink-0"
                              alt=""
                              src="imgPrimes/uuid-428cc27a-99f7-4c7e-954e-ad5633c5309sa.png"
                            />
                            <div className="flex flex-col items-center justify-center">
                              <div className="relative font-semibold flex flex-col">
                                {user.user.firstName + " " + user.user.lastName}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WelcomeMessage;
