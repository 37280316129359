import React, { useEffect, useRef } from "react";
import useState from "react-usestateref";
import { useTranslation } from "react-i18next";
import { Checkbox, Popover as PopoverAntd } from "antd";
import { useSelector, useDispatch } from "react-redux";
import Modal from "./Modal";
import CustomSelect from "./CustomSelect";
import { isInteger, parseInt } from "lodash";
import {
  actionGetOptions,
  actionUpdateIndicator,
} from "../../redux/actions/indicatorAction";
import { useSnackbar } from "notistack";
import RadioButton from "./RadioButton";
import Bar from "../../Bar_graph.svg";
import Linear from "../../Linear_graph.svg";
import Pie from "../../circle.png";
import FilterView from "./FilterView";
import { Link } from "react-router-dom";

let theme = "1";
const data = localStorage.getItem("theme");
if (data) {
  theme = JSON.parse(data).theme;
}

export default function ModalUpdateIndicator({ visibility, onCancel, data }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const token = useSelector((state) => state.user.token);
  const board = useSelector((state) => state.board.dashboard);
  const isUpdate = useSelector((state) => state.indicator.isUpdate);

  const [description, setDescription, descriptionRef] = useState("");
  const [showRadioRegroupe, setShowRadioRegroupe, showRadioRegroupeRef] =
    useState(false);
  const [
    radioRegroupeDefault,
    setRadioRegroupeDefault,
    radioRegroupeDefaultRef,
  ] = useState("1");

  const formuleOptions = [
    { value: 1, label: "Somme" },
    { value: 2, label: "Moyenne" },
    { value: 3, label: "Aucun calcul" },
    { value: 4, label: "Compteur" },
  ];

  const [operation, setOperation, operationRef] = useState(formuleOptions[0]);
  const [size, setSize, sizeRef] = useState("1");
  const [symbole, setSymbole, symboleRef] = useState("");
  const [indicatorName, setIndicatorName, indicatorNameRef] = useState("");

  const datasources = useSelector((state) => state.datasource.datasources);
  const datasourcesRef = useRef();
  datasourcesRef.current = datasources;

  const lists = useSelector((state) => state.list.lists);
  const listsRef = useRef();
  listsRef.current = lists;

  const [filters, setFilters, filtersRef] = useState([]);
  const [source, setSource, sourceRef] = useState(null);
  const [metric, setMetric, metricRef] = useState(null);
  const [showLastValue, setShowLastValue] = useState(false);
  const [larged, setLarged] = useState(false);
  const [selectedLists, setSelectedLists] = useState([]);
  const [notShowCumulWithGoal, setNotShowCumulWithGoal] = useState(false);
  const [showCumul, setShowCumul] = useState(false);
  const [axeDate, setAxeDate, axeDateRef] = useState(null);
  const [axeEmail, setAxeEmail, axeEmailRef] = useState(null);
  const [showByEmail, setShowByEmail, showByEmailRef] = useState(false);
  const [axes, setAxes, axesRef] = useState(null);
  const [type, setType, typeRef] = useState(null);
  const [defaultAxe, setDefaultAxe, defaultAxeRef] = useState(null);

  const [optionsMetric, setOptionsMetric] = useState([]);
  const [optionsAxe, setOptionsAxe] = useState([]);
  const [optionsAxeDate, setOptionsAxeDate] = useState([]);
  const [optionsAxeEmail, setOptionsAxeEmail] = useState([]);
  const [optionsDatasources, setOptionsDatasources] = useState([]);

  useEffect(() => {
    if (visibility) {
      for (let i = 0; i < datasourcesRef.current.length; i++) {
        const datasource = datasourcesRef.current[i];
        if (
          parseInt(datasource.iddatasource) ===
          parseInt(data.columns[0].iddatasource)
        ) {
          handlerChangeDatasource({ value: i, label: datasource.name });
          setTimeout(() => {
            setIndicatorName(data.name);
            setDescription(data.description);
            setSize(data.taille);
            setSymbole(data.symbole);
            setLarged(Boolean(data.large));
            setShowCumul(Boolean(data.showcumul));
            setShowByEmail(Boolean(data.showByEmail));
            setNotShowCumulWithGoal(Boolean(data.notshowcumulwithgoal));
            setRadioRegroupeDefault(data.periode);
            setShowRadioRegroupe(data.showperiode);
            setShowLastValue(Boolean(data.showlastvalue));

            const selectedLists = [];
            for (let j = 0; j < lists.length; j++) {
              const list = lists[j];
              for (let k = 0; k < data.listIndicators.length; k++) {
                const listIndicator = data.listIndicators[k];
                if (+listIndicator.idlist === +list.idlist) {
                  selectedLists.push({ value: list.idlist, label: list.name });
                  break;
                }
              }
            }
            setSelectedLists(selectedLists);

            let items = [];

            for (let i = 0; i < datasource.columns.length; i++) {
              const item = datasource.columns[i];

              if (
                data.columns.find(
                  (column) => +column.idcolumn === +item.idcolumn
                )
              ) {
                if (parseInt(item.idcolumn) === parseInt(data.idaxe)) {
                  setMetric({ value: item.idcolumn, label: item.name });
                }

                if (parseInt(item.idcolumn) === parseInt(data.idEmailColumn)) {
                  setAxeEmail({ value: item.idcolumn, label: item.name });
                }

                if (parseInt(item.idcolumn) === parseInt(data.idfilter)) {
                  setAxeDate({ value: item.idcolumn, label: item.name });
                  if (!data.iddefault) {
                    setDefaultAxe({ value: item.idcolumn, label: item.name });
                  }
                }

                if (data.iddefault) {
                  if (parseInt(item.idcolumn) === parseInt(data.iddefault)) {
                    setDefaultAxe({ value: item.idcolumn, label: item.name });
                  }
                }

                if (
                  item.columnType !== "indicator" &&
                  parseInt(item.idcolumn) !== parseInt(data.idEmailColumn)
                ) {
                  items.push({ value: item.idcolumn, label: item.name });
                }
              }
            }

            setAxes(items);

            for (let i = 1; i < formuleOptions.length; i++) {
              const value = formuleOptions[i].value;

              if (value === parseInt(data.operation)) {
                setOperation(formuleOptions[i]);
                break;
              }
            }

            if (data.type === "0") {
              setType({ value: "0", label: "Bar Graph" });
            } else if (data.type === "1") {
              setType({ value: "1", label: "Linear Graph" });
            } else if (data.type === "2") {
              setType({ value: "2", label: "Pie Graph" });
            }
          }, 75);
          break;
        }
      }
    }
  }, [visibility, lists]);

  useEffect(() => {
    let datasourcesArray = [];
    for (let i = 0; i < datasources.length; i++) {
      const datasource = datasources[i];
      datasourcesArray.push({ value: i, label: datasource.name });
    }

    setAxes(null);
    setSource(null);
    setMetric(null);
    setAxeDate(null);
    setDefaultAxe(null);
    setAxeEmail(null);

    setOptionsAxe([]);
    setOptionsMetric([]);
    setOptionsAxeDate([]);
    setOptionsDatasources(datasourcesArray);
  }, [datasources]);

  useEffect(() => {
    if (sourceRef.current) {
      const filters = [];
      const datasource = datasourcesRef.current[sourceRef.current.value];
      const columns = datasource.columns.filter((item) => +item.dataType !== 3);
      if (data.indicatorFilterColumns) {
        for (let i = 0; i < columns.length; i++) {
          const column = columns[i];
          const item = data.indicatorFilterColumns.find(
            (item) => +item.idcolumn === +column.idcolumn
          );

          filters.push({
            id: column.idcolumn,
            name: column.name,
            type: parseInt(column.dataType),
            checked: item ? true : false,
            indicator: Boolean(column.columnType === "indicator"),
            min: item?.min ? +item.min : null,
            max: item?.max ? +item.max : null,
            loading: parseInt(column.dataType) === 2 ? true : false,
            value: null,
            values: item?.values ? JSON.parse(item.values) : [],
            options: [],
            interval: [null, null],
          });

          setFilters([...filters]);

          if (parseInt(column.dataType) === 2) {
            dispatch(
              actionGetOptions(
                column.iddatasource,
                column.name,
                (status, result) => {
                  if (status === 200) {
                    let alpha = [...filtersRef.current];
                    for (let j = 0; j < alpha.length; j++) {
                      const item = alpha[j];
                      if (parseInt(item.id) === parseInt(column.idcolumn)) {
                        alpha[j]["options"] = result;
                        alpha[j]["loading"] = false;
                        setFilters([...alpha]);
                      }
                    }
                  }
                }
              )
            );
          }
        }
      }
    } else {
      setFilters([]);
    }
  }, [metric, axeDate, axeEmail, axes, source, data]);

  const cancel = () => {
    onCancel();
    setLarged(false);
    setShowCumul(false);
    setSource(null);
    setMetric(null);
    setAxeDate(null);
    setAxeEmail(null);
    setShowByEmail(false);
    setAxes(null);
    setType(null);
    setDefaultAxe(null);
    setDescription("");
    setSymbole("");
    setSize("1");
    setSelectedLists([]);
    setRadioRegroupeDefault("1");
    setShowRadioRegroupe(false);
    setShowLastValue(false);
  };

  const handlerChangeDatasource = (data) => {
    setSource(data);
    const { value } = data;
    if (isInteger(parseInt(value))) {
      const index = parseInt(value);
      const src = datasources[index];
      let axesLanda = [];
      let axesEmail = [];
      let metricsLanda = [];
      let timesLanda = [];

      for (let i = 0; i < src.columns.length; i++) {
        const column = src.columns[i];
        if (column.columnType === "indicator") {
          metricsLanda.push({ value: column.idcolumn, label: column.name });
        } else {
          axesLanda.push({ value: column.idcolumn, label: column.name });
        }
        if (parseInt(column.dataType) === 2) {
          axesEmail.push({ value: column.idcolumn, label: column.name });
        }
        if (parseInt(column.dataType) === 3) {
          timesLanda.push({ value: column.idcolumn, label: column.name });
        }
      }

      setOptionsAxe(axesLanda);
      setOptionsAxeEmail(axesEmail);
      setOptionsMetric(metricsLanda);
      setOptionsAxeDate(timesLanda);

      setAxes(null);
      setMetric(null);
      setAxeDate(null);
      setDefaultAxe(null);
      setAxeEmail(null);

      if (timesLanda.length === 1) {
        setAxeDate(timesLanda[0]);
      }

      if (metricsLanda.length === 1) {
        setMetric(metricsLanda[0]);
      }
    }
  };

  const submit = (e) => {
    e.preventDefault();
    if (
      indicatorNameRef.current &&
      selectedLists.length > 0 &&
      sourceRef.current &&
      metricRef.current &&
      axeDateRef.current &&
      typeRef.current &&
      axesRef.current &&
      defaultAxeRef.current
    ) {
      const description = descriptionRef.current;
      const name = indicatorNameRef.current;
      const type = typeRef.current.value;
      const datasource = datasourcesRef.current[sourceRef.current.value];

      const metric = datasource.columns.find(
        (item) => +metricRef.current.value === +item.idcolumn
      );

      const axeDefault = datasource.columns.find(
        (item) => +defaultAxeRef.current.value === +item.idcolumn
      );

      const axeEmail = axeEmailRef.current
        ? datasource.columns.find(
            (item) => +axeEmailRef.current.value === +item.idcolumn
          )
        : null;

      const axeDate = datasource.columns.find(
        (item) => +axeDateRef.current.value === +item.idcolumn
      );

      const filters = filtersRef.current.filter((item) => item.checked);

      for (let i = 0; i < filters.length; i++) {
        const filter = filters[i];
        if (
          (filter.type === 1 || filter.type === 4) &&
          !filter.max &&
          !filter.min
        ) {
          enqueueSnackbar(`Le filtre ${filter.name} n'est pas renseigné`, {
            variant: "warning",
          });
          return;
        } else if (filter.type === 2 && !filter.values.length > 0) {
          enqueueSnackbar(`Le filtre ${filter.name} n'est pas renseigné`, {
            variant: "warning",
          });
          return;
        }
      }

      const axes = [];
      if (!(parseInt(axeDefault.dataType) === 3 && parseInt(type) === 2)) {
        for (let i = 0; i < axesRef.current.length; i++) {
          const item = axesRef.current[i];
          if (parseInt(item.value) !== parseInt(axeDateRef.current.value)) {
            const id = +item.value;
            const column = datasource.columns.find(
              (item) => +item.idcolumn === +id
            );
            if (column) {
              axes.push(+id);
            }
          }
        }
        dispatch(
          actionUpdateIndicator(
            data.idindicator,
            [...selectedLists.map((item) => item.value)],
            parseInt(metric.idcolumn),
            parseInt(axeDate.idcolumn),
            parseInt(axeDefault.idcolumn),
            axeEmail ? parseInt(axeEmail.idcolumn) : null,
            [
              ...axes,
              parseInt(metric.idcolumn),
              parseInt(axeDate.idcolumn),
              ...(axeEmail ? [parseInt(axeEmail.idcolumn)] : []),
            ],
            filters,
            name,
            description,
            type,
            token,
            operationRef.current.value,
            symboleRef.current,
            sizeRef.current,
            showCumul,
            notShowCumulWithGoal,
            showRadioRegroupeRef.current,
            showLastValue,
            showByEmail,
            radioRegroupeDefaultRef.current,
            (status, indicator) => {
              if (status) {
                window.location.reload();
              }
            }
          )
        );
      } else {
        enqueueSnackbar(
          "Un graph de type Pie ne peut avoir pour axe par défaut une date.",
          {
            variant: "warning",
          }
        );
      }
    } else {
      enqueueSnackbar("Veuillez entrer tous les champs obligatoires", {
        variant: "warning",
      });
    }
  };

  return (
    <>
      <Modal
        visible={visibility}
        title={"Modifier l' indicateur"}
        onCancel={isUpdate ? null : cancel}
        large={true}
      >
        <form onSubmit={submit}>
          <div className="row">
            <div className="col-md-6">
              <div className="input-container">
                <label for="nom" className="label-default">
                  Entrer le nom de l'indicateur *
                </label>
                <span className="span-block">
                  <input
                    className="input-default inputCustom"
                    id="nom"
                    type="text"
                    value={indicatorName}
                    onChange={(e) => setIndicatorName(e.target.value)}
                    placeholder="Obligatoire"
                    required
                  />
                </span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="input-container">
                <label for="datasource" className="label-default">
                  Sélectionner la source de donnée *
                </label>
                <span className="span-block">
                  <CustomSelect
                    id="datasource"
                    value={source}
                    disabled={true}
                    options={optionsDatasources}
                    onChange={handlerChangeDatasource}
                  />
                </span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="input-container">
                <label for="metric" className="label-default">
                  Sélectionner la métric *
                </label>
                <span className="span-block">
                  <CustomSelect
                    id="metric"
                    value={metric}
                    disabled={true}
                    options={optionsMetric}
                    onChange={setMetric}
                  />
                </span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="input-container">
                <label for="default-axe-date" className="label-default">
                  Sélectionner l'axe date par défaut *
                </label>
                <span className="span-block">
                  <CustomSelect
                    id="default-axe-date"
                    value={axeDate}
                    options={optionsAxeDate}
                    onChange={setAxeDate}
                  />
                </span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="input-container">
                <label for="axes" className="label-default">
                  Sélectionner axe d'analyse *
                </label>
                <span className="span-block">
                  <CustomSelect
                    id="axes"
                    isMulti
                    value={axes}
                    options={optionsAxe}
                    onChange={(data) => {
                      setAxes(data);
                      setDefaultAxe(null);
                    }}
                  />
                </span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="input-container">
                <label for="default-axe" className="label-default">
                  Sélectionner l'axe d'analyse par défault
                </label>
                <span className="span-block">
                  <CustomSelect
                    id="default-axe"
                    value={defaultAxe}
                    options={axes}
                    onChange={setDefaultAxe}
                  />
                </span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="input-container">
                <label for="axe-email" className="label-default">
                  Sélectionner l'axe email
                </label>
                <span className="span-block">
                  <CustomSelect
                    id="axe-email"
                    isClearable
                    value={axeEmail}
                    options={optionsAxeEmail}
                    onChange={setAxeEmail}
                  />
                </span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="input-container">
                <label for="operation" className="label-default">
                  Formule
                </label>
                <span className="span-block">
                  <CustomSelect
                    id="operation"
                    value={operation}
                    options={formuleOptions}
                    onChange={setOperation}
                  />
                </span>
              </div>
            </div>

            {board?.type === 2 && (
              <div className="col-md-6">
                <div className="input-container">
                  <label className="label-default">Sélectionner vues *</label>
                  <span className="span-block">
                    <CustomSelect
                      isMulti
                      value={selectedLists}
                      options={lists.map((item) => {
                        return { value: item.idlist, label: item.name };
                      })}
                      onChange={setSelectedLists}
                    />
                  </span>
                </div>
              </div>
            )}

            <div className="col-md-6">
              <div className="input-container">
                <label for="type" className="label-default">
                  Sélectionner type graph
                </label>
                <span className="span-block">
                  <CustomSelect
                    id="type"
                    value={type}
                    options={[
                      { value: "0", label: "Bar Graph" },
                      { value: "1", label: "Linear Graph" },
                      { value: "2", label: "Pie Graph" },
                    ]}
                    onChange={setType}
                  />
                </span>
              </div>
            </div>

            <div className="col-md-6">
              <div className="input-container">
                <label for="description" className="label-default">
                  Description
                </label>
                <span className="span-block">
                  <textarea
                    id="description"
                    className="input-default inputCustom"
                    name="descritpion"
                    type="text"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    placeholder="Optionnel"
                    style={{ height: "100px" }}
                  />
                </span>
              </div>
            </div>

            {type && (
              <div className="col-md-6">
                <div className="input-container">
                  <label for="description" className="label-default">
                    Visualisation du type de graph
                  </label>

                  <span className="span-block" style={{ textAlign: "center" }}>
                    {type && (
                      <img
                        src={
                          type.label.split(" ")[0] === "Linear"
                            ? Linear
                            : type.label.split(" ")[0] === "Pie"
                            ? Pie
                            : Bar
                        }
                        width={100}
                      />
                    )}
                  </span>
                </div>
              </div>
            )}

            <div className="col-12">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "left",
                  marginBottom: "15px",
                }}
              >
                <h4 className="title-4">Filtres</h4>
                <div
                  style={{
                    height: "1px",
                    flex: "2",
                    margin: "0px 10px",
                    border: `1px dashed ${
                      theme === "0" ? "#FFFFFF50" : "#1f212850"
                    }`,
                  }}
                ></div>
              </div>
            </div>

            <div
              className="col-md-6"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginBottom: "30px",
              }}
            >
              <FilterView
                filtersOptions={filters}
                setFilterOptions={setFilters}
              />
              <PopoverAntd
                placement={"right"}
                content={
                  <div>
                    {filters.map((e, index) => (
                      <p>
                        <Checkbox
                          checked={filters[index].checked}
                          onClick={() => {
                            let landa = [...filters];
                            landa[index].checked = !filters[index].checked;
                            setFilters([...landa]);
                          }}
                        >
                          <span
                            style={{
                              color: "#1f2128",
                            }}
                            className="color-black"
                          >
                            {e.name}
                          </span>
                        </Checkbox>
                      </p>
                    ))}
                  </div>
                }
                title="Ajouter un filtre"
                trigger="click"
              >
                <Link to="">
                  <i className="fa-light fa-plus add-filter"></i>
                </Link>
              </PopoverAntd>
            </div>

            <div className="col-12">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "left",
                  marginBottom: "30px",
                }}
              >
                <h4 className="title-4">Options d'affichage</h4>
                <div
                  style={{
                    height: "1px",
                    flex: "2",
                    margin: "0px 10px",
                    border: `1px dashed ${
                      theme === "0" ? "#FFFFFF50" : "#1f212850"
                    }`,
                  }}
                ></div>
              </div>
            </div>

            <div className="input-container col-md-6">
              <label for="symbole" className="label-default">
                Entrer le symbole (optionnel)
              </label>
              <span className="span-block">
                <input
                  className="input-default inputCustom"
                  id="symbole"
                  type="text"
                  value={symbole}
                  onChange={(e) => setSymbole(e.target.value)}
                  placeholder="Ex. %"
                />
              </span>
            </div>

            <div className="col-md-6">
              <div className="input-container">
                <label for="large" className="label-default">
                  Affichage
                </label>
                <span className="span-block h-custom-options">
                  <RadioButton
                    onChange={(value) => {}}
                    defaultValue={"1"}
                    value={size}
                    setValue={setSize}
                    options={[
                      { label: "Miniature", value: "0" },
                      { label: "Normal", value: "1" },
                      { label: "En évidence", value: "2" },
                    ]}
                  />
                </span>
              </div>
            </div>

            <div className="col-md-12">
              <div className="row">
                {axeEmail && (
                  <div className="input-container col-md-4">
                    <span className="span-block">
                      <Checkbox
                        id="show-by-email"
                        checked={showByEmail}
                        onClick={() => {
                          setShowByEmail(!showByEmail);
                        }}
                      >
                        <span>Afficher donnée par email</span>
                      </Checkbox>
                    </span>
                  </div>
                )}
                <div className="input-container col-md-4">
                  <span className="span-block">
                    <Checkbox
                      id="show-cumul"
                      checked={showCumul}
                      onClick={() => {
                        setShowCumul(!showCumul);
                      }}
                    >
                      <span>Afficher courbe de cumul</span>
                    </Checkbox>
                  </span>
                </div>
                <div className="input-container col-md-4">
                  <span className="span-block">
                    <Checkbox
                      id="not-show-cumul-with-goal"
                      checked={notShowCumulWithGoal}
                      onClick={() => {
                        setNotShowCumulWithGoal(!notShowCumulWithGoal);
                      }}
                    >
                      <span>Ne pas afficher cumul avec l'objectif</span>
                    </Checkbox>
                  </span>
                </div>
                <div
                  className="input-container col-md-4"
                  hidden={(() => {
                    if (sourceRef.current && defaultAxeRef.current) {
                      const datasource =
                        datasourcesRef.current[sourceRef.current.value];
                      const axeDefault = datasource.columns.map(
                        (item) =>
                          +defaultAxeRef.current.value === +item.idcolumn
                      );
                      if (axeDefault.dataType === "3") {
                        return false;
                      }
                      return true;
                    }
                  })()}
                >
                  <span
                    className="span-block h-custom-options"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      margin: 0,
                      alignItems: "start",
                      justifyContent: "center",
                    }}
                  >
                    <Checkbox
                      checked={showRadioRegroupe}
                      onClick={() => {
                        setShowRadioRegroupe(!showRadioRegroupe);
                      }}
                      style={{ marginBottom: "10px" }}
                    >
                      <span>Afficher periode</span>
                    </Checkbox>
                    <RadioButton
                      defaultValue={"1"}
                      value={radioRegroupeDefault}
                      hidden={!showRadioRegroupe}
                      setValue={setRadioRegroupeDefault}
                      options={[
                        { label: "Jour", value: "1" },
                        { label: "Semaine", value: "4" },
                        { label: "Mois", value: "2" },
                        { label: "Année", value: "3" },
                      ]}
                    />
                  </span>
                </div>
                <div className="input-container col-md-4">
                  <span className="span-block">
                    <Checkbox
                      id="idShowLastValue"
                      checked={showLastValue}
                      onClick={() => {
                        setShowLastValue(!showLastValue);
                      }}
                    >
                      <span>Afficher valeur précédente</span>
                    </Checkbox>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-12 wrap-btn-modal">
              <div className="btn-container">
                <button
                  className="btn-border close-btn-submit"
                  type="reset"
                  onClick={isUpdate ? null : cancel}
                >
                  Annuler
                </button>
                <button className="btn-full" type="submit" disabled={isUpdate}>
                  {isUpdate ? "Loading..." : "Modifier"}
                </button>
              </div>
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
}
