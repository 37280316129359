import React from "react";

export default function Modal({ title, children, visible, onCancel, large }) {
  return (
    <>
      {visible ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "#00000060",
            zIndex: 20,
            position: "fixed",
            display: visible ? "flex" : "none",
            flexDirection: "row",
            justifyContent: "center",
            left: 0,
            top: 0,
            overflow: "auto",
            padding: "50px 0px 50px 0px",
            marginBottom: 0,
          }}
          className="parent-modal-closable"
          onClick={(e) => {
            if (e.target.className === "parent-modal-closable") {
              if (onCancel) {
                onCancel();
              }
            }
          }}
        >
          <div
            className="modal-custom bg-default"
            style={{
              display: visible ? "block" : "none",
              width: large ? "80%" : "40%",
              minWidth: "300px",
              height: "max-content",
              marginTop: "auto",
              marginBottom: "auto",
            }}
          >
            <div
              style={{
                marginBottom: 20,
                right: 1,
                display: "flex",
                flexDirection: "row",
                justifyContent: "right",
              }}
            >
              <i className="fa-thin fa-xmark close-btn" onClick={onCancel}></i>
            </div>
            <h3 className="title-3">{title}</h3>
            <br />
            {children}
          </div>
        </div>
      ) : null}
    </>
  );
}
