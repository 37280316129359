import React, { useEffect, useState, useRef } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import GoalList from "../Views/GoalList";
import Modal from "../Views/Modal";
import ModalAddGoal from "../Views/ModalAddGoal";
import ModalUpdateGoal from "../Views/ModalUpdateGoal";
import {
  actionSetGoals,
  actionGetGoals,
  actionDeleteGoal,
} from "../../redux/actions/GoalAction";
import { actionGetIndicators } from "../../redux/actions/indicatorAction";
import { useSnackbar } from "notistack";
import { actionGetTeamsByUser } from "../../redux/actions/teamAction";
const _ = require("lodash");

export default function Goals() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector((state) => state.user.token);
  const idUser = useSelector((state) => state.user.id);
  const tokenRef = useRef();
  tokenRef.current = token;
  const [visibility, setVisibility] = useState(false);
  const items = useSelector((state) => state.goal.goals);
  const isDeleteGoal = useSelector((state) => state.goal.isDelete);
  const [deleteVisibility, setDeleteVisibility] = useState(false);
  const [visibilityUpdateGoal, setVisibilityUpdateGoal] = useState(false);
  const [currentGoal, setCurrentGoal] = useState(null);
  const currentGoalRef = useRef();
  currentGoalRef.current = currentGoal;
  const { enqueueSnackbar } = useSnackbar();
  const teams = useSelector((state) => state.team.teams);

  let usersTeams = [];
  const users = [];
  for (let i = 0; i < teams.length; i++) {
    usersTeams.push(
      ...teams[i].userTeams.map((u) => {
        return {
          label: `${u.user.firstName} ${u.user.lastName}`,
          value: u.user.id,
          key: u.user.id,
        };
      })
    );
  }
  usersTeams = usersTeams.filter((t) => +t.value !== +idUser);
  for (let i = 0; i < usersTeams.length; i++) {
    const element = usersTeams[i];
    if (!users.find((u) => +u.value === element.value)) users.push(element);
  }

  const cancel = () => {
    setVisibility(false);
  };

  useEffect(() => {
    const data = localStorage.getItem("data");
    if (!data) {
      navigate("/");
    } else {
      dispatch(
        actionGetGoals(
          JSON.parse(data).userId,
          "Bearer " + JSON.parse(data).token,
          (status) => {}
        )
      );
      dispatch(actionGetIndicators(JSON.parse(data).userId, (status) => {}));
    }
  }, [dispatch, navigate]);

  useEffect(() => {
    dispatch(actionGetTeamsByUser(idUser, token));
  }, [dispatch, idUser, token]);

  return (
    <>
      <Modal
        visible={deleteVisibility}
        title={
          currentGoal
            ? `Supprimer l'objectif ${currentGoal.name}`
            : "Confirmer la suppression"
        }
        onCancel={isDeleteGoal ? null : () => setDeleteVisibility(false)}
      >
        <div className="row">
          <div className="col-md-12 wrap-btn-modal">
            <div className="btn-container">
              <button
                className="btn-border close-btn-submit"
                type="reset"
                onClick={isDeleteGoal ? null : () => setDeleteVisibility(false)}
              >
                Annuler
              </button>
              <button
                className="btn-full"
                type="submit"
                disabled={isDeleteGoal}
                onClick={() => {
                  dispatch(
                    actionDeleteGoal(
                      currentGoalRef.current.idgoal,
                      tokenRef.current,
                      (status) => {
                        if (status === 200) {
                          setDeleteVisibility(false);
                          setCurrentGoal(null);
                          enqueueSnackbar("Cet objectif a été supprimé", {
                            variant: "success",
                          });
                        } else {
                          setDeleteVisibility(false);
                          enqueueSnackbar(
                            "Cet objectif n'a pas été supprimé !",
                            {
                              variant: "warning",
                            }
                          );
                        }
                      }
                    )
                  );
                }}
              >
                {isDeleteGoal ? "Loading..." : "Supprimer"}
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <ModalAddGoal
        visibility={visibility}
        onCancel={cancel}
        large
        users={users || []}
      />

      <ModalUpdateGoal
        goal={currentGoal}
        visibility={visibilityUpdateGoal}
        onCancel={() => {
          setVisibilityUpdateGoal(false);
          setCurrentGoal(null);
        }}
        large
        users={users || []}
      />

      <Header />
      <main>
        <div className="container">
          <div className="head">
            <h1 className="title-1">Objectifs</h1>
            <div className="wrap-select types">
              <button
                className="btn-full"
                type="submit"
                onClick={() => {
                  setVisibility(true);
                }}
              >
                Ajouter un objectif
              </button>
            </div>
          </div>
          <br />
          <br />
          <div className="bloc-fond-fonce2 wrap-table">
            <GoalList
              large
              items={items}
              setItems={(data) => {
                dispatch(actionSetGoals({ type: "ALL", data }));
              }}
              onDelete={(item) => {
                setCurrentGoal(item);
                setDeleteVisibility(true);
              }}
              onUpdate={(item) => {
                setCurrentGoal(item);
                setVisibilityUpdateGoal(true);
              }}
            />
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}
