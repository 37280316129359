import React, { useState } from "react";
import styled from "styled-components";
import { UserAddOutlined } from "@ant-design/icons";
import { MinusOutlined } from "@ant-design/icons";
import Pagination from "./Pagination";
import { useSelector, useDispatch } from "react-redux";
import Modal from "../Views/Modal";
import { useSnackbar } from "notistack";
import Photo from "../../img/user-1.jpg";
import {
  actionInvitedUser,
  actionRemoveUser,
} from "../../redux/actions/teamAction";

function ListCard({ users, color, role, id }) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const idUser = useSelector((state) => state.user.id);
  const token = useSelector((state) => state.user.token);
  const isLoading = useSelector((state) => state.team.isUpdateTeamUser);

  const [user, setUser] = useState(null);
  const [email, setEmail] = useState("");
  // const [image, setImage] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [visibility, setVisibility] = useState(false);
  const [deleteVisibility, setDeleteVisibility] = useState(false);

  const cardsPerPage = 7;
  const indexOfLastCard = currentPage * cardsPerPage;
  const indexOfFirstCard = indexOfLastCard - cardsPerPage;
  const currentCards = users.slice(indexOfFirstCard, indexOfLastCard);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const onInvite = (e) => {
    e.preventDefault();
    if (email) {
      dispatch(
        actionInvitedUser(email, id, "DEFAULT", token, (is) => {
          if (is) {
            setVisibility(false);
            enqueueSnackbar("L'invitation à bien été envoyé.", {
              variant: "success",
            });
          } else {
            enqueueSnackbar(
              "Une erreur est survenue lors l'envoie de l'invitation.",
              {
                variant: "warning",
              }
            );
          }
        })
      );
    }
  };

  const show = () => {
    setEmail("");
    setVisibility(true);
  };

  const onDelete = () => {
    if (user) {
      dispatch(
        actionRemoveUser(user.id, id, token, (is) => {
          if (is) {
            setDeleteVisibility(false);
            setUser(null);
            enqueueSnackbar("L'utilisateur a bien été retirer de l'équipe.", {
              variant: "success",
            });
          } else {
            enqueueSnackbar("Une erreur est survenue.", {
              variant: "error",
            });
          }
        })
      );
    }
  };

  // const onChangeImage = () => {};

  return (
    <>
      <Modal
        visible={deleteVisibility}
        title={`Retirer de l'équipe "${user?.firstName} ${user?.lastName}"`}
        onCancel={isLoading ? null : () => setDeleteVisibility(false)}
      >
        <div className="row">
          <div className="col-md-12 wrap-btn-modal">
            <div className="btn-container">
              <button
                className="btn-border close-btn-submit"
                type="reset"
                onClick={isLoading ? null : () => setDeleteVisibility(false)}
              >
                Annuler
              </button>
              <button
                className="btn-full"
                disabled={isLoading}
                onClick={isLoading ? null : onDelete}
              >
                {isLoading ? "Loading..." : "Retirer"}
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        visible={visibility}
        height={"400px"}
        title={"Inviter un membre à rejoindre l'équipe"}
        onCancel={isLoading ? null : () => setVisibility(false)}
      >
        <div className="row">
          <div className="col-md-12">
            <label className="label-default">Entrer l'email</label>
            <input
              className="input-default inputCustom"
              name="email"
              id="email"
              type="email"
              placeholder="Obligatoire"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className="col-md-12 wrap-btn-modal">
            <ButtonGroup>
              <button
                className="btn-border close-btn-submit"
                onClick={isLoading ? null : () => setVisibility(false)}
              >
                Fermer
              </button>
              <button
                className="btn-full"
                disabled={isLoading}
                onClick={onInvite}
              >
                {isLoading ? "Loading..." : "Ajouter le membre"}
              </button>
            </ButtonGroup>
          </div>
        </div>
      </Modal>

      <div>
        <p className="label-default">Membre de l'équipe ({users.length})</p>
        <List>
          {role === "OWNER" && (
            <AddCard
              onClick={show}
              style={
                color === "0"
                  ? {
                      backgroundColor: "#323541",
                    }
                  : {
                      backgroundColor: "#e7e7e7",
                    }
              }
            >
              <Text>Ajouter un membre à l'équipe</Text>
              <span>
                <UserAddOutlined
                  style={{
                    color: "rgb(84, 183, 237)",
                    fontSize: 30,
                  }}
                />
              </span>
            </AddCard>
          )}
          {currentCards.map(({ user }, index) => (
            <Card
              key={index}
              style={
                color === "0"
                  ? {
                      backgroundColor: "#323541",
                    }
                  : {
                      backgroundColor: "#e7e7e7",
                    }
              }
            >
              {role === "OWNER" && idUser !== user.id && (
                <DeleteButton
                  onClick={() => {
                    setUser(user);
                    setDeleteVisibility(true);
                  }}
                >
                  <MinusOutlined
                    style={{
                      color: "#fff",
                      fontSize: 15,
                      fontWeight: "bolder",
                    }}
                  />
                </DeleteButton>
              )}
              <Image src={Photo} />
              <Title
                style={
                  color === "0"
                    ? {
                        color: "#fff",
                      }
                    : {
                        color: "#000",
                      }
                }
              >{`${user.firstName} ${user.lastName}`}</Title>
              <Small
                style={
                  color === "0"
                    ? {
                        color: "#fff",
                      }
                    : {
                        color: "#000",
                      }
                }
              >
                {user.email}
              </Small>
            </Card>
          ))}
        </List>
        {users.length > 7 ? (
          <Pagination
            cardsPerPage={cardsPerPage}
            totalCards={users.length}
            currentPage={currentPage}
            paginate={paginate}
          />
        ) : (
          ""
        )}

        {/* {role === "OWNER" && (
          <GroupImage>
            <SecondText
              style={
                color === "0"
                  ? {
                      color: "#fff",
                    }
                  : {
                      color: "#000",
                    }
              }
            >
              Photo de couverture
            </SecondText>
            <CardImage>
              <LargeImage src={image} />
              <input
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                id="image"
                name="image"
                onChange={onChangeImage}
              />
              <EditImage htmlFor="image">
                <pan style={{ cursor: "pointer" }}>
                  <EditOutlined
                    style={{
                      fontSize: 25,
                      color: "rgb(84, 183, 237)",
                    }}
                  />
                </pan>
              </EditImage>
            </CardImage>
          </GroupImage>
        )} */}
      </div>
    </>
  );
}

const ButtonGroup = styled.div`
  margin-top: 50px;
  column-gap: 10px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

const List = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;

  @media (max-width: 1100px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 800px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 550px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const Text = styled.div`
  font-size: 0.8rem;
  text-align: center;
  font-weight: bold;
  margin-bottom: 4px;
`;

const AddCard = styled.div`
  height: 162px;
  background-color: #e7e7e7;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px 7px;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
  cursor: pointer;
`;

const DeleteButton = styled.span`
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: #ff725e;
  border-radius: 4px;
  border: none;
  padding: 0;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const Card = styled(AddCard)`
  position: relative;
  background-color: #fff;
  cursor: default;
`;

const Image = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: grey;
  margin-bottom: 4px;
  object-fit: cover;
  object-position: center;
`;

const Title = styled.h2`
  font-size: 0.9rem;
  text-align: center;
  color: #000;
`;
const Small = styled.h2`
  font-size: 0.7rem;
  color: #3b3b3b;
  text-align: center;
`;

const GroupImage = styled.div`
  margin-top: 25px;
`;

const SecondText = styled.p`
  font-size: 1rem;
  color: #000;
`;

const CardImage = styled.div`
  display: flex;
  column-gap: 10px;
`;

const LargeImage = styled.img`
  width: 280px;
  height: 150px;
  border-radius: 10px;
  overflow: hidden;
  margin-top: 5px;
  object-fit: cover;
  object-position: center;
  position: relative;
  &::before {
    content: "Ajouter une image";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const EditImage = styled.label`
  width: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export default ListCard;
