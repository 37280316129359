import {
  ACTION_SET_RAPPORT,
  ACTION_SET_RAPPORTS,
  ACTION_ADD_RAPPORT,
  ACTION_REMOVE_RAPPORT,
  ACTION_IS_GET_RAPPORT,
  ACTION_IS_UPDATE_RAPPORT,
  ACTION_IS_DELETE_RAPPORT,
  ACTION_IS_ADD_RAPPORT,
  ACTION_SET_LOADING,
  ACTION_SET_RAPPORT_NOTIFICATION,
} from "../actions/rapportAction";

const initialState = {
  reports: [],
  isLoading: false,
  isDelete: false,
  isUpdate: false,
  isGet: false,
  isAdd: false,
};

const rapportReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_SET_RAPPORTS: {
      return {
        ...state,
        reports: action.payload,
      };
    }

    case ACTION_SET_RAPPORT_NOTIFICATION: {
      const reports = [...state.reports];
      const { id, idreport, type, data } = action.payload;
      if (type === "ADD") {
        for (let i = 0; i < reports.length; i++) {
          const report = reports[i];
          if (+report.idreport === +idreport) {
            reports[i].reportNotifications.push(data);
            break;
          }
        }
      } else if (type === "DELETE") {
        for (let i = 0; i < reports.length; i++) {
          const report = reports[i];
          for (let j = 0; j < report.reportNotifications.length; j++) {
            const reportNotification = report.reportNotifications[j];
            if (+reportNotification.idreportnotification === +id) {
              reports[i].reportNotifications.splice(j, 1);
              break;
            }
          }
        }
      } else if (type === "UPDATE") {
        for (let i = 0; i < reports.length; i++) {
          const report = reports[i];
          for (let j = 0; j < report.reportNotifications.length; j++) {
            const reportNotification = report.reportNotifications[j];
            if (+reportNotification.idreportnotification === +id) {
              reports[i].reportNotifications[j] = data;
              break;
            }
          }
        }
      }
      return {
        ...state,
        reports: reports,
      };
    }

    case ACTION_REMOVE_RAPPORT: {
      const reports = [...state.reports];
      for (let i = 0; i < reports.length; i++) {
        const report = reports[i];
        if (parseInt(report.idreport) === parseInt(action.payload)) {
          reports.splice(i, 1);
          break;
        }
      }

      return {
        ...state,
        reports: [...reports],
      };
    }

    case ACTION_ADD_RAPPORT: {
      return {
        ...state,
        reports: [...state.reports, action.payload],
      };
    }

    case ACTION_SET_RAPPORT: {
      const reports = [...state.reports];
      const report = action.payload;
      for (let i = 0; i < reports.length; i++) {
        if (parseInt(reports[i].idreport) === parseInt(report.idreport)) {
          reports[i].name = report.name;
          reports[i].updated_at = report.updated_at;
          reports[i].beginDate = report.beginDate;
          reports[i].goalReports = report.goalReports;
          reports[i].indicatorReports = report.indicatorReports;
          break;
        }
      }

      return {
        ...state,
        reports: [...reports],
      };
    }

    case ACTION_IS_GET_RAPPORT: {
      return {
        ...state,
        isGet: action.payload,
      };
    }

    case ACTION_IS_ADD_RAPPORT: {
      return {
        ...state,
        isAdd: action.payload,
      };
    }

    case ACTION_IS_UPDATE_RAPPORT: {
      return {
        ...state,
        isUpdate: action.payload,
      };
    }

    case ACTION_IS_DELETE_RAPPORT: {
      return {
        ...state,
        isDelete: action.payload,
      };
    }

    case ACTION_SET_LOADING: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }

    default:
      return state;
  }
};

export default rapportReducer;
