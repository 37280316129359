import host from "./host";

export const ACTION_SET_LIST = "ACTION_SET_LIST";
export const ACTION_SET_LISTS = "ACTION_SET_LISTS";
export const ACTION_IS_ADD_LIST = "ACTION_IS_ADD_LIST";
export const ACTION_IS_DELETE_LIST = "ACTION_IS_DELETE_LIST";
export const ACTION_IS_UPDATE_LIST = "ACTION_IS_UPDATE_LIST";
export const ACTION_IS_GET_LIST = "ACTION_IS_GET_LIST";
export const ACTION_IS_LOADING_LIST = "ACTION_IS_LOADING_LIST";
export const ACTION_SET_INDICATORS_LOCATIONS =
  "ACTION_SET_INDICATORS_LOCATIONS";

// * --------- Begin actions --------

export const actionSetList = (payload) => {
  return { type: ACTION_SET_LIST, payload };
};

export const actionSetLists = (payload) => {
  return { type: ACTION_SET_LISTS, payload };
};

export const actionisAddList = (payload) => {
  return { type: ACTION_IS_ADD_LIST, payload };
};

export const actionisDeleteList = (payload) => {
  return { type: ACTION_IS_DELETE_LIST, payload };
};

export const actionisUpdateList = (payload) => {
  return { type: ACTION_IS_UPDATE_LIST, payload };
};

export const actionisGetList = (payload) => {
  return { type: ACTION_IS_GET_LIST, payload };
};

export const actionisLoadingList = (payload) => {
  return { type: ACTION_IS_LOADING_LIST, payload };
};

export const actionSetIndicatorsLocations = (payload) => {
  return { type: ACTION_SET_INDICATORS_LOCATIONS, payload };
};

// * --------- End actions --------

// * --------- Begin --------

export const actionAddList = (
  name,
  readonly,
  iduser,
  iddashboard,
  token,
  callBack
) => {
  return (dispatch) => {
    dispatch(actionisAddList(true));
    let myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");
    
    let raw = JSON.stringify({
      name,
      readonly,
      positionx: new Date().getTime(),
      positiony: 0,
      iddashboard: iddashboard,
      iduser: iduser,
    });
    
    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    
    fetch(`${host}/list/create`, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) {
          return null;
        }
        return response.json();
      })
      .then((result) => {
        if (result) {
          result.new.indicators = [];
          dispatch(
            actionSetLists({
              type: "ADD",
              data: result.new,
            })
          );
          callBack(200);
        } else {
          callBack(400);
        }
      })
      .catch(() => {
        callBack(400);
      })
      .then(() => dispatch(actionisAddList(false)));
  };
};

export const actionMoveLists = (data, token) => {
  return (dispatch) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      data,
    });

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${host}/list/move-many`, requestOptions)
      .then((response) => {
        if (response.status != 200 && response.status != 201) {
          return null;
        }
      })
      .then((result) => {})
      .catch(() => {});
  };
};

export const actionDeleteList = (id, token, callback) => {
  return (dispatch) => {
    dispatch(actionisDeleteList(true));
    let myHeaders = new Headers();
    myHeaders.append("Authorization", token);

    let requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${host}/list/delete/${id}`, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) {
          callback(false);
          return null;
        } else {
          callback(true);
          dispatch(actionSetLists({ type: "REMOVE", data: id }));
        }
      })
      .catch(() => {})
      .then(() => dispatch(actionisDeleteList(false)));
  };
};

export const actionUpdateList = (name, readonly, id, x, y, token, callback) => {
  return (dispatch) => {
    dispatch(actionisUpdateList(true));

    let myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    let raw = JSON.stringify({
      name: name,
      readonly: readonly,
      positionx: x,
      positiony: y,
    });

    let requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${host}/list/update/${id}`, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) {
          callback(false);
          return null;
        }
        callback(true);
        return response.json();
      })
      .then((result) => {
        if (result) {
          dispatch(actionSetLists({ id, type: "UPDATE", data: result.update }));
        } else {
        }
      })
      .catch(() => {})
      .then(() => {
        dispatch(actionisUpdateList(false));
      });
  };
};

// * --------- End --------
