import React, { useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";

export default function CustomDrawer({ anchor, open, onClose, children, width }) {
  const toggleDrawer = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    onClose();
  };

  return (
    <div>
      <Drawer anchor={anchor || "left"} open={open} onClose={toggleDrawer}>
        <Box
          sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : width || 250 }}
          role="presentation"
        >
           { children }
        </Box>
      </Drawer>
    </div>
  );
}
