import React, { useState } from "react";
// import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import ModalIndicator from "./ModalIndicator";
import { Chart } from "react-google-charts";
import { Draggable } from "react-dnd-beautiful";
import PropagateLoader from "react-spinners/PropagateLoader";
import { useNavigate } from "react-router-dom";
import {
  actionSetIndicator,
  actionSetComments,
} from "../../redux/actions/indicatorAction";

import {
  FullscreenOutlined,
  // DeleteOutlined,
  // ShareAltOutlined,
} from "@ant-design/icons";
import { actionSetGoals } from "../../redux/actions/GoalAction";
// import CircularProgress from "@mui/material/CircularProgress";
import { Progress } from "antd";
import { numberFormat } from "./IndicatorView";
const numeral = require("numeral");
const _ = require("lodash");

let theme = "1";
const data = localStorage.getItem("theme");
if (data) {
  theme = JSON.parse(data).theme;
}

export default function Card({
  isLarge,
  data,
  key,
  index,
  setIsDraggable,
  isDraggable,
}) {
  // const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [visibility, setVisibility] = useState(false);
  const isViewOnly = useSelector((state) => state.board.isViewOnly);
  const timeInterval = useSelector((state) => state.time.interval);
  const timeValue = useSelector((state) => state.time.value);
  const idUser = useSelector((state) => state.user.id);
  const onCancel = () => {
    setIsDraggable(true);
    setVisibility(false);
  };

  const renderLastValue = () => {
    const { total, lastValue } = data;
    const pourcentage = ((total - lastValue) * 100) / lastValue;

    const periode = [
      "Hier",
      "La semaine dernière",
      "Le mois dernier",
      "Le trimestre dernier",
      "Le semestre dernier",
      "L'année dernière",
    ];

    let text = "";

    const nbre = parseInt(timeValue) - 1;

    if (nbre >= 0) {
      text = periode[nbre];
    } else {
      text = `du ${new Date(timeInterval[0]).toISOString().split("T")[0]} au ${
        new Date(timeInterval[1]).toISOString().split("T")[0]
      }`;
    }

    if (total > lastValue) {
      return (
        <span
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img alt="haut" src="/haut.png" height={"20px"} />
          <i
            style={{
              color: "#2ECC71",
              fontStyle: "normal",
              fontWeight: "bold",
              fontSize: "15px",
              display: "inline-block",
              marginLeft: "10px",
            }}
          >
            {numberFormat(pourcentage)}%{" "}
            <i
              style={{
                color: parseInt(theme) === 0 ? "#FFF" : "#000",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "12px",
                display: "inline-block",
                marginLeft: "10px",
              }}
            >
              {text}
            </i>
          </i>
        </span>
      );
    } else {
      return (
        <span
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img alt="bas" src="/bas.png" height={"20px"} />
          <i
            style={{
              color: "#E74C3C",
              fontStyle: "normal",
              fontWeight: "bold",
              fontSize: "15px",
              display: "inline-block",
              marginLeft: "10px",
            }}
          >
            {numberFormat(pourcentage)}%{" "}
            <i
              style={{
                color: parseInt(theme) === 0 ? "#FFF" : "#000",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "12px",
                display: "inline-block",
                marginLeft: "10px",
              }}
            >
              {text}
            </i>
          </i>
        </span>
      );
    }
  };

  const getAxe = ()=>{
    let axe = null;
    for (let index = 0; index < data.columns.length; index++) {
      const element = data.columns[index];
      if (
        parseInt(element.idcolumn) === parseInt(data.idaxe)
      ) {
        axe = element;
        break;
      }
    }
    return axe;
  }

  return (
    <>
      <ModalIndicator
        visible={visibility}
        onCancel={onCancel}
        data={data}
        setVisible={setVisibility}
      />
      <Draggable
        draggableId={data.idindicator + ""}
        index={index}
        isDragDisabled={isViewOnly || !idUser || visibility}
      >
        {(provided) => (
          <div
            className={false ? "col-xl-6 col-md-12 col-sm-6" : "col-12"}
            key={key}
            id={`indicator-${data.idindicator}`}
            onClick={() => {
              setVisibility(true);
              setIsDraggable(false);
            }}
            ref={provided.innerRef}
            {...provided.dragHandleProps}
            {...provided.draggableProps}
          >
            <div className="bloc-fond-fonce rose">
              <div
                className="indicator_header"
                /* style={{
                  position: "relative",
                  top: "-10px",
                  display: "flex",
                  justifyContent: "space-between",
                }} */
              >
                <div
                  className="indicator_icon"
                  /* style={{
                    position: "relative",
                    display: "flex",
                  }} */
                >
                  {/* <DeleteOutlined
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    style={{ fontSize: "17px", color: "#fff" }}
                  />
                  <ShareAltOutlined
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    style={{ fontSize: "17px", color: "#fff" }}
                  /> */}
                  <FullscreenOutlined
                    onClick={(e) => {
                      e.stopPropagation();
                      sessionStorage.setItem("indicator", JSON.stringify(data));
                      dispatch(actionSetIndicator(data));
                      dispatch(actionSetComments([...data.commentaires]));
                      dispatch(
                        actionSetGoals({ type: "ALL", data: data.goals })
                      );
                      navigate("/indicator");
                    }}
                    style={{
                      paddingRight: "10px",
                      fontSize: "17px",
                      color:
                        theme === "0"
                          ? "#fff"
                          : theme === "1"
                          ? "#1f2128"
                          : "#1f2128",
                      // "#fff"
                    }}
                  />
                  {/* <i
                  className="fa-light fa-ellipsis more"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  style={{
                    paddingRight: "10px",
                    color:
                      theme === "0"
                        ? "#fff"
                        : theme === "1"
                        ? "#1f2128"
                        : "#1f2128",
                    // "#fff",
                    cursor: "pointer",
                  }}
                ></i> */}
                </div>
                {/* <i
                  className="fa-light fa-ellipsis more"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  style={{
                    color:
                      theme === "0"
                        ? "#fff"
                        : theme === "1"
                        ? "#1f2128"
                        : "#1f2128",
                    // "#fff",
                    cursor: "pointer",
                  }}
                ></i> */}
              </div>
              <div className="row">
                <div className="col-md stats no-padding-right-md">
                  <p className="title">{data.name}</p>
                  <p
                    className="chiffre"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      fontSize: "25px",
                    }}
                  >
                    {data && parseInt(data.operation) === 1
                      ? numberFormat(data.total)
                      : data && parseInt(data.operation) === 2
                      ? numberFormat(data.avg)
                      : data && parseInt(data.operation) === 4
                      ? numberFormat(data.count)
                      : numberFormat(data.total)}
                    {data && data.symbole !== null && data.symbole !== ""
                      ? ` ${data.symbole} `
                      : " "}
                    {/* <span>
                      {data && parseInt(data.operation) === 1
                        ? "(Cumul)"
                        : data && parseInt(data.operation) === 2
                        ? "(Moyenne)"
                        : "(Cumul)"}
                    </span> */}
                  </p>
                  {data.lastValue ? renderLastValue() : null}
                  <div id="vente_5" className="gchart">
                    {!data.is || data.isLoading ? (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                          minHeight: "50px",
                        }}
                      >
                        <PropagateLoader
                          // color={"#fff"}
                          color={
                            theme === "0"
                              ? "#fff"
                              : theme === "1"
                              ? "#1f2128"
                              : "#1f2128"
                          }
                          loading={true}
                          size={5}
                          speedMultiplier={0.75}
                        />
                      </div>
                    ) : (
                      <Chart
                        width={"100%"}
                        height={"50px"}
                        chartLanguage="fr"
                        chartType={"LineChart"}
                        // chartType={
                        //   data && data.type === "2" ? "PieChart" : "LineChart"
                        // }
                        loading={true}
                        loader={
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                              minHeight: "50px",
                            }}
                          >
                            <PropagateLoader
                              // color={"#fff"}
                              color={
                                theme === "0"
                                  ? "#fff"
                                  : theme === "1"
                                  ? "#1f2128"
                                  : "#1f2128"
                              }
                              loading={true}
                              size={5}
                              speedMultiplier={0.75}
                            />
                          </div>
                        }
                        columns={(() => {
                          // if (data && data.type === "2") {
                          //   return [
                          //     { label: data.cols[0].label, type: "string" },
                          //     data.cols[1],
                          //   ];
                          // }
                          if (
                            data.goals.length > 0 &&
                            parseInt(data.operation) === 1
                            && !data.notshowcumulwithgoal
                          ) {
                            return [
                              ...data.cols,
                              { label: "cumul", type: "number" },
                              { label: "Objectif", type: "number" },
                            ];
                          } else if (
                            data.goals.length > 0 &&
                            (parseInt(data.operation) === 2 || data.notshowcumulwithgoal)
                          ) {
                            return [
                              ...data.cols,
                              { label: "Objectif", type: "number" },
                            ];
                          }
                          return data.cols;
                        })()}
                        rows={(() => {
                          // if (data && data.type === "2") {
                          //   return data.rows;
                          // }
                          if (data.goals.length > 0) {
                            let finish = [];
                            let total = 0;
                            let noPeriodique = _.sortBy(data.goals, [
                              (o) => o.startdate,
                            ]);
                            // let item = noPeriodique[0];
                            for (let i = 0; i < data.rows.length; i++) {
                              const row = data.rows[i];
                              let item = null;
                              for (let i = 0; i < noPeriodique.length; i++) {
                                const goal = noPeriodique[i];
                                let start = new Date(goal.startdate).getTime();
                                let end = new Date(goal.enddate).getTime();
                                let current = new Date(row[0]).getTime();
                                if (current >= start && current <= end) {
                                  item = goal;
                                }
                              }
                              total += row[1];

                              if (parseInt(data.operation) === 1 && !data.notshowcumulwithgoal) {
                                finish.push([
                                  ...row,
                                  total,
                                  item ? item.targetvalue : null,
                                ]);
                              } else {
                                finish.push([
                                  ...row,
                                  item ? item.targetvalue : null,
                                ]);
                              }
                            }
                            // console.log('finish :>> ', finish);
                            return finish;
                          }
                          return data.rows;
                        })()}
                        options={{
                          chartArea: { height: "100%", width: "100%" },
                          crosshair: { trigger: "both", orientation: "both" },
                          // trendlines: {
                          //   0: {
                          //     // type: "linear",
                          //     // type: "exponential",
                          //     type: "polynomial",
                          //     degree: 3,
                          //     color: "green",
                          //     // color: "#e22749",
                          //     // opacity: 1,
                          //     // pointsVisible: true
                          //   },
                          //   // n: {
                          //   //   pointsVisible: false
                          //   // }
                          // },

                          hAxis: {
                            slantedText: false,
                            gridlines: {
                              color: "transparent",
                            },
                          },
                          vAxis: {
                            gridlines: {
                              color: "transparent",
                            },
                            baselineColor: "transparent",
                          },
                          backgroundColor: "transparent",
                          // colors: ["transparent"],
                          colors: [
                            "#05BAF3",
                            // theme === "0" || theme === "1"
                            //   ? "#d02173"
                            //   : "#007FC1",
                            ...(data && (parseInt(data.operation) === 2 || data.notshowcumulwithgoal)
                              ? ["#25F700", "blue"]
                              : ["blue", "#25F700"]),
                            "#EF6C00",
                            "#AD1457",
                            "#00B0FF",
                            "#D4E157",
                            "#283593",
                            "#3D5AFE",
                            "#6A1B9A",
                            "#42A5F5",
                            "#5C6BC0",
                            "#9E9D24",
                            "#FF7043",
                            "#651FFF",
                            "#7E57C2",
                            "#FF9100",
                            "#FF1744",
                            "#1565C0",
                            "#D500F9",
                            "#FF3D00",
                            "#EC407A",
                          ],
                          legend: { position: "none" },
                        }}
                      />
                    )}
                  </div>
                  {/* <p className="vs"> -22.09 % (vs 10/2021)</p> */}
                  {/* <p className="vs" style={{ color: "#fff" }}>
                    {data.total}
                    {" (Cumul)"} ~ +10%
                  </p> */}
                </div>
                <div className="col-md-auto">
                  {data && data.goals && data.goals.length > 0
                    ? (() => {
                        let noPeriodique = _.sortBy(data.goals, [
                          (o) => o.startdate,
                        ]);
                        let item = null;
                        let current = new Date().getTime();
                        for (let i = 0; i < noPeriodique.length; i++) {
                          const goal = noPeriodique[i];
                          let start = new Date(goal.startdate).getTime();
                          let end = new Date(goal.enddate).getTime();
                          if (current >= start && current <= end) {
                            item = goal;
                          }
                        }
                        if (!item) {
                          let end = new Date(noPeriodique[0].enddate).getTime();
                          if (current > end) {
                            item = noPeriodique[noPeriodique.length - 1];
                          } else {
                            item = noPeriodique[0];
                          }
                        }
                        return (
                          <div
                            className="bloc-border-blanc-inner"
                            title={`Objectif du ${item.startdate} au ${item.enddate}`}
                          >
                            <p className="objectif">{`${item.enddate}`}</p>
                            {/* <p style={{color: "#000"}}>Objectif</p><br/> */}
                            <div
                              // className="gpercent"
                              style={{
                                // width: "200px",
                                // height: "30px",
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                                // backgroundColor: "#323541",
                                // color: "#fff",
                              }}
                            >
                              <Progress
                                type="circle"
                                width="50px"
                                percent={
                                  item.currentvalue
                                    ? _.round(
                                        (item.currentvalue / item.targetvalue) *
                                          100,
                                        1
                                      )
                                    : 0
                                }
                                strokeColor={"#05BAF3"}
                                trailColor={"#CCD4E2"}
                                // strokeColor={theme === "0" ? "#fff" : theme === "1" ? "#1f2128" : "#1f2128"}
                                format={(percent) => {
                                  return (
                                    <i
                                      style={{
                                        // color: "#fff",
                                        color:
                                          theme === "0"
                                            ? "#fff"
                                            : theme === "1"
                                            ? "#1f2128"
                                            : "#1f2128",
                                        fontSize: "12px",
                                      }}
                                    >
                                      {_.round(
                                        (item.currentvalue / item.targetvalue) *
                                          100,
                                        1
                                      ) + "%"}
                                    </i>
                                  );
                                }}
                              />
                            </div>
                            <p className="t-center">
                              <br />
                              {item.targetvalue
                                ? numeral(item.targetvalue)
                                    .format("0,0")
                                    .split(",")
                                    .join(" ")
                                : 0}
                            </p>
                          </div>
                        );
                      })()
                    : null}
                </div>
              </div>
              {data &&
              data.goals &&
              data.type !== "2" &&
              data.goals.length > 0 ? (
                <div
                  style={{
                    // backgroundColor: "yellow",
                    height: "25px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <p>
                    <span
                      style={{
                        display: "inline-block",
                        width: "10px",
                        height: "10px",
                        backgroundColor: "#25F700",
                      }}
                    ></span>{" "}
                    <i style={{ fontSize: "15px" }}>Objectif</i>
                  </p>

                  {parseInt(data.operation) === 1 && !data.notshowcumulwithgoal ? (
                    <p>
                      <span
                        style={{
                          display: "inline-block",
                          width: "10px",
                          height: "10px",
                          marginLeft: "10px",
                          backgroundColor: "blue",
                        }}
                      ></span>{" "}
                      <i style={{ fontSize: "15px" }}>Cumul</i>
                    </p>
                  ) : null}

                  <p>
                    <span
                      style={{
                        display: "inline-block",
                        width: "10px",
                        height: "10px",
                        marginLeft: "10px",
                        backgroundColor: "#05BAF3",
                        // backgroundColor:
                        //   theme === "0" || theme === "1"
                        //     ? "#d02173"
                        //     : "#007FC1",
                      }}
                    ></span>{" "}
                    <i style={{ fontSize: "15px" }}>Réalisé</i>
                  </p>
                </div>
              ) : null}
              {/* {data.lastValue ? renderLastValue() : null} */}
            </div>
          </div>
        )}
      </Draggable>
    </>
  );
}
