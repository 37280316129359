import React, { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Iframe from "react-iframe";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Connector from "./Connector";
const ModalAntd = require("antd").Modal;

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 10,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 8,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 5,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 3,
  },
};

export default function ModalAddSource({
  visibility,
  onCancel,
  id,
  url,
  setId,
  setUrl,
  // onReceive
}) {
  const { t } = useTranslation();
  const connectors = useSelector((state) => state.connector.connectors);
  // const [id, setId] = useState(null);
  // const [url, setUrl] = useState(null);

  useEffect(() => {
    if (connectors.length > 0) {
      setId(connectors[0].idconnector);
      setUrl(connectors[0].url);
    }
  }, [connectors]);

  return (
    <>
      <ModalAntd
        visible={visibility}
        bodyStyle={{ padding: 0, margin: 0 }}
        width={"75%"}
        closable={false}
        destroyOnClose={true}
        onCancel={onCancel}
        footer={null}
      >
        <div style={{ width: "100%", minHeight: "500px", color: "#000" }}>
          <div
            style={{
              width: "100%",
              height: "100px",
              color: "#000",
              backgroundColor: "rgba(0,0,0,0.125)",
            }}
          >
            <Carousel responsive={responsive}>
              {connectors.map((item) => {
                return (
                  <Connector
                    url={item.url}
                    id={item.idconnector}
                    name={item.name}
                    selected={Boolean(url === item.url)}
                    image={item.image}
                    onClick={(newId, newUrl) => {
                      if (newUrl !== url) {
                        setId(newId);
                        setUrl(newUrl);
                      }
                    }}
                  />
                );
              })}
            </Carousel>
          </div>
          <Iframe
            id={"if1"}
            url={url}
            width="100%"
            height="500px"
            display="initial"
            position="relative"
          />
        </div>
      </ModalAntd>
    </>
  );
}
