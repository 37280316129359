import { ACTION_SET_TIME, ACTION_SET_TIME_VALUE } from "../actions/timeAction";

const initialStateUser = {
  interval: [null, null],
  value: null,
};

const timeReducer = (state = initialStateUser, action) => {
  switch (action.type) {
    case ACTION_SET_TIME: {
      return {
        ...state,
        interval: [...action.payload],
      };
    }

    case ACTION_SET_TIME_VALUE: {
      return {
        ...state,
        value: action.payload,
      };
    }

    default:
      return state;
  }
};

export default timeReducer;
