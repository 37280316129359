//ACTIONS:
export const ACTION_GET_OPERATORS = "ACTION_GET_OPERATORS";
export const ACTION_SET_OPERATOR = "ACTION_SET_OPERATOR";

export const actionGetOperators = () => {
  const operators = ["<", ">", "<=", ">=", "="];

  return {
    type: ACTION_GET_OPERATORS,
    payload: operators,
  };
};

export const actionSetOperator = (operator) => {
  return {
    type: ACTION_SET_OPERATOR,
    payload: operator,
  };
};
