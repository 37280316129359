import React from "react";
import styled from "styled-components";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";

const Paginate = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  gap: 5px;
`;

const Button = styled.button`
  cursor: pointer;
  border: none;
  background: transparent;
  &:focus {
    outline: none;
  }
`;

function Pagination({ cardsPerPage, totalCards, currentPage, paginate }) {
  const pagesNumber = [];
  for (let i = 1; i <= Math.ceil(totalCards / cardsPerPage); i++) {
    pagesNumber.push(i);
  }
  return (
    <Paginate>
      <Button
        onClick={() => paginate(currentPage - 1)}
        disabled={currentPage === 1 ? true : false}
      >
        <ArrowLeftOutlined
          style={{
            fontWeight: "bold",
            color: currentPage === 1 ? "#cecece" : "#000",
          }}
        />
      </Button>
      <span>
        Page {currentPage}/{pagesNumber.length}
      </span>
      <Button
        onClick={() => paginate(currentPage + 1)}
        disabled={currentPage === pagesNumber.length ? true : false}
      >
        <ArrowRightOutlined
          style={{
            fontWeight: "bold",
            color: currentPage === pagesNumber.length ? "#cecece" : "#000",
          }}
        />
      </Button>
    </Paginate>
  );
}

export default Pagination;
