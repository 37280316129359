import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import Header from "../Header";
import Footer from "../Footer";
import Planche from "../Views/Planche";
import Modal from "../Views/Modal";
import {
  actionAddDashboardMid,
  actionDeleteDashboard,
  actionGetDashboardsByUser,
  actionUpdateDashboard,
  actionMoveDashboards,
  actionSetDashboards,
} from "../../redux/actions/dashboardAction";
import ContentLoader from "react-content-loader";
import { useSnackbar } from "notistack";
import CustomSelect from "../Views/CustomSelect";
import { Pagination } from "antd";
import styled from "styled-components";
import { Fragment } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import ViewDashboard from "../Views/ViewDashBoard";

const ResponsiveGridLayout = WidthProvider(Responsive);

const _ = require("lodash");
let theme = "1";
const data = localStorage.getItem("theme");

if (data) {
  theme = JSON.parse(data).theme;
}

export default function Home() {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const options = [
    { value: 2, label: "Simple tableau de board" },
    { value: 1, label: "Tableau de bord avec list" },
  ];
  const [type, setType] = useState(options[0]);
  const [visibility, setVisibility] = useState(false);
  const [deleteVisibility, setDeleteVisibility] = useState(false);
  const [id, setId] = useState(false);
  const token = useSelector((state) => state.user.token);
  const idUser = useSelector((state) => state.user.id);
  const dashboards = useSelector((state) => state.board.dashboards);
  const isAdd = useSelector((state) => state.board.isAdd);
  const isDelete = useSelector((state) => state.board.isDelete);
  const isGet = useSelector((state) => state.board.isGet);

  useEffect(() => {
    dispatch(actionGetDashboardsByUser(idUser, token));
  }, []);

  const addBoard = (e) => {
    e.preventDefault();
    if (name.trim() === "") {
      enqueueSnackbar("entrez un nom valide", {
        variant: "warning",
      });
      return;
    }
    dispatch(
      actionAddDashboardMid(name.trim(), type.value, idUser, token, (is) => {
        if (is) {
          setVisibility(false);
          setName("");
          enqueueSnackbar("Tableau de bord ajouté", {
            variant: "success",
          });
        } else {
          enqueueSnackbar("Le tableau de bord n'est pas ajouté !", {
            variant: "warning",
          });
        }
      })
    );
  };

  const deleteBoard = () => {
    dispatch(
      actionDeleteDashboard(id, token, (is) => {
        if (is) {
          setId(null);
          setDeleteVisibility(false);
          enqueueSnackbar("Ce tableau de bord est supprimé", {
            variant: "success",
          });
        } else {
          enqueueSnackbar("Ce tableau de bord n'est pas supprimé !", {
            variant: "warning",
          });
        }
      })
    );
  };

  const confirmDeleteBoard = (id) => {
    setId(id);
    setDeleteVisibility(true);
  };

  const updateBoard = (name, id) => {
    dispatch(actionUpdateDashboard(name, id, token, () => {}));
  };

  const [page, setPage] = useState(1);
  const handleChange = (value) => {
    setPage(value);
  };

  const [ordre, setOrdre] = useState(false);

  const setDash = (data) => {
    dispatch(actionSetDashboards({ type: "all", data }));
    dispatch(
      actionMoveDashboards(
        data.map((item, index) => {
          return {
            iduser: item.iduser,
            iddashboard: item.iddashboard,
            positionx: index,
          };
        }),
        token
      )
    );
  };
  return (
    <>
      <Modal
        visible={ordre}
        title={"Modifier l'ordre des dashboards"}
        onCancel={() => setOrdre(false)}
      >
        <>
          <ViewDashboard items={dashboards} setItems={setDash} />
        </>
      </Modal>
      <Modal
        visible={deleteVisibility}
        title={"Confirmer la suppression"}
        onCancel={isDelete ? null : () => setDeleteVisibility(false)}
      >
        <div className="row">
          <div className="col-md-12 wrap-btn-modal">
            <div className="btn-container">
              <button
                className="btn-border close-btn-submit"
                type="reset"
                onClick={isDelete ? null : () => setDeleteVisibility(false)}
              >
                Annuler
              </button>
              <button
                className="btn-full"
                type="submit"
                disabled={isDelete}
                onClick={deleteBoard}
              >
                {isDelete ? "Loading..." : "Supprimer"}
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        visible={visibility}
        title={"Ajouter un tableau"}
        onCancel={isAdd ? null : () => setVisibility(false)}
      >
        <form onSubmit={addBoard}>
          <div className="row">
            <div className="col-md-12">
              <div className="input-container">
                <label for="nom" className="label-default">
                  Entrer le nom *
                </label>
                <span className="span-block">
                  <input
                    className="input-default inputCustom"
                    name="nom"
                    id="nom"
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Obligatoire"
                    required
                  />
                </span>
              </div>
            </div>
            <div className="col-md-12">
              <div className="input-container">
                <label className="label-default">Type de dashboard</label>
                <CustomSelect
                  value={type}
                  style={{ textSize: "5px" }}
                  options={options}
                  onChange={setType}
                />
              </div>
            </div>
            <div className="col-md-12 wrap-btn-modal">
              <div className="btn-container">
                <button
                  className="btn-border close-btn-submit"
                  type="reset"
                  onClick={isAdd ? null : () => setVisibility(false)}
                >
                  Annuler
                </button>
                <button className="btn-full" type="submit" disabled={isAdd}>
                  {isAdd ? "Loading..." : "Ajouter"}
                </button>
              </div>
            </div>
          </div>
        </form>
      </Modal>

      <Header />
      <main className="homemain">
        <div className="container">
          <div className="head">
            <h1 className="title-1">Mes tableaux de bord</h1>
            <button
              className="btn-border close-btn-submit"
              type="reset"
              onClick={() => {
                setOrdre(true);
              }}
            >
              Modifier l'ordre des dashboards
            </button>
          </div>

          <div className="row cards">
            <div className="col-xs-12 col-md-6 col-lg-3">
              <div
                className="ajout-planche to_that"
                onClick={() => setVisibility(true)}
                style={{ height: "210px", marginTop: 0 }}
              >
                <span class="add-card">
                  <i class="fa fa-plus"></i>
                </span>
                <span title="Ajouter un tableau" target="_self" class="bind_me">
                  Ajouter un tableau
                </span>
              </div>
            </div>
            {isGet ? (
              <Fragment>
                {[1, 2, 3].map(() => (
                  <div class="col-xs-12 col-md-6 col-lg-3 mb-2">
                    <ContentLoader
                      height={"195px"}
                      width={"100%"}
                      speed={1}
                      backgroundColor={"#80808075"}
                      foregroundColor={"#a9a9a975"}
                      className="planche"
                    >
                      <rect
                        x="0"
                        y="0"
                        rx="9px"
                        ry="0px"
                        width="100%"
                        height="160px"
                      />
                      <rect
                        x="8px"
                        y="170px"
                        rx="9px"
                        ry="9px"
                        width="70%"
                        height="13px"
                      />
                    </ContentLoader>
                  </div>
                ))}
              </Fragment>
            ) : (
              dashboards.slice(page * 3 - 3, page * 3).map((item, index) => (
                <div
                  class="col-xs-12 col-md-6 col-lg-3"
                  key={item.dashboard.iddashboard}
                >
                  <Planche
                    id={item.dashboard.iddashboard}
                    dashboard={item.dashboard}
                    name={item.dashboard.name}
                    isViewOnly={Boolean(item.role === "VIEW")}
                    isOwner={Boolean(item.role === "OWNER")}
                    image={
                      index % 2 === 0 ? "./planche2.jpg" : "./planche1.jpg"
                    }
                    onDelete={confirmDeleteBoard}
                    onUpdate={updateBoard}
                  />
                </div>
              ))
            )}
            {dashboards.length > 3 ? (
              <Pagination
                total={Math.ceil(dashboards.length / 3) * 10}
                current={page}
                onChange={handleChange}
                className="col-xs-12 col-md-12 text-right"
              />
            ) : (
              " "
            )}
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}
const Container = styled(ResponsiveGridLayout)`
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    border-radius: 8px;
    margin: 10px;
    width: 5px;
  }
  ::-webkit-scrollbar-button {
    display: none;
  }
  ::-webkit-scrollbar-corner {
    border-radius: 8px;
    margin: 3px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #05baf3;
    border-radius: 8px;
    border-color: #05bcf341;
    margin: 3px;
    border-width: 2px;
  }
  ::-webkit-scrollbar-track {
    border-radius: 8px;
    margin: 3px;
  }
`;
