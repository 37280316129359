import { Button } from "antd";
import React from "react";
import pptxgen from "pptxgenjs";
import { Print } from "@material-ui/icons";
import { useSelector } from "react-redux";
import { numberFormat, regroupe } from "./IndicatorView";
import back from "../../img/back.jpg";
import back2 from "../../img/back2.jpg";
import back4 from "../../img/back4.jpg";

export default function ModalPrint({ interval }) {
  const lists = useSelector((state) => state.list.lists);
  const board = useSelector((state) => state.board.dashboard);

  const print = () => {
    let indicators = [];
    for (let i = 0; i < lists.length; i++) {
      const list = lists[i];
      indicators = [...indicators, ...list.indicators];
    }
    let pptx = new pptxgen();
    pptx.author = "Generated by viabber";
    pptx.company = "https://app.viabber.com";
    pptx.subject = "Subject of Report";
    pptx.title = "Title of Presentation";
    let slide = pptx.addSlide();
    slide.background = {
      path: back,
    };

    // slide.addText(board.name, {
    //   x: 1,
    //   y: 1,
    //   h: 1,
    //   bold: true,
    //   fontSize: 28,
    //   // fill: "1e2128",
    //   color: "ffffff",
    //   align: "left",
    // });

    slide.addText(
      `Du ${new Date(interval[0]).toLocaleDateString()} au ${new Date(
        interval[1]
      ).toLocaleDateString()}`,
      {
        x: 0,
        y: 0,
        h: 1,
        w: "100%",
        fontSize: 18,
        color: "ffffff",
        align: "right",
      }
    );
    slide.addText(`by VIABBER`, {
      x: 1,
      y: 4,
      h: 1,
      bold: true,
      fontSize: 10,
      align: "right",
      color: "363636",
    });

    for (let i = 0; i < indicators.length; i++) {
      const indicator = indicators[i];
      const axe = indicator.columns.filter(
        (item) => +item.idcolumn === +indicator.iddefault
      )[0];
      const page = pptx.addSlide();
      page.background = {
        path: back2,
      };
      // page.color = "ffffff";

      page.addText(indicator.name.toUpperCase(), {
        x: 0.5,
        y: 0.25,
        h: 1,
        bold: true,
        fontSize: 20,
        color: "24293d",
        align: "left",
      });
      page.addText(
        `Du ${new Date(interval[0]).toLocaleDateString()} au ${new Date(
          interval[1]
        ).toLocaleDateString()}`,
        {
          x: 0,
          y: 0,
          h: 1,
          w: "100%",
          bold: true,
          fontSize: 15,
          color: "24293d",
          align: "right",
        }
      );
      page.addText(
        +indicator.operation === 1
          ? `${numberFormat(indicator.total)} ${indicator.symbole}`
          : `${numberFormat(indicator.avg)} ${indicator.symbole}`,
        {
          x: 1,
          y: 1,
          h: 1,
          w: "80%",
          bold: true,
          fontSize: 38,
          color: "24293d",
          align: "center",
        }
      );
      let rows = indicator.rows;
      if (+axe.dataType === 3) {
        rows = regroupe(indicator.rows, `${indicator.periode}`);
      }

      let dataChartAreaLine = [
        {
          name: indicator.name,
          labels: [
            ...rows.map((item) => {
              if (+axe.dataType === 3 && `${indicator.periode}` === "1") {
                return new Date(item[0]).toLocaleString();
              }
              return item[0];
            }),
          ],
          values: [...rows.map((item) => item[1])],
        },
        {
          name: "",
          labels: [],
          values: [],
        },
      ];

      page.addChart(
        +indicator.type === 0
          ? pptx.ChartType.bar
          : +indicator.type === 1
          ? pptx.ChartType.line
          : pptx.ChartType.pie,
        dataChartAreaLine,
        {
          x: 0.5,
          y: 2,
          w: "90%",
          h: 3,
          align: "center",
          // color: "ffffff",
          showLegend: +indicator.type === 2 ? true : false,
          catAxisLabelColor: "444444",
          valAxisLabelColor: "444444",
          legendPos: "r",
          legendColor: "444444",
          chartColors: [
            "00b6ec",
            "24293d",
            "2979FF",
            "C62828",
            "F50057",
            "AB47BC",
            "4527A0",
            "FFA726",
            "EF5350",
            "FF3D00",
            "0277BD",
            "C6FF00",
            "EF6C00",
            "AD1457",
            "00B0FF",
            "D4E157",
            "283593",
            "3D5AFE",
            "6A1B9A",
            "42A5F5",
            "5C6BC0",
            "9E9D24",
            "FF7043",
            "651FFF",
            "7E57C2",
            "FF9100",
            "FF1744",
            "1565C0",
            "D500F9",
            "FF3D00",
            "EC407A",
          ],
        }
      );
    }
    let end = pptx.addSlide();
    end.background = {
      path: back4,
    };
    pptx.writeFile({ fileName: "teste" });
  };
  return (
    <Button
      type="primary"
      style={{
        padding: 0,
        width: "40px",
        height: "30px",
        borderWidth: 0,
        borderRadius: "5px",
        background: "#05baf375",
      }}
      icon={<Print />}
      onClick={print}
    />
  );
}
