import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { actionAcceptInvitation } from "../../redux/actions/UserAction";

let theme = "1";
const data = localStorage.getItem("theme");

if (data) {
  theme = JSON.parse(data).theme;
}

export default function Invited() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const loading = useSelector((state) => state.user.loading);
  const dispatch = useDispatch();
  const [token, setToken] = useState(null);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    setToken(searchParams.get("noreply"));
    if (!searchParams.get("noreply")) {
      navigate("/");
    }
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(
      actionAcceptInvitation(token, (status) => {
        if (status === 200) {
          navigate("/board", {
            replace: true,
          });
        }
      })
    );
  };

  return (
    <main className="intro-pages">
      <div className="filtre"></div>
      <section id="register">
        <div className="container">
          <span className="welcome">Bienvenue sur</span>
          <div className="logo-container"></div>

          <h2 className="title-2">
            Vous avez été invité à rejoindre un Tableau
          </h2>
          <p className="content">
            Bienvenue sur Viabber, un outil d'analyse de données simple,
            rapide, fiable et collaboratif.
          </p>

          <div action="#" className="form-langage">
            <div className="input-container">
              <label className="label-default" for="langue">
                Langue
              </label>
              <select className="custom-select" 
              style={{backgroundColor: theme==="0" ? "": "white", width: "250px"}}
              name="langue" id="langue">
                <option value="fr">Français</option>
                <option value="en">Anglais</option>
                {/* <option value="it">Italien</option>
                <option value="es">Espagnol</option> */}
              </select>
            </div>
            <button
              className="btn-full"
              style={{backgroundColor: "#05BAF3", color: "#fff", marginTop: "-17px"}}
              type="submit"
              disabled={loading}
              onClick={onSubmit}
            >
              {loading ? "loading..." : "Accepter l'invitation"}
            </button>
          </div>
        </div>
      </section>
    </main>
  );
}
