import {
  ACTION_SET_INDICATOR,
  ACTION_SET_INDICATOR_DATA,
  ACTION_IS_GET_INDICATOR,
  ACTION_IS_UPDATE_INDICATOR,
  ACTION_IS_DELETE_INDICATOR,
  ACTION_IS_ADD_INDICATOR,
  ACTION_IS_SEND_COMMENT,
  ACTION_IS_DELETE_COMMENT,
  ACTION_SET_COMMENTS,
  ACTION_ADD_COMMENT,
  ACTION_REMOVE_COMMENT,
  ACTION_SET_INDICATORS,
  ACTION_IS_GENERATING_INDICATOR,
} from "../actions/indicatorAction";

const initialStateUser = {
  indicator: null,
  indicators: [],
  commentaires: [],
  filters: [],
  axes: [],
  interval: [null, null],
  defaultAxes: null,
  defaultFilter: null,
  data: [],
  rows: [],
  columns: [],
  isDelete: false,
  isUpdate: false,
  isAdd: false,
  isGet: false,
  isDeleteComment: false,
  isSendComment: false,
  isGenerating: false,
};

const indicatorReducer = (state = initialStateUser, action) => {
  switch (action.type) {
    
    case ACTION_SET_INDICATORS: {
      return {
        ...state,
        indicators: action.payload,
      };
    }

    case ACTION_SET_INDICATOR: {
      return {
        ...state,
        indicator: action.payload,
      };
    }

    case ACTION_REMOVE_COMMENT: {
      const commentaires = [...state.commentaires];
      for (let i = 0; i < commentaires.length; i++) {
        const element = commentaires[i];
        if (
          parseInt(element.idcommentaire) === parseInt(action.payload)
        ) {
          commentaires.splice(i, 1);
          break;
        }
      }

      return {
        ...state,
        commentaires: [...commentaires],
      };
    }

    case ACTION_ADD_COMMENT: {
      const commentaires = [...state.commentaires];
      commentaires.unshift(action.payload);
      return {
        ...state,
        commentaires: [...commentaires],
      };
    }

    case ACTION_SET_COMMENTS: {
      return {
        ...state,
        commentaires: [...action.payload],
      };
    }

    case ACTION_IS_GET_INDICATOR: {
      return {
        ...state,
        isGet: action.payload,
      };
    }

    case ACTION_IS_ADD_INDICATOR: {
      return {
        ...state,
        isAdd: action.payload,
      };
    }

    case ACTION_IS_UPDATE_INDICATOR: {
      return {
        ...state,
        isUpdate: action.payload,
      };
    }

    case ACTION_IS_DELETE_COMMENT: {
      return {
        ...state,
        isDeleteComment: action.payload,
      };
    }

    case ACTION_IS_DELETE_INDICATOR: {
      return {
        ...state,
        isDelete: action.payload,
      };
    }

    case ACTION_IS_SEND_COMMENT: {
      return {
        ...state,
        isSendComment: action.payload,
      };
    }

    case ACTION_SET_INDICATOR_DATA: {
      return {
        ...state,
        ...action.payload
      };
    }

    case ACTION_IS_GENERATING_INDICATOR: {
      return {
        ...state,
        isGenerating: action.payload
      };
    }

    default:
      return state;
  }
};

export default indicatorReducer;
