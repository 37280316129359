import React, { useEffect, useState, useRef } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Modal from "../Views/Modal";
import { useSnackbar } from "notistack";
import {
  actionCreatePeriod,
  actionDeletePeriod,
  actionGetPeriodByUserH,
  actionUpdatePeriod,
} from "../../redux/actions/periodAction";
import CalendarView from "../Views/CalendarView";
import moment from "moment";
const _ = require("lodash");

export default function Periode() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const idUser = useSelector((state) => state.user.id);
  const token = useSelector((state) => state.user.token);
  const periodes = useSelector((state) => state.period.periodes);
  const tokenRef = useRef();
  tokenRef.current = token;
  const [role, setRole] = useState("ADD");
  const [name, setName] = useState("");
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const [value, setValue] = useState(null);
  const [visibility, setVisibility] = useState(false);
  const isDeleting = useSelector((state) => state.period.isDeletePeriod);
  const isUpdating = useSelector((state) => state.period.isUpdatePeriod);
  const isAdding = useSelector((state) => state.period.isAddPeriod);
  const [deleteVisibility, setDeleteVisibility] = useState(false);
  const [periode, setPeriode] = useState(null);
  const periodeRef = useRef();
  periodeRef.current = periode;
  const { enqueueSnackbar } = useSnackbar();

  const cancel = () => {
    setRole("ADD");
    setName("");
    setEnd(null);
    setStart(null);
    setPeriode(null);
    setVisibility(false);
    setDeleteVisibility(false);
  };

  const add = () => {
    dispatch(
      actionCreatePeriod(name, start, end, idUser, token, (status) => {
        if (status) {
          enqueueSnackbar("Insertion réalisé avec succès", {
            variant: "success",
          });
          cancel();
        } else {
          enqueueSnackbar("Erreur lors de la création", {
            variant: "error",
          });
        }
      })
    );
  };

  const edit = () => {
    dispatch(
      actionUpdatePeriod(
        periode.idperiod,
        name,
        start,
        end,
        idUser,
        token,
        (status) => {
          if (status) {
            enqueueSnackbar("Insertion réalisé avec succès", {
              variant: "success",
            });
            cancel();
          } else {
            enqueueSnackbar("Erreur lors de la création", {
              variant: "error",
            });
          }
        }
      )
    );
  };

  const submit = (e) => {
    e.preventDefault();
    if (name && start && end) {
      if (role === "ADD") {
        add();
      } else if (role === "UPDATE" && periode) {
        edit();
      }
    } else {
      enqueueSnackbar("Tous les champs sont obligatoires", {
        variant: "warning",
      });
    }
  };

  const remove = (e) => {
    e.preventDefault();
    if (periode) {
      dispatch(
        actionDeletePeriod(periode?.idperiod, token, (status) => {
          if (status) {
            enqueueSnackbar("Période retirer avec succès", {
              variant: "success",
            });
            cancel();
          } else {
            enqueueSnackbar("Erreur lors de la suppression", {
              variant: "error",
            });
          }
        })
      );
    }
  };

  useEffect(() => {
    const data = localStorage.getItem("data");
    if (!data) {
      navigate("/");
    } else {
      dispatch(
        actionGetPeriodByUserH(
          JSON.parse(data).userId,
          "Bearer " + JSON.parse(data).token,
          (status) => {}
        )
      );
    }
  }, [dispatch, navigate]);

  useEffect(() => {
    if (periode && visibility && role === "UPDATE") {
      setName(periode.periodName);
      setStart(moment(new Date(periode.startdate)));
      setEnd(moment(new Date(periode.enddate)));
    }
  }, [periode, visibility, role]);

  return (
    <>
      <Modal
        visible={deleteVisibility && periode}
        title={`Supprimer la période "${periode?.periodName}"`}
        onCancel={isDeleting ? null : cancel}
      >
        <div className="row">
          <div className="col-md-12 wrap-btn-modal">
            <div className="btn-container">
              <button
                className="btn-border close-btn-submit"
                type="reset"
                onClick={isDeleting ? null : cancel}
              >
                Annuler
              </button>
              <button
                className="btn-full"
                type="submit"
                disabled={isDeleting}
                onClick={isDeleting ? null : remove}
              >
                {isDeleting ? "Loading..." : "Supprimer"}
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        visible={visibility}
        title={role === "ADD" ? "Ajouter période" : "Modifier période"}
        onCancel={isAdding || isUpdating ? null : cancel}
      >
        <div className="row">
          <div className="col-md-12">
            <div className="input-container">
              <label for="nom" className="label-default">
                Libellé *
              </label>
              <span className="span-block">
                <input
                  className="input-default inputCustom"
                  name="nom"
                  id="nom"
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Obligatoire"
                  required
                />
              </span>
            </div>
          </div>

          <div className="col-md-12">
            <div className="input-container">
              <label for="nom" className="label-default">
                Période *
              </label>
              <span className="span-block">
                <CalendarView
                  selectStyle={{ width: "40%" }}
                  calendarStyle={{ width: "55%", marginLeft: "10px" }}
                  value={value}
                  interval={[start, end]}
                  onChange={(interval, value) => {
                    setStart(interval[0]);
                    setEnd(interval[1]);
                    setValue(value);
                  }}
                />
              </span>
            </div>
          </div>
          <div className="col-md-12 wrap-btn-modal">
            <div className="btn-container">
              <button
                className="btn-border close-btn-submit"
                type="reset"
                onClick={isAdding ? null : cancel}
              >
                Annuler
              </button>
              <button
                className="btn-full"
                type="submit"
                disabled={isAdding || isUpdating}
                onClick={isAdding || isUpdating ? null : submit}
              >
                {isAdding || isUpdating ? "Loading..." : "Enregistrer"}
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <Header />
      <main>
        <div className="container">
          <div className="head">
            <h1 className="title-1">Périodes</h1>
            <div className="wrap-select types">
              <button
                className="btn-full"
                type="submit"
                onClick={() => {
                  setVisibility(true);
                }}
              >
                Ajouter une période
              </button>
            </div>
          </div>
          <br />
          <br />
          <div className="bloc-fond-fonce2 wrap-table">
            <table id="sources" className="display responsive">
              <thead>
                <tr>
                  <th style={{ textAlign: "center" }}>
                    <span>Libellé</span>
                  </th>
                  <th style={{ textAlign: "center" }}>
                    <span>Date de début</span>
                  </th>
                  <th style={{ textAlign: "center" }}>
                    <span>Date de Fin</span>
                  </th>
                  <th style={{ textAlign: "center" }}>
                    <span>Actions</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {periodes.map((period) => {
                  return (
                    <tr id={period.idperiod}>
                      <td
                        className="table-style"
                        style={{ textAlign: "center" }}
                      >
                        {period.periodName}
                      </td>
                      <td
                        className="table-style"
                        style={{ textAlign: "center" }}
                      >
                        {period.startdate.split("T")[0]}
                      </td>
                      <td
                        className="table-style"
                        style={{ textAlign: "center" }}
                      >
                        {period.enddate.split("T")[0]}
                      </td>
                      <td
                        className="table-style"
                        style={{ textAlign: "center", width: 150 }}
                      >
                        <ul className="actions">
                          <li
                            onClick={() => {
                              setRole("UPDATE");
                              setPeriode(period);
                              setVisibility(true);
                            }}
                          >
                            <a href="#" className="btn-simple-round modifier">
                              <i className="fa-light fa-pen-to-square"></i>
                            </a>
                          </li>
                          <li
                            onClick={() => {
                              setDeleteVisibility(true);
                              setPeriode(period);
                            }}
                          >
                            <a href="#" className="btn-simple-round supprimer">
                              <i className="fa-light fa-trash-can"></i>
                            </a>
                          </li>
                        </ul>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}
