import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Iframe from "react-iframe";
import "react-multi-carousel/lib/styles.css";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Stack from "@mui/material/Stack";
const ModalAntd = require("antd").Modal;

export default function ModalRefreshSource({ visibility, onCancel, url }) {
  const { t } = useTranslation();

  return (
    <>
      <ModalAntd
        visible={visibility}
        bodyStyle={{ padding: 0, margin: 0 }}
        width={"75%"}
        closable={false}
        destroyOnClose={true}
        onCancel={onCancel}
        footer={null}
      >
        <div style={{ width: "100%", minHeight: "500px", color: "#000" }}>
          <div
            style={{
              width: "100%",
              height: "100px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Stack sx={{ width: "80%" }} spacing={2}>
              <Alert severity="warning">
                <AlertTitle>Attention</AlertTitle>
                La configuration doit être la même qu'avant — <strong>un changement pourrait corrompre les données</strong>
              </Alert>
            </Stack>
          </div>
          <Iframe
            id={"if2"}
            url={url}
            width="100%"
            height="500px"
            display="initial"
            position="relative"
          />
        </div>
      </ModalAntd>
    </>
  );
}
