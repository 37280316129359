import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ControlCamera } from "@material-ui/icons";
import { MinusOutlined } from "@ant-design/icons";
import { DragDropContext, Draggable, Droppable } from "react-dnd-beautiful";
import { useSnackbar } from "notistack";
const _ = require("lodash");

let theme = "1";
const data = localStorage.getItem("theme");

if (data) {
  theme = JSON.parse(data).theme;
}

const ObjectifDragNDrop = ({
  objectifs,
  selectedGoalState,
  setSelectedGoalState,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [goalState, setGoalState] = useState([]);

  useEffect(() => {
    setGoalState(
      _.differenceBy(objectifs, selectedGoalState, (item) => item.idgoal)
    );
  }, [objectifs, selectedGoalState]);

  const onDragEnd = (result) => {
    const { destination, source } = result;
    if (!destination) return;

    if (
      destination.droppableId === "selectedGoal" &&
      selectedGoalState.length === 4
    ) {
      enqueueSnackbar("Le nombre maximal d'objectif est 4.", {
        variant: "warning",
      });
      return;
    }

    if (
      destination.droppableId === "selectedGoal" &&
      selectedGoalState.length < 4
    ) {
      const items = Array.from(goalState);
      const [item] = items.splice(source.index, 1);
      setGoalState(items);
      setSelectedGoalState([...selectedGoalState, item]);
    }
  };

  const deleteSelectedIndicator = (goal) => {
    setSelectedGoalState([
      ...selectedGoalState.filter((item) => item.idgoal !== goal.idgoal),
    ]);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Container>
        <Column>
          <List>
            <Text>Objectifs disponibles</Text>
            <Small>
              Faire glisser pour ajouter un indicateur dans la liste
            </Small>
            <BlockList>
              <Filter className="title-4" style={{ marginBottom: 8 }}>
                Nom
              </Filter>
              <Droppable droppableId="availableGoal">
                {(droppableProvided, droppableSnapshot) => (
                  <BlockItems
                    ref={droppableProvided.innerRef}
                    {...droppableProvided.droppableProps}
                  >
                    {goalState.map((goal, index) => (
                      <Draggable
                        key={goal.idgoal}
                        draggableId={`${goal.idgoal}`}
                        index={index}
                      >
                        {(draggableProvided, draggableProps) => (
                          <Items
                            key={goal.idgoal}
                            ref={draggableProvided.innerRef}
                            {...draggableProvided.draggableProps}
                            {...draggableProvided.dragHandleProps}
                          >
                            <Text
                              className="title-4"
                              style={{ fontSize: ".8rem" }}
                            >
                              {goal.name}
                            </Text>
                            <ControlCamera
                              style={{
                                fontSize: 20,
                                color: "rgb(84, 183, 237)",
                              }}
                            />
                          </Items>
                        )}
                      </Draggable>
                    ))}
                    {droppableProvided.placeholder}
                  </BlockItems>
                )}
              </Droppable>
            </BlockList>
          </List>
        </Column>

        <Column>
          <Text>Objectif(s) sélectionné(s) ({selectedGoalState.length}/4)</Text>
          <Droppable droppableId="selectedGoal">
            {(droppableProvided, droppableSnapshot) => (
              <GridCard
                ref={droppableProvided.innerRef}
                {...droppableProvided.droppableProps}
              >
                {selectedGoalState.length === 0
                  ? Array.of(1, 2, 3, 4).map(() => <Card></Card>)
                  : selectedGoalState.length === 1
                  ? Array.of(...selectedGoalState, 1, 2, 3).map((goal) => {
                      if (typeof goal === "number") {
                        return <Card></Card>;
                      } else
                        return (
                          <CardContained key={goal.idgoal}>
                            {goal.name}
                            <DeleteButton
                              onClick={() => deleteSelectedIndicator(goal)}
                            >
                              <MinusOutlined
                                style={{
                                  color: "#fff",
                                  fontSize: 15,
                                  fontWeight: "bolder",
                                }}
                              />
                            </DeleteButton>
                          </CardContained>
                        );
                    })
                  : selectedGoalState.length === 2
                  ? Array.of(...selectedGoalState, 1, 2).map((goal) => {
                      if (typeof goal === "number") {
                        return <Card></Card>;
                      } else
                        return (
                          <CardContained key={goal.idgoal}>
                            {goal.name}
                            <DeleteButton
                              onClick={() => deleteSelectedIndicator(goal)}
                            >
                              <MinusOutlined
                                style={{
                                  color: "#fff",
                                  fontSize: 15,
                                  fontWeight: "bolder",
                                }}
                              />
                            </DeleteButton>
                          </CardContained>
                        );
                    })
                  : selectedGoalState.length === 3
                  ? Array.of(...selectedGoalState, 1).map((goal) => {
                      if (typeof goal === "number") {
                        return <Card></Card>;
                      } else
                        return (
                          <CardContained key={goal.idgoal}>
                            {goal.name}
                            <DeleteButton
                              onClick={() => deleteSelectedIndicator(goal)}
                            >
                              <MinusOutlined
                                style={{
                                  color: "#fff",
                                  fontSize: 15,
                                  fontWeight: "bolder",
                                }}
                              />
                            </DeleteButton>
                          </CardContained>
                        );
                    })
                  : selectedGoalState.length === 4 &&
                    Array.of(...selectedGoalState).map((goal) => {
                      if (typeof goal === "number") {
                        return <Card></Card>;
                      } else
                        return (
                          <CardContained key={goal.idgoal}>
                            {goal.name}
                            <DeleteButton
                              onClick={() => deleteSelectedIndicator(goal)}
                            >
                              <MinusOutlined
                                style={{
                                  color: "#fff",
                                  fontSize: 15,
                                  fontWeight: "bolder",
                                }}
                              />
                            </DeleteButton>
                          </CardContained>
                        );
                    })}
              </GridCard>
            )}
          </Droppable>
        </Column>
      </Container>
    </DragDropContext>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-top: 10px;
`;

const List = styled.div`
  display: "flex";
  flex-direction: column;
  column-gap: 5px;
`;

const Column = styled.div`
  display: block;
`;

const GridCard = styled.div`
  display: grid;
  margin-top: 10px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 80px 80px;
  gap: 5px;
`;

const Card = styled.div`
  border: 1px dashed #858585;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DeleteButton = styled.span`
  position: absolute;
  top: -2px;
  right: -2px;
  background-color: #ff725e;
  border-radius: 4px;
  border: none;
  padding: 0;
  height: 17px;
  width: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const CardContained = styled.div`
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.2);
  position: relative;
  padding: 10px;
  text-align: center;
  background: ${theme === "0" ? "#323541" : "#FFFFFF"};
`;

const Text = styled.p`
  font-size: 0.8rem;
  margin-bottom: 0;
`;

const Small = styled.p`
  font-size: 0.6rem;
  font-style: italic;
  margin-top: 5px;
  color: ${theme === "0" ? "#fff" : "#000"};
`;

const BlockList = styled.div`
  display: flex;
  flex-direction: column;
`;

const Filter = styled.h2`
  display: flex;
  justify-content: flex-start;
  font-size: 1rem;
  font-weight: bold;
  margin-top: 5px;
`;

const BlockItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 200px;
  overflow: auto;
  padding: 0 5px;

  &::-webkit-scrollbar {
    border-radius: 5px;
  }
  &::-webkit-scrollbar-button {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #05baf3;
    border-radius: 8px;
    border-color: #05bcf341;
    margin: 5px;
    border-width: 4px;
    width: 4px;
  }
  &::-webkit-scrollbar {
    /* background-color: #1ad586; */
    border-radius: 8px;
    margin: 10px;
    height: 15px;
    width: 5px;
  }
  &::-webkit-scrollbar-track-piece {
    background-color: #05bcf341;
    border-radius: 8px;
    margin: 10px;
  }
`;

const Items = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  padding: 5px 8px;
  background: ${theme === "0" ? "#323541" : "#E0E0E0"};
  box-shadow: 0.5px 1px 3px rgba(0, 0, 0, 0.2);
`;

export default ObjectifDragNDrop;
