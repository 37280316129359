import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { actionChangePassword } from "../../redux/actions/UserAction";

export default function Reset() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const loading = useSelector((state) => state.user.loading);
  const dispatch = useDispatch();

  const [password, setPassword] = useState("");
  const [confirm, setConfirm] = useState("");
  const [token, setToken] = useState(null);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    setToken(searchParams.get("noreply"));
    if (!searchParams.get("noreply")) {
      navigate("/");
    }
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    if (password === confirm) {
      dispatch(
        actionChangePassword(password, token, (status) => {
          if (status === 200) {
            navigate("/home");
          }
        })
      );
    } else {
      alert("password are not confirm");
      setConfirm("");
    }
  };

  return (
    <main className="intro-pages">
      <section id="login">
        <div className="container">
          <div className="logo-container"></div>

          <div className="text-center subtitles">
            <span>Modifier votre mot de passe</span>
          </div>

          <form action="" className="login-form bg-default" onSubmit={onSubmit}>
            <div className="row">
              <div className="col-md-6">
                <div className="input-container">
                  <label for="" className="label-default">
                    Mot de passe
                  </label>
                  <span className="span-block picto-password">
                    <input
                      id="password"
                      className="input-default inputCustom"
                      type="password"
                      placeholder="Obligatoire"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                      minLength={8}
                    />
                    <i
                      className="fa-regular fa-eye"
                      onClick={(e) => {
                        if (
                          document.getElementById("password").type ===
                          "password"
                        ) {
                          e.target.className = "fa fa-eye-slash";
                          document.getElementById("password").type = "text";
                        } else {
                          e.target.className = "fa-regular fa-eye";
                          document.getElementById("password").type = "password";
                        }
                      }}
                    ></i>
                  </span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="input-container">
                  <label for="" className="label-default">
                    Confirmer le mot de passe
                  </label>
                  <span className="span-block picto-password">
                    <input
                      id="password1"
                      className="input-default inputCustom"
                      type="password"
                      placeholder="Obligatoire"
                      value={confirm}
                      onChange={(e) => setConfirm(e.target.value)}
                      required
                      minLength={8}
                    />
                    <i
                      className="fa-regular fa-eye"
                      onClick={(e) => {
                        if (
                          document.getElementById("password1").type ===
                          "password"
                        ) {
                          e.target.className = "fa fa-eye-slash";
                          document.getElementById("password1").type = "text";
                        } else {
                          e.target.className = "fa-regular fa-eye";
                          document.getElementById("password1").type =
                            "password";
                        }
                      }}
                    ></i>
                  </span>
                </div>
              </div>
              <div className="col-md-12">
                <button className="btn-full" type="submit" disabled={loading}>
                  {loading ? "loading..." : "Envoyer email"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
    </main>
  );
}
