import {
  ACTION_SET_DATASOURCES,
  ACTION_REMOVE_DATASOURCE,
  ACTION_IS_GET_DATASOURCES,
  ACTION_IS_UPDATE_DATASOURCE,
  ACTION_IS_DELETE_DATASOURCE,
  ACTION_IS_ADD_DATASOURCE,
  ACTION_SET_DATA_DATASOURCE,
  ACTION_SET_LAST_DATE_DATASOURCE,
  ACTION_SET_ONE_DATASOURCE,
} from "../actions/datasourceAction";

const initialStateUser = {
  datasources: [],
  id: null,
  visibilityProgress: false,
  pourcent: 0,
  isDelete: false,
  isUpdate: false,
  isGet: false,
  isAdd: false,
};

const datasourceReducer = (state = initialStateUser, action) => {
  switch (action.type) {
    case ACTION_REMOVE_DATASOURCE: {
      const datasources = [...state.datasources];
      for (let i = 0; i < datasources.length; i++) {
        const element = datasources[i];
        if (parseInt(element.iddatasource) === parseInt(action.payload)) {
          datasources.splice(i, 1);
          break;
        }
      }

      return {
        ...state,
        datasources: [...datasources],
      };
    }

    case ACTION_SET_DATASOURCES: {
      if (action.payload.type === "UPDATE") {
        const datasources = [...state.datasources];
        for (let i = 0; i < datasources.length; i++) {
          const element = datasources[i];
          if (
            parseInt(element.iddatasource) ===
            parseInt(action.payload.data.iddatasource)
          ) {
            datasources[i] = {
              ...action.payload.data,
              connector: datasources[i].connector,
              columns: datasources[i].columns,
            };
            break;
          }
        }
        return {
          ...state,
          datasources: [...datasources],
        };
      } else if (action.payload.type === "ADD") {
        const datasources = [...state.datasources];
        datasources.push(action.payload.data);
        return {
          ...state,
          datasources: [...datasources],
        };
      } else if (action.payload.type === "ALL") {
        return {
          ...state,
          datasources: [...action.payload.data],
        };
      }
    }

    case ACTION_SET_ONE_DATASOURCE: {
      const datasources = [...state.datasources];
      for (let i = 0; i < datasources.length; i++) {
        const element = datasources[i];
        if (
          parseInt(element.iddatasource) ===
          parseInt(action.payload.iddatasource)
        ) {
          datasources[i] = { ...action.payload };
          break;
        }
      }
      return {
        ...state,
        datasources: [...datasources],
      };
    }

    case ACTION_SET_LAST_DATE_DATASOURCE: {
      const datasources = [...state.datasources];
      for (let i = 0; i < datasources.length; i++) {
        const element = datasources[i];
        if (parseInt(element.iddatasource) === parseInt(action.payload)) {
          datasources[i] = { ...element, lastdate: new Date().toISOString() };
          break;
        }
      }
      return {
        ...state,
        datasources: [...datasources],
      };
    }

    case ACTION_IS_GET_DATASOURCES: {
      return {
        ...state,
        isGet: action.payload,
      };
    }

    case ACTION_IS_ADD_DATASOURCE: {
      return {
        ...state,
        isAdd: action.payload,
      };
    }

    case ACTION_IS_UPDATE_DATASOURCE: {
      return {
        ...state,
        isUpdate: action.payload,
      };
    }

    case ACTION_IS_DELETE_DATASOURCE: {
      return {
        ...state,
        isDelete: action.payload,
      };
    }

    case ACTION_SET_DATA_DATASOURCE: {
      return {
        ...state,
        ...action.payload,
      };
    }

    default:
      return state;
  }
};

export default datasourceReducer;
