import React from "react";
import FilterItem from "./FilterItem";
import { message, Input, Select } from "antd";
import CalendarView from "./CalendarView";
import { useDispatch } from "react-redux";
import { actionSetIndicatorData } from "../../redux/actions/indicatorAction";
const _ = require("lodash");

export default function FilterView({
  filtersOptions,
  setFilterOptions,
  removable,
}) {
  const dispatch = useDispatch();

  const onChangeFilter = (value, element, index, tag) => {
    const items = [...filtersOptions];
    if (tag === "min" || tag === "max") {
      if (/^[0-9]*$/.test(value.target.value))
        items[index][tag] = value.target.value;
    } else if (tag == "value") {
      items[index]["value"] = value.value;
      items[index]["interval"] = value.interval;
    } else {
      items[index][tag] = value;
    }
    if (setFilterOptions) {
      setFilterOptions([...items]);
    } else {
      dispatch(
        actionSetIndicatorData({
          filters: [...items],
        })
      );
    }
    // setFilterOptions();
  };

  const onDelete = (index) => {
    if (setFilterOptions) {
      let items = [...filtersOptions];
      items[index]["checked"] = false;
      setFilterOptions([...items]);
      return;
    }

    let data = _.filter([...filtersOptions], "checked");

    if (data.length > 1 || removable) {
      let items = [...filtersOptions];
      items[index]["checked"] = false;
      // setFilterOptions();
      dispatch(
        actionSetIndicatorData({
          filters: [...items],
        })
      );
    } else {
      message.warning("Il faut garder au moins un filtre");
    }
  };

  return (
    <>
      {filtersOptions.map((e, index) => {
        if (e.checked) {
          return (
            <div
              key={index}
              style={{
                width: "max-content",
                height: 50,
                display: "flex",
                alignItems: "center",
                columnGap: 10,
              }}
            >
              {e.type === 1 || e.type === 4 ? (
                // User Popup
                <div
                  style={{
                    width: 190,
                    display: "flex",
                    justifyContent: "center",
                    padding: 5,
                  }}
                >
                  <FilterItem
                    content={
                      <Input.Group compact>
                        <div style={{ display: "flex" }}>
                          <Input
                            value={e["min"]}
                            // maxLength="90px"
                            onChange={(min) =>
                              onChangeFilter(min, e, index, "min")
                            }
                            style={{ textAlign: "center" }}
                            placeholder="Minimum"
                            // className="ant-input-affix-wrapper ant-input"
                          />

                          <Input
                            className="site-input-split"
                            style={{
                              width: 30,
                              borderLeft: 0,
                              borderRight: 0,
                              pointerEvents: "none",
                            }}
                            placeholder="~"
                            disabled
                          />
                          <Input
                            value={e["max"]}
                            onChange={(max) =>
                              onChangeFilter(max, e, index, "max")
                            }
                            className="site-input-right"
                            style={{
                              textAlign: "center",
                            }}
                            placeholder="Maximum"
                          />
                        </div>
                      </Input.Group>
                    }
                    showIcon={e["min"] || e["max"]}
                    name={e.name}
                    onDelete={() => onDelete(index)}
                  />
                </div>
              ) : // End User Popup
              e.type === 2 ? (
                <div style={{ width: 190, padding: 5 }}>
                  <FilterItem
                    content={
                      <Select
                        loading={e.loading}
                        style={{ width: 200, marginRight: "5px" }}
                        mode="multiple"
                        className="color-black"
                        value={e["values"]}
                        // placeholder={e.name}
                        onChange={(values) =>
                          onChangeFilter(values, e, index, "values")
                        }
                      >
                        {e.options
                          ? e.options.map((item) => (
                              <Select.Option value={item}>{item}</Select.Option>
                            ))
                          : null}
                      </Select>
                    }
                    showIcon={e["values"]["length"]}
                    name={e.name}
                    onDelete={() => onDelete(index)}
                  />
                </div>
              ) : e.type === 3 ? (
                <div style={{ width: 190, padding: 5 }}>
                  <FilterItem
                    content={
                      <CalendarView
                        value={e["value"]}
                        interval={[e["interval"][0], e["interval"][1]]}
                        onChange={(interval, value) =>
                          onChangeFilter({ interval, value }, e, index, "value")
                        }
                      />
                    }
                    showIcon={e["interval"][0] || e["interval"][1]}
                    name={e.name}
                    onDelete={() => onDelete(index)}
                  />
                </div>
              ) : null}
            </div>
          );
        }
      })}
    </>
  );
}
