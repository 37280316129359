import {
  ACTION_SET_LISTS,
  ACTION_IS_ADD_LIST,
  ACTION_IS_DELETE_LIST,
  ACTION_IS_UPDATE_LIST,
  ACTION_IS_GET_LIST,
  ACTION_IS_LOADING_LIST,
  ACTION_SET_INDICATORS_LOCATIONS,
} from "../actions/listAction";

const initialStateUser = {
  lists: [],
  isAdd: false,
  isGet: false,
  isDelete: false,
  isUpdate: false,
  loading: false,
};

const listReducer = (state = initialStateUser, action) => {
  switch (action.type) {
    case ACTION_SET_LISTS: {
      if (action.payload.type === "ADD") {
        const lists = [...state.lists];
        lists.push(action.payload.data);
        return {
          ...state,
          lists: [...lists],
        };
      } else if (action.payload.type === "REMOVE") {
        const lists = [...state.lists];
        for (let i = 0; i < lists.length; i++) {
          const element = lists[i];
          if (parseInt(element.idlist) === parseInt(action.payload.data)) {
            lists.splice(i, 1);
            break;
          }
        }
        return {
          ...state,
          lists: [...lists],
        };
      } else if (action.payload.type === "UPDATE") {
        const lists = [...state.lists];
        for (let i = 0; i < lists.length; i++) {
          const element = lists[i];
          if (parseInt(element.idlist) === parseInt(action.payload.id)) {
            lists[i].name = action.payload.data.name;
            lists[i].readonly = action.payload.data.readonly;
            break;
          }
        }
        return {
          ...state,
          lists: [...lists],
        };
      } else if (
        action.payload.type === "GET" ||
        action.payload.type === "ALL"
      ) {
        return {
          ...state,
          lists: [...action.payload.data],
        };
      }
    }

    case ACTION_IS_GET_LIST: {
      return {
        ...state,
        isGet: action.payload,
      };
    }

    case ACTION_IS_ADD_LIST: {
      return {
        ...state,
        isAdd: action.payload,
      };
    }

    case ACTION_IS_UPDATE_LIST: {
      return {
        ...state,
        isUpdate: action.payload,
      };
    }

    case ACTION_IS_DELETE_LIST: {
      return {
        ...state,
        isDelete: action.payload,
      };
    }

    case ACTION_IS_LOADING_LIST: {
      return {
        ...state,
        loading: action.payload,
      };
    }

    case ACTION_SET_INDICATORS_LOCATIONS: {
      const lists = [...state.lists];
      const { data, idlist, type } = action.payload;
      for (let i = 0; i < lists.length; i++) {
        const list = lists[i];
        if (+list.idlist === +idlist) {
          for (let j = 0; j < data.length; j++) {
            const item = data[j];
            for (let k = 0; k < list.indicators.length; k++) {
              const indicator = list.indicators[k];
              if (+indicator.idindicator === +item.i) {
                for (let l = 0; l < indicator.listIndicators.length; l++) {
                  const listIndicator = indicator.listIndicators[l];
                  if (+listIndicator.idlist === +idlist) {
                    lists[i].indicators[k].listIndicators[l][`x_${type}`] =
                      data[j].x;
                    lists[i].indicators[k].listIndicators[l][`y_${type}`] =
                      data[j].y;
                    lists[i].indicators[k].listIndicators[l][`w_${type}`] =
                      data[j].w;
                    lists[i].indicators[k].listIndicators[l][`h_${type}`] =
                      data[j].h;
                    break;
                  }
                }
                break;
              }
            }
          }
          break;
        }
      }

      return {
        ...state,
        lists: [...lists],
      };
    }

    default:
      return state;
  }
};

export default listReducer;
