import host from "./host";
export const ACTION_SET_CONNECTORS = "ACTION_SET_CONNECTORS";
export const ACTION_IS_GET_CONNECTORS = "ACTION_IS_GET_CONNECTORS";

// * --------- Begin actions --------

export const actionIsGetConnectors = (payload) => {
  return { type: ACTION_IS_GET_CONNECTORS, payload };
};

export const actionSetConnectors = (payload) => {
  return { type: ACTION_SET_CONNECTORS, payload };
};

// * --------- End actions --------

// * --------- Begin --------

export const actionGetConnectors = (token) => {
  return (dispatch) => {
    dispatch(actionIsGetConnectors(true));

    let myHeaders = new Headers();
    myHeaders.append("Authorization", token);

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${host}/connector`, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) {
          return null;
        }
        return response.json();
      })
      .then((result) => {
        if (result) {
          dispatch(actionSetConnectors(result));
        }
      })
      .catch(() => {})
      .then(() => dispatch(actionIsGetConnectors(false)));
  };
};

// * --------- End --------
