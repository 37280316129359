import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const EvolutionPrimesUser = ({ bonusTransactions, interval }) => {
  const [data, setData] = useState([]);
  const startDate = new Date(interval?.[0]);
  const endDate = new Date(interval?.[1]);
  useEffect(() => {
    // Initialiser les données avec des valeurs nulles pour chaque mois de l'année
    const currentDate = new Date();

    const initialData = [];
    for (let i = 0; i < 12; i++) {
      const month = new Date(currentDate.getFullYear(), i);
      initialData.push({
        month: abbreviateMonth(
          month.toLocaleString("default", { month: "long" })
        ),
        fullMonth: month.toLocaleString("default", { month: "long" }),
        individuelles: 0,
        equipe: 0,
      });
    }
    setData(initialData);

    if (bonusTransactions && bonusTransactions.length > 0) {
      const [startDate, endDate] = interval;

      // Filtrer les transactions pour celles qui sont dans l'intervalle spécifié
      const filteredTransactions = bonusTransactions.filter((transaction) => {
        const transactionDate = new Date(transaction.dateBonusTransaction);
        return transactionDate >= startDate && transactionDate <= endDate;
      });

      // Mettre à jour les données uniquement pour les mois où il y a des transactions
      const updatedData = [...initialData];
      filteredTransactions.forEach((transaction) => {
        const transactionDate = new Date(transaction.dateBonusTransaction);
        const monthIndex = transactionDate.getMonth();

        if (!transaction.iduser) {
          updatedData[monthIndex].equipe += parseFloat(transaction.commission);
        }
        if (transaction.iduser) {
          updatedData[monthIndex].individuelles += parseFloat(
            transaction.commission
          );
        }
      });

      setData(updatedData);
    }
  }, [bonusTransactions, interval]);

  const abbreviateMonth = (month) => {
    const options = { month: "long", locale: "fr" };
    switch (month.toLocaleString("default", options)) {
      case "janvier":
        return "jan";
      case "février":
        return "fév";
      case "mars":
        return "mar";
      case "avril":
        return "avr";
      case "mai":
        return "mai";
      case "juin":
        return "jui";
      case "juillet":
        return "jui";
      case "août":
        return "aou";
      case "septembre":
        return "sep";
      case "octobre":
        return "oct";
      case "novembre":
        return "nov";
      case "décembre":
        return "dec";
      default:
        return month;
    }
  };

  return (
    <div className="rounded-lg box-border w-full h-[280px] flex flex-col items-start justify-between py-2 px-6 border-[1px] border-solid border-[#B2EAFB]">
      <div className="self-stretch flex flex-row items-start justify-between w-full">
        <div className="opacity-50 text-sm font-['Poppins']">
          Evolution des primes -
          {interval?.[0] && interval?.[1] && (
            <span className="ml-1 text-gray-600 text-sm">
              ({endDate.getFullYear()})
            </span>
          )}
        </div>
      </div>
      <div className="relative text-center text-xs w-full">
        <ResponsiveContainer width={640} height={200}>
          <BarChart width={300} height={200} data={data}>
            <XAxis dataKey="month" />
            <Tooltip
              labelFormatter={(value) =>
                data.find((entry) => entry.month === value)?.fullMonth || value
              }
            />
            <Legend />
            <Bar
              dataKey="individuelles"
              fill="#b19fffa2"
              barSize={15}
              radius={[4, 4, 4, 4]}
            />
            <Bar
              dataKey="equipe"
              fill="#B09FFF"
              barSize={15}
              radius={[4, 4, 4, 4]}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default EvolutionPrimesUser;
