import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip, Pagination } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { actionGetBonusesByUser } from "../../../redux/actions/bonusAction";
import Progress from "../Progress";
import { numberFormat } from "../IndicatorView";

const GoalsQualities = ({ goalsQualities }) => {
  const idUser = useSelector((state) => state.user.id);
  const token = useSelector((state) => state.user.token);
  const bonuses = useSelector((state) => state.bonus.bonuses);
  const isGetBonus = useSelector((state) => state.bonus.isGetBonus);
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 3;

  useEffect(() => {
    dispatch(actionGetBonusesByUser(idUser, token));
  }, [dispatch, idUser, token]);

  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  // if (isGetBonus) {
  //   return (
  //     <div className="flex justify-center items-center h-screen">
  //       <LoadingOutlined
  //         className="flex justify-center items-center content-center font-semibold text-lg"
  //         spin
  //       />
  //     </div>
  //   );
  // }

  // Maintain a set of unique goal IDs
  const uniqueGoalIds = new Set();

  // Filter and map bonuses
  const paginatedData = goalsQualities.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  return (
    <>
      {paginatedData.length === 0 ? (
        <div className=" w-full overflow-hidden p-4 relative flex flex-col justify-center items-center content-center">
          <img
            className="no-bonus-gif w-48 animate-pulse"
            src="/no-goal.svg"
            alt="no-goal"
          />
          <em>Aucun objectif qualitatif trouvé</em>
        </div>
      ) : (
        <>
          <div className="text-[#05BAF3] text-lg font-bold font-['Poppins'] mb-4">
            Objectifs qualitatifs
          </div>
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className=" text-left text-xs font-medium text-gray-500 uppercase "
                >
                  Indicateur
                </th>
                <th
                  scope="col"
                  className=" text-left text-xs font-medium text-gray-500 uppercase "
                >
                  Valeur actuelle
                </th>
                <th
                  scope="col"
                  className=" text-left text-xs font-medium text-gray-500 uppercase"
                >
                  Condition
                </th>
                <th
                  scope="col"
                  className=" text-left text-xs font-medium text-gray-500 uppercase "
                >
                  État de l'objectif
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {paginatedData.map(({ bonus, goal }) => {
                const percentage = parseInt(
                  (goal.currentvalue * 100) / goal.targetvalue
                );

                return (
                  <tr key={goal.idgoal} className="bg-white">
                    <td className="pl-0  whitespace-nowrap">
                      <Tooltip title={goal.name} placement="topLeft">
                        <div className="text-sm font-medium text-gray-900 truncate w-24">
                          {goal.name}
                        </div>
                      </Tooltip>
                    </td>
                    <td className="pl-0  whitespace-nowrap">
                      <Tooltip
                        title={`${numberFormat(goal.currentvalue)} € `}
                        placement="topLeft"
                      >
                        <div className="text-sm text-gray-900">
                          {numberFormat(goal.currentvalue)} €
                        </div>
                      </Tooltip>
                    </td>
                    <td className="pl-0  whitespace-nowrap">
                      <Tooltip
                        title={`${goal.operator} ${numberFormat(
                          goal.targetvalue
                        )} € `}
                        placement="topLeft"
                      >
                        <div className="text-sm text-gray-900">
                          {goal.operator} {numberFormat(goal.targetvalue)} €
                        </div>
                      </Tooltip>
                    </td>
                    <td className="pl-0  whitespace-nowrap">
                      {goal.status === "ATTEINT" ? (
                        <div className="flex justify-center items-center bg-[#F5FFF7] w-24 h-7 rounded-md text-xs font-bold text-[#00B327]">
                          Atteint
                        </div>
                      ) : (
                        <div className="flex justify-center items-center bg-[#FBEFED] w-24 h-7 rounded-md text-xs font-bold text-[#CD4C2F]">
                          Non atteint
                        </div>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="absolute bottom-4 right-4 flex justify-end">
            <Pagination
              current={currentPage}
              total={paginatedData.length}
              pageSize={pageSize}
              onChange={handleChangePage}
              showSizeChanger={false}
              size="small"
            />
          </div>
        </>
      )}
    </>
  );
};

export default GoalsQualities;
