import host from "./host";
export const ACTION_SET_TEAM = "ACTION_SET_TEAM";
export const ACTION_SET_TEAM_USERS = "ACTION_SET_TEAM_USERS";
export const ACTION_SET_TEAMS = "ACTION_SET_TEAMS";
export const ACTION_ADD_TEAM = "ACTION_ADD_TEAM";
export const ACTION_REMOVE_TEAM = "ACTION_REMOVE_TEAM";
export const ACTION_IS_GET_TEAM = "ACTION_IS_GET_TEAM";
export const ACTION_IS_UPDATE_TEAM = "ACTION_IS_UPDATE_TEAM";
export const ACTION_IS_DELETE_TEAM = "ACTION_IS_DELETE_TEAM";
export const ACTION_IS_ADD_TEAM = "ACTION_IS_ADD_TEAM";
export const ACTION_IS_SET_TEAM_USERS = "ACTION_IS_SET_TEAM_USERS";
export const ACTION_ADD_SUB_TEAM = "ACTION_ADD_SUB_TEAM";
export const ACTION_REMOVE_SUB_TEAM = "ACTION_REMOVE_SUB_TEAM";
export const ACTION_IS_ADD_SUB_TEAM = "ACTION_IS_ADD_SUB_TEAM";
export const ACTION_IS_REMOVE_SUB_TEAM = "ACTION_IS_REMOVE_SUB_TEAM";

// * --------- Begin actions --------

export const actionIsGetTeam = (payload) => {
  return { type: ACTION_IS_GET_TEAM, payload };
};

export const actionIsUpdateTeam = (payload) => {
  return { type: ACTION_IS_UPDATE_TEAM, payload };
};

export const actionIsDeleteTeam = (payload) => {
  return { type: ACTION_IS_DELETE_TEAM, payload };
};

export const actionIsAddTeam = (payload) => {
  return { type: ACTION_IS_ADD_TEAM, payload };
};

export const actionSetTeam = (payload) => {
  return { type: ACTION_SET_TEAM, payload };
};

export const actionSetTeams = (payload) => {
  return { type: ACTION_SET_TEAMS, payload };
};

export const actionRemoveTeam = (payload) => {
  return { type: ACTION_REMOVE_TEAM, payload };
};

export const actionAddTeam = (payload) => {
  return { type: ACTION_ADD_TEAM, payload };
};

export const actionSetTeamUsers = (payload) => {
  return { type: ACTION_SET_TEAM_USERS, payload };
};

export const actionIsSetTeamUsers = (payload) => {
  return { type: ACTION_IS_SET_TEAM_USERS, payload };
};
export const actionAddsubTeam = (payload) => {
  return { type: ACTION_ADD_SUB_TEAM, payload };
};
export const actionDeleteSubTeam = (payload) => {
  return { type: ACTION_REMOVE_SUB_TEAM, payload };
};
export const actionIsAddSubTeam = (payload) => {
  return { type: ACTION_IS_ADD_SUB_TEAM, payload };
};
export const actionIsDeleteSubTeam = (payload) => {
  return { type: ACTION_IS_REMOVE_SUB_TEAM, payload };
};

// * --------- End actions --------

// * --------- Begin --------

export const actionGetTeamsByUser = (id, token) => {
  return (dispatch) => {
    dispatch(actionIsGetTeam(true));

    let myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${host}/team/get-by-user/${id}`, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) {
          if (response.status === 401) {
            localStorage.removeItem("data");
            window.location.replace("/");
          }
          return null;
        }
        return response.json();
      })
      .then((result) => {
        if (result) {
          dispatch(actionSetTeams(result));
        }
      })
      .catch(() => { })
      .then(() => dispatch(actionIsGetTeam(false)));
  };
};

export const actionCreateTeam = (
  name,
  idparent,
  description,
  id,
  token,
  callBack
) => {
  return (dispatch) => {
    dispatch(actionIsAddTeam(true));
    let myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    let raw = JSON.stringify({
      name,
      idparent,
      description,
      iduser: id,
    });

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${host}/team/create`, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) {
          callBack(false);
          return null;
        }
        callBack(true);
        return response.json();
      })
      .then((result) => {
        if (result) {
          dispatch(actionAddTeam({ ...result, role: "OWNER" }));
        }
      })
      .catch((err) => {
        callBack(false);
      })
      .then(() => dispatch(actionIsAddTeam(false)));
  };
};

export const actionUpdateTeam = (
  name,
  idparent,
  description,
  id,
  token,
  callback
) => {
  // return;
  return (dispatch) => {
    dispatch(actionIsUpdateTeam(true));

    let myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    let raw = JSON.stringify({
      name,
      idparent,
      description,
    });

    let requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${host}/team/update/${id}`, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) {
          callback(false);
          return null;
        }
        callback(true);
        return response.json();
      })
      .then((result) => {
        if (result) {
          dispatch(actionSetTeam({ id, idparent, name, description }));
        }
      })
      .catch(() => { })
      .then(() => {
        dispatch(actionIsUpdateTeam(false));
      });
  };
};

export const actionDeleteTeam = (id, token, callback) => {
  return (dispatch) => {
    dispatch(actionIsDeleteTeam(true));
    let myHeaders = new Headers();
    myHeaders.append("Authorization", token);

    let requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${host}/team/delete/${id}`, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) {
          callback(false);
          return null;
        } else {
          callback(true);
          dispatch(actionRemoveTeam(id));
        }
      })
      .catch(() => {
        callback(false);
      })
      .then(() => dispatch(actionIsDeleteTeam(false)));
  };
};

export const actionInvitedUser = (email, idteam, role, token, callBack) => {
  return (dispatch) => {
    dispatch(actionIsSetTeamUsers(true));
    let myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    let raw = JSON.stringify({
      email,
      idteam,
      role,
    });

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${host}/team/invited`, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) {
          callBack(false);
          return null;
        }
        callBack(true);
        return response.json();
      })
      .then((result) => {
        if (result) {
          if (result.new) {
            dispatch(actionSetTeamUsers({ data: result.new, type: "ADD" }));
          }
        }
      })
      .catch(() => {
        callBack(false);
      })
      .then(() => dispatch(actionIsSetTeamUsers(false)));
  };
};

export const actionRemoveUser = (iduser, idteam, token, callBack) => {
  return (dispatch) => {
    dispatch(actionIsSetTeamUsers(true));
    let myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    let raw = JSON.stringify({
      iduser,
      idteam,
    });

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${host}/team/remove-user`, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) {
          callBack(false);
          return null;
        }
        callBack(true);
        dispatch(actionSetTeamUsers({ data: iduser, type: "DELETE" }));
      })
      .catch((e) => {
        console.log("error====>", e);
        callBack(false);
      })
      .then(() => dispatch(actionIsSetTeamUsers(false)));
  };
};

export const actionRemoveSubTeam = (idteam, idsubteam, token, callBack) => {
  return (dispatch) => {
    dispatch(actionIsDeleteSubTeam(true));
    let myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    let raw = JSON.stringify({
      idteam,
      idsubteam,
    });

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${host}/team/remove-subteam`, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) {
          callBack(false);
          return null;
        }
        callBack(true);
        dispatch(actionDeleteSubTeam({ idteam, idsubteam }));
      })
      .catch((e) => {
        console.log("error====>", e);
        callBack(false);
      })
      .then(() => dispatch(actionIsDeleteSubTeam(false)));
  };
};
export const actionAddSubTeam = (idteam, idsubteam, token, callBack) => {
  return (dispatch) => {
    dispatch(actionIsAddSubTeam(true));
    let myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    let raw = JSON.stringify({
      idteam,
      idsubteam,
    });

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${host}/team/add-subteam`, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) {
          callBack(false);
          return null;
        }
        callBack(true);
        dispatch(actionAddsubTeam({ idteam, idsubteam }));
      })
      .catch((e) => {
        console.log("error====>", e);
        callBack(false);
      })
      .then(() => dispatch(actionIsAddSubTeam(false)));
  };
};

// * --------- End --------
