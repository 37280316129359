import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import teamReducer from "./reducers/teamReducer";
import userReducer from "./reducers/UserReducer";
import usersReducer from "./reducers/UsersReducer";
import dashboardReducer from "./reducers/dashboardReducer";
import listReducer from "./reducers/listReducer";
import indicatorReducer from "./reducers/indicatorReducer";
import connectorReducer from "./reducers/connectorReducer";
import datasourceReducer from "./reducers/datasourceReducer";
import GoalReducer from "./reducers/GoalReducer";
import timeReducer from "./reducers/timeReducer";
import rapportReducer from "./reducers/rapportReducer";
import operatorReducer from "./reducers/operatorReducer"
import bonusReducer from "./reducers/bonusReducer";
import ruleReducer from "./reducers/ruleReducer";
import periodReducer from "./reducers/periodReducer";
import bonusTransactionReducer from "./reducers/bonusTransactionReducer"

const root = combineReducers({
  team: teamReducer,
  user: userReducer,
  users: usersReducer,
  board: dashboardReducer,
  list: listReducer,
  indicator: indicatorReducer,
  connector: connectorReducer,
  datasource: datasourceReducer,
  goal: GoalReducer,
  time: timeReducer,
  rapport: rapportReducer,
  operator: operatorReducer,
  bonus: bonusReducer,
  rule: ruleReducer,
  period: periodReducer,
  bonusTransaction: bonusTransactionReducer
});
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(root, composeEnhancers(applyMiddleware(thunk)));
export default store;
