import { ACTION_IS_ADD_RULE, ACTION_IS_DELETE_RULE, ACTION_SET_RULES, ACTION_REMOVE_RULE_FROM_BONUS, ACTION_IS_UPDATE_RULE } from "../actions/ruleAction";

const initialState = {
    rules: [],
    isAddRule: false,
    isDeleteRule: false,
    isUpdateRule: false,
    isGetRule: false,
};

const ruleReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_SET_RULES: {
            if (action.payload.type === "ADD") {
                const rules = [...state.rules];
                rules.unshift(action.payload.data);
                return {
                    ...state,
                    rules: [...rules],
                };
            } else if (action.payload.type === "UPDATE") {
                const rules = [...state.rules];
                for (let i = 0; i < rules.length; i++) {
                    const element = rules[i];
                    if (parseInt(element.idrule) === parseInt(action.payload.id)) {
                        rules[i] = action.payload.data;
                        break;
                    }
                }
                return {
                    ...state,
                    rules: [...rules],
                };
            } else if (action.payload.type === "DELETE") {
                const rules = [...state.rules];
                for (let i = 0; i < rules.length; i++) {
                    const element = rules[i];
                    if (parseInt(element.idrule) === parseInt(action.payload.data)) {
                        rules.splice(i, 1);
                        break;
                    }
                }
                return {
                    ...state,
                    rules: [...rules],
                };
            }
            else if (action.payload.type === "ALL") {
                return {
                    ...state,
                    rules: [...action.payload.data],
                };

            }
            break;
        }
        case ACTION_IS_ADD_RULE: {
            return {
                ...state,
                isAddRule: action.payload,
            };
        }
        case ACTION_IS_UPDATE_RULE: {
            return {
                ...state,
                isUpdateRule: action.payload,
            };
        }
        case ACTION_IS_DELETE_RULE: {
            return {
                ...state,
                isDeleteRule: action.payload,
            };
        }

        case ACTION_REMOVE_RULE_FROM_BONUS: {
            const bonuses = [...state.bonuses];
            const rules = [...state.rules];

            const { idrule, idbonus } = action.payload;
            for (let i = 0; i < bonuses.length; i++) {
                const bonus = bonuses[i]
                if (+bonus.idbonus === +idbonus) {
                    const rules = bonus.rules.filter(rule => +rule.idrule !== +idrule)
                }
            }
            for (let j = 0; j < rules.length; j++) {
                const element = rules[j];
                if (parseInt(element.idrule) === parseInt(action.payload.data)) {
                    rules.splice(j, 1);
                    break;
                }

            }
            return {
                ...state,
                rules
            }
        }
        default:
            return state;
    }
};

export default ruleReducer;
