import React, { useState } from "react";
import { Pagination } from "antd";
import EvolutionPrimesUserMiniature from "./EvolutionPrimesUserMiniature";
import { numberFormat } from "../IndicatorView";

const Equipe = ({ interval, filteredTransactions }) => {
  const startDate = new Date(interval?.[0]);
  const endDate = new Date(interval?.[1]);

  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 4;

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // Filtrer les transactions uniques par utilisateur et calculer la somme des commissions
  const usersData = {};
  console.log("filteredTransactions", filteredTransactions);

  filteredTransactions.forEach((transaction) => {
    const user = transaction.user;
    const commission = parseFloat(transaction.commission);
    if (user) {
      if (!usersData[user.id]) {
        usersData[user.id] = {
          id: user.id,
          firstName: user.firstName,
          lastName: user.lastName,
          totalCommission: commission,
        };
      } else {
        usersData[user.id].totalCommission += commission;
      }
    }
  });
  console.log("usersData", usersData);
  // Convertir l'objet d'utilisateurs en tableau
  const usersList = Object.values(usersData);
  // Pagination des utilisateurs
  const indexOfLastItem = currentPage * pageSize;
  const indexOfFirstItem = indexOfLastItem - pageSize;
  const currentUsers = usersList.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <div className="mt-7 inset-0 rounded-lg bg-white h-[99.57%] w-full shadow-md">
      <div className="rounded-lg bg-white w-full overflow-hidden flex flex-col items-start justify-start py-4 px-4 box-border relative gap-4">
        <div className="flex flex-row items-center justify-center">
          <div className="text-[#05BAF3] text-xl font-bold font-['Poppins']">
            Commissions de mon équipe
          </div>
          {interval?.[0] && interval?.[1] && (
            <span className="ml-1 text-gray-600 text-lg">
              ({endDate.getFullYear()})
            </span>
          )}
        </div>

        <div className="flex items-center justify-start gap-4 w-full h-full overflow-hidden">
          {currentUsers.length > 0 ? (
            currentUsers.map((user) => (
              <div
                key={user.id}
                className="relative w-[330px] h-[350px] border-[#B2EAFB] border-[1px]"
              >
                <div className=" w-full flex flex-col items-center justify-start px-4 py-4 box-border gap-[12px]">
                  <div className="flex flex-row items-start justify-between w-full">
                    <div className="w-full flex items-center justify-start gap-[5px]">
                      <div className="profil-picture relative overflow-hidden rounded-full w-[60px] h-[60px]">
                        <img
                          src="./styles/img/user-1.jpg"
                          alt={`${user.firstName} ${user.lastName}`}
                          className="absolute top-0 left-0 w-full h-full object-cover"
                        />
                      </div>
                      <div className="relative text-lg font-semibold font-['Poppins'] text-center rounded-md">
                        {`${user.firstName} ${user.lastName}`}
                      </div>
                    </div>
                  </div>
                  <img
                    className="self-stretch relative max-w-full overflow-hidden h-px shrink-0"
                    alt="vector-379.svg"
                    src="imgPrimes/Vector 379.png"
                  />
                  <div className="self-stretch flex flex-row items-center justify-between text-sm">
                    <div className="flex-1 flex flex-col items-start justify-center gap-[5px]">
                      <div className="flex flex-row items-center justify-start">
                        <div className="text-sm  text-[#AAAAAA] relative">
                          Montant des commissions
                        </div>
                      </div>
                      <div className="self-stretch flex flex-row items-center justify-between  text-foundation-blue-darker">
                        <div className="relative font-medium text-lg font-['Poppins'] text-[#024155]">
                          {numberFormat(user.totalCommission)} €
                        </div>
                      </div>
                    </div>
                  </div>
                  <img
                    className="self-stretch relative max-w-full overflow-hidden h-px shrink-0"
                    alt="vector-379.svg"
                    src="imgPrimes/Vector 379.png"
                  />

                  <div className="w-full overflow-hidden">
                    <div className="text-xs text-[#AAAAAA] relative flex flex-row items-start justify-start">
                      Evolution mensuelle des commissions
                    </div>
                    {/* <EvolutionPrimesUserMiniature
                      filteredTransactions={filteredTransactions}
                      interval={interval}
                    /> */}
                    <EvolutionPrimesUserMiniature
                      filteredTransactions={filteredTransactions}
                      interval={interval}
                      userId={user.id} // Assuming you have access to user data in Equipe component
                    />
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="text-center text-gray-500">
              Aucune transaction pour la période (
              {startDate.toLocaleDateString()} - {endDate.toLocaleDateString()})
            </div>
          )}
        </div>
        {/* Pagination */}
        {usersList.length > 0 && (
          <div className="flex justify-end w-full">
            <Pagination
              current={currentPage}
              total={usersList.length}
              pageSize={pageSize}
              onChange={handlePageChange}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Equipe;
