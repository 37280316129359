import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Popover from "@mui/material/Popover";
import { EditText } from "react-edit-text";
import ModalShared from "./ModalShared";
import { color } from "@mui/system";

export default function Planche({
  name,
  image,
  onDelete,
  id,
  onUpdate,
  dashboard,
  isViewOnly,
  isOwner,
}) {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const onClick = () => {
    navigate("/board");
    sessionStorage.setItem("dashboard", JSON.stringify(dashboard));
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const [label, setLabel] = useState(name);
  const [landa, setLanda] = useState("title-5");
  const [visibility, setVisibility] = useState(false);
  const token = useSelector((state) => state.user.token);

  const update = () => {
    setAnchorEl(null);
    let element = document.getElementById(`input-default-text-editable-${id}`);
    let evObj = document.createEvent("Events");
    evObj.initEvent("click", true, false);
    element.dispatchEvent(evObj);
    setTimeout(() => {
      document.getElementById(`input-default-text-editable-${id}`).focus();
    }, 250);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  let theme = "1";
  const data = localStorage.getItem("theme");
  if (data) {
    theme = JSON.parse(data).theme;
  }
  const [color, setColor] = useState(theme === "0" ? "white" : "black");
  const open = Boolean(anchorEl);
  const tag = open ? `simple-popover${name}` : undefined;
  return (
    <>
      <ModalShared
        style={{ postion: "absolute" }}
        visibility={visibility}
        dashboard={dashboard}
        onCancel={() => setVisibility(false)}
        token={token}
        isViewOnly={isViewOnly}
      />
      <div className="planche">
        <div
          className="image"
          onClick={onClick}
          style={{ backgroundImage: `url('${image}')`, cursor: "pointer" }}
        ></div>
        <div className="infos">
          {isOwner ? (
            <EditText
              id={`input-default-text-editable-${id}`}
              defaultValue={name}
              value={label}
              onChange={(n) => {
                setLabel(n);
              }}
              className={landa}
              type="text"
              onBlur={() => {
                setColor(theme === "0" ? "white" : "black");
                setLanda("title-5");
              }}
              onEditMode={() => {
                setColor("black");
                setLanda("input-default-text-editable");
              }}
              // onClose={()=>{console.log('close')}}
              // onDelete={()=>{console.log('delete')}}
              style={{
                color,
                maxWidth: "175px",
                borderColor: "black",
                borderRadius: "2px",
              }}
              onSave={() => {
                if (!label) {
                  setLabel(name);
                } else {
                  onUpdate(label, id);
                }
              }}
            />
          ) : (
            <h5
              // style={{paddingTop: "1px", paddingBottom: "5px"}}
              className="title-5"
            >
              {name}
            </h5>
          )}
          <i
            aria-describedby={tag}
            className="fa-light fa-ellipsis more"
            style={{
              color:
                theme === "0" ? "#fff" : theme === "1" ? "#1f2128" : "#1f2128",
            }}
            onClick={handleClick}
          ></i>
        </div>
        <Popover
          id={tag}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "left",
          }}
        >
          <div className="mui-popover-custom">
            <ul>
              {isOwner ? (
                <>
                  <li>
                    <a
                      href="#"
                      title="Modifier"
                      className="mui-popover-item"
                      onClick={update}
                    >
                      Modifier
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      onClick={() => {
                        setAnchorEl(null);
                        setVisibility(true);
                      }}
                      title="Inviter à rejoindre"
                      className="mui-popover-item"
                    >
                      Inviter à rejoindre
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      title="Supprimer"
                      className="mui-popover-item"
                      onClick={() => {
                        setAnchorEl(null);
                        onDelete(id);
                      }}
                    >
                      Supprimer
                    </a>
                  </li>
                </>
              ) : (
                <li>
                  <a
                    href="#"
                    onClick={() => {
                      setAnchorEl(null);
                      setVisibility(true);
                    }}
                    title="Inviter à rejoindre"
                    className="mui-popover-item"
                  >
                    Inviter à rejoindre
                  </a>
                </li>
              )}
            </ul>
          </div>
        </Popover>
      </div>
    </>
  );
}
