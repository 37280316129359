import React, { useState, useEffect, useRef } from "react";
import WelcomeMessage from "../Views/PrimeViews/WelcomeMessage";
import CommissionsBloc1 from "../Views/PrimeViews/CommissionsBloc1";
import Equipe from "../Views/PrimeViews/Equipe";
import BonusDetails from "../Views/PrimeViews/BonusDetails";
import { useDispatch, useSelector } from "react-redux";
import { actionGetUser } from "../../redux/actions/UserAction";
import CalendarView from "../Views/CalendarView";
import moment from "moment";
import {
  actionSetTime,
  actionSetTimeValue,
} from "../../redux/actions/timeAction";
import {
  actionGetDataByFilter,
  actionGetTotalByFilter,
  actionSetIndicatorData,
} from "../../redux/actions/indicatorAction";
import { actionChangeTime } from "../../redux/actions/dashboardAction";
import { actionSetLists } from "../../redux/actions/listAction";
import CommissionsDetails from "../Views/PrimeViews/CommissionsDetails";
import { actionGetBonusTransactionsByUser } from "../../redux/actions/bonusTransactionAction";
import PrimesParametrees from "../Views/PrimeViews/PrimesParametrees";

const ManagerPrime = ({ interval }) => {
  const teams = useSelector((state) => state.team.teams);
  const [userTeams, setUserTeams] = useState([]);
  const idUser = useSelector((state) => state.user.id);
  const token = useSelector((state) => state.user.token);
  const [enabled, setEnabled] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState(null);

  const dispatch = useDispatch();
  const bonusTransactions = useSelector(
    (state) => state.bonusTransaction.bonusTransactions
  );
  const [filteredTransactions, setFilteredTransactions] = useState([]);

  const ownerTeam = userTeams.find(
    (team) => team.iduser === idUser && team.role === "OWNER"
  );
  const isManager = ownerTeam?.iduser === idUser ? true : false;

  const handleChange = (val) => {
    setEnabled(val);
  };
  const teamsOwnedByUser = teams.filter((team) =>
    team.userTeams.some(
      (member) => member.iduser === idUser && member.role === "OWNER"
    )
  );

  useEffect(() => {
    dispatch(
      actionGetBonusTransactionsByUser(idUser, token, (status, data) => {})
    );
  }, [dispatch, idUser, token]);
  useEffect(() => {
    setUserTeams(
      Array.from(
        new Set(
          teams.flatMap((team) => team.userTeams.map((userTeam) => userTeam))
        )
      )
    );
  }, [teams]);
  useEffect(() => {
    dispatch(actionGetUser(idUser, token));
  }, []);

  useEffect(() => {
    if (bonusTransactions && bonusTransactions.length > 0) {
      // Filtrer les transactions en fonction de l'intervalle spécifié
      const filtered = bonusTransactions.filter((transaction) => {
        const transactionDate = new Date(transaction.dateBonusTransaction);
        return (
          transactionDate >= interval[0] && // Date de début de l'intervalle
          transactionDate <= interval[1] // Date de fin de l'intervalle
        );
      });
      setFilteredTransactions(filtered);
    }
  }, [bonusTransactions, interval]);

  return (
    <div>
      {teams &&
        teamsOwnedByUser &&
        isManager !== null &&
        enabled !== null &&
        interval &&
        filteredTransactions && (
          <WelcomeMessage
            teams={teams}
            teamsOwnedByUser={teamsOwnedByUser}
            isManager={isManager}
            enabled={enabled}
            change={handleChange}
            interval={interval}
            filteredTransactions={filteredTransactions}
          />
        )}

      {isManager && !enabled ? (
        <>
          <CommissionsDetails
            interval={interval}
            filteredTransactions={filteredTransactions}
          />
          <Equipe
            interval={interval}
            filteredTransactions={filteredTransactions}
          />
          <BonusDetails
            interval={interval}
            filteredTransactions={filteredTransactions}
          />
          <PrimesParametrees interval={interval} />
        </>
      ) : (
        <>
          <CommissionsDetails
            interval={interval}
            filteredTransactions={filteredTransactions}
          />
          <BonusDetails
            interval={interval}
            filteredTransactions={filteredTransactions}
          />
        </>
      )}
    </div>
  );
};

export default ManagerPrime;
