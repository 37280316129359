import React from "react";
import {
  SortableContainer,
  SortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import { MenuOutlined } from "@ant-design/icons";

export default function ViewDashboard({ items, setItems, onDelete, onUpdate }) {
  const onSortEnd = ({ oldIndex, newIndex }) => {
    setItems(arrayMoveImmutable(items, oldIndex, newIndex));
  };

  const DragHandle = sortableHandle(() => (
    <MenuOutlined style={{ cursor: "grab", color: "#999" }} />
  ));

  const Item = SortableElement((props) => <tr {...props} />);

  const Container = SortableContainer((props) => (
    <tbody {...props}>
      {props.items.map((item, index) => (
        <Item key={`item-${index}`} index={index}>
          <>
            <td className="table-style">
              <DragHandle />
            </td>
            <td className="table-style">{item.dashboard.name}</td>
            <td className="table-style"></td>
          </>
        </Item>
      ))}
    </tbody>
  ));

  return (
    <>
      <div className="wrap-table">
        <table id="sources" className="display responsive">
          <Container
            items={items}
            axis="y"
            lockAxis="y"
            useDragHandle
            disableAutoscroll
            helperClass="row-dragging"
            onSortEnd={onSortEnd}
          />
        </table>
      </div>
    </>
  );
}
