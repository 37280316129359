import {
  ACTION_SET_GOALS,
  ACTION_IS_GET_GOALS,
  ACTION_IS_ADD_GOAL,
  ACTION_IS_UPDATE_GOAL,
  ACTION_IS_DELETE_GOAL,
} from "../actions/GoalAction";

const initialStateUser = {
  goals: [],
  isGet: false,
  isDelete: false,
  isUpdate: false,
  isAdd: false,
};

const GoalReducer = (state = initialStateUser, action) => {
  switch (action.type) {
    case ACTION_SET_GOALS: {
      if (action.payload.type === "ADD") {
        const goals = [...state.goals];
        goals.unshift(action.payload.data);
        return {
          ...state,
          goals: [...goals],
        };
      } else if (action.payload.type === "UPDATE") {
        const goals = [...state.goals];
        for (let i = 0; i < goals.length; i++) {
          const element = goals[i];
          if (parseInt(element.idgoal) === parseInt(action.payload.id)) {
            goals[i] = action.payload.data;
            break;
          }
        }
        return {
          ...state,
          goals: [...goals],
        };
      } else if (action.payload.type === "DELETE") {
        const goals = [...state.goals];
        for (let i = 0; i < goals.length; i++) {
          const element = goals[i];
          if (parseInt(element.idgoal) === parseInt(action.payload.data)) {
            goals.splice(i, 1);
            break;
          }
        }
        return {
          ...state,
          goals: [...goals],
        };
      } else if (action.payload.type === "ALL") {
        return {
          ...state,
          goals: [...action.payload.data],
        };
      }
      break;
    }

    case ACTION_IS_GET_GOALS: {
      return {
        ...state,
        isGet: action.payload,
      };
    }

    case ACTION_IS_ADD_GOAL: {
      return {
        ...state,
        isAdd: action.payload,
      };
    }

    case ACTION_IS_UPDATE_GOAL: {
      return {
        ...state,
        isUpdate: action.payload,
      };
    }

    case ACTION_IS_DELETE_GOAL: {
      return {
        ...state,
        isDelete: action.payload,
      };
    }

    default:
      return state;
  }
};

export default GoalReducer;
