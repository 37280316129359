import { actionGetBonusesByUser } from "./bonusAction";
import host from "./host";

export const ACTION_ADD_RULE = 'ACTION_ADD_RULE';
export const ACTION_IS_ADD_RULE = 'ACTION_IS_ADD_RULE';
export const ACTION_IS_DELETE_RULE = "ACTION_IS_DELETE_RULE";
export const ACTION_IS_UPDATE_RULE = "ACTION_IS_UPDATE_RULE";
export const ACTION_IS_GET_RULE = "ACTION_IS_GET_RULE";
export const ACTION_SET_RULES = 'ACTION_SET_RULES';
export const ACTION_SET_RULE = 'ACTION_SET_RULE';
export const ACTION_REMOVE_RULE = 'ACTION_REMOVE_RULE'
export const ACTION_REMOVE_RULE_FROM_BONUS = 'ACTION_REMOVE_RULE_FROM_BONUS'


// * --------- Begin actions --------
export const actionSetRules = (payload) => {
    return { type: ACTION_SET_RULES, payload };
};

export const actionIsAddRule = (payload) => ({
    type: ACTION_IS_ADD_RULE,
    payload
})
export const actionAddRule = (newRule) => ({
    type: ACTION_ADD_RULE,
    payload: newRule,
});

export const actionSetRule = (payload) => {
    return { type: ACTION_SET_RULE, payload };
};

export const actionIsDeleteRule = (payload) => {
    return { type: ACTION_IS_DELETE_RULE, payload };
};

export const actionIsGetRule = (payload) => {
    return { type: ACTION_IS_GET_RULE, payload };
};
export const actionIsUpdateRule = (payload) => {
    return { type: ACTION_IS_UPDATE_RULE, payload };
};
export const actionRemoveRule = (payload) => {
    return { type: ACTION_REMOVE_RULE, payload };
};
export const actionRemoveRuleFromBonus = (payload) => {
    return { type: ACTION_REMOVE_RULE_FROM_BONUS, payload };
};


export const createRule = (name, formula, idPeriod, idGoal, percent, startDate, endDate, token, callback) => {
    return (dispatch) => {
        dispatch(actionIsAddRule(true))

        let myHeaders = new Headers();
        myHeaders.append("Authorization", token);
        myHeaders.append("Content-Type", "application/json");
        let raw = JSON.stringify({
            name,
            formula,
            idPeriod,
            idGoal,
            percent,
            startDate,
            endDate
        });
        let requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        fetch(`${host}/rule/create`, requestOptions)
            .then((response) => {
                if (response.status !== 200 && response.status !== 201) {
                    return null;
                }
                return response.json();
            })
            .then((result) => {
                if (result) {
                    dispatch(actionSetRules({ type: "ADD", data: result.new }));
                    callback(200, result.new);
                } else {
                    callback(400);
                }

            })
            .catch((error) => {
                console.error('Error adding rule:', error);
                callback(400);
            })
            .then(() => dispatch(actionIsAddRule(false)));

    }
};

export const actionUpdateRule = (idrule, name, formula, percent, idPeriod, startDate, endDate, idGoal, token, callback) => {
    return (dispatch) => {
        dispatch(actionIsUpdateRule(true));
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", token);

        let requestBody = {
            idrule, name, formula, percent, idPeriod, startDate, endDate, idGoal
        }

        let requestOptions = {
            method: "PUT",
            headers: myHeaders,
            body: JSON.stringify(requestBody),
            redirect: "follow",
        };

        fetch(`${host}/rule/update/${idrule}`, requestOptions)
            .then((response) => {
                if (response.status !== 200 && response.status !== 201) {
                    return null;
                }
                return response.json();
            })
            .then((result) => {
                if (result) {
                    console.log('result from update rule', result)
                    dispatch(actionSetRules({ type: "UPDATE", data: result.new, id: idrule }
                    ));
                    callback(true, result.new);
                } else {
                    console.log("error update rule")
                }
            })
            .catch((e) => {
                console.log('e', e);
            }).then(() => {
                dispatch(actionIsUpdateRule(false));
            });
    };
};

export const actionDeleteRule = (id, token, callback) => {
    return (dispatch) => {
        dispatch(actionIsDeleteRule(true));
        let myHeaders = new Headers();
        myHeaders.append("Authorization", token);

        let requestOptions = {
            method: "DELETE",
            headers: myHeaders,
            redirect: "follow",
        };

        fetch(`${host}/rule/delete/${id}`, requestOptions)
            .then((response) => {
                if (response.status !== 200 && response.status !== 201) {
                    callback(false);
                    return null;
                }
                else {
                    callback(true);
                    dispatch(actionSetRules({ type: "DELETE", data: id }));
                }

            })
            .catch((error) => {
                console.error('Error deleting bonus:', error);
                callback(false);
            })

            .then(() => dispatch(actionIsDeleteRule(false)));
    };
};
