import React from "react";

export default function ThemeSelector({ children }) {
  let Theme = React.lazy(() => import("./ThemeLight"));
  const data = localStorage.getItem("theme");

  if (data) {
    if (JSON.parse(data).theme === "0") {
      Theme = React.lazy(() => import("./ThemeDark"));
    } else if (JSON.parse(data).theme === "1") {
      Theme = React.lazy(() => import("./ThemeLight"));
    } else {
      Theme = React.lazy(() => import("./ThemeLight"));
    }
  } else {
    Theme = React.lazy(() => import("./ThemeLight"));
  }
  return (
    <>
      <React.Suspense fallback={() => null}>
        <Theme />
      </React.Suspense>
      {children}
    </>
  );
}
