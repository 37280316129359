import React from "react";
import { FileExcelOutlined, GoogleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import Paper from "@mui/material/Paper";

export default function Connector({ id, url, image, selected, onClick, name }) {
  const { t } = useTranslation();

  return (
    <>
      <Paper
        onClick={() => onClick(id, url)}
        title={name}
        className={selected ? "connector-selected" : "connector-simple"}
        elevation={3}
        style={{
          width: "75px",
          height: "75px",
          margin: "10px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
          backgroundColor: selected ? "#1f2128" : "#fff",
          color: selected ? "#05BAF3" : "#000",
        }}
      >
        {/* {url.includes("ga_form") ? <GoogleOutlined /> : <FileExcelOutlined />} */}

        <img
          src={image}
          height={'60px'}
          width={"60px"}
          style={{borderRadius: "3px"}}
        />
      </Paper>
    </>
  );
}
