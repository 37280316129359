import React, { useState } from "react";
// import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import ModalIndicator from "./ModalIndicator";
import { Draggable } from "react-dnd-beautiful";
import { useNavigate } from "react-router-dom";
import {
  actionSetIndicator,
  actionSetComments,
} from "../../redux/actions/indicatorAction";

import { FullscreenOutlined } from "@ant-design/icons";
import { actionSetGoals } from "../../redux/actions/GoalAction";
import { Progress } from "antd";
import { numberFormat } from "./IndicatorView";
// import { Divider } from "@mui/material";
// const numeral = require("numeral");
const _ = require("lodash");

let theme = "1";
const data = localStorage.getItem("theme");
if (data) {
  theme = JSON.parse(data).theme;
}

export default function CardMini({
  isLarge,
  data,
  key,
  index,
  setIsDraggable,
  isDraggable,
}) {
  // const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [visibility, setVisibility] = useState(false);
  const isViewOnly = useSelector((state) => state.board.isViewOnly);
  const idUser = useSelector((state) => state.user.id);
  const onCancel = () => {
    setIsDraggable(true);
    setVisibility(false);
  };

  return (
    <>
      <ModalIndicator
        visible={visibility}
        onCancel={onCancel}
        data={data}
        setVisible={setVisibility}
      />
      <Draggable
        draggableId={data.idindicator + ""}
        index={index}
        isDragDisabled={isViewOnly || !idUser || visibility}
      >
        {(provided) => (
          <div
            {...isLarge ? {} : {className: "col-5"}}
            key={key}
            id={`indicator-${data.idindicator}`}
            onClick={() => {
              setVisibility(true);
              setIsDraggable(false);
            }}
            ref={provided.innerRef}
            {...provided.dragHandleProps}
            {...provided.draggableProps}
            style={{ ...isLarge ? {width: "150px"} : {}, margin: "0px 5px 0px 0px", height: "100%" }}
          >
            <div className="bloc-fond-fonce rose" style={{ height: "100%" }}>
              <div className="indicator_header">
                <div
                  className="indicator_icon"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginTop: "3px"
                  }}
                >
                  <p className="title" style={{ fontSize: "12px" }}>
                    {data.name}
                  </p>
                  <FullscreenOutlined
                    onClick={(e) => {
                      e.stopPropagation();
                      sessionStorage.setItem("indicator", JSON.stringify(data));
                      dispatch(actionSetIndicator(data));
                      dispatch(actionSetComments([...data.commentaires]));
                      dispatch(
                        actionSetGoals({ type: "ALL", data: data.goals })
                      );
                      navigate("/indicator");
                    }}
                    style={{
                      paddingRight: "5px",
                      fontSize: "17px",
                      color:
                        theme === "0"
                          ? "#fff"
                          : theme === "1"
                          ? "#1f2128"
                          : "#1f2128",
                    }}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                  marginTop: "5px"
                }}
              >
                {data && data.goals && data.goals.length > 0
                  ? (() => {
                      let noPeriodique = _.sortBy(data.goals, [
                        (o) => o.startdate,
                      ]);
                      let item = noPeriodique[0];
                      return (
                        <div
                          title={`Objectif du ${item.startdate} au ${item.enddate}`}
                        >
                          <Progress
                            type="circle"
                            width="50px"
                            percent={
                              item.currentvalue
                                ? _.round(
                                    (item.currentvalue / item.targetvalue) *
                                      100,
                                    1
                                  )
                                : 0
                            }
                            strokeColor={"#05BAF3"}
                            trailColor={"#CCD4E2"}
                            format={(percent) => {
                              return (
                                <i
                                  style={{
                                    color:
                                      theme === "0"
                                        ? "#fff"
                                        : theme === "1"
                                        ? "#1f2128"
                                        : "#1f2128",
                                    fontSize: "12px",
                                  }}
                                >
                                  {_.round(
                                    (item.currentvalue / item.targetvalue) *
                                      100,
                                    1
                                  ) + "%"}
                                </i>
                              );
                            }}
                          />
                        </div>
                      );
                    })()
                  : null}
                <p
                  className="chiffre"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    fontSize: "15px",
                  }}
                >
                  {data && parseInt(data.operation) === 1
                    ? numberFormat(data.total)
                    : data && parseInt(data.operation) === 2
                    ? numberFormat(data.avg)
                    //ajout du counter:
                    : data && parseInt(data.operation) === 4
                    ? numberFormat(data?.data?.length)
                    ////////////////////////////////////////
                    : numberFormat(data.total)}
                    
                  {data && data.symbole !== null && data.symbole !== ""
                    ? ` ${data.symbole} `
                    : " "}
                </p>
              </div>
            </div>
          </div>
        )}
      </Draggable>
    </>
  );
}
