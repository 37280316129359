import { useEffect } from "react";
import ManagerPrime from "./ManagerPrime";

const BonusWrapper = ({ isViewOnly, height, screenX, interval }) => {
  return (
    <div className=" pr-[20px] pl-[20px] gap-[30px] mt-[122px]">
      <ManagerPrime interval={interval} />
    </div>
  );
};

export default BonusWrapper;
