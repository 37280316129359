import {
  ACTION_GET_ALL_USER,
  ACTION_IS_GET_USER,
  ACTION_GET_USER_BY_EMAIL,
  ACTION_GET_ERROR,
  ACTION_GET_INDICATOR_GOAL,
  ACTION_GET_DASHBORAD_BY_USER_ID,
  ACTION_POST_USER_TEAM,
  ACTION_GET_TEAMS,
  ACTION_UPDATE_TEAMS,
  ACTION_GET_USER_TEAM,
  ACTION_GET_USER_TEAMS_BY_USER_ID,
  ACTION_DELETE_USERS_TEAMS,
  ACTION_GET_TEAMS_BY_USER,
} from "../actions/UsersAction";

const initialStateUser = {
  error: false,
  user: "",
  users: [],
  isGet: false,
  infosUser: {},
  userDashboard: [],
  userTeam: "",
  teams: "",
  userTeams: [],
};

const usersReducer = (state = initialStateUser, action) => {
  switch (action.type) {
    case ACTION_GET_ALL_USER: {
      return {
        ...state,
        users: [...action.payload],
      };
    }
    case ACTION_DELETE_USERS_TEAMS: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case ACTION_GET_TEAMS: {
      return {
        ...state,
        teams: action.payload,
      };
    }

    case ACTION_GET_TEAMS_BY_USER: {
      return {
        ...state,
        teams: action.payload,
      };
    }

    case ACTION_GET_USER_TEAM: {
      return {
        ...state,
        userTeams: [...action.payload],
      };
    }
    case ACTION_GET_USER_TEAMS_BY_USER_ID: {
      return {
        ...state,
        teams: action.payload,
      };
    }

    case ACTION_POST_USER_TEAM: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case ACTION_UPDATE_TEAMS: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case ACTION_GET_DASHBORAD_BY_USER_ID: {
      return {
        ...state,
        userDashboard: action.payload,
      };
    }

    case ACTION_GET_INDICATOR_GOAL: {
      return {
        ...state,
        infosUser: action.payload,
      };
    }

    case ACTION_GET_ERROR: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case ACTION_GET_USER_BY_EMAIL: {
      return {
        ...state,
        user: action.payload,
      };
    }

    case ACTION_IS_GET_USER: {
      return {
        ...state,
        isGet: action.payload,
      };
    }

    default:
      return state;
  }
};

export default usersReducer;
