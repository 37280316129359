import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Responsive, WidthProvider } from "react-grid-layout";
import CardResponsive from "../Views/CardResponsive";
import { useSelector } from "react-redux";
import ContentLoader from "react-content-loader";
import { useDispatch } from "react-redux";
import { actionMoveMany } from "../../redux/actions/indicatorAction";
import { Select, Checkbox } from "antd";
import Modal from "../Views/Modal";
import {
  actionAddList,
  actionDeleteList,
  actionMoveLists,
  actionSetLists,
  actionUpdateList,
} from "../../redux/actions/listAction";
import { enqueueSnackbar } from "notistack";
import ViewContainer from "../Views/ViewContainer";
import ModalIndicator from "../Views/ModalIndicator";
const ResponsiveGridLayout = WidthProvider(Responsive);

let theme = "1";
const data = localStorage.getItem("theme");

if (data) {
  theme = JSON.parse(data).theme;
}

const Container = styled(ResponsiveGridLayout)`
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    border-radius: 8px;
    margin: 10px;
    width: 5px;
  }
  ::-webkit-scrollbar-button {
    display: none;
  }
  ::-webkit-scrollbar-corner {
    border-radius: 8px;
    margin: 3px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #05baf3;
    border-radius: 8px;
    border-color: #05bcf341;
    margin: 3px;
    border-width: 2px;
  }
  ::-webkit-scrollbar-track {
    border-radius: 8px;
    margin: 3px;
  }
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  padding-top: 5px;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: inset 0 4px 8px 0 rgba(0, 0, 0, 0.25),
    0 6px 20px 0 rgba(0, 0, 0, 0.15);
`;

const Tools = styled.div`
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  align-items: center;
  height: min-content;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  border-bottom: 1px dashed ${theme === "0" ? "#ffffff15" : "#00000015"};
`;

const View = styled.div`
  align-items: center;
  height: min-content;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
`;

const Button = styled.button`
  height: 35px;
  margin: 0px;
  border-radius: 5px;
  padding: 3px 30px 3px 30px;
`;

export default function SimpleDashboard({
  lists,
  idlist,
  height,
  screenX,
  setIdList,
  isViewOnly,
  onAddIndicator,
}) {
  const dispatch = useDispatch();
  const [can, setCan] = useState(false);
  const canRef = useRef();
  canRef.current = can;

  const [label, setLabel] = useState("");
  const [readonly, setReadOnly] = useState(false);
  const [resolution, setResolution] = useState("lg");
  const [indicator, setIndicator] = useState();
  const [indicators, setIndicators] = useState([]);
  const [open, setOpen] = useState(false);
  const [visibility, setVisibility] = useState(false);
  const [updateVisibility, setUpdateVisibility] = useState(false);
  const [selectedList, setSelectedList] = useState(null);
  const [deleteVisible, setDeleteVisible] = useState(false);

  const idUser = useSelector((state) => state.user.id);
  const token = useSelector((state) => state.user.token);
  const loading = useSelector((state) => state.board.isGet);
  const isAddList = useSelector((state) => state.list.isAdd);
  const isUpdate = useSelector((state) => state.list.isUpdate);
  const board = useSelector((state) => state.board.dashboard);
  const isDelete = useSelector((state) => state.list.isDelete);

  const items = [
    {
      x: 0,
      y: 0,
      w: 8,
      h: 8,
      static: true,
    },
    {
      x: 8,
      y: 0,
      w: 4,
      h: 4,
      static: true,
    },
    {
      x: 8,
      y: 4,
      w: 4,
      h: 4,
      static: true,
    },
  ];

  useEffect(() => {
    if (Array.isArray(lists) && Boolean(idlist)) {
      let indicators = [];
      for (let i = 0; i < lists.length; i++) {
        if (+idlist === +lists[i].idlist) {
          indicators = [...lists[i].indicators];
          break;
        }
      }
      setIndicators(
        indicators.map((indicator) => {
          return {
            ...indicator,
            key: `${indicator.idindicator}-${idlist}-${resolution}`,
          };
        })
      );
    }
  }, [lists, idlist, resolution]);

  useEffect(() => {
    setCan(false);
  }, [resolution]);

  useEffect(() => {
    if (lists.length > 0) {
      const response = lists.filter((item) => +item.idlist === +idlist);
      if (response.length === 0) {
        setIdList(+lists[0].idlist);
      }
    }
  }, [lists]);

  useEffect(() => {
    setCan(false);
  }, [idlist]);

  const addList = (e) => {
    e.preventDefault();
    if (label) {
      dispatch(
        actionAddList(
          label,
          readonly,
          idUser,
          board.iddashboard,
          token,
          (status) => {
            if (status === 200) {
              setLabel("");
              setReadOnly(false);
              enqueueSnackbar("Une liste a été ajoutée", {
                variant: "success",
              });
            } else {
              enqueueSnackbar("La liste n'a pas été ajoutée !", {
                variant: "warning",
              });
            }
          }
        )
      );
    } else {
      enqueueSnackbar("Le nom est obligatoire.", {
        variant: "warning",
      });
    }
  };

  const confirmDeleteList = () => {
    if (selectedList?.idlist) {
      dispatch(
        actionDeleteList(selectedList.idlist, token, (status) => {
          if (status) {
            setDeleteVisible(false);
            setSelectedList(null);
            setVisibility(true);
            enqueueSnackbar("La liste a bien été supprimé", {
              variant: "success",
            });
          } else {
            enqueueSnackbar("Une erreur est survenue.", {
              variant: "error",
            });
          }
        })
      );
    }
  };

  const onDelete = (list) => {
    setVisibility(false);
    setSelectedList(list);
    setDeleteVisible(true);
  };

  const cancelUpdate = () => {
    setSelectedList(null);
    setUpdateVisibility(false);
    setLabel("");
    setReadOnly(false);
    setVisibility(true);
  };

  const showUpdate = (list) => {
    setSelectedList(list);
    setVisibility(false);
    setLabel(list?.name);
    setReadOnly(list?.readonly);
    setUpdateVisibility(true);
  };

  const onUpdate = (e) => {
    e.preventDefault();
    if (label && selectedList) {
      dispatch(
        actionUpdateList(
          label,
          readonly,
          selectedList.idlist,
          selectedList.positionx,
          selectedList.positiony,
          token,
          (status) => {
            if (status) {
              cancelUpdate();
            } else {
              enqueueSnackbar("Une erreur est survenue", {
                variant: "error",
              });
            }
          }
        )
      );
    } else {
      enqueueSnackbar("Le nom est obligatoire.", {
        variant: "warning",
      });
    }
  };

  const setLists = (data) => {
    dispatch(actionSetLists({ type: "ALL", data }));
    dispatch(
      actionMoveLists(
        data.map((item, index) => {
          return { id: item.idlist, positionx: index };
        }),
        token
      )
    );
  };

  const getCoordonnee = (indicator, idlist, resolution) => {
    if (indicator.listIndicators) {
      for (let i = 0; i < indicator.listIndicators.length; i++) {
        const listIndicator = indicator.listIndicators[i];
        if (+listIndicator.idlist === +idlist) {
          const x = listIndicator?.[`x_${resolution}`];
          const y = listIndicator?.[`y_${resolution}`];
          const h = listIndicator?.[`h_${resolution}`];
          const w = listIndicator?.[`w_${resolution}`];

          return {
            x,
            y,
            w: w > 2 ? w : 2,
            h: h > 2 ? h : 2,
            minW: indicator.showperiode ? 4 : 2,
            minH: indicator.showperiode ? 6 : 2,
          };
        }
      }
    } else {
      return {
        x: indicator?.x,
        y: indicator?.y,
        w: indicator?.w > 2 ? indicator?.w : 2,
        h: indicator?.h > 2 ? indicator?.h : 2,
        minW: indicator.showperiode ? 4 : 2,
        minH: indicator.showperiode ? 6 : 2,
      };
    }
  };

  return (
    <>
      {indicator && (
        <ModalIndicator
          visible={open}
          onCancel={() => {
            setVisibility(false);
            setIndicator(null);
          }}
          data={indicator}
          setVisible={setOpen}
        />
      )}

      <Modal
        visible={deleteVisible}
        title={`Confirmer la suppression de la vue "${selectedList?.name}"`}
        onCancel={
          isDelete
            ? null
            : () => {
                setDeleteVisible(false);
                setVisibility(true);
              }
        }
      >
        <div className="row">
          <div className="col-md-12 wrap-btn-modal">
            <div className="btn-container">
              <button
                className="btn-border close-btn-submit"
                type="reset"
                disabled={isDelete}
                onClick={isDelete ? null : () => setDeleteVisible(false)}
              >
                Annuler
              </button>
              <button
                className="btn-full"
                type="submit"
                disabled={isDelete}
                onClick={
                  isDelete
                    ? null
                    : () => {
                        confirmDeleteList();
                      }
                }
              >
                {isDelete ? "Loading..." : "Confirmer"}
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        visible={updateVisibility}
        title={"Modifier une vue"}
        onCancel={isUpdate ? null : cancelUpdate}
      >
        <form onSubmit={onUpdate}>
          <div className="row">
            <div className="col-md-12">
              <div className="input-container">
                <label for="nom" className="label-default">
                  Entrer le nom
                </label>
                <span className="span-block">
                  <input
                    className="input-default inputCustom"
                    name="nom"
                    id="nom"
                    type="text"
                    value={label}
                    onChange={(e) => setLabel(e.target.value)}
                    placeholder="Obligatoire"
                    required
                  />
                </span>
              </div>
              <div
                className="input-container"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginLeft: 2,
                }}
              >
                <label for="readonly" className="label-default">
                  <Checkbox
                    id="readonly"
                    checked={readonly}
                    onChange={(e) => {
                      setReadOnly(e.target.checked);
                    }}
                  >
                    {" "}
                    Visible en lecture seul
                  </Checkbox>
                </label>
              </div>
            </div>
            <div className="col-md-12 wrap-btn-modal">
              <div className="btn-container">
                <button className="btn-full" type="submit" disabled={isUpdate}>
                  {isUpdate ? "Loading..." : "Modifier"}
                </button>
              </div>
            </div>
          </div>
        </form>
      </Modal>

      <Modal
        visible={visibility}
        title={"Gestion des vues"}
        onCancel={() => (isAddList ? null : setVisibility(false))}
      >
        <form onSubmit={addList}>
          <div className="row">
            <div className="col-md-12">
              <div className="input-container">
                <label for="nom" className="label-default">
                  Entrer le nom
                </label>
                <span className="span-block">
                  <input
                    className="input-default inputCustom"
                    name="nom"
                    id="nom"
                    type="text"
                    value={label}
                    onChange={(e) => setLabel(e.target.value)}
                    placeholder="Obligatoire"
                    required
                  />
                </span>
              </div>
              <div
                className="input-container"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginLeft: 2,
                }}
              >
                <label for="readonly" className="label-default">
                  <Checkbox
                    id="readonly"
                    checked={readonly}
                    onChange={(e) => {
                      setReadOnly(e.target.checked);
                    }}
                  >
                    {" "}
                    Visible en lecture seul
                  </Checkbox>
                </label>
              </div>
            </div>
            <div className="col-md-12 wrap-btn-modal">
              <div className="btn-container">
                <button className="btn-full" type="submit" disabled={isAddList}>
                  {isAddList ? "Loading..." : "Ajouter"}
                </button>
              </div>
            </div>
          </div>
        </form>
        <br />
        <ViewContainer
          items={lists}
          setItems={setLists}
          onDelete={onDelete}
          onUpdate={showUpdate}
        />
      </Modal>

      <Main style={{ height: height, maxHeight: height, paddingBottom: "5px" }}>
        <Tools>
          <View>
            {screenX > 600 && <p>{screenX > 750 && "Sélectionner "}vue :</p>}
            <Select
              style={{
                height: "35px",
                marginLeft: "5px",
                maxHeight: "35px",
                minWidth: "150px",
                marginRight: "10px",
              }}
              value={idlist}
              onChange={setIdList}
              className="color-black"
              options={lists.map((item) => {
                return { value: +item.idlist, label: item.name };
              })}
            />
            {!isViewOnly && (
              <Button
                onClick={() => {
                  setVisibility(true);
                }}
                className="btn-full"
                type="button"
              >
                Gestion des vues
              </Button>
            )}
          </View>
          {!isViewOnly && (
            <Button
              onClick={() => {
                onAddIndicator(true);
              }}
              className="btn-full"
              type="button"
            >
              Ajouter un indicateur
            </Button>
          )}
        </Tools>
        <Container
          style={{ flex: 1 }}
          cols={{ lg: 12, md: 12, sm: 8, xs: 4, xxs: 2 }}
          rowHeight={45}
          onLayoutChange={(data) => {
            setIndicators([...indicators]);
            if (canRef.current) {
              dispatch(
                actionMoveMany(
                  data.map((item) => {
                    return { ...item, i: item.i.split("-")[0] };
                  }),
                  idlist,
                  resolution,
                  token
                )
              );
            }
          }}
          onDragStop={() => {
            setCan(true);
          }}
          onResizeStop={() => {
            setCan(true);
          }}
          onBreakpointChange={(resolution, col) => {
            setResolution(resolution);
          }}
          isDraggable={!isViewOnly}
          isResizable={!isViewOnly}
          width={window.innerWidth}
          margin={[10, 10]}
        >
          {loading
            ? items.map((item, index) => (
                <div
                  key={`item-loading-${index}`}
                  data-grid={item}
                  style={{
                    borderRadius: "9px",
                    boxShadow:
                      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                  }}
                >
                  <ContentLoader
                    height={"100%"}
                    width={"100%"}
                    speed={1}
                    backgroundColor={"#80808075"}
                    foregroundColor={"#a9a9a975"}
                  >
                    <rect
                      x="0"
                      y="0"
                      rx="9px"
                      ry="9px"
                      width="100%"
                      height="100%"
                    />
                  </ContentLoader>
                </div>
              ))
            : indicators.map((indicator, index) => (
                <div
                  key={indicator.key}
                  data-grid={getCoordonnee(indicator, idlist, resolution)}
                >
                  <CardResponsive
                    data={indicator}
                    onOpen={(data) => {
                      setIndicator(data);
                      setOpen(true);
                    }}
                  />
                </div>
              ))}
        </Container>
      </Main>
    </>
  );
}
