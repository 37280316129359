import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import Header from "../Header";
import Footer from "../Footer";
import ContentLoader from "react-content-loader";
import {
  EditOutlined,
  CalendarTodayOutlined,
  CloseOutlined,
} from "@material-ui/icons";
import Planche2 from "../../img/planche2.jpg";
import PropagateLoader from "react-spinners/PropagateLoader";
import styled from "styled-components";
import Modal from "../Views/Modal";
import CustomSelect from "../Views/CustomSelect";
import { DragNDrop } from "../DragNDrop/DragNDrop";
import ObjectifDragNDrop from "../DragNDrop/ObjectifDragNDrop";
import {
  actionCreateRapport,
  actionCreateReportNotification,
  actionDeleteRapport,
  actionDeleteReportNotification,
  actionGetRapportsByUser,
  actionSendEmail,
  actionUpdateRapport,
  actionUpdateReportNotification,
} from "../../redux/actions/rapportAction";
import { useSnackbar } from "notistack";
import { SendOutlined } from "@material-ui/icons";
import SelectedIndicator from "../RapportInfos/SelectedIndicator";
import SelectedObjectifs from "../RapportInfos/SelectedObjectifs";
import { styled as MUIStyled } from "@mui/material/styles";
import { Switch } from "@mui/material";
import { TimePicker } from "antd";
import { ShareOutlined } from "@material-ui/icons";
import {
  actionGetDashboard,
  actionGetDashboardsByUser,
} from "../../redux/actions/dashboardAction";
import Card from "./Card";
import moment from "moment";
import { actionGetTeamsByUser } from "../../redux/actions/teamAction";
import { getIntervalByValue } from "../Board";
import { ReactMultiEmail } from "react-multi-email";
import "react-multi-email/dist/style.css";
const _ = require("lodash");

let theme = "1";
const data = localStorage.getItem("theme");

if (data) {
  theme = JSON.parse(data).theme;
}

// export const getIntervalByValue = (value) => {
//   if (value !== "0") {
//     let a = moment();
//     let b = moment();
//     switch (value) {
//       case "1":
//         a.hours(0);
//         b.hours(24);

//         a.minutes(0);
//         b.minutes(0);

//         a.seconds(0);
//         b.seconds(0);

//         a.milliseconds(0);
//         b.milliseconds(0);

//         break;

//       case "2":
//         a.hours(0);
//         b.hours(0);

//         a.minutes(0);
//         b.minutes(0);

//         a.seconds(0);
//         b.seconds(0);

//         a.milliseconds(0);
//         b.milliseconds(0);

//         a.weekday(0);
//         b.weekday(7);

//         break;

//       case "3":
//         a.hours(0);
//         b.hours(0);

//         a.minutes(0);
//         b.minutes(0);

//         a.seconds(0);
//         b.seconds(0);

//         a.milliseconds(0);
//         b.milliseconds(0);

//         a.date(1);
//         b.date(1);

//         b.month(b.month() + 1);

//         break;

//       case "4":
//         a.hours(0);
//         b.hours(0);

//         a.minutes(0);
//         b.minutes(0);

//         a.seconds(0);
//         b.seconds(0);

//         a.milliseconds(0);
//         b.milliseconds(0);

//         a.date(1);
//         b.date(1);
//         a.month(a.month() - 2);
//         b.month(b.month() + 1);

//         break;

//       case "5":
//         a.hours(0);
//         b.hours(0);

//         a.minutes(0);
//         b.minutes(0);

//         a.seconds(0);
//         b.seconds(0);

//         a.milliseconds(0);
//         b.milliseconds(0);

//         a.date(1);
//         b.date(1);
//         a.month(a.month() - 5);
//         b.month(b.month() + 1);

//         break;

//       case "6":
//         a.hours(0);
//         b.hours(0);

//         a.minutes(0);
//         b.minutes(0);

//         a.seconds(0);
//         b.seconds(0);

//         a.milliseconds(0);
//         b.milliseconds(0);

//         a.date(1);
//         b.date(1);

//         a.month(0);
//         b.month(0);

//         a.month(0);
//         b.year(b.year() + 1);

//         break;
//       default:
//         return [null, null];
//     }
//     return [a, b];
//   } else {
//     return [null, null];
//   }
// };

export default function Home() {
  const days = [
    "Lundi",
    "Mardi",
    "Mercredi",
    "Jeudi",
    "Vendredi",
    "Samedi",
    "Dimanche",
  ];
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const token = useSelector((state) => state.user.token);
  const userId = useSelector((state) => state.user.id);
  const dashboards = useSelector((state) => state.board.dashboards);
  const isGet = useSelector((state) => state.rapport.isGet);
  const isAdd = useSelector((state) => state.rapport.isAdd);
  const isUpdate = useSelector((state) => state.rapport.isUpdate);
  const isDelete = useSelector((state) => state.rapport.isDelete);
  const reports = useSelector((state) => state.rapport.reports);
  const isGetDashboard = useSelector((state) => state.board.isGet);
  const isLoading = useSelector((state) => state.rapport.isLoading);

  const [name, setName] = useState("");
  const [notificationName, setNotificationName] = useState("");
  const [beginDate, setBeginDate] = useState(null);
  const [objectifs, setObjectifs] = useState([]);
  const [dashboard, setDashboard] = useState(null);
  const [indicators, setIndicators] = useState([]);
  const teams = useSelector((state) => state.team.teams);
  const [selectedObjectifs, setSelectedObjectifs] = useState([]);
  const [selectedDashboard, setSelectedDashboard] = useState(null);
  const [selectedIndicators, setSelectedIndicators] = useState([]);
  const [report, setReport] = useState(null);
  const [reportNotification, setReportNotification] = useState(null);
  const [deleteVisibility, setDeleteVisibility] = useState(false);
  const [editReportVisibility, setEditReportVisibility] = useState(false);
  const [editRole, setEditRole] = useState(1);
  const [editNotificationRole, setEditNotificationRole] = useState(1);
  const [shareVisibility, setShareVisibility] = useState(false);
  const [teamSelected, setTeamSelected] = useState([]);
  const [frequency, setFrequency] = useState({ value: 1, label: "Journalier" });
  const [shareDay, setShareDay] = useState(null);
  const [shareTime, setShareTime] = useState(null);

  const [deleteShareVisibility, setDeleteShareVisibility] = useState(false);
  const [showVisibility, setShowVisibility] = useState(false);

  useEffect(() => {
    if (selectedDashboard) {
      dispatch(
        actionGetDashboard(
          selectedDashboard.value,
          token,
          (status, result, userDashboards) => {
            if (result) {
              let goals = [];
              let indicators = [];
              for (let i = 0; i < result.lists.length; i++) {
                const list = result.lists[i];
                indicators = [...indicators, ...list.indicators];
              }

              for (let i = 0; i < indicators.length; i++) {
                const indicator = indicators[i];
                goals = [...goals, ...indicator.goals];
              }

              let dashboard = {
                ...result,
                beginDate: new Date(getIntervalByValue("3")[0]),
              };

              for (let i = 0; i < userDashboards.length; i++) {
                const userDashboard = userDashboards[i];
                if (userDashboard.role === "OWNER") {
                  dashboard = {
                    ...dashboard,
                    beginDate:
                      userDashboard.interval && userDashboard.interval !== "0"
                        ? new Date(
                            getIntervalByValue(userDashboard.interval)[0]
                          )
                        : userDashboard.datedebut
                        ? new Date(userDashboard.datedebut)
                        : new Date(getIntervalByValue("3")[0]),
                  };
                }
              }

              setDashboard(dashboard);
              setObjectifs(goals);
              setIndicators(indicators);
            }
          }
        )
      );
    }
  }, [selectedDashboard, dispatch, token]);

  useEffect(() => {
    if (report) {
      for (let i = 0; i < dashboards.length; i++) {
        const item = dashboards[i];
        if (report.iddashboard === item.dashboard.iddashboard) {
          setSelectedDashboard({
            value: item.dashboard.iddashboard,
            label: item.dashboard.name,
          });
          break;
        }
      }
      const goals = [];
      const indicators = [];

      for (let i = 0; i < report.goalReports.length; i++) {
        const goalReport = report.goalReports[i];
        goals.push(goalReport.goal);
      }

      for (let i = 0; i < report.indicatorReports.length; i++) {
        const indicatorReport = report.indicatorReports[i];
        indicators.push(indicatorReport.indicator);
      }

      setName(report.name);
      setSelectedObjectifs(goals);
      if (report.beginDate) {
        setBeginDate(new Date(report.beginDate).toISOString().split("T")[0]);
      }
      setSelectedIndicators(indicators);
    }
  }, [report, dashboards]);

  useEffect(() => {
    dispatch(actionGetRapportsByUser(userId, token));
    dispatch(actionGetDashboardsByUser(userId, token));
    dispatch(actionGetTeamsByUser(userId, token));
  }, [dispatch, userId, token]);

  const onEditReport = (e) => {
    e.preventDefault();
    if (!name.trim()) {
      enqueueSnackbar("Le nom est obligatoire.", {
        variant: "warning",
      });
      return;
    }

    if (selectedIndicators.length === 0 && selectedObjectifs.length === 0) {
      enqueueSnackbar(
        "Le rapport doit au moins contenir un indicateur ou un objectif.",
        {
          variant: "warning",
        }
      );
      return;
    }

    const goals = [];
    const indicators = [];

    for (let i = 0; i < selectedIndicators.length; i++) {
      const indicator = selectedIndicators[i];
      indicators.push({
        idindicator: indicator.idindicator,
        size: 1,
        position: i,
      });
    }

    for (let i = 0; i < selectedObjectifs.length; i++) {
      const goal = selectedObjectifs[i];
      goals.push({
        idgoal: goal.idgoal,
        size: 1,
        position: i,
      });
    }

    if (editRole === 1) {
      dispatch(
        actionCreateRapport(
          name,
          beginDate,
          dashboard.iddashboard,
          goals,
          indicators,
          userId,
          token,
          (is) => {
            if (is) {
              closeEdit();
              enqueueSnackbar("Le rapport a été créé avec succès.", {
                variant: "success",
              });
            } else {
              enqueueSnackbar(
                "Un problème a été rencontré lors de la création du rapport.",
                {
                  variant: "error",
                }
              );
            }
          }
        )
      );
    } else {
      dispatch(
        actionUpdateRapport(
          report.idreport,
          name,
          beginDate,
          goals,
          indicators,
          token,
          (is) => {
            if (is) {
              closeEdit();
              enqueueSnackbar("Le rapport a été modifier avec succès.", {
                variant: "success",
              });
            } else {
              enqueueSnackbar(
                "Un problème a été rencontré lors de la modification du rapport.",
                {
                  variant: "error",
                }
              );
            }
          }
        )
      );
    }
  };

  const closeEdit = () => {
    setName("");
    setBeginDate(null);
    setReport(null);
    setSelectedDashboard(null);
    setIndicators([]);
    setObjectifs([]);
    setSelectedIndicators([]);
    setSelectedObjectifs([]);
    setEditReportVisibility(false);
  };

  const onDeleteReport = (e) => {
    e.preventDefault();
    if (report) {
      dispatch(
        actionDeleteRapport(report.idreport, token, (is) => {
          if (is) {
            enqueueSnackbar("Le rapport a bien été supprimé.", {
              variant: "success",
            });
            setReport(null);
            setDeleteVisibility(false);
            closeEdit();
          } else {
            enqueueSnackbar(
              "Un problème a été rencontré lors de la suppression.",
              {
                variant: "error",
              }
            );
          }
        })
      );
    }
  };

  const initShareValue = () => {
    setTeamSelected([]);
    setShareDay(null);
    setShareTime(null);
    setEmails([]);
    setNotificationName("");
    setFrequency({ value: 1, label: "Journalier" });
  };

  const onEditNotification = (e) => {
    e.preventDefault();

    if (
      // !teamSelected ||
      Array.isArray(teamSelected) &&
      teamSelected.length === 0 &&
      // !emails ||
      Array.isArray(emails) &&
      emails.length === 0
    ) {
      enqueueSnackbar(
        "Veuillez sélectionner minimum une équipe ou entrer une adresse mail",
        {
          variant: "warning",
        }
      );
      return;
    }

    if (!notificationName) {
      enqueueSnackbar("Veuillez inserer un nom.", {
        variant: "warning",
      });
      return;
    }

    if (!shareTime) {
      enqueueSnackbar("Aucune date de diffusion n'a été insérée.", {
        variant: "warning",
      });
      return;
    }

    if (+frequency.value !== 1 && !shareDay) {
      enqueueSnackbar("Sélectionné le jour de la semaine.", {
        variant: "warning",
      });
      return;
    }

    if (editNotificationRole === 1) {
      dispatch(
        actionCreateReportNotification(
          JSON.stringify(emails),
          report.idreport,
          notificationName,
          true,
          frequency.value,
          shareDay ? shareDay.value : null,
          `${new Date(shareTime).getHours()}:${new Date(
            shareTime
          ).getMinutes()}`,
          teamSelected.map((item) => item.value),
          token,
          (is) => {
            if (is) {
              setShareVisibility(false);
              initShareValue();
              enqueueSnackbar("Insertion réalisé avec succès.", {
                variant: "success",
              });
            } else {
              enqueueSnackbar("Une erreur est survenue.", {
                variant: "error",
              });
            }
          }
        )
      );
    } else {
      dispatch(
        actionUpdateReportNotification(
          JSON.stringify(emails),
          reportNotification.idreportnotification,
          notificationName,
          true,
          frequency.value,
          shareDay ? shareDay.value : null,
          `${new Date(shareTime).getHours()}:${new Date(
            shareTime
          ).getMinutes()}`,
          teamSelected.map((item) => item.value),
          token,
          (is) => {
            if (is) {
              setShareVisibility(false);
              initShareValue();
              setReportNotification(null);
              enqueueSnackbar("Modification réalisé avec succès.", {
                variant: "success",
              });
            } else {
              enqueueSnackbar("Une erreur est survenue.", {
                variant: "error",
              });
            }
          }
        )
      );
    }
  };

  const onDeleteReportNotification = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (report) {
      dispatch(
        actionDeleteReportNotification(
          reportNotification.idreportnotification,
          token,
          (is) => {
            if (is) {
              setDeleteShareVisibility(false);
              setReportNotification(null);
              enqueueSnackbar("La diffussion à bien été supprimé.", {
                variant: "success",
              });
            } else {
              enqueueSnackbar("Une erreur est survenue.", {
                variant: "error",
              });
            }
          }
        )
      );
    }
  };

  const formatDate = (hours, minutes) => {
    let hour = null;
    let minute = null;
    if (hours < 10) {
      hour = `0${hours}`;
    } else if (hours === 0) {
      hour = null;
    } else {
      hour = hours;
    }

    if (minutes < 10) {
      minute = `0${minutes}`;
    } else if (minutes === 0) {
      minute = null;
    } else {
      minute = minutes;
    }

    return hour + "h " + minute;
  };

  const sendEmailNow = (idReportNotification) => {
    const key = enqueueSnackbar("Rapport en cours d'acheminement...", {
      variant: "info",
      persist: true,
    });

    dispatch(
      actionSendEmail(idReportNotification, token, (status) => {
        closeSnackbar(key);
        if (status) {
          enqueueSnackbar("Rapport envoyé avec succès.", {
            variant: "success",
          });
        } else {
          enqueueSnackbar(
            "Une erreur c'est produite lors de l'envoi du rapport.",
            {
              variant: "error",
            }
          );
        }
      })
    );
  };
  const date = new Date(new Date().getFullYear(), 0, 1);
  const formatted_date =
    date.getFullYear() + "-0" + (date.getMonth() + 1) + "-0" + date.getDate();
  const [dateDebut, setDateDebut] = useState(formatted_date);
  const [emails, setEmails] = useState([]);
  const [focused, setFocused] = useState(false);
  return (
    <>
      <Modal
        visible={editReportVisibility}
        title={editRole === 1 ? "Nouveau rapport" : "Modifier rapport"}
        onCancel={isAdd || isUpdate ? null : closeEdit}
        large
      >
        <form onSubmit={onEditReport}>
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-6 input-container">
                  <label for="nom" className="label-default">
                    Nom du rapport *
                  </label>
                  <span className="span-block">
                    <input
                      className="input-default inputCustom"
                      name="nom"
                      id="nom"
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      placeholder="Obligatoire"
                      required
                    />
                  </span>
                </div>
                <div className="col-md-6 input-container">
                  <label for="nom" className="label-default">
                    Date de début de récupération des données (Optionnelle)
                  </label>
                  <span className="span-block">
                    <input
                      className="input-default inputCustom"
                      name="date"
                      id="date"
                      type="date"
                      value={beginDate}
                      onChange={(e) => {
                        setBeginDate(e.target.value);
                      }}
                    />
                  </span>
                </div>
              </div>

              {editRole === 1 && (
                <div className="input-container">
                  <label for="nom" className="label-default">
                    Tableau sélectionné *
                  </label>
                  <span className="span-block">
                    <CustomSelect
                      classStyle={"input-default"}
                      id="refresh"
                      value={selectedDashboard}
                      style={{ textSize: "5px" }}
                      options={dashboards.map((dashboard) => ({
                        value: dashboard.dashboard.iddashboard,
                        label: dashboard.dashboard.name,
                      }))}
                      onChange={setSelectedDashboard}
                    />
                  </span>
                </div>
              )}
              {!(indicators && indicators.length >= 1) ? (
                <TextTitle>Aucun indicateur trouvé</TextTitle>
              ) : !isGetDashboard ? (
                <DragNDrop
                  indicators={indicators}
                  selectedIndicatorState={selectedIndicators}
                  setSelectedIndicatorState={setSelectedIndicators}
                />
              ) : (
                <LoaderBlock>
                  <PropagateLoader
                    color={
                      theme === "0"
                        ? "#fff"
                        : theme === "1"
                        ? "#1f2128"
                        : "#1f2128"
                    }
                    loading={true}
                    size={5}
                    speedMultiplier={0.75}
                  />
                </LoaderBlock>
              )}

              <br />
              {!(indicators && indicators.length >= 1) ? (
                <TextTitle>Aucun objectif trouvé</TextTitle>
              ) : !isGetDashboard ? (
                <ObjectifDragNDrop
                  objectifs={objectifs}
                  selectedGoalState={selectedObjectifs}
                  setSelectedGoalState={setSelectedObjectifs}
                />
              ) : (
                <LoaderBlock>
                  <PropagateLoader
                    color={
                      theme === "0"
                        ? "#fff"
                        : theme === "1"
                        ? "#1f2128"
                        : "#1f2128"
                    }
                    loading={true}
                    size={5}
                    speedMultiplier={0.75}
                  />
                </LoaderBlock>
              )}
            </div>
            <div className="col-md-12 wrap-btn-modal">
              <ButtonGroup>
                <button
                  className="btn-border close-btn-submit"
                  type="reset"
                  onClick={isAdd || isUpdate ? null : closeEdit}
                >
                  Annuler
                </button>
                <button
                  type="submit"
                  className="btn-full"
                  disabled={isAdd || isUpdate}
                >
                  {isAdd || isUpdate
                    ? "Loading..."
                    : editRole === 1
                    ? "Ajouter le rapport"
                    : "Enregistrer les modifications"}
                </button>
              </ButtonGroup>
            </div>
          </div>
        </form>
      </Modal>

      <Modal
        visible={showVisibility}
        title={report?.name}
        onCancel={
          isLoading
            ? null
            : () => {
                setShowVisibility(false);
                closeEdit();
              }
        }
        large
      >
        <form>
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-6 input-container">
                  <label for="nom" className="label-default">
                    Nom du rapport
                  </label>
                  <span className="span-block">
                    <input
                      className="input-default inputCustom"
                      type="text"
                      disabled
                      value={report?.name}
                    />
                  </span>
                </div>
                <div className="col-md-6 input-container">
                  <label for="nom" className="label-default">
                    Date de début de récupération des données
                  </label>
                  <span className="span-block">
                    <input
                      className="input-default inputCustom"
                      disabled
                      type={"date"}
                      value={beginDate}
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <SelectedIndicator
                beginDate={report?.beginDate || dashboard?.beginDate}
                indicators={
                  report && report.indicatorReports
                    ? report.indicatorReports.map((item) => item.indicator)
                    : []
                }
              />
            </div>
            <div className="col-md-12">
              <SelectedObjectifs goals={selectedObjectifs} />
            </div>
            <div className="col-md-12" style={{ marginTop: 10 }}>
              <h2 className="label-default">Liste des diffusions</h2>
              <Row>
                <AddCard
                  style={
                    theme === "0"
                      ? {
                          backgroundColor: "#323541",
                        }
                      : {
                          backgroundColor: "#E0E0E0",
                        }
                  }
                  onClick={() => {
                    initShareValue();
                    setEditNotificationRole(1);
                    setShareVisibility(true);
                  }}
                >
                  <SubTitle
                    style={
                      theme === "0"
                        ? {
                            color: "#323541",
                          }
                        : {
                            color: "#E0E0E0",
                          }
                    }
                  >
                    Partager
                  </SubTitle>
                  <ShareOutlined
                    style={{
                      color: "#54B7ED",
                    }}
                  />
                </AddCard>
                {report?.reportNotifications.map((report, index) => (
                  <CardSUpdatehare key={index + "2z"}>
                    <DeleteButton
                      onClick={(e) => {
                        e.preventDefault();
                        setDeleteShareVisibility(true);
                        setReportNotification(report);
                      }}
                    >
                      <CloseOutlined color="white" fontSize="25px" />
                    </DeleteButton>
                    <CardShareHeader>
                      <Image src={Planche2} />
                    </CardShareHeader>
                    <CardShareFooter
                      style={
                        theme === "0"
                          ? {
                              backgroundColor: "#323541",
                            }
                          : {
                              backgroundColor: "#E0E0E0",
                            }
                      }
                    >
                      <SubTitleShare className="title-4">
                        Diffusion : {report?.name}
                      </SubTitleShare>
                      <SubTitleShare
                        className="title-4"
                        style={{
                          fontWeight: "400",
                        }}
                      >
                        Partagé à{" "}
                        {report.teamReportNotifications.length == 0
                          ? ""
                          : report.teamReportNotifications.length <= 1
                          ? report.teamReportNotifications.length + " équipe "
                          : report.teamReportNotifications.length + " équipes "}
                        {JSON.parse(report.emails).length >= 1 &&
                        report.teamReportNotifications.length >= 1
                          ? " , "
                          : ""}
                        {JSON.parse(report.emails).length == 0
                          ? ""
                          : JSON.parse(report.emails).length <= 1
                          ? JSON.parse(report.emails).length + " mail"
                          : JSON.parse(report.emails).length + " mails"}
                      </SubTitleShare>
                      <CardShareContent>
                        <DateBox>
                          <CalendarTodayOutlined
                            style={{
                              fontSize: 20,
                              color: "#666A7A",
                            }}
                          />
                          {+report?.frequency === 1 ? (
                            <ParagraphShare className="title-4">
                              {`${
                                +report.frequency === 1
                                  ? "Journalier"
                                  : "Hebdomadaire"
                              } à ${
                                report?.broadcasttime &&
                                formatDate(
                                  +report?.broadcasttime.split(":")[0],
                                  +report?.broadcasttime.split(":")[1]
                                )
                              }`}
                            </ParagraphShare>
                          ) : (
                            <ParagraphShare className="title-4">
                              {`Chaque ${days[+report?.broadcastday]} à ${
                                report?.broadcasttime &&
                                formatDate(
                                  +report?.broadcasttime.split(":")[0],
                                  +report?.broadcasttime.split(":")[1]
                                )
                              }`}
                            </ParagraphShare>
                          )}
                        </DateBox>
                        <EditOutlined
                          className="title-4"
                          onClick={() => {
                            setEmails(
                              report.emails ? JSON.parse(report.emails) : []
                            );
                            setShareVisibility(true);
                            setEditNotificationRole(2);
                            setReportNotification(report);
                            const teams = [];
                            for (
                              let i = 0;
                              i < report.teamReportNotifications.length;
                              i++
                            ) {
                              const teamReportNotification =
                                report.teamReportNotifications[i];
                              teams.push({
                                value: teamReportNotification.idteam,
                                label: teamReportNotification.team.name,
                              });
                            }
                            setTeamSelected(teams);
                            if (report.broadcastday !== null) {
                              setShareDay({
                                value: +report.broadcastday,
                                label: days[+report.broadcastday],
                              });
                            }
                            setNotificationName(report.name);
                            setFrequency({
                              value: +report.frequency,
                              label:
                                +report.frequency === 1
                                  ? "Journalier"
                                  : "Hebdomadaire",
                            });
                            const date = new Date();
                            date.setHours(+report.broadcasttime.split(":")[0]);
                            date.setMinutes(
                              +report.broadcasttime.split(":")[1]
                            );
                            setShareTime(moment(date));
                          }}
                          style={{
                            fontSize: 20,
                            color: "#54B7ED",
                            margin: "0 5px",
                            cursor: "pointer",
                          }}
                        />
                        <SendOutlined
                          className="title-4"
                          style={{
                            fontSize: 20,
                            color: "#54B7ED",
                            margin: "0 5px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            sendEmailNow(report.idreportnotification);
                          }}
                        />
                      </CardShareContent>
                    </CardShareFooter>
                  </CardSUpdatehare>
                ))}
              </Row>
            </div>
            <div className="col-md-12 wrap-btn-modal">
              <ButtonGroup>
                <button
                  className="btn-border close-btn-submit"
                  onClick={
                    isLoading
                      ? null
                      : () => {
                          setShowVisibility(false);
                          closeEdit();
                        }
                  }
                >
                  Fermer
                </button>
              </ButtonGroup>
            </div>
          </div>
        </form>
      </Modal>

      <Modal
        visible={shareVisibility}
        title={
          editNotificationRole === 1
            ? "Ajouter une notification"
            : "Modifier la notification"
        }
        onCancel={
          isLoading
            ? null
            : () => {
                setShareVisibility(false);
                initShareValue();
              }
        }
      >
        <form onSubmit={isLoading ? null : (e) => onEditNotification(e)}>
          <div className="row">
            <div className="col-md-12">
              <TeamsBox>
                <ColumnBox>
                  <SmallBox>
                    <Text className="label-default">Nom de diffussion * </Text>
                    <input
                      style={{
                        height: "40px",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        marginBottom: "7px",
                      }}
                      className="input-default inputCustom"
                      name="nom"
                      id="nom"
                      type="text"
                      value={notificationName}
                      onChange={(e) => setNotificationName(e.target.value)}
                      placeholder="Obligatoire"
                      required
                    />
                  </SmallBox>
                </ColumnBox>

                <ColumnBox>
                  <SmallBox>
                    <Text className="label-default">
                      Sélectionner équipe(s)
                    </Text>
                    <CustomSelect
                      classStyle={"input-default"}
                      id="refresh"
                      style={{ textSize: "5px" }}
                      value={teamSelected}
                      options={teams.map((item) => {
                        return { value: item.idteam, label: item.name };
                      })}
                      onChange={(e) => setTeamSelected([...e])}
                      isMulti={true}
                    />
                  </SmallBox>
                </ColumnBox>
                <ColumnBox>
                  <SmallBox>
                    <Text className="label-default">
                      Entrez les adresses mails (Appuyez Entrez pour rajouter)
                    </Text>
                    <ReactMultiEmail
                      placeholder="Entrez les addresse mails"
                      emails={emails}
                      onChange={(email) => {
                        setEmails([...email]);
                      }}
                      getLabel={(email, index, removeEmail) => {
                        return (
                          <div data-tag key={index}>
                            {email}
                            <span
                              data-tag-handle
                              onClick={() => removeEmail(index)}
                              style={{
                                color: "#444",
                                fontWeight: "bold",
                                fontSize: 14,
                              }}
                            >
                              x
                            </span>
                          </div>
                        );
                      }}
                    />
                  </SmallBox>
                </ColumnBox>
                <ColumnBox>
                  <SmallBox>
                    <Text className="label-default">Fréquence de partage</Text>
                    <CustomSelect
                      id="refresh"
                      style={{ fontSize: "5px" }}
                      value={frequency}
                      options={[
                        { value: 1, label: "Journalier" },
                        { value: 2, label: "Hebdomadaire" },
                      ]}
                      onChange={(e) => setFrequency(e)}
                    />
                  </SmallBox>
                  <h2 className="label-default" style={{ margin: "7px 0 5px" }}>
                    {frequency.label === "Journalier"
                      ? "Heure de partage"
                      : "Jour et heure de partage"}
                  </h2>
                  {frequency.label === "Journalier" ? (
                    <RowBox>
                      <SmallBox>
                        <TimePicker
                          onSelect={(e) => setShareTime(e)}
                          placeholder="Heure"
                          value={shareTime}
                          style={{ height: 40, borderRadius: 5 }}
                          format={"HH:mm"}
                          minuteStep={30}
                          showNow={false}
                          onChange={(e) => setShareTime(e)}
                        />
                      </SmallBox>
                    </RowBox>
                  ) : (
                    <div className="row">
                      <div className="col-md-6">
                        <CustomSelect
                          id="refresh"
                          style={{ fontSize: "5px" }}
                          value={shareDay}
                          options={days.map((day, index) => ({
                            value: index,
                            label: day,
                          }))}
                          onChange={(e) => setShareDay(e)}
                        />
                      </div>
                      <div className="col-md-6">
                        <div className="row">
                          <p
                            style={{
                              marginRight: "10px",
                              textAlign: "center",
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            à
                          </p>
                          <TimePicker
                            onSelect={(e) => setShareTime(e)}
                            placeholder="Heure"
                            value={shareTime}
                            style={{ height: 40, borderRadius: 5 }}
                            format={"HH:mm"}
                            showNow={false}
                            minuteStep={30}
                            onChange={(e) => {
                              setShareTime(e);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </ColumnBox>
              </TeamsBox>
            </div>
            <div className="col-md-12 wrap-btn-modal">
              <ButtonGroup>
                <button
                  className="btn-border close-btn-submit"
                  type="reset"
                  onClick={
                    isLoading
                      ? null
                      : () => {
                          setShareVisibility(false);
                          initShareValue();
                        }
                  }
                >
                  Annuler
                </button>
                <button type="submit" className="btn-full" disabled={isLoading}>
                  {isLoading ? "Loading..." : "Enregistrer"}
                </button>
              </ButtonGroup>
            </div>
          </div>
        </form>
      </Modal>

      <Modal
        visible={deleteVisibility}
        title={`Confirmer la suppression du rapport '${report?.name}'`}
        onCancel={isDelete ? null : () => setDeleteVisibility(false)}
      >
        <div className="row">
          <div className="col-md-12 wrap-btn-modal">
            <div className="btn-container">
              <button
                className="btn-border close-btn-submit"
                type="reset"
                onClick={isDelete ? null : () => setDeleteVisibility(false)}
              >
                Annuler
              </button>
              <button
                className="btn-full"
                disabled={isDelete}
                onClick={onDeleteReport}
              >
                {isDelete ? "Loading..." : "Supprimer"}
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        visible={deleteShareVisibility}
        title={`Confirmer la suppression de la diffusion`}
        onCancel={isLoading ? null : () => setDeleteShareVisibility(false)}
      >
        <div className="row">
          <div className="col-md-12 wrap-btn-modal">
            <div className="btn-container">
              <button
                className="btn-border close-btn-submit"
                type="reset"
                onClick={
                  isLoading ? null : () => setDeleteShareVisibility(false)
                }
              >
                Annuler
              </button>
              <button
                className="btn-full"
                disabled={isLoading}
                onClick={onDeleteReportNotification}
              >
                {isLoading ? "Loading..." : "Supprimer"}
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <Header />
      <main className="homemain">
        <div className="container">
          <div className="head">
            <h1 className="title-1">Rapports</h1>
          </div>

          <Row>
            {!isGet ? null : (
              <>
                {[1, 2, 3, 4].map(() => (
                  <ContentLoader
                    height={"220px"}
                    width={"100%"}
                    speed={1}
                    backgroundColor={"#80808075"}
                    foregroundColor={"#a9a9a975"}
                    className="planche"
                  >
                    <rect
                      x="0"
                      y="0"
                      rx="9px"
                      ry="9px"
                      width="100%"
                      height="160px"
                    />
                    <rect
                      x="8px"
                      y="170px"
                      rx="9px"
                      ry="9px"
                      width="70%"
                      height="13px"
                    />
                  </ContentLoader>
                ))}
              </>
            )}
            {!isGet && (
              <div
                className="ajout-planche to_that"
                onClick={() => {
                  setEditReportVisibility(true);
                  setEditRole(1);
                }}
                style={
                  theme === "0"
                    ? {
                        background: "transparent",
                        margin: 0,
                        marginBottom: 8,
                        border: "1px dashed #FFF",
                        height: 220,
                      }
                    : {
                        background: "#E0E0E0",
                        margin: 0,
                        marginBottom: 8,
                        border: "1px solid #E0E0E0",
                        height: 220,
                      }
                }
              >
                <span
                  title="Ajouter un tableau"
                  target="_self"
                  class="bind_me"
                  style={{
                    fontWeight: "bold",
                    marginBottom: 8,
                  }}
                >
                  Paramétrer un nouveau rapport
                </span>
                <span
                  class="add-card"
                  style={{
                    color: "rgb(84, 183, 237)",
                  }}
                >
                  <i class="fa fa-plus"></i>
                </span>
              </div>
            )}
            {!isGet &&
              reports.map((report, index) => (
                <>
                  <Card
                    name={report.name}
                    updateAt={new Date(report?.updated_at).toLocaleString(
                      "fr-FR",
                      {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                      }
                    )}
                    onDelete={() => {
                      setDeleteVisibility(true);
                      setReport(report);
                    }}
                    onShowDetail={() => {
                      setReport(report);
                      setShowVisibility(true);
                    }}
                    onUpdate={() => {
                      setReport(report);
                      setEditRole(2);
                      setEditReportVisibility(true);
                    }}
                  />
                </>
              ))}
          </Row>
        </div>
      </main>
      <Footer />
    </>
  );
}

const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(200px, 300px));
  gap: 10px;
  height: auto;

  @media (max-width: 1100px) {
    grid-template-columns: repeat(3, minmax(200px, 400px));
  }
  @media (max-width: 800px) {
    grid-template-columns: repeat(2, minmax(200px, 400px));
  }
  @media (max-width: 550px) {
    grid-template-columns: repeat(1, minmax(200px, 550px));
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  gap: 10px;
  padding: 20px 0;
`;

const Image = styled.img`
  background-position-y: center;
  background-size: cover;
  background-position-x: center;
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
`;

const TextTitle = styled.h2`
  width: 100%;
  height: 60px;
  text-align: center;
  line-height: 60px;
  font-weight: 500;
  font-size: 1rem;
  background-color: #ebebeb;
  border-radius: 5px;
`;

const AddCard = styled.div`
  border-radius: 15px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #e0e0e0;
  cursor: pointer;
  height: 250px;
`;

const SubTitle = styled.h3`
  font-weight: 600;
  font-size: 0.8rem;
`;

const Img = styled.img`
  width: 40px;
  height: 40px;
  object-fit: cover;
  object-position: center;
`;

const CheckImg = styled.img`
  width: 15px;
  height: 15px;
  object-fit: contain;
  object-position: center;
`;

const SmallBox = styled.div`
  min-width: 200px;
  max-width: 420px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 100px;
  gap: 15px;
`;

const CardShare = styled.div`
  background-color: #fff;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  transition: linear 0.4s;
  cursor: pointer;
  &:hover {
    background-color: #54b7ed;
  }
`;

const ColumnBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
`;

const RowBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 420px;
`;

const TeamsBox = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(5, auto);
  gap: 10px;
  margin-top: 20px;
`;

const SwitchTeams = styled.div`
  display: flex;
  gap: 10px;
`;

const Text = styled.h2`
  // font-weight: bold;
`;

const AddText = styled.h2`
  border: 1px solid #54b7ed;
  color: #54b7ed;
  padding: 6px 10px;
  font-size: 0.9rem;
  border-radius: 8px;
  font-weight: 500;
  width: fit-content;
`;

const DateBox = styled.div`
  display: flex;
  column-gap: 5px;
  align-items: center;
`;

const CardShareFooter = styled.div`
  height: 45%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 5px;
  padding: 10px;
`;

const SubTitleShare = styled.h3`
  font-weight: 600;
  font-size: 0.8rem;
`;

const ParagraphShare = styled.p`
  font-size: 0.7rem;
  color: #666a7a;
  color: ${theme === "0" ? "#fff" : "#000"};
`;

const CardSUpdatehare = styled.div`
  border-radius: 15px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  height: 250px;
  position: relative;
`;

const CardShareHeader = styled.div`
  height: 55%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CardShareContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 4px;
`;

const CardSocial = styled.div`
  background-color: #fff;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  transition: linear 0.4s;
  cursor: pointer;
  &:hover {
    background-color: #54b7ed;
  }
`;

const DeleteButton = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  width: 30px;
  height: 25px;
  border-radius: 5px;
  background-color: #ff725e;
  color: #6d1f1f;
  cursor: pointer;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoaderBlock = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IOSSwitch = MUIStyled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#54B7ED",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
    backgroundColor: "#FFFFFF",
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#54B7ED",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));
