import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import { useDispatch } from "react-redux";
import useState from "react-usestateref";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

export default function Team() {
  const { t } = useTranslation();
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      {/* <Header/> */}

      <main>
        <div class="head">
          <h1 class="title-1">Les Membres</h1>

          <div class="wrap-select types">
            <div>
              <div
                className="add_list"
                style={{ marginRight: "10px", width: "200px" }}
              ></div>
            </div>
          </div>
        </div>

        {/* <Box sx={{ width: '100%', typography: 'body1' }}>
				<TabContext value={value}>
					<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
						<TabList onChange={handleChange} aria-label="lab API tabs example">
							<Tab label="Item One" value="1" />
							<Tab label="Item Two" value="2" />
							<Tab label="Item Three" value="3" />
						</TabList>
					</Box>
					<TabPanel value="1">Item One</TabPanel>
					<TabPanel value="2">Item Two</TabPanel>
					<TabPanel value="3">Item Three</TabPanel>
				</TabContext>
			</Box> */}
      </main>
    </div>
  );
}
