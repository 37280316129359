import React, { useEffect, useState } from "react";
import { DatePicker, Select } from "antd";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { actionGetPeriodByUserH } from "../../redux/actions/periodAction";
const { RangePicker } = DatePicker;

export default function CalendarView({
  value,
  interval,
  onChange,
  period,
  onPeriodChange,
  selectStyle,
  calendarStyle,
}) {
  const dispatch = useDispatch();
  const periodes = useSelector((state) => state.period.periodes);

  useEffect(() => {
    const data = localStorage.getItem("data");
    if (data) {
      dispatch(
        actionGetPeriodByUserH(
          JSON.parse(data).userId,
          "Bearer " + JSON.parse(data).token,
          (status) => {}
        )
      );
    }
  }, [dispatch]);

  const onChangeValue = (value) => {
    if (value !== "0") {
      let a = moment();
      let b = moment();
      switch (value) {
        case "1":
          a.hours(0);
          b.hours(23);
          a.minutes(0);
          b.minutes(59);
          a.seconds(0);
          b.seconds(59);
          a.milliseconds(0);
          b.milliseconds(0);
          break;
        case "2":
          a.hours(0);
          b.hours(23);
          a.minutes(0);
          b.minutes(59);
          a.seconds(0);
          b.seconds(59);
          a.milliseconds(0);
          b.milliseconds(0);
          a.weekday(1);
          b.weekday(7);
          break;
        case "3":
          a.hours(0);
          b.hours(0);
          a.minutes(0);
          b.minutes(0);
          a.seconds(0);
          b.seconds(0);
          a.milliseconds(0);
          b.milliseconds(0);
          a.date(1);
          b.date(1);
          b.month(b.month() + 1);
          b.dayOfYear(b.dayOfYear() - 1);
          break;
        case "4":
          a.hours(0);
          b.hours(0);
          a.minutes(0);
          b.minutes(0);
          a.seconds(0);
          b.seconds(0);
          a.milliseconds(0);
          b.milliseconds(0);
          // a.date(1);
          // b.date(1);
          // a.month(a.month() - 2);
          // b.month(b.month() + 1);
          if (a.quarter() === 1) {
            a.month(0);
            b.month(2);
            a.date(1);
            b.date(31);
          } else if (a.quarter() === 2) {
            a.month(3);
            b.month(5);
            a.date(1);
            b.date(30);
          } else if (a.quarter() === 3) {
            a.month(6);
            b.month(8);
            a.date(1);
            b.date(30);
          } else {
            a.month(9);
            b.month(11);
            a.date(1);
            b.date(31);
          }
          break;
        case "5":
          a.hours(0);
          b.hours(0);
          a.minutes(0);
          b.minutes(0);
          a.seconds(0);
          b.seconds(0);
          a.milliseconds(0);
          b.milliseconds(0);
          a.date(1);
          b.date(1);
          if (a.month() > 5) {
            a.month(6);
            b.month(11);
            b.date(31);
          } else {
            a.month(0);
            b.month(6);
            b.dayOfYear(b.dayOfYear() - 1);
          }
          // a.month(a.month() - 5);
          // b.month(b.month() + 1);
          break;
        case "6":
          a.hours(0);
          b.hours(0);
          a.minutes(0);
          b.minutes(0);
          a.seconds(0);
          b.seconds(0);
          a.milliseconds(0);
          b.milliseconds(0);
          a.date(1);
          a.month(0);
          b.month(11);
          b.date(31);
          break;
        default:
          a = moment();
          b = moment();
          break;
      }
      if (onChange) {
        onChange([a, b], value);
      }
    } else {
      if (onChange) {
        onChange([null, null], "0");
      }
    }
  };

  const onChangeInterval = (interval, dateStrings, info) => {
    onPeriodChange && onPeriodChange(null);
    if (interval) {
      const [debut, fin] = interval;
      if (onChange) {
        onChange([debut, fin], "0", null);
      }
    } else {
      if (onChange) {
        onChange([null, null], "0", null);
      }
    }
  };

  return (
    <div>
      <Select
        defaultValue={"0"}
        className="color-black"
        style={selectStyle || { width: 150, marginRight: "5px" }}
        value={period ? `id-${period}` : value}
        onChange={(value) => {
          const values = value.split("-");
          if (values.length > 1) {
            onPeriodChange && onPeriodChange(values[1]);
            const period = periodes.find(
              (item) => +item.idperiod === +values[1]
            );
            if (period) {
              onChange(
                [
                  moment(new Date(period.startdate)),
                  moment(new Date(period.enddate)),
                ],
                null,
                values[1]
              );
            }
          } else {
            onPeriodChange && onPeriodChange(null);
            onChangeValue(value);
          }
        }}
      >
        <Select.Option value={"0"}>Personnalisée</Select.Option>
        <Select.Option value={"1"}>Aujourd'hui</Select.Option>
        <Select.Option value={"2"}>Cette semaine</Select.Option>
        <Select.Option value={"3"}>Ce mois</Select.Option>
        <Select.Option value={"4"}>Ce trimestre</Select.Option>
        <Select.Option value={"5"}>Ce semestre</Select.Option>
        <Select.Option value={"6"}>Cette année</Select.Option>
        {periodes.map((item) => (
          <Select.Option value={`id-${item.idperiod}`}>
            {item.periodName}
          </Select.Option>
        ))}
      </Select>
      <RangePicker
        placeholder={["Du", "Au"]}
        className={"color-black"}
        value={interval}
        onCalendarChange={onChangeInterval}
        style={calendarStyle || { width: 250 }}
        format={"DD-MM-YYYY"}
      />
    </div>
  );
}
