import React from "react";
import styled from "styled-components";
import { Tooltip } from "antd";

const Container = styled.div`
  border-radius: 10px;
  height: 20px;
  width: 100%;
  background-color: #45445940;
  position: relative;
  user-select: none;
  overflow: hidden;
`;

const Fill = styled.div`
  height: 100%;
  border-radius: 10px;
  width: ${(props) => props.fill}%;
  background-color: ${(props) => props.color};
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-left: 8px;
  padding-right: 8px;
`;

const Diver = styled.div`
  height: 10px;
  width: 2px;
  border-radius: 5px;
  background-color: white;
`;

const Percent = styled.div`
  color: white;
  font-size: 14px;
  text-align: center;
  vertical-align: center;
  flex: 1;
`;

const Text = styled.div`
  color: white;
  font-size: 14px;
  text-align: center;
  vertical-align: center;
  flex: 1;
  position: absolute;
  bottom: 50%;
  transform: translateY(50%);
  right: 10px;
`;

export default function Progress({ percent, color, text }) {
  return (
    <Tooltip title={percent + "%"}>
      <Container>
        {+percent > 0 && (
          <Fill fill={percent} color={color || "#3498db"}>
            {+percent > 10 && <Percent title={percent}>{percent} %</Percent>}
            <Diver />
          </Fill>
        )}
        <Text>{text}</Text>
      </Container>
    </Tooltip>
  );
}
