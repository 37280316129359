import host from "./host";
export const ACTION_SET_RAPPORT = "ACTION_SET_RAPPORT";
export const ACTION_SET_RAPPORTS = "ACTION_SET_RAPPORTS";
export const ACTION_ADD_RAPPORT = "ACTION_ADD_RAPPORT";
export const ACTION_SET_RAPPORT_NOTIFICATION =
  "ACTION_SET_RAPPORT_NOTIFICATION";
export const ACTION_REMOVE_RAPPORT = "ACTION_REMOVE_RAPPORT";
export const ACTION_IS_GET_RAPPORT = "ACTION_IS_GET_RAPPORT";
export const ACTION_IS_UPDATE_RAPPORT = "ACTION_IS_UPDATE_RAPPORT";
export const ACTION_IS_DELETE_RAPPORT = "ACTION_IS_DELETE_RAPPORT";
export const ACTION_IS_ADD_RAPPORT = "ACTION_IS_ADD_RAPPORT";
export const ACTION_SET_LOADING = "ACTION_SET_LOADING";

// * --------- Begin actions --------

export const actionIsGetRapport = (payload) => {
  return { type: ACTION_IS_GET_RAPPORT, payload };
};

export const actionIsUpdateRapport = (payload) => {
  return { type: ACTION_IS_UPDATE_RAPPORT, payload };
};

export const actionIsDeleteRapport = (payload) => {
  return { type: ACTION_IS_DELETE_RAPPORT, payload };
};

export const actionIsAddRapport = (payload) => {
  return { type: ACTION_IS_ADD_RAPPORT, payload };
};

export const actionSetRapport = (payload) => {
  return { type: ACTION_SET_RAPPORT, payload };
};

export const actionSetRapports = (payload) => {
  return { type: ACTION_SET_RAPPORTS, payload };
};

export const actionRemoveRapport = (payload) => {
  return { type: ACTION_REMOVE_RAPPORT, payload };
};

export const actionAddRapport = (payload) => {
  return { type: ACTION_ADD_RAPPORT, payload };
};

export const actionSetRapportNotification = (payload) => {
  return { type: ACTION_SET_RAPPORT_NOTIFICATION, payload };
};

export const actionSetLoading = (payload) => {
  return { type: ACTION_SET_LOADING, payload };
};

// * --------- End actions --------

// * --------- Begin --------

export const actionGetRapportsByUser = (id, token) => {
  return (dispatch) => {
    dispatch(actionIsGetRapport(true));

    let myHeaders = new Headers();
    myHeaders.append("Authorization", token);

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${host}/report/get-by-user/${id}`, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) {
          if (response.status === 401) {
            localStorage.removeItem("data");
            window.location.replace("/");
          }
          return null;
        }
        return response.json();
      })
      .then((result) => {
        if (result) {
          dispatch(actionSetRapports(result));
        }
      })
      .catch(() => {})
      .then(() => dispatch(actionIsGetRapport(false)));
  };
};

export const actionCreateRapport = (
  name,
  beginDate,
  iddashboard,
  goals,
  indicators,
  iduser,
  token,
  callBack
) => {
  return (dispatch) => {
    dispatch(actionIsAddRapport(true));
    let myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    let raw = JSON.stringify({
      name,
      iduser,
      beginDate,
      iddashboard,
      goals,
      indicators,
    });

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${host}/report/create`, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) {
          callBack(false);
          return null;
        }
        callBack(true);
        return response.json();
      })
      .then((result) => {
        if (result) {
          dispatch(actionAddRapport(result));
        }
      })
      .catch(() => {
        callBack(false);
      })
      .then(() => dispatch(actionIsAddRapport(false)));
  };
};

export const actionCreateReportNotification = (
  emails,
  idreport,
  name,
  automatic,
  frequency,
  broadcastday,
  broadcasttime,
  idteams,
  token,
  callBack
) => {
  return (dispatch) => {
    dispatch(actionSetLoading(true));
    let myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    let raw = JSON.stringify({
      idreport,
      name,
      automatic,
      frequency,
      broadcastday,
      broadcasttime,
      idteams,
      emails,
    });
    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${host}/reportNotification/create`, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) {
          callBack(false);
          return null;
        }
        callBack(true);
        return response.json();
      })
      .then((result) => {
        if (result) {
          dispatch(
            actionSetRapportNotification({
              idreport,
              type: "ADD",
              data: result,
            })
          );
        }
      })
      .catch(() => {
        callBack(false);
      })
      .then(() => dispatch(actionSetLoading(false)));
  };
};

export const actionUpdateReportNotification = (
  emails,
  id,
  name,
  automatic,
  frequency,
  broadcastday,
  broadcasttime,
  idteams,
  token,
  callBack
) => {
  return (dispatch) => {
    dispatch(actionSetLoading(true));
    let myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    let raw = JSON.stringify({
      name,
      automatic,
      frequency,
      broadcastday,
      broadcasttime,
      idteams,
      emails,
    });
    let requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${host}/reportNotification/update/${id}`, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) {
          callBack(false);
          return null;
        }
        callBack(true);
        return response.json();
      })
      .then((result) => {
        if (result) {
          dispatch(
            actionSetRapportNotification({
              id,
              type: "UPDATE",
              data: result,
            })
          );
        }
      })
      .catch(() => {
        callBack(false);
      })
      .then(() => dispatch(actionSetLoading(false)));
  };
};

export const actionSendEmail = (id, token, callBack) => {
  return (dispatch) => {
    let myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${host}/reportNotification/send-mail/${id}`, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) {
          callBack(false);
          return null;
        }
        callBack(true);
        return response.json();
      })
      .then(() => {})
      .catch(() => {
        callBack(false);
      });
  };
};

export const actionDeleteReportNotification = (id, token, callback) => {
  return (dispatch) => {
    dispatch(actionSetLoading(true));
    let myHeaders = new Headers();
    myHeaders.append("Authorization", token);

    let requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${host}/reportNotification/delete/${id}`, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) {
          callback(false);
          return null;
        } else {
          callback(true);
          dispatch(
            actionSetRapportNotification({
              id,
              type: "DELETE",
              data: null,
            })
          );
        }
      })
      .catch((e) => {
        console.log("e", e);
        callback(false);
      })
      .then(() => dispatch(actionSetLoading(false)));
  };
};

export const actionUpdateRapport = (
  id,
  name,
  beginDate,
  goals,
  indicators,
  token,
  callback
) => {
  return (dispatch) => {
    dispatch(actionIsUpdateRapport(true));

    let myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    let raw = JSON.stringify({
      name,
      beginDate,
      goals,
      indicators,
    });

    let requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${host}/report/update/${id}`, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) {
          callback(false);
          return null;
        }
        callback(true);
        return response.json();
      })
      .then((result) => {
        if (result) {
          dispatch(actionSetRapport({ ...result }));
        }
      })
      .catch(() => {
        callback(false);
      })
      .then(() => {
        dispatch(actionIsUpdateRapport(false));
      });
  };
};

export const actionDeleteRapport = (id, token, callback) => {
  return (dispatch) => {
    dispatch(actionIsDeleteRapport(true));
    let myHeaders = new Headers();
    myHeaders.append("Authorization", token);

    let requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${host}/report/delete/${id}`, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) {
          callback(false);
          return null;
        } else {
          callback(true);
          dispatch(actionRemoveRapport(id));
        }
      })
      .catch(() => {
        callback(false);
      })
      .then(() => dispatch(actionIsDeleteRapport(false)));
  };
};

// * --------- End --------
