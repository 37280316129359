import React from "react";
import {
  SortableContainer,
  SortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import { MenuOutlined } from "@ant-design/icons";
import { MdPublic, MdPublicOff } from "react-icons/md";

export default function ViewContainer({ items, setItems, onDelete, onUpdate }) {
  const onSortEnd = ({ oldIndex, newIndex }) => {
    setItems(arrayMoveImmutable(items, oldIndex, newIndex));
  };

  const DragHandle = sortableHandle(() => (
    <MenuOutlined style={{ cursor: "grab", color: "#999" }} />
  ));

  const Item = SortableElement((props) => <tr {...props} />);

  const Container = SortableContainer((props) => (
    <tbody {...props}>
      {props.items.map((item, index) => (
        <Item key={`item-${index}`} index={index}>
          <>
            <td className="table-style">
              <DragHandle />
            </td>
            <td
              className="table-style"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {item.name}
              {item.readonly ? (
                <MdPublic size={20} />
              ) : (
                <MdPublicOff size={20} />
              )}{" "}
            </td>
            <td className="table-style">
              <ul className="actions">
                <li>
                  <a
                    href="#"
                    className="btn-simple-round modifier"
                    onClick={() => onUpdate(item)}
                  >
                    <i className="fa-light fa-pen-to-square"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="btn-simple-round supprimer"
                    onClick={() => onDelete(item)}
                  >
                    <i className="fa-light fa-trash-can"></i>
                  </a>
                </li>
              </ul>
            </td>
          </>
        </Item>
      ))}
    </tbody>
  ));

  return (
    <>
      <div className="wrap-table">
        <table id="sources" className="display responsive">
          <Container
            items={items}
            axis="y"
            lockAxis="y"
            useDragHandle
            disableAutoscroll
            helperClass="row-dragging"
            onSortEnd={onSortEnd}
          />
        </table>
      </div>
    </>
  );
}
