import { Select } from "antd";
import { Button, Dropdown } from "antd";
import PrimeList from "../../Views/PrimeViews/PrimeList";
import ModalAddBonus from "../ModalAddBonus";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  actionDeleteBonus,
  actionGetBonusesByUser,
  actionUpdateBonus,
} from "../../../redux/actions/bonusAction";

const PrimesParametrees = () => {
  const { Option } = Select;
  const [show, setShow] = useState(false);
  const bonuses = useSelector((state) => state.bonus.bonuses);

  const dispatch = useDispatch();
  const idUser = useSelector((state) => state.user.id);
  const token = useSelector((state) => state.user.token);
  //equip items:
  const items = [
    {
      key: "1",
      label: (
        <b className="text-right text-smi text-foundation-blue-normal-active">
          Modifier la règle
        </b>
      ),
    },
    {
      key: "2",
      label: (
        <b className="text-right text-smi text-foundation-blue-normal-active">
          Supprimer la règle
        </b>
      ),
    },
  ];
  useEffect(() => {
    dispatch(
      actionGetBonusesByUser(idUser, token, (bonuses) => {
        if (bonuses) {
          // console.log("bonuses received", bonuses);
        }
      })
    );
  }, [dispatch, idUser, token]);
  return (
    <>
      <ModalAddBonus
        show={show}
        visibility={show}
        onCancel={() => setShow(!show)}
        large={true}
      />
      <div className="mt-7 inset-0 rounded-lg bg-white h-[99.57%] w-full shadow-[0px_2px_2px_rgba(0,_0,_0,_0.1),_0px_4px_20px_rgba(0,_0,_0,_0.1)] mb-4">
        <div className="flex-1 rounded-lg bg-white w-full overflow-hidden flex flex-col items-center justify-center py-4 px-4 box-border relative gap-4">
          <div className="w-full flex flex-row items-center justify-between">
            <div className="text-[#05BAF3] text-xl font-bold font-['Poppins']">
              Primes paramétrées {`(${bonuses.length})`}
            </div>
            <button
              type="button"
              onClick={() => setShow(true)}
              className="bg-[#05BAF3] flex justify-center w-[300px] text-white font-bold h-10 items-center rounded focus:outline-none transition-transform duration-300 transform hover:scale-105"
            >
              + Ajouter une prime
            </button>
            {/*             
            <div className="flex gap-2">
              <Select
                defaultValue="default"
                className="w-[12rem] shadow-md bg-white"
              >
                <Option value="default" disabled>
                  Types de prime
                </Option>
                <Option value="option1">prime 1</Option>
                <Option value="option2">prime 2</Option>
                <Option value="option3">prime 3</Option>
              </Select>
              <Select
                defaultValue="default"
                className="w-[12rem] shadow-md bg-white"
              >
                <Option value="default" disabled>
                  Statut de la prime
                </Option>
                <Option value="option1">statut 1</Option>
                <Option value="option2">statut 2</Option>
                <Option value="option3">statut 3</Option>
              </Select>
            </div> */}
          </div>
          <div className="w-full">
            <PrimeList />
          </div>
        </div>
      </div>
    </>
  );
};

export default PrimesParametrees;
