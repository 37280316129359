import { ACTION_IS_GET_BONUS_TRANSACTIONS, ACTION_SET_BONUS_TRANSACTIONS } from "../actions/bonusTransactionAction";


const initialState = {
    bonusTransactions: [],
    isGetBonusTransactions: false,
};

const bonusTransactionReducer = (state = initialState, action) => {
    switch (action.type) {

        case ACTION_SET_BONUS_TRANSACTIONS: {
            if (action.payload.type === "ALL") {
                return {
                    ...state,
                    bonusTransactions: [...action.payload.data],
                };

            }
            break;
        }


        default:
            return state;
    }
};

export default bonusTransactionReducer;
