import host from "./host";

// Action types
export const ACTION_SET_BONUS_TRANSACTIONS = 'ACTION_SET_BONUS_TRANSACTIONS';
export const ACTION_IS_GET_BONUS_TRANSACTIONS = "ACTION_IS_GET_BONUS_TRANSACTIONS";


// * --------- Begin actions --------
export const actionSetBonusTransactions = (payload) => {
    return { type: ACTION_SET_BONUS_TRANSACTIONS, payload };
};
export const actionIsGetBonusTransactions = (payload) => {
    return { type: ACTION_IS_GET_BONUS_TRANSACTIONS, payload };
};

export const actionGetBonusTransactionsByUser = (id, token, callback) => {
    return (dispatch) => {
        dispatch(actionIsGetBonusTransactions(true));

        let myHeaders = new Headers();
        myHeaders.append("Authorization", token);

        let requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };

        fetch(`${host}/bonus-transaction/get-by-user/${id}`, requestOptions)
            .then((response) => {
                if (response.status !== 200 && response.status !== 201) {
                    return null;
                }
                return response.json();
            })
            .then((result) => {
                console.log('result', result)
                if (result) {
                    dispatch(actionSetBonusTransactions({ type: "ALL", data: result.bonusTransactions }));
                    callback(200, result.bonusTransactions);
                } else {

                    callback(400);
                }
            })
            .catch((error) => {
                console.log('error', error)

                // callback(400);
            })
            .then(() => dispatch(actionIsGetBonusTransactions(false)));
    };
};