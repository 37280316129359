import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "./Modal";
import { Select, Input } from "antd";
import {
  actionGetGoalColumn,
  actionGetGoals,
} from "../../redux/actions/GoalAction";
import { actionGetTeamsByUser } from "../../redux/actions/teamAction";
import { actionUpdateRule } from "../../redux/actions/ruleAction";
import CalendarView from "./CalendarView";

import { actionGetPeriodByUser } from "../../redux/actions/periodAction";

import { enqueueSnackbar } from "notistack";
import { Popover } from "@mui/material";
import moment from "moment";

export default function ModalUpdateRule({ visibility, onCancel, bonus, rule }) {
  const { TextArea } = Input;
  const [ruleName, setRuleName] = useState("");
  const [formula, setFormula] = useState("");
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const dispatch = useDispatch();
  const isAddRule = useSelector((state) => state.rule.isAddRule);
  const isDeleteRule = useSelector((state) => state.rule.isDeleteRule);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const goals = useSelector((state) => state.goal.goals);
  const teams = useSelector((state) => state.team.teams);
  const token = useSelector((state) => state.user.token);
  const idUser = useSelector((state) => state.user.id);
  const [selectedGoal, setSelectedGoal] = useState(null);
  const [periode, setPeriode] = useState("0");
  const [interval, setInterval] = useState([null, null]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [users, setUsers] = useState([]);
  const [teamOrUser, setTeamOrUser] = useState("user");
  const [deleteVisibility, setDeleteVisibility] = useState(false);
  const [updateVisibility, setUpdateVisibility] = useState(false);
  const [selectedRule, setSelectedRule] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [goalCol, setGoalCol] = useState([]);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const currentGoalCol = goalCol.find((el) => el["id"] === selectedGoal);
  const [selectedGoalColumn, setSelectedGoalColumn] = useState(null);
  const [percent, setPercent] = useState("100");
  const [isFormulaValid, setIsFormulaValid] = useState(null);
  const bonuses = useSelector((state) => state.bonus.bonuses);
  const [ruleInBonus, setRuleInBonus] = useState(null);
  // const [periods, setPeriods] = useState([]);
  const [selectedPeriod, setselectedPeriod] = useState(null);
  // useEffect(() => {
  //   dispatch(
  //     actionGetPeriodByUser(idUser, token, (periods, data) => {
  //       if (periods) {
  //         setPeriods(data);
  //       }
  //     })
  //   );
  // }, []);
  useEffect(() => {
    if (selectedGoal && !currentGoalCol)
      dispatch(
        actionGetGoalColumn(selectedGoal, token, (data) => {
          if (typeof data === "object")
            setGoalCol([...goalCol, { id: selectedGoal, data: data.columns }]);
        })
      );
  }, [dispatch, idUser, token, selectedGoal, goalCol, currentGoalCol]);

  useEffect(() => {
    dispatch(
      actionGetGoals(idUser, token, (status) => {
        if (status === 200) {
        } else {
        }
      })
    );
    dispatch(actionGetTeamsByUser(idUser, token));
  }, [dispatch, idUser, token]);

  useEffect(() => {
    if (rule) {
      setRuleName(rule.ruleName);
      setSelectedGoal(rule.idgoal);
      setPercent(rule.percent);
      setFormula(rule.formula);
      setselectedPeriod(rule.idperiod);
      const startDate = new Date(rule.startdate).toISOString();
      const endDate = new Date(rule.enddate).toISOString();
      setStartDate(startDate);
      setEndDate(endDate);
      setInterval([moment(new Date(startDate)), moment(new Date(endDate))]);
    }
  }, [rule]);

  useEffect(() => {
    setUsers(
      Array.from(
        new Set(
          teams
            .flatMap((team) => team.userTeams.map((userTeam) => userTeam.user))
            .map((user) => user.id)
        ),
        (id) =>
          teams
            .flatMap((team) =>
              team.userTeams
                .filter((userTeam) => userTeam.user.id === id)
                .map((userTeam) => userTeam.user)
            )
            .pop()
      )
    );
  }, [teams]);

  useEffect(() => {
    if (!deleteVisibility && !updateVisibility) {
      // setRule(null);
    }
  }, [deleteVisibility, updateVisibility]);

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedGoalColumn(null);
  };
  const handleCurrentGoalCol = (column) => {
    setFormula((prevFormula) => prevFormula + `${column}`);
    setAnchorEl(null);
  };
  const formulaValidator = (formula, columns) => {
    const intitialisations = columns.map((item) => `var ${item} = 1;`);

    try {
      const dynamicInitializations = currentGoalCol.data.map(
        (col) => `var ${col} = 1;`
      );

      // eslint-disable-next-line no-eval
      const result = eval(
        `
        function getIndicatorValueByPeriod(a,b,c){return 1};
        ${intitialisations.join(" ")} ${dynamicInitializations.join(
          " "
        )} ${formula} `
      );
      if (typeof result === "number") {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  };

  const handleFormulaChange = (event) => {
    const enteredFormula = event.target.value;

    if (enteredFormula.includes("#")) {
      setAnchorEl(event.currentTarget);
    } else {
      let updatedFormula = enteredFormula;

      if (selectedGoalColumn) {
        const formattedColumn = selectedGoalColumn.replace(/#/g, "");
        updatedFormula += formattedColumn;

        setAnchorEl(null);
      }

      const isValid = formulaValidator(updatedFormula, ["value", "quantity"]);
      setIsFormulaValid(isValid);
      setFormula(updatedFormula);
    }
  };
  const handleGoalChange = (value) => {
    const goalInSelect = goals.find((goal) => goal.idgoal === value);
    setSelectedGoal(value);
  };
  const handlePercent = (e) => {
    setPercent(e.target.value);
  };
  const handlePeriodChange = (value) => {
    setselectedPeriod(value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const iduser = idUser;
    const idPeriodInArray = rule.periods && rule.periods.map((p) => p.idperiod);
    const idperiod = idPeriodInArray && idPeriodInArray[0];
    const idrule = rule.idrule;
    const idGoal = selectedGoal;

    if (!ruleName) {
      enqueueSnackbar("Veuillez entrer le nom de la règle.", {
        variant: "warning",
      });
      return;
    }

    if (!selectedGoal) {
      enqueueSnackbar("Veuillez sélectionner un objectif.", {
        variant: "warning",
      });
      return;
    }

    if (!formula) {
      enqueueSnackbar("Veuillez saisir une formule.", {
        variant: "warning",
      });
      return;
    }
    if (!isFormulaValid) {
      enqueueSnackbar("Veuillez saisir une formule valide.", {
        variant: "warning",
      });
      return;
    }

    dispatch(
      actionUpdateRule(
        idrule,
        ruleName,
        formula,
        percent,
        selectedPeriod,
        startDate,
        endDate,
        idGoal,
        token,
        (rule, result) => {
          if (rule) {
            onCancel();
            enqueueSnackbar("La règle a été modifiée avec succès.", {
              variant: "success",
            });
          } else {
            enqueueSnackbar(
              "Un problème a été rencontré lors de la modification de la règle.",
              {
                variant: "error",
              }
            );
          }
        }
      )
    );
  };

  const content = (
    <form onSubmit={onSubmit}>
      {" "}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
          minWidth: "200px",
        }}
      >
        <div className="">
          <label htmlFor="formule" className="label-default">
            Nom de la règle *
          </label>
          <Input
            required
            placeholder="Entrer le nom de la règle"
            value={ruleName}
            onChange={(e) => setRuleName(e.target.value)}
          />
        </div>
        <div className="flex flex-col gap-2">
          <label htmlFor="goal" className="label-default">
            Objectif associé à la règle
          </label>
          <div className="self-stretch flex gap-4">
            <div className="relative font-medium opacity-[0.8] text-xs rounded bg-[#CCF3FF]  flex flex-row items-center justify-center py-1 px-2 text-[#05BAF3]">
              SI
            </div>
            <div className="">
              {goals.length > 0 ? (
                <Select
                  required
                  placeholder="Sélectionner un objectif"
                  onChange={handleGoalChange}
                  value={selectedGoal}
                  className="w-[230px]"
                >
                  {goals.map((goal) => (
                    <Select.Option key={goal.idgoal} value={goal.idgoal}>
                      {goal.name}
                    </Select.Option>
                  ))}
                </Select>
              ) : (
                <Select placeholder="Aucun objectif disponible" disabled />
              )}
            </div>
            <div className="truncate w-full relative font-medium opacity-45 text-sm rounded bg-[#CCF3FF] flex flex-row items-center justify-center py-1 px-2 text-[#05BAF3]">
              est atteint à
            </div>
            <div className="w-[400px] rounded bg-[#A28FFC] flex items-center justify-center gap-1 px-2 text-sm font-bold text-white opacity-45">
              <input
                type="text"
                className="rounded w-full text-center bg-[#fff] text-[#989898] focus:outline-none "
                value={percent}
                onChange={handlePercent}
                required
              />
              <b className="">%</b>
            </div>
          </div>
          <div>
            <div className="flex gap-4 items-center">
              <div className="relative font-medium opacity-[0.8] text-xs rounded bg-[#CCF3FF] w-14 flex flex-row items-center justify-center py-1 px-2 text-[#05BAF3]">
                ALORS
              </div>
              <div className="relative font-medium opacity-45 text-sm rounded bg-[#A28FFC] w-24 flex flex-row items-center justify-center py-1 px-2 text-[#fff] ">
                Commission
              </div>
              <div className="relative font-medium opacity-[0.8] text-sm rounded bg-[#CCF3FF] w-14 flex flex-row items-center justify-center py-1 px-2 text-[#05BAF3]">
                =
              </div>
              <div className="flex-1">
                <TextArea
                  aria-describedby={id}
                  onChange={handleFormulaChange}
                  className={`rounded-lg ${
                    isFormulaValid === true ? "border-green-500" : ""
                  } ${
                    isFormulaValid === false
                      ? "border-red-500 animate-blink"
                      : ""
                  }`}
                  required
                  placeholder="Entrer la formule"
                  value={formula}
                  autoSize={{ minRows: 1, maxRows: 2 }}
                />
                {isFormulaValid === false && (
                  <div className="absolute text-red-500 mt-1">
                    La formule n'est pas valide.
                  </div>
                )}
                {isFormulaValid === true && (
                  <div className="absolute text-green-500 mt-1 ">
                    La formule est valide.
                  </div>
                )}
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <div className="mui-popover-custom">
                    <ul className="grid gap-2 grid-cols-1 items-center content-center">
                      {currentGoalCol &&
                        currentGoalCol["data"].map((column) => (
                          <li
                            key={column}
                            className="transition-all duration-300 hover:cursor-pointer hover:bg-[#05BAF3] hover:text-white opacity-45 bg-[#E3E3E3] rounded-lg text-center"
                            onClick={() => handleCurrentGoalCol(column)}
                          >
                            {column}
                          </li>
                        ))}
                    </ul>
                  </div>
                </Popover>
              </div>
            </div>
          </div>
        </div>

        <div className="input-container">
          <label htmlFor="periode" className="label-default">
            Période
          </label>
          <div className="flex flex-col gap-5 w-full">
            <CalendarView
              selectStyle={{ width: "100%" }}
              calendarStyle={{ width: "100%" }}
              value={periode}
              interval={interval}
              period={selectedPeriod}
              onChange={(interval, value) => {
                if (value) {
                  setPeriode(value);
                  setInterval(interval);
                  setStartDate(interval[0]);
                  setEndDate(interval[1]);
                  setselectedPeriod(null);
                }
              }}
              onPeriodChange={(value) => {
                if (value) {
                  setselectedPeriod(value);
                  setInterval([null, null]);
                  setStartDate(null);
                  setEndDate(null);
                }
              }}
            />
          </div>
        </div>
        <button className="btn-full" type="submit" disabled={isAddRule}>
          {isAddRule ? "Loading..." : "Soumettre la règle"}
        </button>
      </div>
    </form>
  );
  return (
    <>
      <Modal title="Modifier la règle" visible={visibility} onCancel={onCancel}>
        {content}
      </Modal>
    </>
  );
}
