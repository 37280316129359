import React from "react";
import styled from "styled-components";
import Chart from "react-google-charts";

let theme = "1";
const data = localStorage.getItem("theme");

if (data) {
  theme = JSON.parse(data).theme;
}

const SelectedObjectifs = ({ goals }) => {
  return (
    <div>
      <h2 className="label-default">Objectif(s) sélectionné(s)</h2>
      {goals.length === 0 ? (
        <EmptyIndicator>
          <h1
            className="label-default"
            style={{
              textAlign: "center",
              margin: 0,
            }}
          >
            Aucun objectif n'a été sélectionné
          </h1>
        </EmptyIndicator>
      ) : (
        <IndicatorContainer>
          {goals.map((goal, index) => (
            <Card key={index}>
              <CardHeader>
                <Chart
                  chartType="PieChart"
                  width="175px"
                  height="175px"
                  data={[
                    ["Task", "Hours per Day"],
                    ["current", goal.currentvalue],
                    [
                      "target",
                      goal.targetvalue > goal.currentvalue
                        ? goal.targetvalue - goal.currentvalue
                        : 0,
                    ],
                  ]}
                  legend_toggle={false}
                  legendToggle={false}
                  options={options}
                  style={{ overflow: "hidden", padding: 0 }}
                />
                <Text style={{ color: "#54B7ED" }}>
                  {goal.currentvalue === 0
                    ? 0
                    : Number(
                        (goal.currentvalue / goal.targetvalue) * 100
                      ).toFixed(1)}
                  %
                </Text>
              </CardHeader>
              <CardFooter>
                <SubTitle className="label-default" style={{ fontSize: 14 }}>
                  {goal.name}
                </SubTitle>

                <Paragraph className="label-default" style={{ fontSize: 13 }}>
                  Valeur actuelle:{" "}
                  {goal.currentvalue
                    ?.toString()
                    ?.replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
                </Paragraph>
              </CardFooter>
            </Card>
          ))}
        </IndicatorContainer>
      )}
    </div>
  );
};

const options = {
  pieHole: 0.9,
  is3D: false,
  backgroundColor: {
    stroke: theme === "0" ? "#ffffff" : "#e0e0e0",
    strokeWidth: 0,
    fill: theme === "0" ? "#ffffff" : "#e0e0e0",
  },
  chartArea: {
    stroke: theme === "0" ? "#ffffff" : "#e0e0e0",
    strokeWidth: 0,
  },
  legend: "none",
  pieSliceText: "none",
  pieStartAngle: 0,
  tooltip: { trigger: "none" },
  slices: {
    0: { color: "#1FAF38" },
    1: { color: theme === "0" ? "#e0e0e0" : "#ffffff" },
  },
};

const IndicatorContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(200px, 400px));
  grid-template-rows: 180px;
  gap: 10px;
  margin: 20px 0 40px;

  @media (max-width: 1100px) {
    grid-template-columns: repeat(3, minmax(200px, 400px));
  }
  @media (max-width: 800px) {
    grid-template-columns: repeat(2, minmax(200px, 400px));
  }
  @media (max-width: 550px) {
    grid-template-columns: repeat(1, minmax(200px, 550px));
  }
`;

const Card = styled.div`
  border-radius: 15px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
  overflow: hidden;
`;

const CardHeader = styled.div`
  background-color: ${theme === "0" ? "#ffffff" : "#e0e0e0"};
  height: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
`;

const Text = styled.h2`
  font-weight: bold;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 18px;
  color: "#54B7ED";
`;

const CardFooter = styled.div`
  height: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 10px;
  background: ${theme === "0" ? "#323541" : "#ffffff"};
`;

const SubTitle = styled.h3`
  font-weight: bold;
  font-size: 0.7rem;
  margin: 0;
`;

const Paragraph = styled.p`
  font-size: 0.7rem;
  color: #9295a1;
  margin: 0;
`;

const EmptyIndicator = styled.h1`
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: ${theme === "0" ? "#323541" : "#E0E0E0"};
`;

export default SelectedObjectifs;
